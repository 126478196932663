import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {client as apiClient} from 'utils/api';
import {useForm} from 'react-hook-form';
import {useMutation, useQuery} from 'react-query';
import {toast} from 'react-toastify';
import Button from 'Components/Common/Button';
import {Field} from 'Components/Common/Forms';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {
  companyUsers,
} from '../../../utils/queryKeys';
import {
  getCompanyUsers
} from '../../../utils/queries'
import CircularProgress from '@mui/material/CircularProgress'

function TeamForm(props) {
  const currentURL = useLocation()
  const navigate = useHistory();
  const [doDisable, setDoDisable] = useState(false);
  const [formData, setFormData] = useState({})


  // Action to use as label
  const path_breakdown = currentURL.pathname.split('/')
  const first = path_breakdown.pop()
  const action = path_breakdown.pop()
  const actionCap = action.charAt(0).toUpperCase() + action.slice(1);

  // FETCH USER LIST
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const users = useQuery([companyUsers, {companyId: companyId}], getCompanyUsers)

  const userOptions =
    users.isLoading ? <div className="lds-dual-ring"></div>
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"projectteam" + item.attributes.email + item.id} value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  // Form

  const notifySuccess = (msg) => {
    toast.success("Project " + msg + " saved successfully!")
  }

  const notifyError = (msg) => {
    toast.error("Project " + msg + " save unsuccessful")
  }

  const {register, handleSubmit, setValue, reset, setError, clearErrors, formState: {errors}} = useForm();

  // Update all fields
  const handleFormChange = (e) => {
    // Update the state(merge current data to the ones found in the state)
    setFormData({
      ...formData, [e.target.id]: e.target.value.trim()
    })
    // Set the value
    setValue(e.target.id, e.target.value.trim())
  }

  async function postForm(data) {
    const {user_id} = data

    if (action === 'manager') {
      return await apiClient.patch("/projects/" + props.props.projectId, {
        manager_id: parseInt(user_id)
      })
    } else if (action === 'approver') {
      return await apiClient.patch("/projects/" + props.props.projectId, {
        approver_id: parseInt(user_id)
      })
    }
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postForm, {
    onSuccess: (data, variables, context) => {
      setDoDisable(false)
      if (action === 'manager') {
        navigate.push("/projects/" + props.props.projectId + "/approver/first")
      } else if (action === 'approver') {
        navigate.push("/projects/" + props.props.projectId + "/image")
      }
    }
  });

  const onSubmit = async (data, e) => {
    try {
      setDoDisable(true)
      await sendData(data)
      notifySuccess(actionCap)
      reset()
      e.target.reset()
    } catch (err) {
      setDoDisable(false)
      notifyError(actionCap)
      console.error(err)
      throw new Error(err)
    }
  }


  return (
    <>
      {users.isLoading && !!users.data === false
        ? <div className="lds-dual-ring"></div> :
        <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {/* ASSIGNEE */}
          <Field>
            <label htmlFor="user_id" className="text-tenzingGray">Pick Project {actionCap}</label>
            <select
              id="user_id"
              placeholder="Assigned To"
              {...register("user_id", {required: true})}
              className={!errors.user_id ? "input-text" : 'input-text-error'}
              value={formData.user_id ?? formData?.user?.id ?? ""}
              onChange={handleFormChange}
            >
              {userOptions}
            </select>
            <FieldInputError item={errors.user_id}></FieldInputError>
          </Field>

          <section className="mt-4 flex justify-end">
            <Button buttonStyle={"orange-solid-button"}
                    disabled={isLoading || doDisable}>
              {
                doDisable ?
                  <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                  : <></>
              }
              Save & Continue
            </Button>
          </section>
        </form>
      }
    </>
  )
}

export default TeamForm



