import React from 'react'
import {useQuery} from 'react-query';
import Section from 'Components/Common/Section'
import BoxFileExplorer from 'Components/FileExplorer';
import {WideColumn} from 'Components/Common/Layout/ContentTwoColumn'
import {
  boxAccessToken,
  project as projectByIdQuery
} from "../../utils/queryKeys";
import {getBoxAccessToken, getProject} from "../../utils/queries";
import {useParams} from "react-router-dom";

function Index() {
  const {projectId} = useParams();

  const project = useQuery([projectByIdQuery, {id: projectId}], getProject)
  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  const bexpConfig = {
    boxable: project.data,
    root_folder_column: 'administration_box_folderid',
    access_token: boxAccess
  }
  return (
    <WideColumn>
      <Section height="h-screen">
        <div className='h-5/6'>
          <h1 className="Section-h1">Documents</h1>
          {
            !project.isLoading
            && project.isSuccess
            && !!project.data?.data ?
              <BoxFileExplorer props={bexpConfig}/> :
              null
          }
        </div>
      </Section>
    </WideColumn>
  )
}

export default Index
