import React, {useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from "react-router-dom"
import List from 'Components/Risks/List';
import {projectRisks as projectRisks} from 'utils/queryKeys';
import {getProjectRisks as getProjectRisks} from 'utils/queries';


function RiskOverview({
                        setRiskPersmissions,
                        columnsToHide
                      }) {

  const params = useParams()

  // Get all the risks
  const risksQuery = useQuery(
    [projectRisks, {projectId: params['projectId']}],
    getProjectRisks
  )

  useEffect(() => {
    if (!risksQuery.isLoading && risksQuery.isSuccess) {
      setRiskPersmissions(risksQuery.data?.permissions)
    }
  }, [!risksQuery.isLoading && risksQuery.isSuccess])

  if (risksQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <section>
      {
        risksQuery.isSuccess
        && risksQuery.data?.data?.length > 0 ?
          <List items={risksQuery.data?.data}
                columnsToHide={columnsToHide}/> :
          <tr>
            <td colSpan="6"><p>No Risks</p></td>
          </tr>
      }
    </section>
  )
}

export default RiskOverview;
