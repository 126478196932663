import React from 'react'

const Button = ({ children, buttonStyle, onClick, disabled, type }) => {

  return(
    <button onClick={onClick}
            className={buttonStyle}
            disabled={disabled}
            type={type}>
        <h3 className="flex items-center">{children}</h3>
    </button>
  )
}

export default Button
