import axios from "axios"
import {
  box_host_name_upload,
  box_host_name_api,
  box_api_version_2
} from "../globals";

//curl -i -X POST "https://upload.box.com/api/2.0/files/content" \
//      -H "Authorization: Bearer <ACCESS_TOKEN>" \
//      -H "Content-Type: multipart/form-data" \
//      -F attributes='{"name":"Contract.pdf", "parent":{"id":"11446498"}}' \
//      -F file=@<FILE_NAME>
// GET AN access token

export const boxClientUpload = axios.create({
  baseURL: box_host_name_upload + box_api_version_2,
  headers: {
    // "Authorization": `Bearer ${access_token}`,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  },
  transformResponse: [function (data) {
    const responseData = JSON.parse(data)
    return responseData
  }],
})

export const boxClient = axios.create({
  baseURL: box_host_name_api,
  headers: {
    // "Authorization": `Bearer ${access_token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  transformResponse: [function (data) {
    const responseData = JSON.parse(data)
    return responseData
  }],
})

