import React, {useEffect, useState, useRef} from "react";
import moment from 'moment'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const SmallCalendar = ({handleCalendarChange}) => {
  const counter = useRef(0)

  // https://www.geeksforgeeks.org/moment-js-moment-month-method/
  // XXX The months in Moment.js are zero-indexed, therefore the range of the months is 0 to 11,
  //     with 0 being January and 11 being December.
  //     A value higher than 11 would make it bubble up to the next year.
  let initialDateObjects = [
    moment().add(0, 'M'),
    moment().add(1, 'M'),
    moment().add(2, 'M'),
    moment().add(3, 'M')
  ]

  const [calendarDates, setCalendarDates] = useState(initialDateObjects)

  const getNextRange = () => {
    counter.current++
    let newRange = calendarDates.map((_, idx) => moment().add((idx + counter.current), 'M'))
    setCalendarDates(newRange)
  }

  const getPreviousRange = () => {
    counter.current--
    let newRange = calendarDates.map((_, idx) => moment().add((idx + counter.current), 'M'))
    setCalendarDates(newRange)
  }

  return (
    <div className="w-64 h-full">
      <div className="flex space-evenly justify-center my-3">
        <button className="orange-solid-button" onClick={() => getPreviousRange()}>Prev</button>
        <button className="orange-solid-button" onClick={() => {
          counter.current = 0
          setCalendarDates(initialDateObjects)
        }}>Reset</button>
        <button className="orange-solid-button" onClick={() => getNextRange()}>Next</button>
      </div>
      {calendarDates.map((myDate) => {
        return (
          <Calendar
            key={myDate.toDate().toString()}
            className={`small-calendar mb-4 ${myDate.month() === moment().month() && myDate.year() === moment().year() ? "current-month" : ""}`}
            calendarType={"US"}
            activeStartDate={myDate.toDate()}
            showNeighboringMonth={false}
            nextLabel={null}
            next2Label={null}
            prevLabel={null}
            prev2Label={null}
            maxDetail="month"
            minDetail="month"
            onChange={(e) => handleCalendarChange(e)}
          />
        )
      })}
    </div>
  )
}

export default SmallCalendar; 
