import React, {useState, useEffect} from 'react'
import {
  Switch,
  Route,
  Link,
  useLocation,
  useParams
} from 'react-router-dom'
import Collapsible from 'Components/Common/Collapsible'
import Section from 'Components/Common/Section'
import Schedule from 'Components/Schedule'
import NewActivityForm from 'Components/Schedule/Form/New'
import EditActivityForm from 'Components/Schedule/Form/Edit'
import {
  scheduleURL,
  scheduleOwnersURL,
  scheduleDesignURL,
  scheduleContractorURL
} from 'utils/urls'
import OwnerSchedule from '../../Components/Schedule/Files/OwnerSchedule'
import DesignSchedule from '../../Components/Schedule/Files/DesignSchedule'
import ContractorSchedule from '../../Components/Schedule/Files/ContractorSchedule'
import {
  activityTypeValues,
  getRandomInt
} from '../../Components/Schedule/Resources/index'


function Index() {
  const [showForm, setShowForm] = useState(false)
  const [currentForm, setCurrentForm] = useState(null)
  const [activityToEdit, setActivityToEdit] = useState(null)
  const [activitiesPermissions, setActivitiesPermissions] = useState(null)
  const [reloadSchedule, setReloadSchedule] = useState(false)


  const currentURL = useLocation()
  const params = useParams();

  const openNewForm = (setShowForm, setReloadSchedule) => {
    setCurrentForm(
      <NewActivityForm
        setShowForm={setShowForm}
        setReloadSchedule={setReloadSchedule}
      />)
    setShowForm(true)
  }

  useEffect(() => {
    if (activityToEdit != undefined) {
      openEditForm(activityToEdit.taskId, activityToEdit.activityType, setShowForm, setReloadSchedule, showForm)
    }
  }, [activityToEdit])

  const openEditForm = (id, type, setShowForm, setReloadSchedule, showForm) => {
    setCurrentForm(
      <EditActivityForm
        setShowForm={setShowForm}
        activityId={id}
        activityType={type}
        setReloadSchedule={setReloadSchedule}
        showForm={true}
      />)
    setShowForm(true)
  }


  return (
    <div className="flex flex-col order-last xl:flex-row xl:order-first">
      <div className="w-full">
        <Section>
          <section className="flex flex-row space-x-8 mb-4">
            <Link to={scheduleURL(params['projectId'])}
                  className={`Section-h1 ${currentURL.pathname === scheduleURL(params['projectId']) ? "" : "text-lightGray"}`}>
              Project Schedule
            </Link>
            <Link to={scheduleOwnersURL(params['projectId'])}
                  className={`Section-h1 ${currentURL.pathname === scheduleOwnersURL(params['projectId']) ? "" : "text-lightGray"}`}>
              Owners Schedule
            </Link>
            <Link to={scheduleDesignURL(params['projectId'])}
                  className={`Section-h1 ${currentURL.pathname === scheduleDesignURL(params['projectId']) ? "" : "text-lightGray"}`}>
              Design Team Schedule
            </Link>
            <Link to={scheduleContractorURL(params['projectId'])}
                  className={`Section-h1 ${currentURL.pathname === scheduleContractorURL(params['projectId']) ? "" : "text-lightGray"}`}>
              Contractor Schedule
            </Link>
          </section>
          <Switch>
            <Route exact path="/projects/:projectId/schedule"
                   component={() => (<ProjectSchedule setActivitiesPermissions={setActivitiesPermissions}
                                                      activitiesPermissions={activitiesPermissions}
                                                      setActivityToEdit={setActivityToEdit}
                                                      reloadSchedule={reloadSchedule}
                                                      setReloadSchedule={setReloadSchedule}/>)}/>
            <Route exact path="/projects/:projectId/schedule/owners"
                   component={() => (<OwnerSchedule activitiesPermissions={activitiesPermissions}/>)}/>
            <Route exact path="/projects/:projectId/schedule/design"
                   component={() => (<DesignSchedule activitiesPermissions={activitiesPermissions}/>)}/>
            <Route exact path="/projects/:projectId/schedule/contractor"
                   component={() => (<ContractorSchedule activitiesPermissions={activitiesPermissions}/>)}/>
          </Switch>
        </Section>

        <Section>
          <section className="flex justify-end px-2">
            {
              activitiesPermissions?.['create'] ?
                <button className="orange-solid-button" onClick={() => openNewForm(setShowForm, showForm)}>Add New Activity</button>
                : null
            }
          </section>
          <section className="border border-outlineGray my-2">
            {
              activityTypeValues.map(category => {
                return (
                  <Collapsible open={true}
                               key={getRandomInt(356456)}
                               title={category.name}>
                    <Schedule activityType={category.value}
                              setActivitiesPermissions={setActivitiesPermissions}
                              activitiesPermissions={activitiesPermissions}
                              setActivityToEdit={setActivityToEdit}
                              reloadSchedule={reloadSchedule}
                              setReloadSchedule={setReloadSchedule}/>
                  </Collapsible>
                )
              })
            }
          </section>
        </Section>
      </div>
      {showForm ?
        <div className='order-first xl:order-last xl:w-1/3'>
          <div className="ml-2">
            {currentForm}
          </div>
        </div>
        : null
      }
    </div>
  )
}


function ProjectSchedule({
                           setActivitiesPermissions,
                           activitiesPermissions,
                           setActivityToEdit,
                           reloadSchedule,
                           setReloadSchedule
                         }) {
  return (
    <div className="border border-outlineGray my-2">
      <Schedule title="Overview"
                view="schedule_overview"
                setActivitiesPermissions={setActivitiesPermissions}
                activitiesPermissions={activitiesPermissions}
                setActivityToEdit={setActivityToEdit}
                reloadSchedule={reloadSchedule}
                setReloadSchedule={setReloadSchedule}/>
    </div>
  )
}


export default Index
