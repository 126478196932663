import React, {useState} from 'react';
import {useMutation} from "react-query";
import {moduleToTaskPatch} from "../../../utils/queries";

const ModuleToTask = ({
                        moduleId,
                        moduleQuery,
                        setStepsConfig,
                        setCurrentTask,
                        setCurrentTaskId,
                        setTasksConfig,
                        setTaskCreated,
                        locked
                      }) => {
  const [hideToTask, setHideToTask] = useState(false)
  const handleClick = (event) => {
    // XXX Actually i do not need this. I just add it in order to make the request
    //     Then the backend will ignore it.
    const tdata = {
      order: moduleQuery.data?.data?.attributes?.order
    }

    patchProcessModule([moduleToTaskPatch, {moduleId: parseInt(moduleId), data: tdata}])
  }

  // Update Task
  const {isLoading: moduleToTaskLoading, mutateAsync: patchProcessModule} = useMutation(moduleToTaskPatch, {
    onSuccess: (data, variables, context) => {
      setCurrentTask(data?.data?.data?.attributes?.task)
      setCurrentTaskId(data?.data?.data?.attributes?.task?.id)
      setTasksConfig(true)
      setHideToTask(true)
      setTaskCreated(true)
    },
    onError: (error, variables, context) => {
      console.log('Failed to link Task to Phase.', error)
    }
  });

  if (moduleToTaskLoading) {
    setStepsConfig(false)
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <>
      {
        !hideToTask
        && !locked
        && !moduleQuery.isLoading
        && moduleQuery.isSuccess
        && moduleQuery.data?.data?.attributes?.task == null
        && moduleQuery.data?.data?.attributes?.process_steps?.length === 0 ?
          <div className="bg-tenzingOrange
                          flex
                          text-white
                          cursor-pointer
                          text-center
                          justify-center
                          font-bold"
               data-modulename={moduleQuery.data?.data?.attributes?.module_pool.name}
               onClick={handleClick}>
            TO TASK
          </div>
          : null
      }
    </>
  )
}

export default ModuleToTask;
