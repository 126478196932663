// Contract Tables

import moment from "moment/moment";

export const headCells = [

  {
    id: 'category',
    numeric: false,
    disablePadding: true,
    label: 'Category',
  },
  {
    id: 'subCategory',
    numeric: false,
    disablePadding: true,
    label: 'Sub Category',
  },
  {
    id: 'categoryBreakdown',
    numeric: false,
    disablePadding: true,
    label: 'Category Breakdown',
  },
  {
    id: 'lineItem',
    numeric: false,
    disablePadding: false,
    label: 'Line Item',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Contract #',
  },
  {
    id: 'firm_name',
    numeric: false,
    disablePadding: false,
    label: 'Firm Name',
  },
  {
    id: 'commitment_date',
    numeric: false,
    date: true,
    disablePadding: false,
    label: 'Commitment Date',
  },
  {
    id: 'commitment_value',
    numeric: false,
    disablePadding: false,
    label: 'Commitment Value',
  }, 
  {
    id: 'coi_expiration',
    numeric: false,
    date: true,
    disablePadding: false,
    label: 'COI Expiration',
  }
];

export const formatAmount = (amount) => {
  return `$${parseFloat(amount || 0).toLocaleString('en-US')}`
}

export const formFields = [
  'name',
  'project',
  'line_item',
  'firm_name',
  'commitment_date',
  'commitment_value_cents',
  'coi_expiration'
]

export const defaultValues = {
  line_item_id: 0,
  firm_name: "",
  commitment_date: null,
  commitment_value_cents: "", 
  coi_expiration: null
}

export const formatDate = (date) => {
  if (date == undefined || date === '') {
    return ''
  }
  return moment(date).toDate()
}
