import React, {useEffect, useState} from 'react'
import
{
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import {
  getComparator,
  stableSort
} from '../Utils/index'
import {project} from "utils/queryKeys"
import {getProject} from "utils/queries";
import {useQuery} from "react-query";

function CheckBoxList({
                        users,
                        projectId,
                        setSelectedUsers,
                        selectedUsers
                      }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');


  const projectQuery = useQuery([project, {id: projectId}], getProject)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // On check add the id to the table, on uncheck remove it
  const handleCheckedChange = (event) => {
    if (event.target.checked) {
      setSelectedUsers([...selectedUsers, event.target.dataset.userid])
    } else {
      setSelectedUsers(selectedUsers.filter(userid => userid !== event.target.dataset.userid))
    }
  }

  if (projectQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }


  return (
    <React.Fragment>
      <Box sx={{width: '100%', borderColor: 'grey'}}>
        <Paper sx={{width: '100%', mb: 2}}>
          <TableContainer>
            <Table stickyHeader>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                isDefault={false}
              />
              <TableBody>
                {stableSort(users, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user.id.toString()}>
                      <TableCell align="left">{user.id}</TableCell>
                      <TableCell> {user.attributes["email"]} </TableCell>
                      <TableCell>
                          <span
                            className={user.attributes['status'] === 'removed' ? "bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded capitalize" : ""}>
                            {user.attributes['status']}
                          </span>
                      </TableCell>
                      <TableCell>{user.attributes.user_role['name']}</TableCell>
                      <TableCell>{user.attributes.contact['first_name']} {user.attributes.contact['last_name']}</TableCell>
                      <TableCell>
                        <div className="flex align-center">
                          <input
                            id={`checkbox-${user.id}`}
                            type="checkbox"
                            data-userid={user.id}
                            checked={selectedUsers?.includes(user.id)}
                            className="mr-1 w-4 h-4 text-orange-500 bg-gray-100 border-gray-300 rounded focus:ring-orange-500 focus:ring-0"
                            onChange={handleCheckedChange}
                          />
                          <label
                            htmlFor={`checkbox-${user.id}`}>{projectQuery?.data?.data?.attributes?.["name"]} Member</label>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default CheckBoxList
