import React, {useEffect, useRef, useState} from 'react';
import {useQuery, useQueryClient} from "react-query";
import {processModule} from "../../../utils/queryKeys";
import {getProcessModule} from "../../../utils/queries";
import ModuleToTask from "./moduleToTask";
import DuplicateProcessModule from "./duplicateProcessModule";
import {getPhaseStatusColor} from "../Utils";

const ModuleBox = ({
                     id,
                     module_pool_id,
                     currentModId,
                     modClick,
                     children,
                     autoSelectMod,
                     firstMod,
                     onModulesConfigUpdate,
                     setStepsConfig,
                     setCurrentTask,
                     setCurrentTaskId,
                     setTasksConfig,
                     stepUpdated,
                     setStepUpdated,
                     moduleUpdated,
                     setModuleCopied,
                     moduleCopied,
                     locked,
                     taskUpdated,
                     setTaskUpdated
                   }) => {
  const [taskCreated, setTaskCreated] = useState(false)
  const [duplicateProcessModuleIsLoading, setDuplicateProcessModuleIsLoading] = useState(false)
  let modRef = useRef()
  const queryClient = useQueryClient();

  const moduleQuery = useQuery(
    [processModule, {moduleId: id}],
    getProcessModule, {
      retry: 0,
      cacheTime: (moduleUpdated || moduleCopied || taskCreated || stepUpdated) ? 0 : 300000,
      staleTime: (moduleUpdated || moduleCopied || taskCreated || stepUpdated) ? 0 : 300000,
    })

  useEffect(() => {
    if (autoSelectMod && firstMod) {
      modRef.current.click()
    }
    onModulesConfigUpdate(false)
  }, [])

  useEffect(() => {
    if (moduleUpdated || moduleCopied || taskCreated || stepUpdated) {
      queryClient.invalidateQueries([processModule, {moduleId: currentModId}])
      setTaskCreated(false)
      setStepUpdated(false)
    }
  }, [moduleUpdated || moduleCopied || taskCreated || stepUpdated])

  useEffect(() => {
    if (taskUpdated) {
      queryClient.invalidateQueries([processModule, {moduleId: currentModId}])
      setTaskUpdated(false)
    }
  }, [taskUpdated])

  return (
    <div>
      {
        !moduleQuery.isLoading
        && moduleQuery.isSuccess
        && moduleQuery.data?.data?.attributes != null ?
          <span className="flex flex-col items-center relative z-0">
                <span onClick={() => modClick(id, module_pool_id, moduleQuery)}
                      key={"box-module" + id.toString()}
                      ref={firstMod ? modRef : null}
                      className={`
                              block
                              p-1 
                              mt-8
                              z-10
                              w-44
                              h-16
                              cursor-pointer
                              text-center
                              font-heading
                              leading-none
                              flex
                              items-center
                              justify-center
                              border-4 border-darkGray 
                              uppercase 
                              ${currentModId === id ? "bg-darkGray text-white" : `${getPhaseStatusColor(moduleQuery?.data?.data?.attributes?.['status'])} text-white`}
                          `}>
                  <h2>
                    {moduleQuery?.data?.data?.attributes?.module_pool["name"] + (moduleQuery?.data?.data?.attributes?.['subname'] != undefined ? "-" + moduleQuery?.data?.data?.attributes?.['subname'] : "")}
                  </h2>
                </span>
            {
              !duplicateProcessModuleIsLoading
              && moduleQuery?.data?.data?.attributes?.task == null
              && moduleQuery?.data?.data?.attributes?.process_steps?.length > 0 ?
                <span
                  className={currentModId === id ? `h-6 w-6 inline-block absolute ${(locked || !moduleQuery.data?.permissions?.['update']) ? '-bottom-3' : '-bottom-9'} bg-darkGray rotate-45 z-0` : ""}>
                </span>
                : null
            }
              </span>
          : null
      }
      {
        currentModId === id
          ? <>
            <ModuleToTask moduleId={currentModId}
                          moduleQuery={moduleQuery}
                          setStepsConfig={setStepsConfig}
                          setCurrentTask={setCurrentTask}
                          setCurrentTaskId={setCurrentTaskId}
                          setTasksConfig={setTasksConfig}
                          setTaskCreated={setTaskCreated}
                          locked={locked}/>
            <DuplicateProcessModule moduleId={currentModId}
                                    moduleQuery={moduleQuery}
                                    setStepsConfig={setStepsConfig}
                                    setDuplicateProcessModuleIsLoading={setDuplicateProcessModuleIsLoading}
                                    setModuleCopied={setModuleCopied}
                                    locked={locked}/>
          </>
          : null
      }
    </div>
  )
}

export default ModuleBox;


