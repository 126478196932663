import React from 'react'
import * as MuiIcons from "@mui/icons-material";

// Nav Bar Description
export const navBarConfig = [
  {
    name: "User Home",
    route: "/",
    icon: 'Settings',
    type: 'flat'
  },
  {
    name: "My Projects",
    route: "/",
    icon: {
      closed: 'ArrowDropDown',
      opened: 'ArrowDropUp'
    },
    type: 'nested'
  },
  {
    name: "All My Tasks",
    route: "/tasks/mine_all",
    type: 'flat'
  },
  {
    name: "Correspondence",
    route: "/correspondence",
    type: 'flat'
  },
  {
    name: "Documents",
    route: "/documents",
    type: 'flat'
  },
  {
    name: "Process",
    route: "/process",
    type: 'flat'
  },
  {
    name: "Forms & Templates",
    route: "/templates",
    type: 'flat'
  }
]

// Calculate the Menu Icon Dynamically
export const calcIcon = (icon, status) => {
  if (icon == undefined) {
    return null
  }
  if (typeof icon === 'string') {
    return React.createElement(MuiIcons[icon])
  }

  return status ? React.createElement(MuiIcons[icon.opened])
    : React.createElement(MuiIcons[icon.closed]);
}

// Calculate the project Value
export const calcProjectName = (menuItem) => {
  if (window.localStorage.getItem("projectValue") == undefined
    || menuItem.type == 'flat') {
    return menuItem.name
  }
  const projectOb = JSON.parse(window.localStorage.getItem("projectValue"))
  return projectOb.label
}

export const customDropdownStyles = {
  color: 'white',
  textTransform: 'none',
  fontFamily: 'Raleway, sans-serif',
  backgroundColor: 'transparent',
  borderRadius: '0px',
  borderBottom: '0.25em solid transparent',
  fontSize: '1rem',
  marginBottom: '1px',
  "&:hover": {
    color: '#E66B19',
    borderBottom: '0.25em solid #E66B19',
    backgroundColor: 'transparent'
  }
}

export const customDropdownStylesActive = {
  color: 'white',
  textTransform: 'none',
  fontFamily: 'Raleway, sans-serif',
  backgroundColor: 'transparent',
  borderRadius: '0px',
  borderBottom: '0.25em solid #E66B19',
  fontSize: '1rem',
  marginBottom: '1px',
  "&:hover": {
    color: '#E66B19',
    borderBottom: '0.25em solid #E66B19',
    backgroundColor: 'transparent'
  }
}
