import React from 'react'
import ReactDOM from 'react-dom'
import {makeMirageServer} from './mirage'
import {register as registerServiceWorker} from './serviceWorkerRegistration'
import {ReactQueryDevtools} from 'react-query/devtools'
import {AppProviders} from 'context/providers'
import AuthenticatedApp from './AuthenticatedApp'
import {BrowserRouter} from "react-router-dom";
import LoginForm from "./app/Pages/Login";


// if (process.env.NODE_ENV === "development") {
//   makeMirageServer({environment: "development"})
// }

const have_access = (!!localStorage.getItem("api_token")
                     && !!localStorage.getItem("company_id")
                     && !!localStorage.getItem("assignee_id"))

ReactDOM.render(
  <AppProviders>
    <BrowserRouter>
      {have_access ? <AuthenticatedApp/> : null}
      {!have_access ? <LoginForm/> : null}
      <ReactQueryDevtools/>
    </BrowserRouter>
  </AppProviders>
  , document.getElementById('app')
)

registerServiceWorker()
