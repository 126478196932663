import React from 'react';
import Button from "@mui/material/Button";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DuplicateAction = ({duplicateItem, item, index}) => {

  return (
    <Button
      sx={{
        color: '#3F4454',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '1em',
        width: '100%',
        backgroundColor: 'inherit',
        '&:hover': {
          backgroundColor: 'inherit'
        }
      }}
      aria-label="delete"
      onClick={() => duplicateItem(item.id)}
    >
      <ContentCopyIcon/>
      <span className="ml-2">Duplicate</span>
    </Button>
  );
};

export default DuplicateAction;
