import React, {useRef} from "react";
import {b64Decode} from '../Utils/Index'


// I pass an array which contains the list of mimetypes
// type/text and type/html
const ReadMail = ({mimetypes}) => {
  const found = useRef(false)
  const mimeLen = mimetypes.length

  return (
    <>
      {
        !!mimetypes
        && mimetypes.length > 0 ?
          mimetypes.map((datatype, index) => {
            if (datatype.mimeType == "text/html") {
              found.current = true
              return (
                <div className="mb-4"
                     key={index + '-' + Math.floor(Math.random() * 10000).toString()}
                     dangerouslySetInnerHTML={{__html: b64Decode(datatype.body.data)}}/>
              )
            } else if (mimeLen == 1 && datatype.mimeType == "text/plain") {
              return (
                <div className="mb-4"
                     key={index + '-' + Math.floor(Math.random() * 10000).toString()}>
                  {b64Decode(datatype.body.data)}
                </div>
              )
            }
          }) : null
      }
    </>
  )
}

export default ReadMail;
