import React, {useState,useEffect} from "react";
import {client as apiClient} from '../../../utils/api';
import {
  useStyles,
  reorder
} from '../../../utils/toolBox';
import DraggableList from '../../Draggable/DraggableList';
import Section from '../../../Components/Common/Section'
import Button from '../../../Components/Common/Button';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {phasePoolModulePool, projectPhases} from "../../../utils/queryKeys";
import {useQuery, useQueryClient} from "react-query";
import {getCompanyPhasePools} from "../../../utils/queries";


const PhaseDragList = () => {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const queryClient = useQueryClient();
  const params = useParams()


  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const phasePoolQuery = useQuery(
    [phasePoolModulePool, {companyId: companyId}],
    getCompanyPhasePools)

  // As soon as we get the data we need to transmogrify them into drag list compatible format
  const phase_pool_list =
    !phasePoolQuery.isLoading
    && phasePoolQuery.isSuccess
    && phasePoolQuery.data?.data?.length > 0
    && phasePoolQuery.data?.data?.map((item) => {
      return (
        {
          key: `Phase ${item.id}`,
          id: `${item.id}`,
          primary: item.attributes.name,
          secondary: item.attributes.description
        }
      )
    }, this);

  // Load the Phase Pool
  useEffect(() => {
    // check whether data exists
    if (!phasePoolQuery.loading
        && !!phasePoolQuery.data?.data) {
      setItems(phase_pool_list ?? [])
    }
  }, [!phasePoolQuery.loading && !!phasePoolQuery.data?.data])

  const onDragEnd = ({destination, source}) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };

  // Delete Phases from list
  const onDeleteItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  }

  const duplicateItem = (id) => {
    // todo
    console.log('item duplicated')
  }

  const notifySuccess = () => toast.success("Phases added successfully!");
  const notifyError = () => toast.error("Adding phases failed.")

  const {handleSubmit, formState: {errors}} = useForm();

  const resetPhases = () => {
    setItems(phase_pool_list ?? [])
  }

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await apiClient.post("/projects/" + params['projectId'] + "/phases/setup", {
        list: items,
        project_id: params['projectId']
      }).then((response) => {
        notifySuccess()
        setLoading(false)
        setDoDisable(false)
        // Reload Phases map
        queryClient.refetchQueries([projectPhases, {projectId: params['projectId']}]);
      }).catch((error) => {
        notifyError()
        setLoading(false)
        setDoDisable(false)
      });

    } catch (err) {
      notifyError()
      setLoading(false)
      setDoDisable(false)
      throw new Error(err)
    }
  }

  return (
    <Section>
      <div className="flex justify-between mb-4 xl:w-96">
        <h1 className="Section-h1">Phases</h1>
        <div className="flex flex-row">
          <Button onClick={() => resetPhases()} buttonStyle={"orange-transparent-button"}>
            Reset
          </Button>
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                    disabled={loading || doDisable}>
              {
                doDisable ?
                  <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                  : <></>
              }
              Add All
            </Button>
          </form>
        </div>
      </div>
      <div className={useStyles.root}>
        <div className={useStyles.flexPaper}>
          <DraggableList type="Phases"
                         onDeleteItem={onDeleteItem}
                         duplicateItem={duplicateItem}
                         items={items}
                         onDragEnd={onDragEnd}/>
        </div>
      </div>
    </Section>
  );

}

export default PhaseDragList;

