import React from 'react'
import * as MuiIcons from "@mui/icons-material";

export const sidebarConfig = [
  {
    name: "Home",
    route: "home",
    icon: "Home"
  }, 
  {
    name: "Project Administration",
    route: "files",
    icon: "Settings"
  },
  {
    name: "Project Cost Management", 
    route: "costs", 
    icon: "MonetizationOn"
  }, 
  {
    name: "Project Risks", 
    route: "risks", 
    icon: "GppMaybe"
  }, 
  {
    name: "Project Schedule", 
    route: "schedule", 
    icon: "WaterfallChart"
  }, 
  {
    name: "Project Tasks", 
    route: "tasks", 
    icon: "Assignment"
  }, 
  {
    name: "Project Correspondence", 
    route: "correspondence", 
    icon: "ContactMail"
  },
  {
    name: "Project Documents", 
    route: "documents", 
    icon: "Topic"
  },
  {
    name: "Project Process Map", 
    route: "process", 
    icon: "AccountTree"
  },
  {
    name: "Project Contacts", 
    route: "contacts", 
    icon: "Contacts"
  },
  {
    name: "Project Calendar", 
    route: "calendar", 
    icon: "CalendarToday"
  }
]

// Calculate the Menu Icon Dynamically
export const calcIcon = (icon) => {
  if (icon == undefined) {
    return null
  }
  if (typeof icon === 'string') {
    return React.createElement(MuiIcons[icon])
  }
}