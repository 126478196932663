import {Box, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import React from "react";
import {headCells} from '../Utils/index'

function EnhancedTableHead({
                             order,
                             orderBy,
                             onRequestSort,
                             isDefault = true
                           }) {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // Remove the extra column if not required
  let tableHeads = headCells
  if (isDefault) {
    tableHeads = headCells.filter(headCell => headCell.default === true)
  }


  return (
    <TableHead>
      <TableRow>
        {tableHeads.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id ? 'left' : 'center'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
