import React from 'react'
import { client as apiClient } from 'utils/api';
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import Button from 'Components/Common/Button';
import { FieldInputError } from 'Components/Common/Forms/Errors';
import { Field } from 'Components/Common/Forms';
import {toast} from "react-toastify";
import {companyContacts} from "../../../utils/queryKeys";

function Form() {
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  // FORM
  const queryClient = useQueryClient();

  const {register, handleSubmit, reset, setError, formState: {errors}} = useForm();

  const notifySuccess = () => toast.success("New contact added successfully!");
  const notifyError = () => toast.error("New contact failed.")

  async function postForm(data) {
    const {first_name, last_name, title, phone, address} = data

    return await apiClient.post("/contacts", {
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      title: title.trim(),
      phone: phone.trim(),
      address: address.trim()
    })
  }

  const { isLoading, mutateAsync: sendData } = useMutation(postForm);

  const onSubmit = async (data, e) => {
    try {
      await sendData(data)
      await queryClient.invalidateQueries([companyContacts, {companyId: companyId}])
      notifySuccess()
      reset()
      e.target.reset()
    } catch (err) {
      notifyError()
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, { type: error.status, message: error.detail })
      })
      throw new Error(err)
    }
  }

  return(
    <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

      <Field>
        <label htmlFor="first_name" className="text-tenzingGray">First Name</label>
        <input
          id="first_name"
          type="text"
          placeholder="First Name"
          {...register("first_name", {
            required: true,
            pattern: { value: /[A-Za-z]{2}/, message: "Required string with minimum two Characters" }
          })}
          className={!errors.first_name ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.first_name}></FieldInputError>
      </Field>

      <Field>
        <label htmlFor="last_name" className="text-tenzingGray">Last Name</label>
        <input
          id="last_name"
          type="text"
          placeholder="Last Name"
          {...register("last_name", {
            required: true,
            pattern: { value: /[A-Za-z]{2}/, message: "Required string with minimum two Characters" }
          })}
          className={!errors.last_name ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.last_name}></FieldInputError>
      </Field>

      <Field>
        <label htmlFor="title" className="text-tenzingGray">Title</label>
        <input
          id="title"
          type="text"
          placeholder="Title"
          {...register("title", {
            required: true,
            pattern: { value: /[A-Za-z]{2}/, message: "Required string with minimum two Characters" }
          })}
          className={!errors.title ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.title}></FieldInputError>
      </Field>


      <Field>
        <label htmlFor="phone" className="text-tenzingGray">Phone Number</label>
        <input
          id="phone"
          type="text"
          placeholder="Phone"
          {...register("phone", {
            required: true,
            pattern: { value: /^[(]\d{3}[)]\s\d{3}[-]\d{4}$/, message: "Valid phone format is \"(nnn) nnn-nnnn\"" }
          })}
          className={!errors.phone ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.phone}></FieldInputError>
      </Field>

      <Field>
        <label htmlFor="address" className="text-tenzingGray">Address</label>
        <input
          id="address"
          type="text"
          placeholder="Address"
          {...register("address", {
            required: true
          })}
          className={!errors.address ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.address}></FieldInputError>
      </Field>

      <section className="mt-4 flex justify-end">
        <Button buttonStyle="orange-solid-button" disabled={isLoading}>Add</Button>
      </section>
    </form>
  )
}

export default Form
