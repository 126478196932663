import React, {useEffect, useCallback} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {Xwrapper} from 'react-xarrows';
import {
  companyUserRoles,
  moduleSteps,
} from "../../../utils/queryKeys";
import TaskBox from './taskBox'
import DrawArrows from './taskArrows'
import {getCompanyUserRoles, getProcessStepForModule} from "../../../utils/queries";
import {useResizeDetector} from 'react-resize-detector';


const TaskGrid = ({
                    currentTaskId,
                    setCurrentTask,
                    setCurrentTaskId,
                    currentPhaseId,
                    currentModId,
                    setStepsConfig,
                    stepsConfig,
                    setTasksConfig,
                    taskUpdated,
                    setTaskUpdated,
                    lock,
                    setLock
                  }) => {

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const queryClient = useQueryClient();

  const onResize = useCallback(() => {
    // on resize logic
  }, []);

  const { width, height, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 800,
    onResize
  });


  useEffect(() => {
    queryClient.refetchQueries([moduleSteps, {processModuleId: currentModId}])
  }, [taskUpdated])

  // Fetch the user Roles
  const roleQuery = useQuery([companyUserRoles, {companyId: companyId}], getCompanyUserRoles)

  // Fetch the Steps for the current Module
  const stepQuery = useQuery(
    [moduleSteps, {processModuleId: currentModId}],
    getProcessStepForModule,
    {enabled: !!currentModId})

  useEffect(() => {
    if (!stepQuery.isLoading
      && stepQuery.isSuccess
      && !!stepQuery.data?.data
      && stepQuery.data.data.length > 0) {
      // Hide Process Step drag list
      setStepsConfig(false)
    }
  }, [!stepQuery.isLoading
  && stepQuery.isSuccess
  && !!stepQuery.data?.data
  && stepQuery.data.data.length > 0])

  if (stepQuery.isLoading
    || roleQuery.isLoading) {
    return (<span className="block lds-dual-ring"></span>)
  }

  return (
    <>
      {
        stepQuery.data?.data.length
        && stepQuery.data?.data?.length > 0 ?
          <section>
            <Xwrapper>
              <table id="taskGrid"
                     ref={ref}
                     className="mt-8 w-full"
                     style={{position: "relative"}}>
                <thead>
                <tr>
                  {stepQuery.isLoading ? <span className="block lds-dual-ring"></span> :
                    <>
                      {/* Empty cell at the start of the headers */}
                      <th className="w-10 border border-lightGray"></th>
                      {/* Render the header cells */}
                      {stepQuery.data.data.map((step, i) => {
                        return (
                          <th colSpan={step.attributes.step_pool.num_of_grid_columns}
                              key={"process_step_box-" + step.id.toString() + "-i-" + i.toString()}
                              id={step.id + "-i-" + i.toString()}
                              className="p-3 text-center bg-darkGray text-white border break-normal border-lightGray">
                            {step.attributes.step_pool['name']}
                          </th>
                        )
                      })}
                    </>
                  }
                </tr>
                </thead>
                <tbody>
                {!roleQuery.data.length ? <></> :
                  roleQuery.data.map((role) => {
                    if (!['Project Manager',
                      'Architect/Engineer',
                      'Owner(Client)',
                      'Project Director',
                      'Consultant',
                      'Contractor'].includes(role.attributes["name"])) {
                      return false;
                    }
                    return (
                      <tr key={"process_role-" + role.id.toString()} className="h-44 border border-lightGray">
                        <td className="p-3 bg-outlineGray border border-lightGray">
                              <span
                                className="block w-10 -rotate-90 text-ellipsis break-normal ">{role.attributes["name"]}</span>
                        </td>
                        {stepQuery.data.data.map(step => {
                          // Save one copy of each step in a temp array
                          const num_of_grid_columns = step.attributes.step_pool.num_of_grid_columns
                          let column_step_grid = new Array(num_of_grid_columns).fill(step)

                          return (
                            <React.Fragment>
                              {
                                column_step_grid.map((step, i) => {
                                    return (
                                      <td
                                        key={"process_step-" + step.id.toString() + "-" + step.attributes.step_pool['name'] + "-" + i.toString()}
                                        className={(num_of_grid_columns > 1 && i < (num_of_grid_columns - 1)) ? "p-2 border-r border-r-transparent" : "p-2 border-r border-lightGray"}>
                                        {stepQuery.data.included.length > 0 ?
                                          stepQuery.data.included.map((task) => {
                                            if (task.attributes.process_task_pool.user_role_id == role.id
                                              && task.attributes.process_step.id == step.id
                                              && task.attributes.process_task_pool.grid_step_column == (i + 1)) {
                                              return (
                                                <TaskBox
                                                  key={task.id + "-task-role" + role.id}
                                                  task={task}
                                                  setCurrentTask={setCurrentTask}
                                                  setCurrentTaskId={setCurrentTaskId}
                                                  setTasksConfig={setTasksConfig}
                                                  currentTaskId={currentTaskId}
                                                  taskUpdated={taskUpdated}
                                                  setTaskUpdated={setTaskUpdated}
                                                ></TaskBox>
                                              )
                                            }
                                          })
                                          : null
                                        }
                                      </td>
                                    )
                                  }
                                )
                              }
                            </React.Fragment>
                          )
                        })}
                      </tr>
                    )
                  })
                }
                </tbody>
                {
                  !stepQuery.isLoading
                  && stepQuery.isSuccess
                  && stepQuery.data?.included?.length > 0 ?
                    <DrawArrows  tasks={stepQuery.data.included}
                                 width={width}
                                 height={height}
                    /> : null
                }
              </table>
            </Xwrapper>
          </section>
          : <section></section>
      }
    </>
  )
}

export default TaskGrid;
