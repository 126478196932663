import React from 'react';
import { Link } from 'react-router-dom';
import { costsURL } from 'utils/urls'
import ProgressBar from 'Components/Common/ProgressBar';
import currency from 'currency.js';

function CostSummary({project, isSmall}) {

  const projectID = project.id 
  
  if(project.attributes){
    project = project.attributes
  } else {
    project = project
  }
  const costRatio = project["anticipated_cost_vs_approved_budget"];
  const anticipatedCost = project["anticipated_cost"];
  const approvedBudget = project["approved_budget"];

  let anticipatedApprovedStatus = "On Budget";
  if (anticipatedCost > approvedBudget) {
    anticipatedApprovedStatus = "Over Budget";
  } else if (anticipatedCost < approvedBudget) {
    anticipatedApprovedStatus ="Under Budget";
  }
  const isAnticipatedOverBudget = anticipatedApprovedStatus === "Over Budget";

  let headingStyle = "text-xl font-light";
  if (isSmall) {
    headingStyle = "text-xs font-semibold";
  }


    
    return (
      <section>
        <h4 className={`text-left ${headingStyle} inline-block text-lightGray`}>
          Anticipated Cost vs.<br/> Authorized Budget
        </h4>
        <ProgressBar progress={`${costRatio}%`} isOver={isAnticipatedOverBudget} isSmall={isSmall}/>
        <div className="flex justify-between">
          <p title={`$${anticipatedCost}`} className="text-xs text-lightGray">${anticipatedCost.toLocaleString("en-US")}</p>
          <p title={`$${approvedBudget}`} className="text-xs text-lightGray">${approvedBudget.toLocaleString("en-US")}</p>
        </div>
        <Link to={costsURL(projectID)}>
          <h4 className={`text-right text-${isAnticipatedOverBudget ? 'tenzingRed' : 'trueGreen'} text-xs font-semibold py-1`}>
            {anticipatedApprovedStatus}: {costRatio}% 
          </h4>
          <h4 className={`text-right text-${isAnticipatedOverBudget ? 'tenzingRed' : 'trueGreen'} text-xs font-semibold py-1`}>
            ${( - (approvedBudget - anticipatedCost)).toLocaleString("en-US")}
          </h4>
        </Link>
      </section>
    )
  
}

export default CostSummary
