import React from 'react'
import {FieldInputError} from '../Errors'

/* Basic Field component to wrap form inputs with */

const Field = ({children}) => {
  return (
    <div className="mb-4 flex flex-col">
      {children}
    </div>
  )
}

const FieldWithError = ({name, label, type, error, value, register, required, handleFormChange}) => {

  return (
    <Field>
      <label htmlFor={name} className="text-tenzingGray">{label}</label>
      <input
        id={name}
        name={name}
        type={type ?? 'text'}
        min="1"
        className={!error ? "input-text" : 'input-text-error'}
        {...register(name, {required: required})}
        value={value ?? undefined}
        onChange={handleFormChange ?? null}/>
      <FieldInputError item={error}></FieldInputError>
    </Field>
  )
}

const SelectFieldWithError = ({
                                name,
                                label,
                                value,
                                register,
                                error,
                                required,
                                options,
                                emptySelect,
                                classes,
                                lblClasses,
                                handleFormChange
                              }) => {
  return (
    <Field>
      <label htmlFor={name}
             className={"text-tenzingGray " + lblClasses}>{label}</label>
      <select
        id={name}
        name={name}
        value={value ?? undefined}
        className={!error ? "input-text " + classes : "input-text-error " + classes}
        onChange={(e) => (handleFormChange(e)) ?? undefined}
        {...register(name, {required: required})}>
        {!emptySelect ? undefined :
          <option key={name} value="">{`--Please Choose ${name}--`}</option>}
        {options}
      </select>
      <FieldInputError item={error}></FieldInputError>
    </Field>
  )
}

const FormSlider = ({name, label, disabled, checked, control, register, onClick}) => {
  return (
    <Field>
      <div className="flex items-center">
        <p className="text-lg text-tenzingGray">{label}</p>
        <div>
          <label className="flex items-baseline" htmlFor={name}>
            <input
              id={name}
              name={name}
              type="checkbox"
              control={control}
              checked={checked ?? null}
              disabled={disabled ?? null}
              className="appearance-none invisible peer"
              onClick={onClick ?? null}
              {...register(name)}
            />
            <span className="w-14 h-8
                  flex items-center
                  flex-shrink-0 
                  mx-4 p-1 
                  bg-lightGray 
                  rounded-full 
                  duration-300 ease-in-out
                  peer-checked:bg-tenzingBlue
                  after:w-6 
                  after:h-6 
                  after:bg-white 
                  after:rounded-full 
                  after:shadow-md
                  after:duration-300
                  peer-checked:after:translate-x-6
                  "></span>
          </label>
        </div>
      </div>
    </Field>
  )
}

/*
 * Horizontal field for layout of form inputs and labels in a horizontal fashion

 * Example usage:
    <HorizontalField>
      <ColumnSmall>
        <label>Some label name</label>
      </ColumnSmall>
      <ColumnLarge>
        <input
          name="name"
          type="text"
        />
      </ColumnLarge>
    </HorizontalField>
*/

const HorizontalField = ({children}) => {
  return (
    <div className="mt-4 flex flex-col">
      <div className="flex flex-row">
        {children}
      </div>
    </div>
  )
}

/* Small Column for use with HorizontalField and ColumnLarge */

const ColumnSmall = ({children}) => {
  return (
    <div className="w-1/4">
      {children}
    </div>
  )
}

/* Large Column for use with HorizontalField and ColumnSmall */

const ColumnLarge = ({children}) => {
  return (
    <div className="w-3/4">
      {children}
    </div>
  )
}


export {
  Field,
  HorizontalField,
  ColumnSmall,
  ColumnLarge,
  FieldWithError,
  FormSlider,
  SelectFieldWithError
}
