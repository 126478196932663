import React, {useState} from 'react'
import {useQuery} from "react-query";
import {allProjects} from "../../../utils/queryKeys";
import {getProjects} from "../../../utils/queries";
import {Field} from '../../Common/Forms';

function ProjectPicker({
                         setSelectedProject,
                         setSelectedProjectId
                       }) {
  const [selected, setSelected] = useState('');

  // Get Projects
  const projects = useQuery(
    [allProjects, {status: "active"}],
    getProjects,
    {
      cacheTime: 0,
      staleTime: 0
    })

  if (projects.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  const handleChange = (event) => {
    setSelected(event.target.value)
    setSelectedProjectId(parseInt(event.target.selectedOptions[0].dataset.projectid))
    setSelectedProject(JSON.parse(event.target.selectedOptions[0].dataset.projectdata));
  }

  // Construct list of projects
  const projectOptions = projects?.data?.data?.length && projects?.data?.data?.map((item) => {
    return (
      <option key={"project_value" + item.attributes.name + item.id}
              data-projectdata={JSON.stringify(item)}
              data-projectid={item.id}
              value={item.id}>{item.attributes.name}
      </option>
    )
  }, this);

  return (
    <Field>
      <label htmlFor="project" className="text-tenzingGray">Project</label>
      <select
        id="project_picker"
        name="project_picker"
        className="input-text"
        value={selected}
        onChange={handleChange}
      >
        <option key="project_value_empty" value="">-- Select --</option>
        {projectOptions}
      </select>
    </Field>
  )
}

export default ProjectPicker
