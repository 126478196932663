import React from 'react';

function MenutItemWithBadge({
                              value,
                              badgeValue,
                              badgeColor = "888"
                            }) {
  return (
    <>
      {value}
      <span key={value + "-" + badgeValue}
            className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 py-0.5 pr-2.5 rounded ml-2">
            <span key={value + "-" + badgeValue}
                  style={{backgroundColor: `#${badgeColor}`}}
                  className="text-gray-800 text-sm font-medium mr-2 px-1 py-0.5 rounded-l"></span>
        {badgeValue}
      </span>
    </>
  )
}

export default MenutItemWithBadge;
