import React, {useState, useEffect, useRef} from "react";
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {companyLoggedInUser as companyLoggedInUserQueryKey} from '../../utils/queryKeys';
import {getCompanyLoggedInUSer} from "../../utils/queries";
import Button from 'Components/Common/Button'
import {toast} from "react-toastify";
import {isLocalStorageComplete, removeLocalStorage} from "../../utils/globals";
import {usersSignOut, home} from "../../utils/queryKeys";
import {client as apiClient} from "../../utils/api";
import CldUploadRestApi from '../CldUploadRestApi'
import {
  companyCloudinary
} from '../../utils/queryKeys';
import {
  getCompanyCloudinary
} from '../../utils/queries'

const Index = ({
                 setAnchorEl
               }) => {

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const [location, setLocation] = useState("");
  const [forceLogout, setForceLogout] = useState(false)
  const queryRan = useRef(false);
  const queryClient = useQueryClient();

  const userQuery = useQuery(
    [companyLoggedInUserQueryKey, {companyId: companyId}],
    getCompanyLoggedInUSer
  )

  const cloudinary_config = useQuery(
    [companyCloudinary, {companyId: companyId}],
    getCompanyCloudinary,
    {enabled: false}
  )


  let user = !userQuery.isLoading && userQuery.data.attributes

  // Everytime i get an update, check if all the needed keys are present in the
  // localstorage. If they are not, force a logout
  useEffect(() => {
    isLocalStorageComplete(setForceLogout)
  })

  useEffect(() => {
    if (forceLogout) {
      logout()
    }
  }, [forceLogout])

  const notifyError = () => toast.error("Failed to logout")

  const logout = async () => {
    await apiClient.delete(usersSignOut).then((response) => {
      removeLocalStorage()
      window.location.href = home
    }).catch((error) => {
      console.log(error)
      notifyError()
    });
  }

  // Pass this function to the child
  const assignCloudinaryUrl = (url) => {
    setLocation(url);
    setValue('cloudinary_url', url)
  }

  // Fetch the cloudinary only once.
  useEffect(() => {
    cloudinary_config.refetch()
    queryRan.current = true
  }, [])

  async function updateContact(data) {
    const {cloudinary_url} = data

    return await apiClient.patch('/contacts/' + user.id, {
      cloudinary_url: cloudinary_url
    })
  }

  const {isLoading: isLoadingUpdate, mutateAsync: updateContactMutation} = useMutation(updateContact, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([companyLoggedInUserQueryKey, {
        companyId: companyId
      }]);
    },
    onError: (error) => {
      console.log('Contact update failed.', error)
    }
  });

  useEffect(() => {
    if (location) {
      updateContactMutation({cloudinary_url: location})
      queryClient.refetchQueries([companyLoggedInUserQueryKey, {
        companyId: companyId
      }]);
    }
  }, [location])

  if (isLoadingUpdate) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <section>
      {!userQuery.isLoading && !cloudinary_config.isLoading && user ?
        <article className="p-3 bg-white w-full">
          {/*https://res.cloudinary.com/codii/image/upload/v1655300693/construction_stratos_zg3mrd.jpg*/}
          <div className="grid grid-cols-4 gap-2">
            {
              (user.contact["cloudinary_url"] == undefined || user.contact["cloudinary_url"].trim() == '') ?
                <div className="col-span-1 hover:cursor-pointer">
                  <CldUploadRestApi assignCloudinaryUrl={assignCloudinaryUrl}
                                    config={cloudinary_config.data.attributes}
                                    profile={true}
                  />
                </div>
                :
                <div className="col-span-1 hover:cursor-pointer">
                  <CldUploadRestApi assignCloudinaryUrl={assignCloudinaryUrl}
                                    config={cloudinary_config.data.attributes}
                                    profile={true}
                                    user={user}
                  />
                </div>
            }
            <h3
              className="text-xl text-darkGray font-semibold text-center flex col-span-2 items-center">{`${user.contact.first_name} ${user.contact.last_name}`}</h3>
            <div className="col-span-1">
              <Button onClick={logout} buttonStyle={'orange-transparent-button log_out_btn'}>Log out</Button>
            </div>
          </div>
          <section className="flex flex-col mt-2">
            <h2 className="flex justify-between mx-2 mb-2 border-b border-gray-400 border-dashed">
              <span className="mr-2 w-2/5 font-bold">Email:</span>
              <span className="profile-value w-3/5">{user.email}</span>
            </h2>
            <h2 className="flex justify-between mx-2 mb-2 border-b border-gray-400 border-dashed">
              <span className="mr-2 w-2/5 font-bold">Company:</span>
              <span className="profile-value w-3/5">{user.company.name}</span>
            </h2>
            <h2 className="gray-transparent-rounded-badge mb-2">{user.user_role.name}</h2>
            <h2 className="px-2 mb-2">{user.contact.address}</h2>
            <h2 className="bg-backgroundGray shadow shadow-slate-200 p-2 mb-2">
              <span className="mr-2 font-bold">Stratos ID:</span>
              <span className="profile-value">{user.uid}</span>
            </h2>
          </section>
        </article> : null
      }
    </section>
  )
}

export default Index; 
