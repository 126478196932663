import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from 'react-query';
import ScheduleGantt from './ScheduleGantt';
import {
  projectActivities
} from 'utils/queryKeys';
import {
  getProjectActivities
} from 'utils/queries';

function Schedule({
                    title,
                    view,
                    activityType,
                    setActivitiesPermissions,
                    activitiesPermissions,
                    setActivityToEdit,
                    reloadSchedule,
                    setReloadSchedule
                  }) {
  const queryClient = useQueryClient();
  const params = useParams();

  const projectActivitiesQuery = useQuery(
    [projectActivities, {projectId: params['projectId'], category: activityType ?? 'all'}],
    getProjectActivities,
    {
      cacheTime: reloadSchedule ? 0 : 300000,
      staleTime: reloadSchedule ? 0 : 300000,
    })


  useEffect(() => {
    if (reloadSchedule) {
      queryClient.refetchQueries([
        projectActivities,
        {projectId: params['projectId'], category: activityType ?? 'all'}
      ])
    }
  }, [reloadSchedule])

  useEffect(() => {
    if (projectActivitiesQuery.data?.permissions != undefined) {
      setActivitiesPermissions(projectActivitiesQuery.data.permissions)
    }
    if (!projectActivitiesQuery.isLoading
      && projectActivitiesQuery.isSuccess
      && projectActivitiesQuery.isFetching) {
      setReloadSchedule(false)
    }
  }, [!projectActivitiesQuery.isLoading
           && !projectActivitiesQuery.isFetching
           && projectActivitiesQuery.isSuccess])

  if (projectActivitiesQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <section>
      {/* Info Bars */}
      <div className="p-2 flex justify-between mb-3">
        <h1 className='Section-h1'>{title}</h1>
        <section className="flex space-x-4">
          <div className="flex flex-row">
            <span className="bg-trueGreen w-8 h-3 m-1 text-white rounded-full"></span>
            <p className="text-sm text-lightGray">BASE Schedule</p>
          </div>
          <div className="flex flex-row">
            <span className="bg-tenzingBlue w-8 h-3 m-1 text-white rounded-full"></span>
            <p className="text-sm text-lightGray">Approved Extension</p>
          </div>
          <div className="flex flex-row">
            <span className="border border-tenzingRed w-8 h-3 m-1 text-white rounded-full">.</span>
            <p className="text-sm text-lightGray">DELAY Risk</p>
          </div>
        </section>
      </div>
      {
        !projectActivitiesQuery.isLoading
        && projectActivitiesQuery.data?.data?.length > 0 ?
          <ScheduleGantt
            activities={projectActivitiesQuery.data?.data}
            permissions={projectActivitiesQuery.data?.permissions}
            activityType={activityType ?? 'all'}
            activitiesPermissions={activitiesPermissions}
            view={view}
            setActivityToEdit={setActivityToEdit}
          /> : <p>No Activities to display...</p>
      }
    </section>
  )
}


export default Schedule;
