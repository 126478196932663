import {client as apiClient} from 'utils/api';

// Companies

// GET All companies
export const getCompanies = async () => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const response = await apiClient.get('/companies')
  return response.data
}

// GET Company
export const getCompany = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId)
  return response.data
}

// Projects

// GET Box Token
export const getBoxProjectToken = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.id + "/box_token")
  return response.data
}

// GET Project
export const getProject = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.id)
  return response.data
}

// GET Projects
export const getProjects = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let extra_params = ""
  let paramsList = []
  if (params != undefined && params.list) {
    paramsList.push("list=" + params.list)
  }

  if (params != undefined && params.status) {
    paramsList.push("status=" + params.status)
  }

  if (paramsList.length > 0) {
    extra_params = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/projects' + extra_params)
  return response.data
}

// PUT (update) Project
export const updateProject = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.patch('/projects/' + params?.projectId,
    params.data
  )
}

// GET Company Projects
export const getCompanyProjects = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let extra_params = ""
  if (params != undefined && params.risks) {
    paramsList.push("risks=" + params.risks)
  }
  if (params != undefined && params.activities) {
    paramsList.push("activities=" + params.activities)
  }
  if (params != undefined && params.filter) {
    paramsList.push("filter=" + params.filter)
  }

  if (paramsList.length > 0) {
    extra_params = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/companies/' + params.companyId + '/projects' + extra_params)
  return response.data
}

// Box
// GET Access Token
export const getBoxAccessToken = async () => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const response = await apiClient.get('/box_authorizes/token')
  return response.data
}


// Google
// GET Access Token
export const getGoogleMailAccessToken = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get(`/companies/${params.companyId}/gmtoken`)
  return response.data
}

export const getGoogleCalendarAccessToken = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get(`/companies/${params.companyId}/gctoken`)
  return response.data
}


// Contacts

// GET All contacts
export const getContacts = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let query_params = "?"
  Object.entries(params).forEach(([key, value]) => {
    query_params += `${key}=${value}`
  })
  const extra_params = (query_params !== "?" ? query_params : "")

  const response = await apiClient.get('/contacts' + extra_params)
  return response.data
}

// GET All contacts
export const getCompanyContacts = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/contacts')
  return response.data
}

// GET contact
// User Query example: const contact = useQuery([contactQuery, {id: contactId}], getContact)
export const getContact = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/contacts/' + params.id)
  return response.data
}


export const getProjectContacts = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get(`/projects/${params.projectId}/contacts`)
  return response.data
}


// Risks
//GET All Project Risks
export const getProjectRisks = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.projectId + '/risks')
  return response.data
}

// GET Risk
export const getRisk = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/risks/' + params.id)
  return response.data
}

// Update Risk
export const updateRisk = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.patch('/risks/' + params.riskId,
    params.data
  )
}

// Activities
// GET Project Activities 
export const getProjectActivities = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let extra_params = ""
  if (params != undefined && params.category) {
    paramsList.push("category=" + params.category)
  }

  if (paramsList.length > 0) {
    extra_params = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/projects/' + params.projectId + '/activities' + extra_params)
  return response.data
}

export const getActivity = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey

  const response = await apiClient.get('/activities/' + params.activityId)
  return response.data
}

// Tasks

// GET Project Tasks
export const getProjectTasks = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let response = null
  // todo: Make param list parsing dynamic
  if (params.status && params.view) {
    const params_list = 'status=' + params.status + '&view=' + params.view
    response = await apiClient.get('/projects/' + params.projectId + '/tasks?' + params_list)
  } else if (params.status) {
    response = await apiClient.get('/projects/' + params.projectId + '/tasks?status=' + params.status)
  } else {
    response = await apiClient.get('/projects/' + params.projectId + '/tasks')
  }
  return response.data
}

// GET My Project Tasks
export const getMyProjectTasks = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.projectId + '/tasks?assignee_id=' + params.assigneeId)
  return response.data
}

// GET Calendar
export const getProjectCalendar = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.projectId + '/calendar')
  return response.data
}

// GET Critical Tasks
export const getProjectCriticalTasks = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let extra_params = ""
  if (params != undefined &&  params.status) {
    paramsList.push("status=" + params.status)
  }
  if (params != undefined &&  params.view) {
    paramsList.push("view=" + params.view)
  }

  if (paramsList.length > 0) {
    extra_params = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/projects/' + params.projectId + '/tasks/critical' + extra_params)
  return response.data
}

export const getProjectPendingTasks = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let extra_params = ""
  if (params != undefined &&  params.status) {
    paramsList.push("status=" + params.status)
  }
  if (params != undefined &&  params.view) {
    paramsList.push("view=" + params.view)
  }

  if (paramsList.length > 0) {
    extra_params = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/projects/' + params.projectId + '/tasks/pending' + extra_params)
  return response.data
}

// GET Task
export const getTask = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/tasks/' + params.taskId)
  return response.data
}

// GET Company Tasks
export const getCompanyTasks = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let extra_params = ""
  if (params != undefined &&  params.status) {
    paramsList.push("status=" + params.status)
  }
  if (params != undefined &&  params.view) {
    paramsList.push("view=" + params.view)
  }

  if (paramsList.length > 0) {
    extra_params = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/projects/' + params.companyId + '/tasks' + extra_params)
  return response.data
}

// GET ALL My Tasks 
export const getMyTasks = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let extra_params = ""
  if (params != undefined &&  params.status) {
    paramsList.push("status=" + params.status)
  }
  if (params != undefined &&  params.assigneeId) {
    paramsList.push("assignee_id=" + params.assigneeId)
  }

  if (paramsList.length > 0) {
    extra_params = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/tasks/mine' + extra_params)
  return response.data
}
// Costs

// Get Project Line Items
export const getProjectLineItems = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let queryParams = ""
  if (params != undefined &&  params.category_breakdown_id) {
    paramsList.push("category_breakdown_id=" + params.category_breakdown_id)
  }
  if (paramsList.length > 0) {
    queryParams = "?" + paramsList.join("&")
  }
  const response = await apiClient.get('/projects/' + params.projectId + '/line_items' + queryParams)
  return response.data
}

// UPDATE INVOICE
export const updatePutLineItem = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    // Delete will return nothing on success
    if (data == undefined || data == '') {
      return data
    } else {
      const responseData = JSON.parse(data)
      return responseData.errors
    }
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.put(
    '/line_items/' + params.id,
    params.data
  )
}

export const getProjectInvoices = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey

  const response = await apiClient.get('/projects/' + params.projectId + '/invoices')
  return response.data
}

export const getInvoice = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey

  const response = await apiClient.get('/invoices/' + params.invoiceId)
  return response.data
}

// DELETE INVOICE 
export const deleteInvoice = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    // Delete will return nothing on success
    if (data == undefined || data == '') {
      return data
    } else {
      const responseData = JSON.parse(data)
      return responseData.errors
    }
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.delete('/invoices/' + params.invoiceId)
}

// UPDATE INVOICE
export const updateInvoice = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    // Delete will return nothing on success
    if (data == undefined || data == '') {
      return data
    } else {
      const responseData = JSON.parse(data)
      return responseData.errors
    }
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.patch(
    '/invoices/' + params.invoiceId,
    params.data
  )
}

// UPDATE INVOICE
export const updatePutInvoice = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    // Delete will return nothing on success
    if (data == undefined || data == '') {
      return data
    } else {
      const responseData = JSON.parse(data)
      return responseData.errors
    }
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.put(
    '/invoices/' + params.invoiceId,
    params.data
  )
}

export const updateTask = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.patch('/tasks/' + params.taskId,
    params.data
  )
}

// Get Project Budget Items
export const getProjectBudgetItems = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  let paramsList = []
  let queryParams = ""
  if (params != undefined &&  params.category_breakdown_id) {
    paramsList.push("category_breakdown_id=" + params.category_breakdown_id)
  }
  if (paramsList.length > 0) {
    queryParams = "?" + paramsList.join("&")
  }
  const response = await apiClient.get('/projects/' + params.projectId + '/budget_items' + queryParams)
  return response.data
}

// Get Project Breakdowns
export const getProjectContractBreakdowns = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  let paramsList = []
  let queryParams = ""
  if (params != undefined &&  params.line_item_id) {
    paramsList.push("line_item_id=" + params.line_item_id)
  }
  if (paramsList.length > 0) {
    queryParams = "?" + paramsList.join("&")
  }
  const response = await apiClient.get('/projects/' + params.projectId + '/contract_breakdowns' + queryParams)
  return response.data
}


export const getContractBreakdown = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey

  const response = await apiClient.get('/contract_breakdowns/' + params.contractBreakdownId)
  return response.data
}

// todo: Categories should be per company
// GET All Categories
export const getCategories = async () => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const response = await apiClient.get('/categories')
  return response.data
}

// User Roles
// GET Company Roles
export const getCompanyUserRoles = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/user_roles')
  return response.data
}

// Users
// GET All Users
export const getUsers = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  let paramsList = []
  let queryParams = ""
  if (params != undefined &&  params.contactId) {
    paramsList.push("contact_id=" + params.contactId)
  }
  if (paramsList.length > 0) {
    queryParams = "?" + paramsList.join("&")
  }

  const response = await apiClient.get('/users' + queryParams)
  return response.data
}

export const getUser = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/users/' + params.userId)
  return response.data
}

// GET Logged In User
export const getCompanyLoggedInUSer = async ({queryKey}) => {
  const [_, params] = queryKey
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const response = await apiClient.get(`/companies/${params.companyId}/users/loggedin`)
  return response.data
}

// Sign In
export const signIn = async (payload) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const response = await apiClient.post('/users/sign_in', payload)
  return response.data
}

// Sign Out
export const signOut = async () => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const response = await apiClient.delete('/users/sign_out')
  return response.data
}

// GET Company Users
export const getCompanyUsers = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/users')
  return response.data
}

export const getProjectUsers = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.projectId + '/users')
  return response.data
}

// GET AWS Config
export const getCompanyAWS = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/aws_clients')
  return response.data
}

// GET Cloudinary Config
export const getCompanyCloudinary = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/cloudinary_clients')
  return response.data
}

// GET Phases Defaults
export const getPhasesDefaults = async () => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const response = await apiClient.get('phases?set=defaults')
  return response
}


// GET Phases
export const getProjectPhases = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.projectId + '/phases')
  return response.data
}

// GET Phase
export const getPhase = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/phases/' + params.phaseId)
  return response.data
}

// PATCH/Update Phase
export const phaseToTaskPatch = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.patch(`/phases/${params.phaseId}/totask`,
    params.data
  )
}

export const deepCopyPhase = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.post(`/phases/${params.phaseId}/deepcopy`,
    params.data
  )
}

// GET Company Phase Pool
export const getCompanyPhasePools = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/phase_pools')
  return response.data
}

// Get Project Team
export const getProjectTeam = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/project_teams/' + params.projectID)
  return response.data
}


// POST Project Team Bulk
export const postAssignProjectTeam = async (queryKey) => {
  const [_, params] = queryKey
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const response = await apiClient.post(`companies/${params.companyId}/project_teams/assign`, params.payload)
  return response.data
}

// Process Modules

// GET Module Pools of Phase Pools
export const getPhasePoolsModulePools = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/phase_pools/' + params.phasePoolId + '/module_pools')
  return response.data
}


// GET Process Modules
export const getPhaseModules = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/phases/' + params.phaseId + '/process_modules')
  return response.data
}

// GET Project Phases Process Modules
export const getProjectPhaseModules = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.projectId + '/phases/' + params.phaseId + '/process_modules')
  return response.data
}


// PATCH/Update Module
export const moduleToTaskPatch = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.patch(`/process_modules/${params.moduleId}/totask`,
    params.data
  )
}

export const getProcessModule = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/process_modules/' + params.moduleId)
  return response.data
}

export const deepCopyProcessModule = async (queryKey) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  // todo: We could use the first parameter of the query key to make a generic delete query
  const [_, params] = queryKey
  return await apiClient.post(`/process_modules/${params.moduleId}/deepcopy`,
    params.data
  )
}

// Process Steps

// GET Step Pools of Module Pools
export const getModulePoolsStepPools = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const [_, params] = queryKey

  const response = await apiClient.get('/module_pools/' + params.modulePoolId + '/step_pools')
  return response.data
}

// GET Process Steps for Process Module
export const getProcessStepForModule = async ({queryKey}) => {
  const [_, params] = queryKey
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const response = await apiClient.get('/process_modules/' + params.processModuleId + '/process_steps')
  return response.data
}

// STATS

// GET Stats

// GET Company Stats
export const getCompanyStats = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ?
      responseData.errors : {
        data: responseData.data,
        included: responseData.included
      }
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/stats')
  return response.data
}

// GET Project Stats
export const getProjectStats = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ?
      responseData.errors : {
        data: responseData.data,
        included: responseData.included
      }
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/projects/' + params.projectId + '/stats')
  return response.data
}

// MAIL LABELS

// GET Company Labels
export const getCompanyEmailLabels = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/companies/' + params.companyId + '/email_labels')
  return response.data
}

// GET All Email Labels
export const getEmailLabels = async () => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const response = await apiClient.get('/email_labels')
  return response.data
}

// GET One Email Label
export const getEmailLabel = async ({queryKey}) => {
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const [_, params] = queryKey
  const response = await apiClient.get('/email_labels/' + params.emailLabelId)
  return response.data
}

// POST Email Label
export const createEmailLabel = async (queryKey) => {
  const [_, data] = queryKey
  apiClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const response = await apiClient.post('/email_labels', data.payload)
  return response.data
}

