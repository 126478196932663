import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from 'react-query';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {parseISO} from 'date-fns';
import {client as apiClient} from 'utils/api';
import {toast} from 'react-toastify';
import {Field} from 'Components/Common/Forms';
import Button from "Components/Common/Button";
import {
  activity as activityQueryKey,
  projectActivities,
  projectRisks
} from 'utils/queryKeys';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {
  getProjectRisks,
  getProjectActivities,
  getActivity
} from 'utils/queries';
import {
  linkTypeOptions,
  formFields,
  dateFields,
  activityTypeOptions,
} from '../../Resources/index'
import CircularProgress from "@mui/material/CircularProgress";
import {
  statusValues
} from "../../Resources/index";
import {
  capitalize,
  getNumberStatus
} from "../../../../utils/globals";
import {projectUsers} from "../../../../utils/queryKeys";
import {getProjectUsers} from "../../../../utils/queries";

const EditActivityForm = ({
                            setShowForm,
                            activityId,
                            activityType,
                            setReloadSchedule,
                            showForm
                          }) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const [formData, setFormData] = useState({})
  const [sliderDisabled, setSliderDisabled] = useState(true)
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const isReadOnly = useRef(false)
  const isAncestryRequired = useRef(false)
  const [endDate, setEndDate] = useState("")
  const predecessorStartDate = useRef("")
  const predecessorEndDate = useRef("")

  const companyId = parseInt(window.localStorage.getItem("company_id"))

  const statusOptions = statusValues.map((item, index) => {
    return (
      <option key={"activity" + item + activityId} value={index}>{capitalize(item)}</option>
    )
  }, this)

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!dateFields.includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value.trim()
      })
      // Set the value
      setValue(e.target.id, e.target.value.trim())

      if (e.target.id == 'ancestry') {
        predecessorStartDate.current = e.target.options[e.target.selectedIndex].getAttribute('data-startdate')

        const eDate = e.target.options[e.target.selectedIndex].getAttribute('data-enddate')
        const eApproved = Boolean(e.target.options[e.target.selectedIndex].getAttribute('data-extapproved'))
        const eUntil = e.target.options[e.target.selectedIndex].getAttribute('data-extendeduntil')

        predecessorEndDate.current = eApproved ? eUntil : eDate
      }
    }
  }

  const activityQuery = useQuery(
    [activityQueryKey, {activityId: activityId}],
    getActivity,  {
      cacheTime: showForm ? 0 : 99999999,
      staleTime: showForm ? 0 : 99999999,
      refetchOnMount: "always"
    }
  )

  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed
      && !activityQuery.isLoading
      && activityQuery.isSuccess
      && activityQuery.isFetched
      && activityQuery.data?.data != undefined) {
      // Update the state
      setFormData(activityQuery?.data?.data?.attributes)
      // Update the form values
      formFields.forEach((fd) => {
        if (dateFields.includes(fd)) {
          setValue(fd, activityQuery?.data?.data.attributes[fd] ? parseISO(activityQuery?.data?.data?.attributes[fd]) : '')
        } else if (["user", "project", "risk"].includes(fd)) {
          setValue(fd + '_id', activityQuery?.data?.data?.attributes[fd]?.id)
        } else if (fd === "status") {
          setValue(fd, getNumberStatus(activityQuery.data?.data?.attributes[fd]))
        } else {
          setValue(fd, activityQuery?.data?.data?.attributes[fd])
        }

        // Hide the slider if the extended until is not null
        if (!_.isNull(activityQuery?.data?.data?.attributes["extended_until"]) || "") {
          setSliderDisabled(false)
        } else {
          // Reset the value
          setValue('extension_approved', null)
        }
      })
    }

    // cancel subscription to useEffect
    return () => (isSubscribed = false)
  }, [!activityQuery.isLoading
      && activityQuery.isSuccess
      && activityQuery.isFetched
      && activityQuery.data?.data != undefined])

  // XXX This is a temporary solution
  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed
      && formData?.starts_at != undefined
      && formData?.duration != undefined) {
      setEndDate(moment(formData?.starts_at).add(formData?.duration, 'd').format('MM/DD/YYYY'))
    }

    // cancel subscription to useEffect
    return () => (isSubscribed = false)
  }, [formData?.starts_at, formData?.duration])

  useEffect(() => {
    if (formData?.link_type != undefined
      && formData?.link_type != ""
      && formData?.ancestry != ""
      && formData?.ancestry != undefined) {

      // This will be used for date auto adjustment on link type change
      predecessorStartDate.current = activityQuery?.data?.data?.attributes?.['parent_node']?.["starts_at"]
      predecessorEndDate.current = Boolean(activityQuery?.data?.data?.attributes?.['parent_node']?.["extension_approved"]) ?
        activityQuery?.data?.data?.attributes?.['parent_node']?.["extended_until"]
        : activityQuery?.data?.data?.attributes?.['parent_node']?.["ends_at"]


      // Set the start and end date
      if (formData?.link_type == 'finish_to_finish') {
        let newStartDate = moment(predecessorEndDate.current).add(-parseInt(formData?.duration), 'days')

        if(formData?.extended_until != undefined
          && formData?.extended_until != ""
          && formData?.extension_approved == true) {
          const extendedUntilDuration = moment(formData?.extended_until).diff(moment(formData?.ends_at), 'days')
          const newExtendedUntil = moment(predecessorEndDate.current)
          newStartDate = moment(predecessorEndDate.current).add(-parseInt(extendedUntilDuration), 'days').add(-parseInt(formData?.duration), 'days')

          setFormData({
            ...formData, ['extended_until']: newExtendedUntil
          })
          // Set the value
          setValue('extended_until', newExtendedUntil)
        }


        // Set the new start date
        setFormData({
          ...formData, ['starts_at']: newStartDate
        })
        // Set the value
        setValue('starts_at', newStartDate)
      } else if (formData?.link_type == 'start_to_finish') {
        // The predecessor start date is my current activities end date
        // As a result the start date of my current activity is the end date minus the duration
        // endDate = predecessorStartDate
        const newStartDate = moment(predecessorStartDate.current).add(-parseInt(formData?.duration), 'days');

        // Calculate the new Extended Until date
        if(formData?.extended_until != undefined
           && formData?.extended_until != ""
           && formData?.extension_approved == true) {
          const extendedUntilDuration = moment(formData?.extended_until).diff(moment(formData?.ends_at), 'days')
          const newExtendedUntil = moment(newStartDate).add(parseInt(formData?.duration), 'days').add(parseInt(extendedUntilDuration), 'days');

          setFormData({
            ...formData, ['extended_until']: newExtendedUntil
          })
          // Set the value
          setValue('extended_until', newExtendedUntil)
        }

        // Set the new start date
        setFormData({
          ...formData, ['starts_at']: newStartDate
        })
        // Set the value
        setValue('starts_at', newStartDate)
      } else if (formData?.link_type == 'start_to_start') {
        // Calculate the new Extended Until date
        if(formData?.extended_until != undefined
          && formData?.extended_until != ""
          && formData?.extension_approved == true) {
          const extendedUntilDuration = moment(formData?.extended_until).diff(moment(formData?.ends_at), 'days')
          const newExtendedUntil = moment(predecessorStartDate.current).add(parseInt(formData?.duration), 'days').add(parseInt(extendedUntilDuration), 'days');

          setFormData({
            ...formData, ['extended_until']: newExtendedUntil
          })
          // Set the value
          setValue('extended_until', newExtendedUntil)
        }


        setFormData({
          ...formData, ['starts_at']: predecessorStartDate.current
        })
        // Set the value
        setValue('starts_at', predecessorStartDate.current)
      } else if (formData?.link_type == 'finish_to_start') {
        // Calculate the new Extended Until date
        if(formData?.extended_until != undefined
          && formData?.extended_until != ""
          && formData?.extension_approved == true) {
          const extendedUntilDuration = moment(formData?.extended_until).diff(moment(formData?.ends_at), 'days')
          const newExtendedUntil = moment(predecessorEndDate.current).add(parseInt(formData?.duration), 'days').add(parseInt(extendedUntilDuration), 'days');

          setFormData({
            ...formData, ['extended_until']: newExtendedUntil
          })
          // Set the value
          setValue('extended_until', newExtendedUntil)
        }

        setFormData({
          ...formData, ['starts_at']: predecessorEndDate.current
        })
        // Set the value
        setValue('starts_at', predecessorEndDate.current)
      }
    } else {
      // Reset everything
      setFormData({
        ...formData, ['starts_at']: activityQuery?.data?.data?.attributes["starts_at"]
      })
      setFormData({
        ...formData, ['duration']: activityQuery?.data?.data?.attributes["duration"]
      })
      // Set the value
      setValue('duration', activityQuery?.data?.data?.attributes["duration"])
      // Set the value
      setValue('starts_at', activityQuery?.data?.data?.attributes["starts_at"])
    }
  }, [formData?.link_type, formData?.ancestry])

  const allActivitiesQuery = useQuery(
    [projectActivities,
      {projectId: params['projectId'], category: activityType}],
    getProjectActivities)

  const predecessorOptions =
    !allActivitiesQuery.isLoading
    && allActivitiesQuery.isSuccess
    && allActivitiesQuery.data?.data != undefined
    && allActivitiesQuery?.data?.data?.map(activity => {
      if (activity.id !== activityId) {
        return <option key={"activity-edit" + activity.id}
                       data-startdate={activity.attributes['starts_at']}
                       data-enddate={activity.attributes['ends_at']}
                       data-extendeduntil={activity.attributes['extended_until']}
                       data-extapproved={activity.attributes['extension_approved']}
                       value={activity.id}>{activity.attributes["name"]}</option>
      } else {
        null
      }
    })

  // FETCH USER LIST
  const users = !activityQuery.loading && useQuery([projectUsers, {projectId: activityQuery.data?.data?.attributes['project']?.id}], getProjectUsers)


  const userOptions =
    users.isLoading ? 'Loading...'
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"activity-edit" + item.attributes.email + item.id} value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  // FETCH RISKS
  const risks = useQuery(
    [projectRisks, {projectId: params['projectId']}],
    getProjectRisks
  )

  const riskOptions =
    risks.isLoading ? null
      : risks?.data?.data?.length
      && risks?.data?.data?.map((item) => {
        return (
          <option key={"activity-edit" + item.attributes.name + item.id} value={item.id}>{item.attributes.name}</option>
        )
      }, this);


  const notifySuccess = () => toast.success("Activity updated successfully!");
  const notifyError = () => toast.error("Activity failed to update.")

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: {errors}
  } = useForm();
  const watchLinkType = watch("link_type")

  // You should be able to not include a Start Date for an Activity
  // that has a predesessor link OR if there is a link,
  // ignore the start date
  isReadOnly.current = watchLinkType === 'start_to_start';

  isAncestryRequired.current = watchLinkType != undefined && watchLinkType != '';
  const classes = isAncestryRequired.current ? "" : "hidden";

  async function patchActivity(data) {
    const {
      name,
      starts_at,
      duration,
      starts_at_anticipated,
      ends_at_anticipated,
      extended_until,
      extension_approved,
      link_type,
      ancestry,
      user_id,
      risk_id,
      status
    } = data

    const formattedStart = moment(starts_at).utc().format()
    const formattedExtension = (extended_until == undefined || extended_until == '')
      ? null : moment(extended_until).utc().format()
    const formattedStartsAtAnticipated = (starts_at_anticipated == undefined || starts_at_anticipated == '')
      ? null : moment(starts_at_anticipated).utc().format()
    const approve = sliderDisabled ? null : !!extension_approved
    const formattedEndDate = moment(endDate).utc().format()


    return await apiClient.patch(`/activities/${activityId}`, {
      name: name,
      starts_at: formattedStart,
      starts_at_anticipated: formattedStartsAtAnticipated,
      duration: parseInt(duration),
      ends_at_anticipated: formattedEndDate,
      extended_until: formattedExtension,
      extension_approved: approve,
      link_type: link_type,
      user_id: user_id == undefined ? null : parseInt(user_id),
      risk_id: parseInt(risk_id),
      ancestry: link_type != "" ? parseInt(ancestry) : "",
      status: parseInt(status)
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(patchActivity);

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.refetchQueries([activityQueryKey, {activityId: activityId}])
      notifySuccess()
      setShowForm(false)
      setReloadSchedule(true)
      setLoading(false)
      setDoDisable(false)
    } catch (err) {
      setLoading(false)
      setDoDisable(false)
      notifyError()
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  if(activityQuery.isLoading || activityQuery.isFetching) {
    return(<div className="lds-dual-ring"></div>)
  }

  return (
    <section className="sticky top-2 p-4 mb-4 bg-white rounded-md shadow-sm w-full min-w-fit">
      <h1 className="Section-h1 mb-4">Edit Activity</h1>
      {allActivitiesQuery.isLoading ? "" :
        <form method="post" onSubmit={handleSubmit(onSubmit)}>

          {/* NAME */}
          <Field>
            <label htmlFor="name" className="text-tenzingGray">Activity Name:</label>
            <input
              id="name"
              name="Name"
              type="text"
              className={!errors.name ? "input-text" : 'input-text-error'}
              {...register("name", {required: true})}
              value={formData.name ?? ""}
              onChange={handleFormChange}
            />
            <FieldInputError item={errors.name}></FieldInputError>
          </Field>

          {/*ACTIVITY TYPE*/}
          <Field>
            <label htmlFor="activity_type" className="text-tenzingGray">Activity Type:</label>
            <Controller
              name="activity_type"
              control={control}
              rules={{required: true}}
              render={({field}) => {
                return (
                  <select
                    id="activity_type"
                    value={formData.activity_type ?? ""}
                    onChange={handleFormChange}
                    className={!errors.activity_type ? "input-text" : 'input-text-error'}>
                    <option>Select Activity...</option>
                    {activityTypeOptions}
                  </select>
                )
              }}
            />
            <FieldInputError item={errors.activity_type}></FieldInputError>
          </Field>

          {/* LINK TYPE */}
          <Field>
            <label htmlFor="link_type" className="text-tenzingGray">Link Type:</label>
            <select
              id="link_type"
              name="link_type"
              {...register("link_type")}
              className={!errors.link_type ? "input-text" : 'input-text-error'}
              value={formData?.link_type ?? ""}
              onChange={handleFormChange}>
              {linkTypeOptions}
            </select>
            <FieldInputError item={errors.link_type}></FieldInputError>
          </Field>

          {/* ANCESTRY */}
          <Field>
            <label htmlFor="ancestry"
                   className={!isAncestryRequired.current ? "text-tenzingGray hidden" : "text-tenzingGray"}
            >Select Predecessor</label>
            <select
              id="ancestry"
              name="ancestry"
              required={isAncestryRequired.current}
              {...register("ancestry")}
              className={!errors.ancestry ? "input-text " + classes : "input-text-error" + classes}
              value={formData.ancestry ?? ""}
              onChange={handleFormChange}>
              <option value=""> -- Select --</option>
              {predecessorOptions}
            </select>
            <FieldInputError item={errors.ancestry}></FieldInputError>
          </Field>

          {/* RISK ID */}
          <Field>
            <label htmlFor="risk_id" className="text-tenzingGray">Select Risk</label>
            <select
              id="risk_id"
              name="risk"
              placeholder="Risk"
              {...register("risk_id", {required: false})}
              className={!errors.impact ? "input-text" : 'input-text-error'}
              /* When we load the data in the state we only have the ID of the risk under the
               risk object. This is the current risk data, fetched from the server. After changing the
               value from the frontend we add the risk_id parameter. This will be used in the form.
               As a result, for the edit form, we first check the form parameter, then the object we
               got from the server and last we assign the empty value*/
              value={formData.risk_id ?? formData.risk?.id ?? ""}
              onChange={handleFormChange}
            >
              <option value="">-- Select --</option>
              {riskOptions}
            </select>
            <FieldInputError item={errors.risk_id}></FieldInputError>
          </Field>

          {/* START AT */}
          <Field>
            <label htmlFor="starts_at" className="text-tenzingGray">Activity Start Date:</label>
            <Controller
              name='starts_at'
              control={control}
              rules={{required: !isReadOnly.current}}
              render={({field}) => (
                <DatePicker
                  id='starts_at'
                  minDate={null}
                  popperPlacement="left"
                  disabled={formData.link_type != undefined}
                  readOnly={isReadOnly.current}
                  onChange={(date) => {
                    field.onChange(date)
                    // Set the value
                    setValue("starts_at", date)
                    // Update the state
                    setFormData({
                      ...formData, ["starts_at"]: date
                    })
                  }}
                  selected={!field.value ? null : moment(field.value).toDate()}
                  className={!errors.starts_at ? "input-text" : 'input-text-error'}
                />
              )}
            />
            <FieldInputError item={errors.starts_at}></FieldInputError>
          </Field>

          {/* DURATION */}
          <Field>
            <label htmlFor="duration" className="text-tenzingGray">Duration (in days)</label>
            <input
              id="duration"
              name="Duration"
              min="1"
              type="number"
              disabled={formData.link_type != undefined}
              className={!errors.duration ? "input-text" : 'input-text-error'}
              {...register("duration", {required: true})}
              value={formData.duration ?? ""}
              onChange={handleFormChange}
            />
            <FieldInputError item={errors.duration}></FieldInputError>
          </Field>

          {/* ENDS AT */}
          <Field>
            <label htmlFor="ends_at" className="text-tenzingGray">Activity End Date:</label>
            <input
              id="ends_at"
              name="ends_at"
              type="text"
              disabled={true}
              className={!errors.ends_at ? "input-text" : 'input-text-error'}
              {...register("ends_at", {required: false})}
              value={endDate}
            />
          </Field>

          {/* STARTS AT ANTICIPATED */}
          <Field>
            <label htmlFor="starts_at_anticipated" className="text-tenzingGray">Activity Anticipated Start Date:</label>
            <Controller
              name='starts_at_anticipated'
              control={control}
              rules={{required: !isReadOnly.current}}
              render={({field}) => (
                <DatePicker
                  id='starts_at_anticipated'
                  minDate={null}
                  popperPlacement="left"
                  readOnly={isReadOnly.current}
                  onChange={(date) => {
                    field.onChange(date)
                    // Set the value
                    setValue("starts_at_anticipated", date)
                    // Update the state
                    setFormData({
                      ...formData, ["starts_at_anticipated"]: date
                    })
                  }}
                  selected={!field.value ? null : moment(field.value).toDate()}
                  className={!errors.starts_at_anticipated ? "input-text" : 'input-text-error'}
                />
              )}
            />
            <FieldInputError item={errors.starts_at_anticipated}></FieldInputError>
          </Field>

          {/* EXTENDED UNTIL */}
          <Field>
            <label htmlFor="extended_until" className="text-tenzingGray">Activity Anticipated End Date:</label>
            <Controller
              name='extended_until'
              control={control}
              render={({field}) => (
                <DatePicker
                  id='extended_until'
                  minDate={null}
                  popperPlacement="left"
                  onChange={(date) => {
                    field.onChange(date)
                    if (date != undefined && date.toString().trim() != '') {
                      setSliderDisabled(false)
                    } else {
                      setSliderDisabled(true)
                    }
                    // Set the value
                    setValue("extended_until", date)
                    // Update the state
                    setFormData({
                      ...formData, ["extended_until"]: date
                    })
                  }}
                  selected={!field.value ? null : moment(field.value).toDate()}
                  className={!errors.extended_until ? "input-text" : 'input-text-error'}
                />
              )}
            />
            <FieldInputError item={errors.extended_until}></FieldInputError>
          </Field>

          {/* EXTENSION APPROVED */}
          {sliderDisabled ? null :
            <Field>
              <div className="flex items-center">
                <p className="text-lg text-tenzingGray">Extension Approved</p>
                <div>
                  <label className="flex items-baseline" htmlFor="extension_approved">
                    <input
                      id="extension_approved"
                      name="extension_approved"
                      type="checkbox"
                      checked={Boolean(formData.extension_approved)}
                      className="appearance-none invisible peer"
                      onClick={(event) => {
                        // Set the value
                        setValue("extension_approved", event.target.checked)
                        // Update the state
                        setFormData({
                          ...formData, ["extension_approved"]: event.target.checked
                        })
                      }}
                      {...register("extension_approved")}
                    />
                    <span className="w-14 h-8
                                flex items-center
                                flex-shrink-0
                                mr-4 p-1
                                bg-lightGray
                                rounded-full
                                duration-300 ease-in-out
                                peer-checked:bg-tenzingBlue
                                after:w-6
                                after:h-6
                                after:bg-white
                                after:rounded-full
                                after:shadow-md
                                after:duration-300
                                peer-checked:after:translate-x-6
                                "></span>
                  </label>
                </div>
              </div>
            </Field>
          }

          {/* USER ID */}
          <Field>
            <label htmlFor="user_id" className="text-tenzingGray">Responsible (Optional):</label>
            <select
              id="user_id"
              placeholder="Assigned To"
              {...register("user_id", {required: false})}
              className={!errors.user_id ? "input-text" : 'input-text-error'}
              value={formData.user_id ?? formData.user?.id ?? ""}
              onChange={handleFormChange}
            >
              <option value="">-- Select --</option>
              {userOptions}
            </select>
            <FieldInputError item={errors.user_id}></FieldInputError>
          </Field>

          {/* STATUS */}
          <Field>
            <label htmlFor="status" className="text-tenzingGray ">Activity Status:</label>
            <select
              id="status"
              name="status"
              className={!errors.status ? "input-text" : 'input-text-error'}
              {...register("status", {required: true})}
              value={formData.status ? getNumberStatus(formData.status) : ""}
              onChange={handleFormChange}
            >
              <option value=""> -- Select --</option>
              {statusOptions}
            </select>
            <FieldInputError item={errors.status}></FieldInputError>
          </Field>

          <section className="flex justify-between">
            <button onClick={() => setShowForm(false)} className="align-middle mt-6 gray-transparent-button">Cancel
            </button>
            <Button buttonStyle={(loading || doDisable) ? "mt-6 gray-solid-button" : "mt-6 orange-solid-button"}
                    type="submit"
                    disabled={loading || doDisable}>
              {
                doDisable ?
                  <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                  : <></>
              }
              Update Activity
            </Button>
          </section>
        </form>
      }
    </section>
  )
}

export default EditActivityForm;


