import React, {useState, useEffect} from "react";
import {client as apiClient} from '../../../../utils/api';
import {
  useStyles,
  reorder
} from '../../../../utils/toolBox';
import DraggableList from '../../../Draggable/DraggableList';
import Section from '../../../../Components/Common/Section'
import Button from '../../../../Components/Common/Button';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  modulePoolStepPool, moduleSteps,
  processStepsSetup
} from "../../../../utils/queryKeys";
import {useQuery, useQueryClient} from "react-query";
import {getModulePoolsStepPools} from "../../../../utils/queries";
import Modal from "../../../Common/Modal"
import CustomStepFields from "../CustomStepFields"


const StepDragList = ({
                        currentModulePoolId,
                        currentModId,
                        setStepUpdated
                      }) => {
  const [doDisable, setDoDisable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const queryClient = useQueryClient();

  // Pass this function to the modal
  const handleShowModal = (onoff) => {
    setShowModal(onoff)
  }

  const stepPoolQuery = useQuery(
    [modulePoolStepPool, {modulePoolId: currentModulePoolId}],
    getModulePoolsStepPools)

  // As soon as we get the data we need to transmogrify them into drag list compatible format
  const step_pool_list =
    stepPoolQuery.isLoading ? []
      : stepPoolQuery.data?.data?.length > 0 ? stepPoolQuery.data?.data?.map((item) => {
        return (
          {
            key: `Step ${item.id}`,
            id: `${item.id}`,
            primary: item.attributes.name,
            secondary: item.attributes.description
          }
        )
      }, this) : [];

  // Load the Step Pool
  useEffect(() => {
    // check whether data exists
    if (!stepPoolQuery.loading && !!stepPoolQuery.data?.data) {
      setItems(step_pool_list)
    }
  }, [stepPoolQuery.data?.data, stepPoolQuery.loading])


  const onDragEnd = ({destination, source}) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };

  // Delete Steps from list
  const onDeleteItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  }


  const resetSteps = () => {
    setItems(step_pool_list)
  }

  const notifySuccess = () => toast.success("Process Steps added successfully!");
  const notifyError = () => toast.error("Adding Process Steps failed.")

  const {handleSubmit, formState: {errors}} = useForm();

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      // Post to modules
      await apiClient.post(processStepsSetup, {
        list: items,
        process_module_id: currentModId
      }).then((response) => {
        notifySuccess()
        setLoading(false)
        setDoDisable(false)
        setStepUpdated(true)
        // Reload Process Modules
        queryClient.refetchQueries([moduleSteps, {processModuleId: currentModId}]);
      }).catch((error) => {
        notifyError()
        setLoading(false)
        setDoDisable(false)
      });

    } catch (err) {
      notifyError()
      setLoading(false)
      setDoDisable(false)
      throw new Error(err)
    }
  }

  return (
    <Section>
      <div className="flex justify-between mb-4 xl:w-96">
        <h1 className="Section-h1">Steps</h1>
        <div className="flex flex-row">
          <Button onClick={() => resetSteps()} buttonStyle={"orange-transparent-button"}>
            Reset
          </Button>
          <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                    disabled={loading || doDisable}>
              {
                doDisable ?
                  <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                  : <></>
              }
              Add All
            </Button>
          </form>
        </div>
      </div>
      <div className={useStyles.root}>
        {
          stepPoolQuery.isLoading ?
            <div className="lds-dual-ring"></div>
            :
            <div className={useStyles.flexPaper}>
              <DraggableList type="Step"
                             onDeleteItem={onDeleteItem}
                             items={items}
                             onDragEnd={onDragEnd}
                             handleShowModal={handleShowModal}
              />
            </div>
        }
      </div>

      {/*Modal for the Custom Modal fields */}
      <Modal mdlTitle="Add Custom Step"
             buttonStyle=""
             content={CustomStepFields({handleShowModal, currentModulePoolId})}
             btnLabel=""
             handleShowModal={handleShowModal}
             showModal={showModal}
      />
    </Section>
  );

}

export default StepDragList;

