import React from "react";


const Modal = ({
                 mdlTitle,
                 buttonStyle,
                 content,
                 handleShowModal,
                 btnLabel,
                 showModal
               }) => {


  return (
    <>
      <button
        className={buttonStyle}
        type="button"
        onClick={() => handleShowModal(true)}
      >{btnLabel}</button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div
                className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div style={{minWidth: "30em"}} className="flex items-start justify-between p-5">
                  <h3 className="text-3xl font-semibold mr-4">{mdlTitle}</h3>
                  <button
                    className="p-1 ml-auto bg-transparent text-gray-600 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => handleShowModal(false)}>X
                  </button>
                </div>
                {/* Add a form in here */}
                {content}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}


export default Modal; 
