import React, {useState, useRef} from "react";
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import Button from '../../../Components/Common/Button';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Section from 'Components/Common/Section'
import {MenuProps, useStyles} from '../Utils/Index'
import {
  projectTasksNotPendingIndex,
  companyTasks
} from "utils/queryKeys";
import {
  getProjectTasks,
  getCompanyTasks
} from "utils/queries";
import CircularProgress from "@mui/material/CircularProgress";
import {toast} from "react-toastify";
import MenutItemWithBadge from "../../Common/Badges/MenutItemWithBadge";

function TaskPicker({setSelectedLabels}) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedLabelId, setSelectedLabelId] = useState([]);
  const taskListRef = useRef(null)

  const params = useParams()
  const classes = useStyles();

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  // We need either the project tasks or the company tasks
  const compTasks = useQuery(
    [companyTasks, {
      companyId: companyId,
      status: 'not_pending',
      view: 'index'
    }],
    getCompanyTasks,
    {enabled: !!!params['projectId']})

  const projTasks = useQuery(
    [projectTasksNotPendingIndex, {
      projectId: params['projectId'],
      status: 'not_pending',
      view: 'index'
    }],
    getProjectTasks,
    {enabled: !!params['projectId']})


  let tasksQuery = []
  let isAllSelected = false
  if (!!params['projectId']) {
    tasksQuery = !projTasks.isLoading && projTasks.data?.data
    isAllSelected = tasksQuery
      && tasksQuery.length > 0
      && selected.length === tasksQuery.length;
  } else {
    tasksQuery = !compTasks.isLoading && compTasks.data?.data
    isAllSelected = tasksQuery
      && tasksQuery.length > 0
      && selected.length === tasksQuery.length;
  }


  const handleChange = (event) => {
    const value = event.target.value;
    if (event.currentTarget.dataset.labelid == undefined || event.currentTarget.dataset.labelid == '') {
      toast.warning(`No Tag assigned to ${event.currentTarget.dataset.value} Task.`);
      return;
    }
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === tasksQuery.length ? [] : tasksQuery.map(task => task.attributes["name"]));
      setSelectedLabelId(selectedLabelId.length === tasksQuery.length ? [] : tasksQuery.map(task => task.attributes["gmail_labelid"]));
      return;
    }
    setSelected(value);
    if (!selected.includes(event.currentTarget.dataset.value)) {
      setSelectedLabelId([...selectedLabelId, event.currentTarget.dataset.labelid]);
    } else if (selected.includes(event.currentTarget.dataset.value)) {
      setSelectedLabelId(selectedLabelId.filter(label_id => label_id !== event.currentTarget.dataset.labelid))
    }
  }

  const handleFilterClick = () => {
    if (params['projectId'] != undefined && params['projectId'] != '') {
      const projectValue = JSON.parse(window.localStorage.getItem("projectValue") ?? '{}');
      setSelectedLabels([projectValue?.project?.attributes["gmail_labelid"], ...selectedLabelId])
    } else {
      setSelectedLabels(selectedLabelId)
    }
  }


  return (
    <Section>
      <div style={{height: "100%"}} className="flex justify-between">
        <h1 className="Section-h1">Filter by Task label</h1>
        <section>
          <Button variant="text"
                  buttonStyle="text-gray-600 mr-4"
                  type="button"
                  onClick={() => {
                    setSelected([])
                    setSelectedLabelId([])
                    if (params['projectId'] != undefined && params['projectId'] != '') {
                      const projectValue = JSON.parse(window.localStorage.getItem("projectValue") ?? '{}');
                      setSelectedLabels(current => [projectValue?.project?.attributes["gmail_labelid"]])
                    } else {
                      setSelectedLabels(current => [])
                    }
                  }}
          >Clear</Button>
          <Button type="button"
                  onClick={handleFilterClick}
                  buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={loading || doDisable}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Filter
          </Button>
        </section>
      </div>
      {!tasksQuery ? <div>No tasks available...</div> :
        <FormControl className={classes.formControl}>
          <InputLabel id="mutiple-select-label">Select Tasks</InputLabel>
          <Select
            labelId="mutiple-select-label"
            ref={taskListRef}
            multiple
            value={selected}
            onChange={handleChange}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : ""
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{indeterminate: classes.indeterminateColor}}
                  sx={{
                    color: "#E66B19",
                    '&.Mui-checked': {
                      color: "#E66B19",
                    },
                  }}
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < tasksQuery.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.selectAllText}}
                primary="Select All"
              />
            </MenuItem>
            {!!tasksQuery && tasksQuery.map((task) => (
              <MenuItem key={task.id}
                        data-labelid={task.attributes['gmail_labelid'] ?? ''}
                        value={task.attributes["name"]}>
                <ListItemIcon>
                  <Checkbox
                    checked={selected.indexOf(task.attributes["name"]) > -1}
                    sx={{
                      color: "#E66B19",
                      '&.Mui-checked': {
                        color: "#E66B19",
                      },
                    }}
                  />
                </ListItemIcon>
                {
                  !!!params['projectId'] ?
                    <ListItemText primary={<MenutItemWithBadge value={task.attributes["name"]}
                                                               badgeValue={task?.attributes?.project?.['identifier']}
                                                               badgeColor={task?.attributes?.project?.['color_identifier']}/>}/>
                    :
                    <ListItemText primary={task.attributes["name"]}/>
                }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </Section>
  );
}

export default TaskPicker;

