import React, {useEffect, useState} from 'react';
import {
  Switch,
  Route,
  Link,
  useLocation,
  useParams
} from 'react-router-dom';
import {useQuery} from 'react-query';
import List from '../../Components/Tasks/List/List';
import Section from 'Components/Common/Section';
import Form from 'Components/Tasks/Form';
import {
  projectCriticalTasksIndex,
  projectTasksNotPendingIndex,
  projectMyTasks,
  project as projectKey,
  projectPendingTasks,
  googleMailToken
} from "../../utils/queryKeys";
import {
  getProjectCriticalTasks,
  getProjectTasks,
  getMyProjectTasks,
  getProject,
  getProjectPendingTasks,
  getGoogleMailAccessToken
} from "../../utils/queries";
import {
  DiscoveryDocs,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "../../utils/google/Auth";
import
  ContentTwoColumn, {WideColumn, NarrowColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import {gapi} from "gapi-script";
import SendEmail from "../../Components/Correspondence/Inbox/SendEmail";
import DropZone from "../../Components/BoxDropZone/DropZone";
import QuickNote from "Components/QuickNotes"
import {emailContext} from "../../Components/Correspondence/Utils/Index";
import {CorrespondenceContext} from "../../context/CorrespondenceContext";

function Index() {
  const [showForm, setShowForm] = useState(true)
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [objectLabelId, setObjectLabelId] = useState(null)
  const [objectLabelName, setObjectLabelName] = useState("")
  const [dropZoneVisibility, setDropZoneVisibility] = useState(false)
  const [dropZoneObject, setDropZoneObject] = useState("")
  const [selectedLabels, setSelectedLabels] = useState([])
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [correspondenceContext, setCorrespondenceContext] = useState(emailContext);

  const currentURL = useLocation()
  const params = useParams()

  const project = useQuery([projectKey, {id: params.projectId}], getProject)

  const tasks = useQuery(
    [projectTasksNotPendingIndex, {
      projectId: params['projectId'],
      status: 'not_pending',
      view: 'index'
    }],
    getProjectTasks)

  const criticalTasks = useQuery(
    [projectCriticalTasksIndex, {
      projectId: params['projectId'],
      status: 'not_pending',
      view: 'index'
    }],
    getProjectCriticalTasks)

  const pendingTasks = useQuery(
    [projectPendingTasks, {
      projectId: params['projectId'],
      status: 'pending',
      view: 'index'
    }],
    getProjectPendingTasks)

  const myTasks = useQuery(
    [projectMyTasks, {
      projectId: params['projectId'],
      assigneeId: window.localStorage.getItem("assignee_id")
    }],
    getMyProjectTasks)

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  if(tasks.isLoading
    || criticalTasks.isLoading
    || pendingTasks.isLoading
    || project.isLoading){
    return(<div className="lds-dual-ring"></div>)
  }

  return (
    <>
      {!tasks.isLoading
       && tasks.isSuccess
       && !pendingTasks.isLoading
       && pendingTasks.isSuccess
       && !criticalTasks.isLoading
       && criticalTasks.isSuccess
       && !project.isLoading
       && project.isSuccess ?
        <ContentTwoColumn>
          <WideColumn>
            <Section>
              <div className="flex flex-row justify-between">
                <section className="flex flex-row space-x-8 mb-4">
                  <Link to={`/projects/${params['projectId']}/tasks`}
                        className={`pb-2 Section-h1 ${currentURL.pathname === "/projects/" + params['projectId'] + "/tasks" ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                    ALL TASKS
                  </Link>
                  <Link to={`/projects/${params['projectId']}/tasks/critical`}
                        className={`pb-2 Section-h1 ${currentURL.pathname === "/projects/" + params['projectId'] + "/tasks/critical" ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                    CRITICAL TASKS
                  </Link>
                  <Link to={`/projects/${params['projectId']}/tasks/pending`}
                        className={`pb-2 Section-h1 ${currentURL.pathname === "/projects/" + params['projectId'] + "/tasks/pending" ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                    PENDING TASKS
                  </Link>
                  <Link to={`/projects/${params['projectId']}/tasks/mine`}
                        className={`pb-2 Section-h1 ${currentURL.pathname === "/projects/" + params['projectId'] + "/tasks/mine" ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                    MY {project.data?.data?.attributes?.["name"]} TASKS
                  </Link>
                </section>
              </div>
              {/*Documenation: https://reactrouter.com/docs/en/v6/upgrading/v5#upgrade-to-react-router-v6*/}
              {/* fixme: Fetch the data for each query only when needed*/}
              <Switch>
                <Route exact path="/projects/:projectId/tasks/critical"
                       component={() => (<List items={criticalTasks.data?.data}
                                               gapi={gapi}
                                               setShowSendEmail={setShowSendEmail}
                                               setShowForm={setShowForm}
                                               setObjectLabelId={setObjectLabelId}
                                               setObjectLabelName={setObjectLabelName}
                                               setDropZoneVisibility={setDropZoneVisibility}
                                               setDropZoneObject={setDropZoneObject}
                                               setSelectedLabels={setSelectedLabels}/>)}/>
                <Route exact path="/projects/:projectId/tasks/pending"
                       component={() => (<List items={pendingTasks.data?.data}
                                               gapi={gapi}
                                               setShowSendEmail={setShowSendEmail}
                                               setShowForm={setShowForm}
                                               setObjectLabelId={setObjectLabelId}
                                               setObjectLabelName={setObjectLabelName}
                                               setDropZoneVisibility={setDropZoneVisibility}
                                               setDropZoneObject={setDropZoneObject}
                                               setSelectedLabels={setSelectedLabels}/>)}/>
                <Route exact path="/projects/:projectId/tasks/mine"
                       component={() => (<List items={myTasks.data?.data}
                                               gapi={gapi}
                                               setShowSendEmail={setShowSendEmail}
                                               setShowForm={setShowForm}
                                               setObjectLabelId={setObjectLabelId}
                                               setObjectLabelName={setObjectLabelName}
                                               setDropZoneVisibility={setDropZoneVisibility}
                                               setDropZoneObject={setDropZoneObject}
                                               setSelectedLabels={setSelectedLabels}/>)}/>
                <Route exact path="/projects/:projectId/tasks"
                       component={() => (<List items={tasks.data?.data}
                                               gapi={gapi}
                                               setShowSendEmail={setShowSendEmail}
                                               setShowForm={setShowForm}
                                               setObjectLabelId={setObjectLabelId}
                                               setObjectLabelName={setObjectLabelName}
                                               setDropZoneVisibility={setDropZoneVisibility}
                                               setDropZoneObject={setDropZoneObject}
                                               setSelectedLabels={setSelectedLabels}/>)}/>
              </Switch>
            </Section>
          </WideColumn>
          <NarrowColumn hidden={!tasks.data?.permissions?.['create']}>
            {
              tasks.data?.permissions?.['create'] && showForm ?
                <>
                  <Form gapi={gapi}
                        project={project}
                        setSelectedProjectId={setSelectedProjectId}
                        selectedProjectId={selectedProjectId}/>
                  <QuickNote projectId={project?.data?.data?.id}/>
                </>
                : null
            }
            {showSendEmail
            && isAuthorized
            && objectLabelId ?
              <CorrespondenceContext.Provider value={[correspondenceContext, setCorrespondenceContext]}>
                <SendEmail gapi={gapi}
                           objectLabelId={objectLabelId}
                           setShowSendEmail={setShowSendEmail}
                           setShowForm={setShowForm}
                           objectLabelName={objectLabelName}
                           selectedLabels={selectedLabels}
                           labelListUpdated={false}/>
              </CorrespondenceContext.Provider>
              : null}
            {
              dropZoneVisibility ?
                <DropZone dropZoneObject={dropZoneObject}
                          setDropZoneVisibility={setDropZoneVisibility}
                          setShowSendEmail={setShowSendEmail}
                          setShowForm={setShowForm}/> : null
            }
          </NarrowColumn>
        </ContentTwoColumn> : null

      }
    </>
  )
}


export default Index
