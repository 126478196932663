import React, {useState, useEffect} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import PhaseBox from '../Boxes/phaseBox';
import ModuleBox from '../Boxes/moduleBox';
import TaskGrid from '../Boxes/taskGrid';
import {getProjectPhases, getProjectPhaseModules} from "../../../utils/queries";
import {projectPhases, projectPhaseModules, processModule} from "../../../utils/queryKeys";
import {useParams} from "react-router-dom";

const Map = ({
               currentPhaseId,
               setCurrentPhaseId,
               currentModId,
               setCurrentModId,
               projectId,
               onModulesConfigUpdate,
               onPhaseConfigUpdate,
               setStepsConfig,
               stepsConfig,
               setCurrentPhasePoolId,
               setCurrentModulePoolId,
               setTasksConfig,
               setCurrentTask,
               setCurrentTaskId,
               currentTaskId,
               taskUpdated,
               setTaskUpdated,
               setModuleUpdated,
               moduleUpdated,
               stepUpdated,
               setStepUpdated,
               locked
             }) => {
  const [autoSelectMod, setAutoSelectMod] = useState(false)
  const [modRendering, setModRendering] = useState(null)
  const [phaseRendering, setPhaseRendering] = useState(null)
  const [moduleCopied, setModuleCopied] = useState(false)
  const [phaseCopied, setPhaseCopied] = useState(false)

  const queryClient = useQueryClient();
  const params = useParams()


  const phaseQuery = useQuery(
    [projectPhases, {projectId: projectId}],
    getProjectPhases,
    {
      cacheTime: phaseCopied ? 0 : 300000,
      staleTime: phaseCopied ? 0 : 300000,
    }
  )

  // Load the Module Pool
  useEffect(() => {
    // check whether data exists
    if (!phaseQuery.loading
      && !!phaseQuery.data?.data
      && phaseQuery.data?.data?.length > 0) {
      setCurrentPhaseId(phaseQuery.data?.data?.id)
      onPhaseConfigUpdate(false)
    }
  }, [phaseQuery.data?.data, phaseQuery.loading])

  // Dependent query: https://react-query.tanstack.com/guides/dependent-queries
  const phaseModules = useQuery(
    [projectPhaseModules, {phaseId: params['id'], projectId: params['projectId']}],
    getProjectPhaseModules,
    {
      enabled: !!currentPhaseId,
      cacheTime: moduleCopied ? 0 : 300000,
      staleTime: moduleCopied ? 0 : 300000,
    }
  )

  // Fetch and refresh the list of modules or phases
  useEffect(() => {
    if(moduleCopied) {
      queryClient.invalidateQueries([projectPhaseModules, {phaseId: params['id'], projectId: params['projectId']}])
      setModuleCopied(false)
    }
    if(phaseCopied) {
      queryClient.invalidateQueries([projectPhases, {projectId: projectId}])
      setPhaseCopied(false)
    }
  }, [moduleCopied || phaseCopied])

  function phaseClick(id, phase_pool_id, phase) {
    // XXX Already rendering the correct phase box. No need to reload
    if (phaseRendering === id) {
      return
    }

    // This is a phase-task
    if (phase?.data?.data?.attributes?.task != null) {
      setCurrentTaskId(phase?.data?.data?.attributes?.task?.id)
      setCurrentTask(phase?.data?.data?.attributes?.task)
      setTasksConfig(true)
      onModulesConfigUpdate(false)
    } else {
      setCurrentTaskId(null)
      // XXX Render the module form and let the module box to close it if needed
      onModulesConfigUpdate(true)
      setTasksConfig(false)
    }

    setPhaseRendering(id)
    setCurrentPhasePoolId(phase_pool_id)
    setCurrentPhaseId(id)
    setCurrentModId(null)
    setAutoSelectMod(false)
    setStepsConfig(false)
  }

  function modClick(id, module_pool_id, process_module) {
    // XXX Already rendering the correct phase box. No need to reload
    if (modRendering === id) {
      return
    }

    // This is a module-task
    if (process_module?.data?.data?.attributes?.task != null) {
      setCurrentTaskId(process_module?.data?.data?.attributes?.task?.id)
      setCurrentTask(process_module?.data?.data?.attributes?.task)
      setTasksConfig(true)
      setStepsConfig(false)
    } else {
      setCurrentTaskId(null)
      // XXX Render the module form and let the module box to close it if needed
      setStepsConfig(true)
      setTasksConfig(false)
    }

    setModRendering(id)
    setCurrentModulePoolId(module_pool_id)
    setCurrentModId(id)
  }

  return (
    <div className="p-1 border-t border-x border-outlineGray">
      <div className="mt-2 flex space-x-2 justify-evenly w-full items-center">
        {phaseQuery.isLoading ? <div className="lds-dual-ring"></div> :
          phaseQuery.data?.data?.length > 0 ?
            phaseQuery.data?.data?.map(phase => {
              return (
                <PhaseBox
                  key={"phase_box-" + phase.id.toString()}
                  id={phase.id}
                  phase_pool_id={phase.attributes.phase_pool.id}
                  currentPhaseId={currentPhaseId}
                  phaseClick={phaseClick}
                  setAutoSelectMod={setAutoSelectMod}
                  projectId={projectId}
                  onPhaseConfigUpdate={onPhaseConfigUpdate}
                  onModulesConfigUpdate={onModulesConfigUpdate}
                  setCurrentTask={setCurrentTask}
                  setCurrentTaskId={setCurrentTaskId}
                  setTasksConfig={setTasksConfig}
                  setModuleUpdated={setModuleUpdated}
                  moduleUpdated={moduleUpdated}
                  setPhaseCopied={setPhaseCopied}
                  phaseCopied={phaseCopied}
                  locked={locked}
                  setTaskUpdated={setTaskUpdated}
                  taskUpdated={taskUpdated}>
                </PhaseBox>
              )
            }) : null
        }
      </div>

      {(phaseModules.isIdle || phaseModules.isLoading) ?
        <></> :
        phaseModules.data?.data?.length ?
          <div className="flex space-evenly flex-wrap w-full">
            {phaseModules.data?.data?.map((mod, i) => {
              return (
                <div className="flex items-center">
                  <ModuleBox
                    key={"mod_box-" + mod.attributes.module_pool["name"] + "-" + mod.attributes.id.toString()}
                    id={mod.id}
                    module_pool_id={mod.attributes.module_pool.id}
                    currentModId={currentModId}
                    modClick={modClick}
                    autoSelectMod={autoSelectMod}
                    firstMod={i == 0 ? true : false}
                    onModulesConfigUpdate={onModulesConfigUpdate}
                    setStepsConfig={setStepsConfig}
                    setCurrentTask={setCurrentTask}
                    setCurrentTaskId={setCurrentTaskId}
                    setTasksConfig={setTasksConfig}
                    setModuleUpdated={setModuleUpdated}
                    moduleUpdated={moduleUpdated}
                    stepUpdated={stepUpdated}
                    setStepUpdated={setStepUpdated}
                    setModuleCopied={setModuleCopied}
                    moduleCopied={moduleCopied}
                    locked={locked}
                    setTaskUpdated={setTaskUpdated}
                    taskUpdated={taskUpdated}>
                  </ModuleBox>
                  {i === (phaseModules.data?.data?.length - 1) ? "" :
                    <svg xmlns="http://www.w3.org/2000/svg"
                         key={"mod_box-arrow" + mod.attributes.module_pool["name"] + "-" + mod.attributes.id.toString()}
                         className="h-5 w-5"
                         viewBox="0 0 20 20"
                         fillRule="currentColor">
                      <path fillRule="evenodd"
                            key={"mod_box-arrow-path" + mod.attributes.module_pool["name"] + "-" + mod.attributes.id.toString()}
                            d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"/>
                    </svg>
                  }
                </div>
              )
            })}
          </div> : <></>
      }

      {!currentModId ? null :
        <TaskGrid
          currentTaskId={currentTaskId}
          setCurrentTask={setCurrentTask}
          setCurrentTaskId={setCurrentTaskId}
          currentPhaseId={currentPhaseId}
          currentModId={currentModId}
          setStepsConfig={setStepsConfig}
          stepsConfig={stepsConfig}
          setTasksConfig={setTasksConfig}
          taskUpdated={taskUpdated}
          setTaskUpdated={setTaskUpdated}
        ></TaskGrid>
      }

    </div>
  )
}

export default Map;
