import React, {useState} from 'react'
import {client as apiClient} from 'utils/api';
import {useQuery} from 'react-query'
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import Button from 'Components/Common/Button';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {
  Field
} from 'Components/Common/Forms';
import {
  allUsers, companyUserRoles,
  contact as contactQuery,
} from "../../../utils/queryKeys";
import {getCompanyUserRoles} from "../../../utils/queries";
import {availableRoles} from '../Utils/index'
import CircularProgress from "@mui/material/CircularProgress";

function CreateUserForm({
                          contactId,
                          setShowEditContact,
                          setShowCreateUser,
                          setShowEditUser
                        }) {
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({})
  const {register, handleSubmit, setValue, setError, reset, formState: {errors}} = useForm();

  // Fetch the user Roles
  const roleQuery = useQuery([companyUserRoles, {companyId: companyId}], getCompanyUserRoles)

  const roleOptions =
    !roleQuery.isLoading
    && roleQuery.isSuccess
    && roleQuery.data != undefined
    && roleQuery.data.map((item) => {
      if(availableRoles.includes(item.attributes.name))
        return (
          <option key={"role" + item + item.id} value={item.id}>{item.attributes.name}</option>
        )
      }, this)

  const notifySuccess = () => toast.success("User created!");
  const notifyError = () => toast.error("User create failed.")

  const handleFormChange = (e) => {
    setFormData({
      ...formData, [e.target.id]: e.target.value
    })
    // Set the value
    setValue(e.target.id, e.target.value)
  }

  async function postForm(data) {
    // Unpack values to variable
    const {email, status, password, user_role} = data
    // Make the request and wait for the response
    return await apiClient.post("/users/", {
      "user": {
        status: status.trim(),
        email: email.trim(),
        contact_id: contactId,
        password: password.trim(),
        user_role_id: user_role
      }
    })
  }

  const {isLoading: postUserLoading, mutateAsync: sendData} = useMutation(postForm);

  const onSubmit = async (data, e) => {
    try {
      await sendData(data)
      queryClient.refetchQueries([contactQuery, {id: contactId}])
      queryClient.refetchQueries([allUsers, {contactId: contactId}])
      reset()
      e.target.reset()
      setShowEditContact(true)
      setShowCreateUser(false)
      notifySuccess()
    } catch (err) {
      notifyError()
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  if (roleQuery.isLoading) {
    return (<span className="block lds-dual-ring"></span>)
  }

  return (
    roleQuery.isSuccess
    && roleQuery.data != undefined ?
      <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <Field>
          <label htmlFor="email" className="text-tenzingGray">Email Address</label>
          <input
            id="email"
            type="text"
            placeholder="email"
            {...register("email", {
              required: true,
              pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Invalid email format."}
            })}
            value={formData.email ?? ""}
            onChange={handleFormChange}
            className={!errors.email ? "input-text" : 'input-text-error'}
          />
          <FieldInputError item={errors.email}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="password" className="text-tenzingGray">Password</label>
          <input
            id="password"
            type="password"
            placeholder="password"
            {...register("password", {
              required: true,
              pattern: {value: /^.{6,}$/, message: "Password should be at least 6 characters long."}
            })}
            value={formData.password ?? ""}
            onChange={handleFormChange}
            className={!errors.password ? "input-text" : 'input-text-error'}
          />
          <FieldInputError item={errors.password}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="status" className="text-tenzingGray">Status</label>
          <select
            id="status"
            name="status"
            {...register("status", {required: true})}
            className={!errors.status ? "input-text" : 'input-text-error'}
            value={formData.status ?? ""}
            onChange={handleFormChange}
          >
            <option value="active">Active</option>
            <option value="removed">Removed</option>
          </select>
          <FieldInputError item={errors.status}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="impact" className="text-tenzingGray">User Role</label>
          <select
            id="user_role"
            name="user_role"
            {...register("user_role", {required: true})}
            className={!errors.user_role ? "input-text" : 'input-text-error'}
            value={formData.user_role ?? ""}
            onChange={handleFormChange}
          >
            {roleOptions}
          </select>
          <FieldInputError item={errors.user_role}></FieldInputError>
        </Field>

        <div className="flex items-center justify-end p-4">
          <button
            className="underline text-gray-400 py-0.5 px-4 m-1"
            onClick={() => {
              setShowEditContact(true)
              setShowEditUser(false)
              setShowCreateUser(false)
            }}
          >Cancel
          </button>
          <Button
            buttonStyle={(postUserLoading) ? "gray-solid-button" : "orange-solid-button"}
            disabled={(postUserLoading)}>
            {
              (postUserLoading) ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add
          </Button>
        </div>
      </form> : null
  )

}

export default CreateUserForm;
