import React, {useEffect, useState, useRef} from "react"
import {DropzoneArea} from "material-ui-dropzone";
import Section from '../Common/Section'
import Button from '../Common/Button';
import {toast} from "react-toastify";
import {client as apiClient} from "../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import {useQueries} from "react-query";
import Checkbox from "@material-ui/core/Checkbox";
import {
  uploadFileKey,
  uploadFilePost,
  uploadFileVersionKey,
  uploadFileVersionPost
} from "../../utils/boxApi/Queries";

// This module is used to upload files to BOX
function DropZone({
                    dropZoneObject,
                    setDropZoneVisibility,
                    setShowSendEmail,
                    setShowForm
                  }) {

  const [newFiles, setNewFiles] = useState([])
  const [newVersionFileList, setNewVersionFileList] = useState([])
  const [versioningChecked, setVersioningChecked] = useState(false)
  const [hasBoxId, setHasBoxId] = useState(false);
  const [postFiles, setPostFiles] = useState(false);
  const accessToken = useRef(null)

  // Drop zone Add files
  const handleDZFilesAdd = (files) => {
    setNewFiles(newFiles => [...newFiles, ...files])
  }

  // Drop zone Remove files
  function handleDZFilesRemove(file) {
    setNewFiles(newFiles => newFiles.filter((fw) => fw !== file));
  }

  const fnSuccess = (msg) => {
    toast.success(msg)
  };

  const fnFail = (msg) => {
    toast.error(msg)
  };

  // Handle existence or not of the Box Folder Id
  useEffect(() => {
    if (dropZoneObject['box_folderid'] != undefined) {
      setHasBoxId(true)
    } else {
      setHasBoxId(false)
    }
    setPostFiles(false)
  }, [dropZoneObject['id']])

  // Get the access token
  useEffect(() => {
    // Get access token
    apiClient.get("box_authorizes/token")
      .then((response) => {
        accessToken.current = response.data.attributes
      })
      .catch((error) => {
        fnFail(error);
      });
  }, []);


  const uploadedFiles = useQueries(
    newFiles && newFiles.map(file => {
      return {
        queryKey: [uploadFileKey, {
          file: file,
          accessToken: accessToken.current,
          box_folderid: dropZoneObject.box_folderid
        }],
        queryFn: () => uploadFilePost([uploadFileKey, {
          file: file,
          accessToken: accessToken.current,
          box_folderid: dropZoneObject.box_folderid
        }]),
        enabled: postFiles,
        cacheTime: 0,
        retry: 1,
        onError: (error) => handleError(error, [uploadFileKey, {
          file: file,
          accessToken: accessToken.current,
          box_folderid: dropZoneObject.box_folderid
        }]),
        onSuccess: (data) => handleSuccess(data, "", file)
      }
    })
  )

  const isFetching = uploadedFiles && uploadedFiles.some(query => query.isFetching)

  const handleSuccess = (data, msg = "", file = null) => {
    // // In here i can remove the file from the list after the upload
    // if(file) {
    //   setNewFiles(newFiles => newFiles.filter((fw) => fw !== file));
    // }

    if (msg !== "") {
      fnSuccess(`${data.data.entries[0].name} successfully uploaded. (${msg})`)
    } else {
      fnSuccess(`${data.data.entries[0].name} successfully uploaded.`)
    }
  }

  const handleError = (error, queryKey) => {
    fnFail(error.response.data.message)

    // This is a name conflict. Push a new version of the file
    if (error.response.status === 409 && versioningChecked) {
      const [_, params] = queryKey
      const newVersion = {
        fileId: error.response.data.context_info.conflicts.id,
        file: params.file
      }

      setNewVersionFileList(newVersionFileList => [...newVersionFileList, newVersion])
    }
  }

  const handleVersionError = (error, queryKey) => {
    const [_, params] = queryKey
    setNewVersionFileList(newVersionFileList => newVersionFileList.filter((fw) => fw.file !== params.file));
    handleError(error, queryKey)
  }

  const handleVersionSuccess = (data, file) => {
    setNewVersionFileList(newVersionFileList => newVersionFileList.filter((fw) => fw.file !== file));
    handleSuccess(data, "New Version", file)
  }

  const newVersionQueries = useQueries(
    newVersionFileList && newVersionFileList.map(version => {
      return {
        queryKey: [uploadFileVersionKey, {
          file: version.file,
          accessToken: accessToken.current,
          box_folderid: dropZoneObject.box_folderid,
          fileId: version.fileId
        }],
        queryFn: () => uploadFileVersionPost([uploadFileVersionKey, {
          file: version.file,
          accessToken: accessToken.current,
          box_folderid: dropZoneObject.box_folderid,
          fileId: version.fileId
        }]),
        cacheTime: 0,
        retry: 1,
        enabled: !isFetching,
        onSuccess: (data) => handleVersionSuccess(data, version.file),
        onError: (error) => handleVersionError(error, [uploadFileKey, {
          file: file,
          accessToken: accessToken.current,
          box_folderid: dropZoneObject.box_folderid
        }]),
      }
    })
  )

  useEffect(() => {
    if (!isFetching) {
      setPostFiles(false)
    }
  }, [isFetching])


  const handleVersioningCheckedChange = (event) => {
    setVersioningChecked(event.target.checked);
  };

  return (
    <>
      {/*  DropZone*/}
      <Section>
        <h1 className='Section-h1 mb-2'>{dropZoneObject['name']}</h1>
        <div className="flex justify-between items-baseline">
          <div style={{width: '100%'}} className="mb-4 flex flex-col">
            <section className="mt-4 flex justify-between items-center mb-2">
              <label htmlFor="add_files"
                     className={hasBoxId ? "text-tenzingGray" : "text-tenzingRed underline decoration-2 "}>
                {hasBoxId ? 'Drop your files HERE' : 'Connection to Box has NOT been detected!'}
              </label>
            </section>
            <DropzoneArea
              acceptedFiles={
                ['image/*',
                  'video/*',
                  'application/pdf',
                  '.doc',
                  '.docx',
                  '.xls',
                  '.xlsx',
                  '.csv',
                  '.tsv',
                  '.ppt',
                  '.pptx',
                  '.pages',
                  '.odt',
                  '.rtf']}
              onDrop={handleDZFilesAdd}
              onDelete={handleDZFilesRemove}
              showFileNames
              dropzoneText="Attachments"
              showAlerts={false}
              filesLimit={20}
              // initialFiles = {[file]} // Fetch all the files using the directory
            />
            <div className="flex justify-between items-center mt-2">
              <div>
                <Button
                  type="button"
                  onClick={() => setPostFiles(true)}
                  buttonStyle={(isFetching || newFiles.length === 0 || !hasBoxId) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={isFetching || newFiles.length === 0 || !hasBoxId}>
                  {
                    isFetching ?
                      <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                      : <></>
                  }
                  Upload
                </Button>
                <Checkbox color="secondary"
                          disabled={isFetching || newFiles.length === 0 || !hasBoxId}
                          checked={versioningChecked}
                          onChange={handleVersioningCheckedChange}/>
                <span>Allow file versioning</span>
              </div>
              <Button variant="text"
                      buttonStyle="text-gray-600"
                      type="button"
                      onClick={() => {
                        setDropZoneVisibility(false)
                        setShowSendEmail(true)
                        setShowForm(true)
                      }}
              >Close</Button>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default DropZone;
