import React, {useEffect} from "react";
import InboxHtml from "./InboxHtml";
import {useQuery} from "react-query";
import {gmailGetList, gmailGetListKey} from "../../../utils/google/Queries";
import InboxLine from "./InboxLine";

function SentList({
                    isAuthorized,
                    gapi,
                    selectedLabels,
                    setShowForm,
                    setShowSendEmail,
                    setSelectedMessages,
                    selectedMessages,
                    setUpdateEmailList,
                    updateEmailList
                  }) {
  // Fetch all Inbox emails
  const mailSentList = useQuery(
    [gmailGetListKey, {
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      queue: 'in:sent',
      labelIds: selectedLabels
    }],
    gmailGetList,
    {
      enabled: (isAuthorized
        && gapi.auth != undefined
        && gapi.auth.getToken() != undefined)
    })

  const inboxSentList =
    mailSentList.isLoading ? <div className="lds-dual-ring"></div> :
      !!mailSentList.data
      && mailSentList.isSuccess
      && mailSentList.data.length > 0
      && mailSentList.data.map((message) => {
        return (
          <InboxLine
            key={message.id}
            mailListItem={message}
            gapi={gapi}
            tabType="sent"
            setShowForm={setShowForm}
            setShowSendEmail={setShowSendEmail}
            setSelectedMessages={setSelectedMessages}
            selectedMessages={selectedMessages}
            setUpdateEmailList={setUpdateEmailList}
            updateEmailList={updateEmailList}
          />
        )
      })

  return (
    <InboxHtml emailsList={inboxSentList}/>
  )
}

export default SentList;
