import React, {useEffect, useState} from 'react'
import Section from 'Components/Common/Section'
import Map from 'Components/Process/Map'
import {useParams} from "react-router-dom";
import PhaseDragList from "../../Components/Process/PhaseDragList";
import ModuleDragList from "../../Components/Process/ModuleDragList/List";
import StepDragList from "../../Components/Process/StepDragList/List";
import EditTaskForm from "../../Components/Tasks/Form/edittaskform"
import {useQuery} from "react-query";
import {
  boxAccessToken,
  project as projectKey,
  googleMailToken
} from "../../utils/queryKeys";
import {
  getBoxAccessToken,
  getProject,
  getGoogleMailAccessToken
} from "../../utils/queries";
import {gapi} from "gapi-script";
import {DiscoveryDocs, REACT_APP_CLIENT_ID, SCOPES} from "../../utils/google/Auth";
import SliderLock from 'Components/Process/Slider'
import EditModule from "../../Components/Process/Forms/editModule";
import EditPhase from "../../Components/Process/Forms/editPhase";

const Index = () => {
  const params = useParams()
  const [currentPhaseId, setCurrentPhaseId] = useState(null)
  const [currentModulePoolId, setCurrentModulePoolId] = useState(null)
  const [currentPhasePoolId, setCurrentPhasePoolId] = useState(null)
  const [currentModId, setCurrentModId] = useState(null)
  const [phasesConfig, setPhasesConfig] = useState(true)
  const [modulesConfig, setModulesConfig] = useState(false)
  const [stepsConfig, setStepsConfig] = useState(false)
  const [tasksConfig, setTasksConfig] = useState(false)
  const [currentTask, setCurrentTask] = useState(null)
  const [currentTaskId, setCurrentTaskId] = useState(null)
  const [taskUpdated, setTaskUpdated] = useState(false)
  const [moduleUpdated, setModuleUpdated] = useState(false)
  const [phaseUpdated, setPhaseUpdated] = useState(false)
  const [stepUpdated, setStepUpdated] = useState(false)
  const [locked, setLocked] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)


  const project = useQuery(
    [projectKey, {id: params['projectId']}],
    getProject,
    {
      cacheTime: locked ? 0 : 300000,
      staleTime: locked ? 0 : 300000
    })

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  const onPhaseConfigUpdate = (onoff) => {
    setPhasesConfig(onoff)
  }

  const onModulesConfigUpdate = (onoff) => {
    setModulesConfig(onoff)
  }

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)

    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  const bexpConfig = {
    boxable: currentTask?.data,
    access_token: boxAccess,
    taskId: currentTaskId,
    processMap: true
  }


  // use to set lock status once we have it. 
  useEffect(() => {
    if (!project.isLoading && !!project.data?.data?.attributes) {
      setLocked(Boolean(project.data?.data?.attributes?.process_map_locked))
    }
  }, [!project.isLoading && project.isSuccess])

  return (
    <div className='flex flex-col order-last lg:flex-row lg:order-first'>
      <div className='w-full'>
        <Section>
          <div className='flex justify-between'>
            <h1 className="Section-h1 mb-2">Process Map</h1>
            <SliderLock
              locked={locked}
              setLocked={setLocked}
              project={project}
            />
          </div>
          <Map
            currentPhaseId={currentPhaseId}
            setCurrentPhaseId={setCurrentPhaseId}
            currentModId={currentModId}
            setCurrentModId={setCurrentModId}
            projectId={params['projectId']}
            onModulesConfigUpdate={onModulesConfigUpdate}
            onPhaseConfigUpdate={onPhaseConfigUpdate}
            setStepsConfig={setStepsConfig}
            stepsConfig={stepsConfig}
            setCurrentPhasePoolId={setCurrentPhasePoolId}
            setCurrentModulePoolId={setCurrentModulePoolId}
            setTasksConfig={setTasksConfig}
            setCurrentTask={setCurrentTask}
            setCurrentTaskId={setCurrentTaskId}
            currentTaskId={currentTaskId}
            taskUpdated={taskUpdated}
            setTaskUpdated={setTaskUpdated}
            setModuleUpdated={setModuleUpdated}
            moduleUpdated={moduleUpdated}
            phaseUpdated={phaseUpdated}
            stepUpdated={stepUpdated}
            setStepUpdated={setStepUpdated}
            locked={locked}
          />
        </Section>
      </div>
      <div className='order-first lg:order-last lg:ml-2 w-1/3'>
        {/* PHASES */}
        {
          phasesConfig
          && !locked
          && project.data?.permissions?.['update'] ?
            <div className='lg:w-full'>
              <PhaseDragList/>
            </div> : null
        }
        {
          !locked
          && project.data?.permissions?.['update'] ?

            <div className='lg:w-full'>
              <EditPhase currentPhaseId={currentPhaseId}
                         setPhaseUpdated={setPhaseUpdated}
                         locked={locked}/>
            </div> : null
        }
        {/* MODULES */}
        {
          modulesConfig
          && !locked
          && project.data?.permissions?.['update'] ?
            <div className='lg:w-full'>
              <ModuleDragList currentPhasePoolId={currentPhasePoolId}
                              setModuleUpdated={setModuleUpdated}/>
            </div> : null
        }
        {
          !locked
          && project.data?.permissions?.['update'] ?
            <div className='lg:w-full'>
              <EditModule currentModId={currentModId}
                          setModuleUpdated={setModuleUpdated}
                          locked={locked}/>
            </div> : null
        }
        {/* STEPS */}
        {
          stepsConfig
          && !locked
          && project.data?.permissions?.['update'] ?
            <div className='lg:w-full'>
              <StepDragList currentModulePoolId={currentModulePoolId}
                            currentModId={currentModId}
                            setStepUpdated={setStepUpdated}/>
            </div> : null
        }
        {/* TASKS */}
        {
          isAuthorized
          && tasksConfig ?
            <div className='lg:w-full'>
              <EditTaskForm props={bexpConfig}
                            setTaskUpdated={setTaskUpdated}
                            gapi={gapi}
                            locked={locked}
              /></div> : null
        }
      </div>
    </div>
  )
}

export default Index;
