import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import Section from 'Components/Common/Section'
import
{
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
} from '@mui/material/';
import UpdateOrDeleteRiskForm from 'Components/Risks/Form/editRiskForm'
import
  ContentTwoColumn, {WideColumn, NarrowColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import {
  risk as riskQueryKey,
  googleMailToken
} from 'utils/queryKeys';
import {
  getRisk as getRisk,
  getGoogleMailAccessToken
} from 'utils/queries';
import {boxAccessToken} from "../../utils/queryKeys";
import {getBoxAccessToken} from "../../utils/queries";
import {formatDate, statusColor} from 'utils/globals'
import {
  rankColor
} from "../../utils/globals"
import {
  headCells
} from "./Resources/index"
import {emailContext} from "../../Components/Correspondence/Utils/Index";
import {gapi} from "gapi-script";
import {DiscoveryDocs, REACT_APP_CLIENT_ID, SCOPES} from "../../utils/google/Auth";
import {CorrespondenceContext} from "../../context/CorrespondenceContext";
import Inbox from "../../Components/Correspondence/Inbox/Inbox";

function Show(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const [selectedLabels, setSelectedLabels] = useState([])
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [correspondenceContext, setCorrespondenceContext] = useState(emailContext);

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )


  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if (googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])


  // Fetch Risk
  const {riskId} = useParams()
  const risk = useQuery([riskQueryKey, {id: riskId}], getRisk)
  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  useEffect(() => {
    if (!risk.isLoading && risk.isSuccess) {
      if (risk.data?.data?.attributes?.["gmail_labelid"]
        && risk.data?.data?.attributes?.['project']?.["gmail_labelid"]) {
        setSelectedLabels([
          risk?.data?.data?.attributes?.["gmail_labelid"],
          risk?.data?.data?.attributes?.['project']["gmail_labelid"]
        ])
      }
    }
  }, [!risk.isLoading && risk.isSuccess])

  const bexpConfig = {
    boxable: risk.data,
    access_token: boxAccess,
    riskId: riskId
  }

  useEffect(() => {
    if (!risk.isLoading && risk.isSuccess) {
      if (risk.data?.data?.attributes?.["gmail_labelid"]
        && risk.data?.data?.attributes?.['project']?.["gmail_labelid"]) {
        setSelectedLabels([
          risk?.data?.data?.attributes?.["gmail_labelid"],
          risk?.data?.data?.attributes?.['project']["gmail_labelid"]
        ])
      }
    }
  }, [!risk.isLoading && risk.isSuccess])

  if (risk.isLoading
    || boxAccess.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <ContentTwoColumn>
      <WideColumn>
        <Section>
          <h1 className="Section-h1">{risk?.data?.data?.attributes?.["name"]}</h1>
          <React.Fragment>
            <Box sx={{width: '100%', marginTop: '10px'}}>
              <Paper sx={{width: '100%', mb: 2}}>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.id ? 'left' : 'center'}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                                <span
                                  className={`inline-block align-middle w-5 h-5 rounded-full mr-2 ${rankColor(risk?.data?.data?.attributes?.["rank"])}`}></span>
                        </TableCell>
                        <TableCell>{risk?.data.data?.["id"]}</TableCell>
                        <TableCell>{risk?.data?.data?.attributes?.project?.['name']}</TableCell>
                        <TableCell>{risk?.data?.data?.attributes?.["name"]}</TableCell>
                        <TableCell>{risk?.data?.data?.attributes?.['user']?.["email"]}</TableCell>
                        <TableCell>{formatDate(risk?.data?.data?.attributes?.["due_date"])}</TableCell>
                        <TableCell>{risk?.data?.data?.attributes?.["impact"]}</TableCell>
                        <TableCell>{risk?.data?.data?.attributes?.["rank"]}</TableCell>
                        <TableCell>
                      <span
                        className={`inline-block align-middle w-5 h-5 rounded-full mr-2 ${statusColor(risk?.data?.data?.attributes?.["status"])}`}></span>
                            </TableCell>
                        <TableCell>{risk?.data?.data?.attributes?.["critical"] ? 'yes' : 'no'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </React.Fragment>
        </Section>
        <Section>
          <h2 className="Section-h1 mb-2">Correspondence</h2>
          {
            selectedLabels.length > 0 ?
              <CorrespondenceContext.Provider value={[correspondenceContext, setCorrespondenceContext]}>
                <Inbox isAuthorized={isAuthorized}
                       gapi={gapi}
                       selectedLabels={selectedLabels}
                       url={props.match.url}
                       queryParams={queryParams}/>
              </CorrespondenceContext.Provider>
              : "Email Label Information is missing."
          }
        </Section>
      </WideColumn>
      {
        risk.data.permissions['update'] ?
          <NarrowColumn>
            <Section>
              <h2 className="Section-h1">Edit Risk</h2>
              <UpdateOrDeleteRiskForm/>
            </Section>
          </NarrowColumn>
          : null
      }
    </ContentTwoColumn>
  )
}


export default Show
