import React, {useEffect, useRef, useState} from 'react'
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useQueries} from "react-query";
import {toast} from "react-toastify";
import {gmailModifyMessage, gmailModifyMessageKey} from "../../../utils/google/Queries";

function AssignEmailLabelButton({
                                  label,
                                  isAuthorized,
                                  gapi,
                                  selectedMessages,
                                  selectedLabels,
                                  setUpdateEmailList,
                                  updateEmailList
                                }) {
  const [postLabels, setPostLabels] = useState(false)
  const data = useRef(null)

  const fnSuccess = (msg) => {
    toast.success(msg)
  };

  const fnFail = (msg) => {
    toast.error(msg)
  };

  const assignTag = () => {
    data.current = {
      "addLabelIds": [label.id]
    }
    setPostLabels(true)
  }


  const assingEmailTags = useQueries(
    selectedMessages && selectedMessages.map(msg => {
      return {
        queryKey: [gmailModifyMessageKey, {
          messageId: msg,
          data: data.current,
          access_token: gapi?.auth?.getToken()?.access_token
        }],
        queryFn: () => gmailModifyMessage([gmailModifyMessageKey, {
          messageId: msg,
          data: data.current,
          access_token: gapi?.auth?.getToken()?.access_token
        }]),
        enabled: postLabels,
        cacheTime: 0,
        retry: 1,
        onError: (error) => fnFail(error.response.data.message),
        onSuccess: (data) => handleSuccess(data, "Tag Assigned.")
      }
    })
  )

  const handleSuccess = (data, msg = "") => {
    if (msg !== "") {
      fnSuccess(msg)
    } else {
      fnSuccess('Tag Assigned')
    }
  }

  const isFetching = assingEmailTags && assingEmailTags.some(query => query.isFetching)
  const isSuccess = assingEmailTags && assingEmailTags.some(query => query.isSuccess)

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setPostLabels(false)
      setUpdateEmailList(!updateEmailList)
    }
  }, [isFetching])

  return (
    <>
      <Tooltip component={'span'} title="Assign Tag">
        <IconButton onClick={assignTag}
                    size="small"
                    disabled={!(selectedMessages.length > 0)}
                    className="mr-0"
                    aria-label="assign tag">
          <AddCircleIcon/>
        </IconButton>
      </Tooltip>
      <div className={`lds-dual-ring ${!isFetching ? 'hidden' : ''}`}></div>
    </>
  )
}

export default AssignEmailLabelButton;
