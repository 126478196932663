import React from 'react';

function ProgressBar({
                       progress,
                       isOver,
                       isSmall,
                       isCompleted
                     }) {

  const final_progress = isCompleted ? '100%' : progress
  const overStyle = (isOver && !isCompleted) ? "bg-tenzingRed" : "bg-trueGreen";
  const barSize = isSmall ? "h-3" : "h-6";

  return (
    <div className={`overflow-hidden ${barSize} mb-1 text-xs flex rounded-full bg-outlineGray`}>
      <div
        style={{width: final_progress}}
        className={`${overStyle} ProgressBar`}>
      </div>
    </div>
  )
}

export default ProgressBar;
