import React, {useState, useEffect} from 'react'
import Section from '../../Components/Common/Section'
import ContentTwoColumn, {NarrowColumn, WideColumn} from "../../Components/Common/Layout/ContentTwoColumn"
import Inbox from '../../Components/Correspondence/Inbox/Inbox'
import SendEmail from '../../Components/Correspondence/Inbox/SendEmail'
import {toast} from "react-toastify";
import {gapi} from "gapi-script";
import {
  DiscoveryDocs,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "../../utils/google/Auth"
import {CorrespondenceContext} from "../../context/CorrespondenceContext";
import {
  emailContext
} from '../../Components/Correspondence/Utils/Index'
import TaskPicker from 'Components/Correspondence/Inbox/TaskPicker'
import LabelListBox from 'Components/Correspondence/Tags/LabelListBox'
import {useParams} from "react-router-dom";
import RefreshButton from "../../Components/Correspondence/Utils/RefreshButton";
import {getGoogleMailAccessToken} from "../../utils/queries"
import {googleMailToken} from "../../utils/queryKeys"
import {useQuery} from "react-query";


function Index() {
  const params = useParams()
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [updateEmailList, setUpdateEmailList] = useState(false)
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [updateRelated, setUpdateRelated] = useState(false)
  const [correspondenceContext, setCorrespondenceContext] = useState(emailContext);
  const projectValue = JSON.parse(window.localStorage.getItem("projectValue") ?? '{}');

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )


  useEffect(() => {
    if (params['projectId'] != undefined && params['projectId'] != '') {
      const projectCurr = JSON.parse(window.localStorage.getItem("projectValue"));
      setSelectedLabels([projectCurr?.project?.attributes["gmail_labelid"]])
    } else {
      setSelectedLabels([])
    }
  }, [params['projectId']])


  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if (googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)

    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])


  return (

    <CorrespondenceContext.Provider value={[correspondenceContext, setCorrespondenceContext]}>
      <ContentTwoColumn>
        <WideColumn>
          <div className="flex justify-between items-center p-4 mb-4 bg-white rounded-md shadow-sm w-full min-w-fit">
            <div>
              <h1 className="Section-h1 mb-2">
                {!!params['projectId'] ? `Project ${projectValue?.project?.attributes["name"]} ` : ''}Correspondence
              </h1>
            </div>
            {
              isAuthorized ?
                <div style={{height: "100%"}} className="flex">
                  <RefreshButton gapi={gapi}
                                 selectedLabels={selectedLabels}/>
                </div> : null
            }
          </div>
          <Section>
            <TaskPicker setSelectedLabels={setSelectedLabels}/>
          </Section>
          <Section>
            <Inbox isAuthorized={isAuthorized}
                   gapi={gapi}
                   selectedLabels={selectedLabels}
                   setShowForm={setShowForm}
                   setShowSendEmail={setShowSendEmail}
                   setSelectedMessages={setSelectedMessages}
                   selectedMessages={selectedMessages}
                   setUpdateEmailList={setUpdateEmailList}
                   updateEmailList={updateEmailList}/>
          </Section>
        </WideColumn>
        <NarrowColumn>
          <SendEmail isAuthorized={isAuthorized}
                     gapi={gapi}
                     setShowSendEmail={setShowSendEmail}
                     setShowForm={setShowForm}
                     selectedLabels={selectedLabels}
                     labelListUpdated={updateRelated}/>
          <LabelListBox isAuthorized={isAuthorized}
                        gapi={gapi}
                        selectedMessages={selectedMessages}
                        selectedLabels={selectedLabels}
                        setUpdateEmailList={setUpdateEmailList}
                        updateEmailList={updateEmailList}
                        setSelectedLabels={setSelectedLabels}
                        setUpdateRelated={setUpdateRelated}
                        updateRelated={updateRelated}/>
        </NarrowColumn>
      </ContentTwoColumn>
    </CorrespondenceContext.Provider>
  )
}

export default Index;

