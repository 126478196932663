import React, {useEffect, useState, useRef} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import List from "Components/Projects/List";
import Section from "Components/Common/Section";
import Button from "Components/Common/Button";
import BadgeSquare from "Components/Common/BadgeSquare";
import CalendarDay from "Components/Calendar/Day";
import ContentTwoColumn, {
  WideColumn,
  NarrowColumn,
} from "Components/Common/Layout/ContentTwoColumn";
import {
  getCompanyProjects,
  getCompanyStats,
  getMyTasks,
  getProjects
} from "utils/queries";
import {
  companyProjects,
  companyStats,
  myTasks,
  allProjects
} from "utils/queryKeys";
import QuickNote from "Components/QuickNotes"
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf'
import moment from 'moment'
import {Tooltip} from '@material-ui/core'
import {removeClassByPrefix} from '../../utils/globals/index'

function Index() {
  const [isRefreshed, setIsRefreshed] = useState(false);
  const currentURL = useLocation();
  const navigate = useHistory();

  const [behindIds, setBehindIds] = useState([])
  const printRef = useRef();

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const projectsQuery = useQuery([companyProjects, {companyId: companyId}], getCompanyProjects)
  const projectValue = JSON.parse(window.localStorage.getItem("projectValue"));

  const statsQuery = useQuery(
    [companyStats, {companyId: companyId}],
    getCompanyStats,
    {
      retry: false,
      retryOnMount: false
    }
  );

  const userTaskQuery = useQuery(
    [myTasks, {
      assigneeId: window.localStorage.getItem("assignee_id")
    }],
    getMyTasks)

  const behindScheduleProjectQuery = useQuery(
    [allProjects, {
      list: behindIds
    }],
    getProjects,
    {
      enabled: statsQuery.isSuccess,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  let overdueTasksQuery = !userTaskQuery.isLoading
    && !!userTaskQuery.data?.data
    && userTaskQuery.data?.data.filter((task) => {
      let today = new Date()
      let dueDate = new Date(task.attributes["due_date"])
      if (
        task.attributes["status"] !== "pending"
        && task.attributes["status"] !== "completed"
        && today.getTime() > dueDate.getTime()
      ) {
        return task
      }
    })

  let todayTasksQuery = !userTaskQuery.isLoading
    && !!userTaskQuery.data?.data
    && userTaskQuery.data?.data.filter((task) => {
      let today = new Date()
      let dueDate = new Date(task.attributes["due_date"])
      if (dueDate.getDate() == today.getDate()
        && dueDate.getMonth() == today.getMonth()
        && dueDate.getFullYear() == today.getFullYear()
      ) {
        return task
      }
    })


  let pendingTasksQuery = !userTaskQuery.isLoading
    && !!userTaskQuery.data?.data
    && userTaskQuery.data?.data.filter(task => task.attributes["status"] === "pending")


  const displayProjects = () => {
    if (currentURL.pathname === "/") {
      return <List items={projectsQuery.data?.data}/>
    } else if (currentURL.search === "?filter=over_budget") {
      return <List items={statsQuery?.data?.included?.over_budget_projects?.data}/>
    } else if (currentURL.search === "?filter=behind_schedule") {
      return <List items={behindScheduleProjectQuery.data?.data}/>
    }
  }

  const handleDownloadPdf = async () => {
    var HTML_Width = printRef.current.offsetWidth;
    var HTML_Height = printRef.current.offsetHeight;

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // https://github.com/niklasvh/html2canvas/issues/1856
    const canvas = await html2canvas(printRef.current, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      imageTimeout: 5000,
      onclone: (clonedElement) => {
        clonedElement.querySelectorAll('[class*="shadow"]').forEach((elem) => {
          removeClassByPrefix(elem, 'shadow')
        });
      }
    });

    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

    pdf.addImage(data, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


    for (var i = 1; i <= totalPDFPages; i++) {
      pdf.addPage(PDF_Width.toString(), PDF_Height.toString());
      pdf.addImage(data, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    }
    pdf.save(`Executive-Summary-${moment().format('l')}.pdf`);
  };

  useEffect(() => {
    if (!statsQuery.isLoading
      && statsQuery.isSuccess
      && statsQuery?.data?.included?.behind_schedule_projects?.data?.length > 0) {
      const project_ids = statsQuery.data.included.behind_schedule_projects.data.map(project => project.id)
      setBehindIds(project_ids)
    }
  }, [statsQuery.isSuccess])

  if (projectsQuery.isLoading || statsQuery.isLoading || behindScheduleProjectQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <div ref={printRef}>
      <ContentTwoColumn>
        <WideColumn orderFirst={true}>
          <Section>
            <div className="flex justify-between">
              <h1 className="Section-h1">Project Summary</h1>
              {/* EXECUTIVE REPORT */}
              <div data-html2canvas-ignore className="flex">
                {
                  projectsQuery.data?.permissions?.['manage']
                  || projectsQuery.data?.permissions?.['superuser'] ?
                    <button onClick={handleDownloadPdf} className="orange-transparent-button">
                      Executive Report PDF
                    </button> : null
                }
                {/* CREATE NEW PROJECT */}
                {
                  projectsQuery.data?.permissions?.['create']
                  || projectsQuery.data?.permissions?.['superuser']
                  || projectsQuery.data?.permissions?.['project_manager'] ?
                    <button className="orange-transparent-button"
                            onClick={() => navigate.push("/projects/new")}>
                      <h3 className="flex items-center">Create New Project</h3>
                    </button> : null
                }
              </div>
            </div>
            {
              !!projectsQuery?.data?.data?.length ? displayProjects() : null
            }
          </Section>

          {/*  MY TASKS */}
          <Section show={!!projectsQuery.data?.data?.length}>
            <h1 className="Section-h1">My Tasks</h1>
            <section className="p-3  grid grid-cols-6 gap-3">
              <BadgeSquare taskCount={overdueTasksQuery.length} link={`/companies/${companyId}/tasks/overdue`}>Over
                Due</BadgeSquare>
              <BadgeSquare taskCount={todayTasksQuery.length} link={`/companies/${companyId}/tasks/today`}>Due
                Today</BadgeSquare>
              <BadgeSquare taskCount={pendingTasksQuery.length} link={`/companies/${companyId}/tasks/pending`}>Total
                Pending</BadgeSquare>
            </section>
          </Section>

          {/* QUICK NOTES */}
          <div data-html2canvas-ignore show={!!projectsQuery.data?.data?.length}>
            {
              projectsQuery.data?.permissions?.['update'] ?
                <QuickNote projectId={projectValue?.value}/> : null
            }
          </div>

        </WideColumn>
        <NarrowColumn>
          <Section show={!!projectsQuery.data?.data?.length}>
            <div className="flex justify-between">
              <h1 className="Section-h1">At-Risk Projects</h1>
              {currentURL.search === "?filter=over_budget" || currentURL.search === "?filter=behind_schedule"
                ? <Link className="orange-solid-button px-2 text-center" to="/">View All Projects</Link>
                : null}
            </div>
            {statsQuery.isLoading && statsQuery?.data?.data == undefined ? (
              <div className="lds-dual-ring"></div>
            ) : (
              <section>
                <section className="p-1 mt-2 grid grid-cols-4 gap-3">
                  <BadgeSquare taskCount={statsQuery?.data?.data?.over_budget_projects}
                               link="/home?filter=over_budget">OVER
                    BUDGET</BadgeSquare>
                  <BadgeSquare taskCount={behindIds.length} link="/home?filter=behind_schedule">
                    BEHIND SCHEDULE
                  </BadgeSquare>
                </section>
                <section className="p-1 grid grid-cols-4 gap-3">
                  <BadgeSquare taskCount={statsQuery?.data?.data?.late_tasks} link={`/companies/${companyId}/tasks`}>
                    LATE TASKS
                  </BadgeSquare>
                  <BadgeSquare taskCount={statsQuery?.data?.data?.red_flagged} link={`/companies/${companyId}/risks`}>
                    RED FLAGGED
                  </BadgeSquare>
                </section>
              </section>
            )}
          </Section>
          <Section show={!!projectsQuery.data?.data?.length}>
            <div data-html2canvas-ignore style={{height: "100%"}} className="flex">
              <Button
                onClick={() => setIsRefreshed(!isRefreshed)}
                buttonStyle={"mr-2 p-2 bg-white text-[#757575] text-sm shadow-sm shadow-[#757575] rounded-sm bg-[#FFFFFF]"}>
                <span className="mr-1"><RefreshIcon/></span>
                Refresh
              </Button>
            </div>
            <CalendarDay isRefreshed={isRefreshed}/>
          </Section>
        </NarrowColumn>
      </ContentTwoColumn>
    </div>
  );
}

export default Index;

const RefreshIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
         strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
    </svg>
  )
}
