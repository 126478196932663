import React from "react";
import {Link, Route, Switch, useParams, useLocation} from "react-router-dom";
import {
  inboxEmails,
  sentEmails,
  readEmails,
  unReadEmails,
  defaultCorrespondence,
  cssActive,
  cssNonActive
} from '../Utils/Index'
import InboxList from './InboxList'
import SentList from './SentList'
import ReadList from './ReadList'
import UnReadList from './UnReadList'

const Inbox = ({
                 isAuthorized,
                 gapi,
                 selectedLabels,
                 url,
                 queryParams,
                 setShowForm,
                 setShowSendEmail,
                 setSelectedMessages,
                 selectedMessages,
                 setUpdateEmailList,
                 updateEmailList
               }) => {
  const {projectId} = useParams();
  const location = useLocation();

  // Only return messages with labels that match all of the specified label IDs.
  //  https://content-gmail.googleapis.com/gmail/v1/users/nicholson%40stratos-app.com/messages?labelIds=Label_16&labelIds=Label_19&q=is%3Aread


  return (
    <>
      {/* Toolbar */}
      <div className="w-24 pb-2">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">Maibox Actions</label>
          <select id="tabs"
                  className="bg-gray-50
                             border
                             border-gray-100
                             text-gray-900
                             sm:text-sm
                             rounded-lg
                             focus:ring-2
                             focus:outline-none
                             focus:ring-tenzingOrange
                             block
                             w-full
                             p-1.5">
            <option>Inbox</option>
            <option>UnRead</option>
            <option>Read</option>
            <option>Sent</option>
          </select>
        </div>
        <ul
          className="hidden text-sm font-medium text-center text-gray-500 rounded-lg divide-x divide-gray-200 shadow sm:flex min-w-[20em]">
          <li className="w-full">
            <Link to={inboxEmails(projectId, url, queryParams)}
                  className={`inline-block p-0.5 pl-2 pr-2 w-full min-w-[3em] rounded-l-lg focus:ring-4 focus:outline-none
                              ${(inboxEmails(projectId, url) === location.pathname
                    || defaultCorrespondence(projectId, url) === location.pathname) ? cssActive : cssNonActive}`}
                  aria-current="page">Inbox</Link>
          </li>
          <li className="w-full">
            <Link to={unReadEmails(projectId, url, queryParams)}
                  className={`inline-block p-0.5 pl-2 pr-2 w-full min-w-[3em] focus:ring-4 focus:outline-none
                  ${(unReadEmails(projectId, url) === location.pathname) ? cssActive : cssNonActive}`}>UnRead</Link>
          </li>
          <li className="w-full">
            <Link to={readEmails(projectId, url, queryParams)}
                  className={`inline-block p-0.5 pl-2 pr-2 w-full min-w-[3em] focus:ring-4 focus:outline-none
                  ${(readEmails(projectId, url) === location.pathname) ? cssActive : cssNonActive}`}>Read</Link>
          </li>
          <li className="w-full">
            <Link to={sentEmails(projectId, url, queryParams)}
                  className={`inline-block p-0.5 pl-2 pr-2 w-full min-w-[3em] rounded-r-lg focus:ring-4 focus:outline-none
                  ${(sentEmails(projectId, url) === location.pathname) ? cssActive : cssNonActive}`}>Sent</Link>
          </li>
        </ul>
      </div>
      <hr/>
      <Switch>
        <Route path={inboxEmails(projectId, url)}
               component={() => (<InboxList isAuthorized={isAuthorized}
                                            gapi={gapi}
                                            selectedLabels={selectedLabels}
                                            setShowForm={setShowForm}
                                            setShowSendEmail={setShowSendEmail}
                                            setSelectedMessages={setSelectedMessages}
                                            selectedMessages={selectedMessages}
                                            setUpdateEmailList={setUpdateEmailList}
                                            updateEmailList={updateEmailList}/>)}/>
        <Route path={sentEmails(projectId, url)}
               component={() => (<SentList isAuthorized={isAuthorized}
                                           gapi={gapi}
                                           selectedLabels={selectedLabels}
                                           setShowForm={setShowForm}
                                           setShowSendEmail={setShowSendEmail}
                                           setSelectedMessages={setSelectedMessages}
                                           selectedMessages={selectedMessages}
                                           setUpdateEmailList={setUpdateEmailList}
                                           updateEmailList={updateEmailList}/>)}/>
        <Route path={readEmails(projectId, url)}
               component={() => (<ReadList isAuthorized={isAuthorized}
                                           gapi={gapi}
                                           selectedLabels={selectedLabels}
                                           setShowForm={setShowForm}
                                           setShowSendEmail={setShowSendEmail}
                                           setSelectedMessages={setSelectedMessages}
                                           selectedMessages={selectedMessages}
                                           setUpdateEmailList={setUpdateEmailList}
                                           updateEmailList={updateEmailList}/>)}/>
        <Route path={unReadEmails(projectId, url)}
               component={() => (<UnReadList isAuthorized={isAuthorized}
                                             gapi={gapi}
                                             selectedLabels={selectedLabels}
                                             setShowForm={setShowForm}
                                             setShowSendEmail={setShowSendEmail}
                                             setSelectedMessages={setSelectedMessages}
                                             selectedMessages={selectedMessages}
                                             setUpdateEmailList={setUpdateEmailList}
                                             updateEmailList={updateEmailList}/>)}/>
        <Route path={defaultCorrespondence(projectId, url)}
               component={() => (<InboxList isAuthorized={isAuthorized}
                                            gapi={gapi}
                                            selectedLabels={selectedLabels}
                                            setShowForm={setShowForm}
                                            setShowSendEmail={setShowSendEmail}
                                            setSelectedMessages={setSelectedMessages}
                                            selectedMessages={selectedMessages}
                                            setUpdateEmailList={setUpdateEmailList}
                                            updateEmailList={updateEmailList}/>)}/>
      </Switch>
    </>
  );
}

export default Inbox;




