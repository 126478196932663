export const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Contact #',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Contact Name',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone Number',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
  },
  {
    id: 'company',
    numeric: true,
    disablePadding: false,
    label: 'Company Name',
  },
  // {
  //   id: 'users',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Users',
  // },
];
