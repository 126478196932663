import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Section from 'Components/Common/Section'
import Form from 'Components/Contacts/Form'
import List from 'Components/Contacts/List'
import
  ContentTwoColumn, {WideColumn, NarrowColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import {companyContacts, contact, projectContacts} from "../../utils/queryKeys";
import {getCompanyContacts, getProjectContacts} from "../../utils/queries";
import {ContactsContext} from "../../context/ContactsContext";
import {useParams} from "react-router-dom";

function Index() {
  const [selectedContacts, setSelectedContacts] = useState({});
  const companyId = parseInt(window.localStorage.getItem("company_id"))

  const {projectId} = useParams();

  // Get Contacts
  const contacts = projectId != undefined ?
    useQuery(
      [projectContacts, {projectId: projectId}],
      getProjectContacts, {
        retry: 0,
      })
    :
    useQuery(
      [companyContacts, {companyId: companyId}],
      getCompanyContacts, {
        retry: 0,
      })
  console.log('contacts', contacts)

  if (contacts.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <ContentTwoColumn>
      <ContactsContext.Provider value={{selectedContacts, setSelectedContacts}}>
        {
          contacts.data?.data?.length ?
            <>
              <WideColumn>
                <Section>
                  <h1 className="Section-h1">Contacts</h1>
                  {
                    contacts.data?.data?.length
                      ? <List items={contacts.data.data}
                              projectid={projectId}/>
                      : <tr>
                        <td colSpan="6"><p>No Contacts</p></td>
                      </tr>
                  }
                </Section>
              </WideColumn>
              {
                projectId == undefined && contacts.data?.permissions?.['create'] ?
                  <NarrowColumn>
                    <Section>
                      <h2 className="Section-h1">Add New Contact</h2>
                      <Form></Form>
                    </Section>
                  </NarrowColumn>
                  : null
              }
            </>
            :
            <div className="col-span-6">
              <Section>
                <h1 className="Section-h1">Contacts</h1>
                <p>No Contacts Available</p>
              </Section>
            </div>
        }
      </ContactsContext.Provider>
    </ContentTwoColumn>
  )
}

export default Index
