import React, {useState, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form';
import Button from '../../../../Components/Common/Button';
import Section from '../../../../Components/Common/Section';
import CategorySelector from '../../Components/CategorySelector'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import {client as apiClient} from '../../../../utils/api';
import {
  Field,
  FieldError,
  ErrorText
} from 'Components/Common/Forms';
import {useMutation, useQueryClient, useQuery} from 'react-query';
import {
  company as companyByIdQuery,
  projectContractBreakdowns,
  projectInvoices,
  projectLineItems
} from '../../../../utils/queryKeys';
import {
  getCompany,
  getProjectContractBreakdowns,
  getProjectLineItems
} from '../../../../utils/queries';
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment/moment";
import {
  formatDate,
  defaultValues
} from '../../Utils/Invoice'
import BoxFileExplorer from "../../../FileExplorer";
import MultiSelectBoxfiles from "../../../FileExplorer/MultiSelectBoxfiles";
import {getListOfFolderItems, getListOfFolderItemsKey} from "../../../../utils/boxApi/Queries";

function Form({
                setShowForm,
                categories,
                projectId,
                bexpConfig
              }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategoryBreakdown, notifyCategoryBreakdown] = useState({});
  const [formData, setFormData] = useState(defaultValues)

  const queryClient = useQueryClient();

  const notifySuccess = () => toast.success("New Invoice added successfully!");
  const notifyError = () => toast.error("New Invoice unsuccessful.")


  const lineItems = useQuery(
    [projectLineItems, {projectId: projectId, category_breakdown_id: selectedCategoryBreakdown?.id}],
    getProjectLineItems,
    {
      enabled: !!projectId
        && !!selectedCategoryBreakdown
        && Object.keys(selectedCategoryBreakdown).length !== 0
    }
  )

  const contractBreakdowns = useQuery(
    [projectContractBreakdowns, {projectId: projectId, line_item_id: formData.line_item}],
    getProjectContractBreakdowns,
    {enabled: !!projectId && !!formData.line_item && formData.line_item !== ''}
  );

  useEffect(() => {
    if (selectedCategoryBreakdown?.id != undefined
      && Object.keys(selectedCategoryBreakdown).length !== 0
      && selectedCategoryBreakdown.id > 0) {
      queryClient.refetchQueries([
        projectLineItems,
        {projectId: projectId, category_breakdown_id: selectedCategoryBreakdown?.id}
      ])
    }
    setFormData({
      ...formData, ['line_item']: ''
    })
    setValue('line_item', '')
  }, [selectedCategoryBreakdown])

  useEffect(() => {
    if (formData.line_item !== '') {
      queryClient.refetchQueries([
        projectContractBreakdowns,
        {projectId: projectId, line_item_id: formData.line_item}
      ])
    }
    setFormData({
      ...formData, ['contract_breakdown']: ''
    })
    setValue('contract_breakdown', '')
  }, [formData.line_item])

  const {control, register, handleSubmit, setValue, reset, formState: {errors}} = useForm(defaultValues);
  const {mutateAsync: sendData} = useMutation(postForm);

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["invoice_date", "recommended_status_date"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value.trim()
      })
      // Set the value
      setValue(e.target.id, e.target.value.trim())
    }
  }

  async function postForm(data) {

    const {
      contract_breakdown,
      invoice_date,
      amount,
      number,
      recommended_status_date,
      approval_status
    } = data

    return await apiClient.post("/invoices", {
      amount_cents: parseInt(amount) * 100, // Manually transform to cents
      recommended_status_date: moment(recommended_status_date).utc().format(),
      invoice_date: moment(invoice_date).utc().format(),
      contract_breakdown_id: parseInt(contract_breakdown),
      approval_status: parseInt(approval_status),
      number: number,
      project_id: parseInt(projectId)
    })
  }

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.refetchQueries([projectInvoices, {projectId: projectId}])
      notifySuccess()
      reset(defaultValues)
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
    } catch (error) {
      setLoading(false)
      setDoDisable(false)
      notifyError();
      throw new Error(error)
    }
  }

  // Template Files
  // FETCH Company data in order to extract the Box Folder Ids we need
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const company = useQuery(
    [companyByIdQuery, {companyId: companyId}],
    getCompany)

  const templateFolderItems = useQuery(
    [getListOfFolderItemsKey,
      {
        folderId: company?.data?.attributes?.["templates_box_folderid"],
        accessToken: bexpConfig?.access_token?.data?.attributes['access_token']
      }],
    getListOfFolderItems,
    {
      enabled: !company.isLoading
        && company.isSuccess
        && !!company?.data?.attributes?.["templates_box_folderid"]
    })

  return (
    <>
      {
        projectId ?
          <Section>
            <h2 className="Section-h1 mb-4">Add New Invoice</h2>
            {/* Select the Category */}
            <CategorySelector categories={categories}
                              notifyCategoryBreakdown={notifyCategoryBreakdown}/>

            {/* LINE ITEM */}
            <Field>
              <select id="line_item"
                      className="input-select"
                      value={formData.line_item}
                      onChange={handleFormChange}
              >
                <option defaultValue="Line Item">Line Item</option>
                {lineItems
                  && !lineItems.isLoading
                  && lineItems?.data?.data
                  && lineItems?.data?.permissions?.['index']
                  && lineItems.data.data.map((lineItem) => {
                    return (
                      <option key={'invoice-form-line-item-id-' + lineItem.id.toString()}
                              value={lineItem.id}>
                        {lineItem.attributes["name"]}
                      </option>
                    )
                  })}
              </select>
            </Field>

            {/* Create my Form */}
            <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

              {/* CONTRACT BREAKDONW */}
              <Field>
                <select id="contract_breakdown"
                        className="input-select"
                        value={formData.contract_breakdown}
                        onChange={handleFormChange}
                >
                  <option defaultValue="Contract Breakdown">Contract Breakdown</option>
                  {contractBreakdowns
                    && !contractBreakdowns.isLoading
                    && contractBreakdowns.data?.data != undefined
                    && contractBreakdowns.data?.data?.map((contractBreakdown) => {
                      return (
                        <option key={'invoice-form-contract-breakdown-id-' + contractBreakdown.id.toString()}
                                value={contractBreakdown.id}>
                          {contractBreakdown.attributes["firm_name"]}
                        </option>
                      )
                    })}
                </select>
              </Field>

              {/* INVOICE DATE */}
              <Field>
                <Controller
                  control={control}
                  name='invoice_date'
                  rules={{required: true}}
                  render={({field}) => (
                    <DatePicker
                      id='invoice_date'
                      placeholderText='Invoice Date'
                      minDate={null}
                      onChange={(date) => {
                        field.onChange(date)
                        // Set the value
                        setValue("invoice_date", date)
                        // Update the state
                        setFormData({
                          ...formData, ["invoice_date"]: date
                        })
                      }}
                      selected={formatDate(formData.invoice_date)}
                      className={!errors.invoice_date ? "input-text" : 'input-text-error'}
                    />
                  )}
                />
                <FieldError>
                  {errors.invoice_date?.type === 'required' &&
                    <ErrorText>Invoice date is required</ErrorText>
                  }
                </FieldError>
              </Field>

              {/* AMOUNT */}
              <Field>
                  <div className='currency-wrap'>
                    <input
                      id="amount"
                      type="text"
                      value={formData.amount}
                      placeholder="Invoice Amount"
                      {...register("amount", {required: true})}
                      className={`pl-4 ${!errors.amount ? "input-text" : "input-text-error"}`}
                      onChange={handleFormChange}
                    />
                  </div>
                <FieldError>
                  {errors.amount?.type === 'required' &&
                    <ErrorText>Invoice amount is required</ErrorText>
                  }
                </FieldError>
              </Field>

              {/* NUMBER */}
              <Field>
                <input
                  id="number"
                  type="text"
                  value={formData.number}
                  placeholder="Invoice Number"
                  {...register("number", {required: true})}
                  className={!errors.number ? "input-text" : 'input-text-error'}
                  onChange={handleFormChange}
                />
                <FieldError>
                  {errors.number?.type === 'required' &&
                    <ErrorText>Invoice number is required</ErrorText>
                  }
                </FieldError>
              </Field>

              {/* RECOMMENDED STATUS DATE */}
              <Field>
                <Controller
                  control={control}
                  name='recommended_status_date'
                  rules={{required: true}}
                  render={({field}) => (
                    <DatePicker
                      id='recommended_status_date'
                      placeholderText='Recommended Status Date'
                      minDate={null}
                      onChange={(date) => {
                        field.onChange(date)
                        // Set the value
                        setValue("recommended_status_date", date)
                        // Update the state
                        setFormData({
                          ...formData, ["recommended_status_date"]: date
                        })
                      }}
                      selected={formatDate(formData.recommended_status_date)}
                      className={!errors.recommended_status_date ? "input-text" : 'input-text-error'}
                    />
                  )}
                />
                <FieldError>
                  {errors.recommended_status_date?.type === 'required' &&
                    <ErrorText>Recommended status date is required</ErrorText>
                  }
                </FieldError>
              </Field>

              {/* APPROVAL */}
              <Field>
                <select id="approval_status"
                        name="approval_status"
                        className="input-select"
                        value={formData.approval_status}
                        onChange={handleFormChange}
                >
                  <option value=""> -- Select --</option>
                  <option value="0">Pending</option>
                  <option value="1">Approved</option>
                  <option value="2">Rejected</option>
                </select>
              </Field>

              <section className="flex justify-between mt-4 mb-4">
                <span className="flex">
                  <Button buttonStyle="gray-transparent-button" type="button"
                          onClick={() => setShowForm(false)}>Cancel</Button>
                  <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                          disabled={loading || doDisable}>
                    {
                      doDisable ?
                        <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                        : <></>
                    }
                    Add New
                  </Button>
                </span>
                <Button variant="text"
                        buttonStyle="text-gray-600"
                        type="button"
                        onClick={() => {
                          reset(defaultValues)
                        }}
                >Clear</Button>
              </section>
            </form>

            {/* Template files add */}
            <h3 className="Section-h1">Select Templates:</h3>
            {
              bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != null
              && !templateFolderItems.isLoading
              && templateFolderItems.isSuccess
              && templateFolderItems.data?.data?.entries?.length > 0 ?
                templateFolderItems.data?.data?.entries.map(dir => {
                  if (dir.type === 'folder') {
                    return (
                      <>
                        <MultiSelectBoxfiles key={dir.id}
                                             boxAccess={bexpConfig.access_token}
                                             boxFolderId={dir.id}
                                             boxFolderName={dir.name}
                                             parentFolderId={bexpConfig.boxable?.data?.attributes["cost_box_folderid"]}/>
                      </>
                    )
                  }
                }) : null
            }

            {/* Import a Box Form */}
            {
              bexpConfig.access_token.isLoading
              || bexpConfig.boxable.isLoading
              || bexpConfig.boxable?.data?.attributes == undefined ? "" :
                (bexpConfig.boxable?.data?.attributes.hasOwnProperty("cost_box_folderid")
                  && (bexpConfig.boxable?.data?.attributes["cost_box_folderid"] == undefined
                    || bexpConfig.boxable?.data?.attributes["cost_box_folderid"] == "")) ?
                  <div>Not linked to Box</div> :
                  <BoxFileExplorer props={bexpConfig}/>
            }
          </Section>
          : <p>Select the project first </p>
      }
    </>
  );
}

export default Form
