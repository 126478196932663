import React from 'react'
import {useQuery} from 'react-query';
import Section from 'Components/Common/Section'
import BoxFileExplorer from 'Components/FileExplorer';
import {WideColumn} from 'Components/Common/Layout/ContentTwoColumn'
import {boxAccessToken, company as companyByIdQuery} from "../../utils/queryKeys";
import {getBoxAccessToken, getCompany} from "../../utils/queries";


function Index() {
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const company = useQuery([companyByIdQuery, {companyId: companyId}], getCompany)
  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  const bexpConfig = {
    boxable: company,
    root_folder_column: 'templates_box_folderid',
    access_token: boxAccess
  }
  
  return (
    <WideColumn>
      <Section height="h-screen">
        <div className='h-5/6'>
          <h1 className="Section-h1">Forms & Templates</h1>
          <BoxFileExplorer props={bexpConfig}/>
        </div>
      </Section>
    </WideColumn>
  )
}

export default Index
