import {Tooltip} from "@material-ui/core";
import React from "react";

const ProfileImageUpdate = ({
                              processFile,
                              user
                            }) => {
  return (
    <Tooltip component={'span'} title="Update Profile Image">
      <div className="col-span-1 hover:cursor-pointer">
        <label htmlFor="profile_image" className="hover:cursor-pointer">
          <img className="object-cover aspect-auto h-20 w-20 rounded-full hover:cursor-pointer"
               src={user.contact["cloudinary_url"]}/>
        </label>
        <input style={{display: "none"}}
               id="profile_image"
               type="file"
               onChange={processFile}/>
      </div>
    </Tooltip>
  )
}

export default ProfileImageUpdate;
