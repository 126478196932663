import React, {useState} from 'react';
import moment from 'moment';
import EditActivityForm from 'Components/Schedule/Form/Edit';
import TimelineChart from "./GanttPartials/TimelineChart"
import {
  EditIcon,
  findEndDate,
  findStartDate,
} from "./Resources/index"

const ScheduleGantt = ({
                         activities,
                         permissions,
                         activityType,
                         activitiesPermissions,
                         view,
                         setActivityToEdit,
                         reloadSchedule,
                         setReloadSchedule
                       }) => {
  const projectStart = findStartDate(activities)
  const projectEnd = findEndDate(activities)

  return (
    <div className="m-4">
      <TimelineChart projectStart={projectStart}
                     projectEnd={projectEnd}
                     activities={activities}
                     permissions={permissions}
                     activityType={activityType}
                     isOverview={activityType === undefined || ["project_home", "schedule_overview"].includes(view)}
                     setActivityToEdit={setActivityToEdit}
                     activitiesPermissions={activitiesPermissions}
                     reloadSchedule={reloadSchedule}
                     setReloadSchedule={setReloadSchedule}
      />
    </div>
  )
}

export default ScheduleGantt;
