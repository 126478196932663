import React from 'react'
import {
  navBarConfig
} from './Resources';
import NavbarLink from "./NavbarLink";
import {useQuery} from "react-query";
import {allContacts} from "../../utils/queryKeys"
import {getContacts} from "../../utils/queries";

function NavbarList() {
  const contactsQuery = useQuery(
    [allContacts, {
      user_id: window.localStorage.getItem("assignee_id")
    }],
    getContacts)


  if (contactsQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    !contactsQuery.isLoading
    && contactsQuery.isSuccess ?
      navBarConfig.map((item, idx) => {
        // Return the menu item
        if (!contactsQuery.data?.permissions?.['superuser']
            && ["Correspondence", "Documents", "Forms & Templates"].includes(item.name)) {
          return null
        } else {
          return (
            <li
              key={item.name + idx.toString()}
              className="inline-block">
              <NavbarLink menuItem={item}
                          idx={idx}/>
            </li>
          )
        }
      })
      : null
  )
}

export default NavbarList;
