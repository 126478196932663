import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Controller, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Button from 'Components/Common/Button';
import {Field} from "Components/Common/Forms";
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {gapi} from "gapi-script";
import moment from "moment";
import {useMutation} from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import {googleCalendarClient} from '../../utils/google/Api';

function EditCalendarEvent({
                        calendarId,
                        currentEvent,
                        setIsRefreshed,
                        isRefreshed,
                        setShowForm
                      }) {
                        
  const defaultFormValues = {
    start: currentEvent.startStr,
    end: currentEvent.endStr,
    summary: currentEvent.title,
    attendees: currentEvent.extendedProps.attendees && currentEvent.extendedProps.attendees.map(person => person.email).join()
  }

  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editEvent, setEditEvent] = useState(defaultFormValues);
  
  const {reset, register, handleSubmit, setValue, control, formState: {errors}} = useForm();
  
  useEffect(() => {
    if (currentEvent.extendedProps.attendees){
      const attendees = currentEvent.extendedProps.attendees.map(person => person.email).join()
      setValue('attendees', attendees)
    }
    setValue('summary', currentEvent.title)
    setValue('start', currentEvent.startStr)
    setValue('end', currentEvent.endStr)
  }, [])

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["due_date"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setEditEvent({
        ...editEvent, [e.target.id]: e.target.value
      })
      // Set the value
      setValue(e.target.id, e.target.value)
    }
  }

  const notifySuccess = () => toast.success("Event added successfully");
  const notifyError = () => toast.error("There was an error adding the event");

  async function postEvent(data) {
    const {summary, start, end, attendees} = data;
    const formattedStartDate = new Date(start)
    const formattedEndDate = new Date(end)
    const formattedAttendees = attendees ? attendees.split(',').map(email => ({email: email.trim()})) : null

    googleCalendarClient.defaults.headers['Authorization'] = `Bearer ${gapi.auth.getToken().access_token}`

    return await googleCalendarClient.put(calendarId + "/events/" + currentEvent.extendedProps.googleId, {
      end: {
        dateTime: formattedEndDate
      },
      start: {
        dateTime: formattedStartDate
      },
      summary: summary.trim(),
      attendees: formattedAttendees,
    }, { params: { sendUpdates: 'all' } })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postEvent);

  async function onSubmit(data, e) {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      notifySuccess()
      reset(defaultFormValues)
      setEditEvent(defaultFormValues)
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
      setIsRefreshed(!isRefreshed)
      setShowForm(false)
    } catch (err) {
        setLoading(false)
        setDoDisable(false)
        notifyError()
      if (err.response) {
        const error = err.response.data;
        console.log('err', error)
          // err.response.data.forEach((error) => {
          //   const param = error.source.pointer.replace('data/params/', '')
          //   setError(param, {type: error.status, message: error.detail})
          // })
      } else if (err.request) {
          console.log(err.request);
      } else {
          console.log('Error', err.message);
      }
      throw new Error(err)
    }
  }

  return (
    <div className="w-96">
      <h1 className="Section-h1 mb-2">Edit: {currentEvent.title}</h1>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>

        {/* Summary */}
        <Field>
          <label htmlFor="summary" className="text-tenzingGray">Event Summary:</label>
          <input
            id="summary"
            name="summary"
            type="text"
            className={!errors.summary ? "input-text" : 'input-text-error'}
            {...register("summary", {required: true})}
            value={editEvent?.summary ?? ""}
            onChange={handleFormChange}
          />
          <FieldInputError item={errors.summary}></FieldInputError>
        </Field>

        {/* Start Date */}
        <Field>
          <label htmlFor="start" className="text-tenzingGray">Event Start Date:</label>
          <Controller
            name="start"
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <DatePicker
                id="start"
                className={!errors.start ? "input-text" : 'input-text-error'}
                showTimeSelect
                minDate={null}
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("start", date)
                  // Update the state
                  setEditEvent({
                    ...editEvent, ["start"]: date
                  })
                }}
                selected={editEvent?.start ? moment(editEvent.start).toDate() : ''}
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            )}
          />
          <FieldInputError item={errors.start}></FieldInputError>
        </Field>

        {/* End Date */}
        <Field>
          <label htmlFor="end" className="text-tenzingGray">Event End Date:</label>
          <Controller
            name="end"
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <DatePicker
                id="end"
                className={!errors.end ? "input-text" : 'input-text-error'}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={null}
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("end", date)
                  // Update the state
                  setEditEvent({
                    ...editEvent, ["end"]: date
                  })
                }}
                selected={editEvent?.end ? moment(editEvent.end).toDate() : ''}
              />
            )}
          />
          <FieldInputError item={errors.end}></FieldInputError>
        </Field>

        {/* Attendees */}
        <Field>
          <label htmlFor="attendees" className="text-tenzingGray">Event Attendees: (separate by comma)</label>
          <input
            id="attendees"
            name="attendees"
            type="text"
            placeholder="ex. example@stratos.com, example2@stratos-app.com"
            className={!errors.attendees ? "input-text" : 'input-text-error'}
            {...register("attendees", {required: false})}
            value={editEvent?.attendees ?? ""}
            onChange={handleFormChange}
          />
          <FieldInputError item={errors.attendees}></FieldInputError>
        </Field>

        {/* SUBMIT BUTTON */}
        <section className="mt-4 flex justify-end">
          <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={loading || doDisable}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add
          </Button>
          <button onClick={() => setShowForm(false)} className="gray-transparent-button">Cancel</button>
        </section>
      </form>
    </div>
  );
}

export default EditCalendarEvent;
