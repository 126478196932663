import React from "react"
import InboxHtml from "./InboxHtml";
import {useQuery} from "react-query";
import {gmailGetList, gmailGetListKey} from "../../../utils/google/Queries";
import InboxLine from "./InboxLine";

function InboxList({
                     isAuthorized,
                     gapi,
                     selectedLabels,
                     setShowForm,
                     setShowSendEmail,
                     setSelectedMessages,
                     selectedMessages,
                   }) {
  // Fetch all Inbox emails
  const mailInboxList = useQuery(
    [gmailGetListKey, {
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      queue: 'in:inbox',
      labelIds: selectedLabels
    }],
    gmailGetList,
    {
      enabled: (isAuthorized
        && gapi.auth != undefined
        && gapi.auth.getToken() != undefined)
    })

  const inboxEmailList =
    mailInboxList.isLoading ? <div className="lds-dual-ring"></div> :
      !!mailInboxList.data
      && mailInboxList.data.length > 0
      && mailInboxList.data.map((message) => {
        return (
          <InboxLine
            key={message.id}
            mailListItem={message}
            gapi={gapi}
            tabType="inbox"
            setShowForm={setShowForm}
            setShowSendEmail={setShowSendEmail}
            setSelectedMessages={setSelectedMessages}
            selectedMessages={selectedMessages}
          />
        )
      })

  return (
    <InboxHtml emailsList={inboxEmailList}/>
  )
}

export default InboxList;
