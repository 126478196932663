import React, {useState, useEffect, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {toast} from 'react-toastify';
import {client as apiClient} from 'utils/api';
import {parseISO} from 'date-fns';
import Button from 'Components/Common/Button';
import Section from 'Components/Common/Section';
import {Field} from 'Components/Common/Forms';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {
  task as taskQueryKey,
} from 'utils/queryKeys';
import {
  getCompany,
  getProjectUsers,
  getTask
} from "../../../utils/queries";
import {
  getNumberStatus,
  formatDate,
  capitalize
} from "../../../utils/globals";
import {
  company as companyByIdQuery,
  projectUsers
} from "../../../utils/queryKeys";
import CircularProgress from "@mui/material/CircularProgress";
import {
  disableCalculation,
  impactValues,
  statusValues
} from '../Utils/index'
import {boxAccessToken} from "../../../utils/queryKeys";
import {getBoxAccessToken} from "../../../utils/queries";
import BoxFileExplorer from '../../../Components/FileExplorer';
import {
  gmailModifyMessage,
  sendEmailNotifications
} from "../../../utils/google/Queries";
import Badge from "@mui/material/Badge";
import CreateGmailLabel from "../../Common/Google/CreateGmailLabel";
import FiFoWrapper from "../../../Components/Common/BoxTree/FiFoWrapper";
import MultiSelectBoxfiles from "../../FileExplorer/MultiSelectBoxfiles";
import {
  getListOfFolderItems,
  getListOfFolderItemsKey
} from "../../../utils/boxApi/Queries";


function EditTaskForm({
                        props,
                        setTaskUpdated,
                        gapi,
                        locked
                      }) {
  const taskId = props.taskId;
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({})
  const [isLabelCreated, setIsLabelCreated] = useState(false);
  const [taskBoxFolderId, setTaskBoxFolderId] = useState(null);

  const assigneeEmailRef = useRef(null)
  const approverEmailRef = useRef(null)
  const managerEmailRef = useRef(null)
  const assigneeOldEmailRef = useRef(null)
  const approverOldEmailRef = useRef(null)
  const managerOldEmailRef = useRef(null)
  const gmailTaskLabelNameRef = useRef(null)
  const gmailProjectLabelNameRef = useRef(null)
  const taskLabelId = useRef(null)
  const projectLabelId = useRef(null)
  const assigneeId = window.localStorage.getItem("assignee_id") ?? -1

  const {control, register, handleSubmit, setValue, reset, formState: {errors}} = useForm();
  const queryClient = useQueryClient();

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["due_date", "critical"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value.trim()
      })
      // Set the value
      setValue(e.target.id, e.target.value.trim())
    }

    if (e.target.id === 'assignee_id') {
      assigneeEmailRef.current = e.target.options[e.target.selectedIndex].getAttribute('data-email')
    } else if (e.target.id === 'manager_id') {
      managerEmailRef.current = e.target.options[e.target.selectedIndex].getAttribute('data-email')
    } else if (e.target.id === 'approver_id') {
      approverEmailRef.current = e.target.options[e.target.selectedIndex].getAttribute('data-email')
    }
  }

  // Fetch Task

  const formFields = [
    'name', 'assignee', 'manager', 'approver', "project", 'due_date', 'impact', 'critical', 'status'
  ]

  // Fixme: Remove this query and probably use the data passed through the properties
  const taskQuery = useQuery(
    [taskQueryKey, {taskId: taskId}],
    getTask,
    {
      cacheTime: 0,
      staleTime: 0
    })

  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)
  const bexpConfig = {
    boxable: taskQuery.data,
    root_folder_column: 'box_folderid',
    access_token: boxAccess
  }

  useEffect(() => {
    if (isLabelCreated) {
      queryClient.refetchQueries([taskQueryKey, {taskId: taskId}])
      setIsLabelCreated(false)
    }
  }, [isLabelCreated])

  // Load the Module Pool
  useEffect(() => {
    if (!taskQuery.loading
      && !!taskQuery.data?.data) {
      setFormData(taskQuery.data?.data?.attributes)
      gmailTaskLabelNameRef.current = taskQuery.data?.data?.attributes['name']
      gmailProjectLabelNameRef.current = taskQuery.data?.data?.attributes?.project?.['identifier']
      taskLabelId.current = taskQuery.data?.data?.attributes['gmail_labelid']
      projectLabelId.current = taskQuery.data?.data?.attributes.project['gmail_labelid']
      setTaskBoxFolderId(taskQuery.data?.data?.attributes['box_folderid'])

      // Update the form values
      formFields.forEach((fd) => {
        if (fd === "due_date") {
          setValue(fd, taskQuery.data?.data?.attributes[fd] ? parseISO(taskQuery.data?.data?.attributes[fd]) : "")
        } else if (["assignee", "manager", "approver", "project"].includes(fd)) {
          setValue(fd + '_id', taskQuery.data?.data?.attributes[fd]?.id)
          // Initiate the email references
          if ((fd + '_id') === 'assignee_id') {
            assigneeEmailRef.current = taskQuery.data?.data?.attributes[fd]?.email
            if (assigneeOldEmailRef.current == undefined) {
              assigneeOldEmailRef.current = taskQuery.data?.data?.attributes[fd]?.email
            }
          } else if ((fd + '_id') === 'manager_id') {
            managerEmailRef.current = taskQuery.data?.data?.attributes[fd]?.email
            if (managerOldEmailRef.current == undefined) {
              managerOldEmailRef.current = taskQuery.data?.data?.attributes[fd]?.email
            }
          } else if ((fd + '_id') === 'approver_id') {
            approverEmailRef.current = taskQuery.data?.data?.attributes[fd]?.email
            if (approverOldEmailRef.current == undefined) {
              approverOldEmailRef.current = taskQuery.data?.data?.attributes[fd]?.email
            }
          }
        } else if (fd === "status") {
          setValue(fd, getNumberStatus(taskQuery.data?.data?.attributes[fd]))
        } else {
          setValue(fd, taskQuery.data?.data?.attributes[fd])
        }
      })
    }
  }, [taskQuery.data, taskQuery.loading])

  // FETCH USER LIST
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const users = !taskQuery.loading && useQuery([projectUsers, {projectId: taskQuery.data?.data?.attributes?.['project']?.id}], getProjectUsers)

  const impactOptions = impactValues.map((item) => {
    return (
      <option key={"task" + item + taskId} value={item}>{capitalize(item)}</option>
    )
  }, this)

  const statusOptions = statusValues.map((item, index) => {
    return (
      <option key={"task" + item + taskId} value={index}>{capitalize(item)}</option>
    )
  }, this)

  // I can not add a spinner here since this is select child
  const assigneeOptions =
    users.isLoading ? null
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"assigneeindex" + item.attributes.email + item.id}
                data-email={item.attributes.email}
                value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  // I can not add a spinner here since this is select child
  const managerOptions =
    users.isLoading ? null
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"managerindex" + item.attributes.email + item.id}
                data-email={item.attributes.email}
                value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  // I can not add a spinner here since this is select child
  const approverOptions =
    users.isLoading ? null
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"approverindex" + item.attributes.email + item.id}
                data-email={item.attributes.email}
                value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  // FORM
  const notifySuccess = () => toast.success("Task updated successfully");
  const notifyError = () => toast.error("Task update failed");

  async function patchTask(data) {
    const {name, assignee_id, manager_id, approver_id, due_date, critical, status, impact} = data;
    const formattedDueDate = moment(due_date).utc().format()

    return await apiClient.patch(`/tasks/${taskId}`, {
      name: name,
      due_date: formattedDueDate,
      critical: critical,
      status: parseInt(status), // This is the default, status: pending
      project_id: parseInt(taskQuery.data?.data?.attributes?.project?.id),
      impact: impact,
      approver_id: parseInt(approver_id),
      manager_id: parseInt(manager_id),
      assignee_id: parseInt(assignee_id)
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(patchTask);

  async function onSubmit(data, e) {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)

      const projectLabel = gmailProjectLabelNameRef.current != undefined ? gmailProjectLabelNameRef.current : ""
      const taskLabel = gmailTaskLabelNameRef.current != undefined ? gmailTaskLabelNameRef.current : ""

      // Notify the users
      if (assigneeEmailRef.current != assigneeOldEmailRef.current) {
        // Assignee
        emailNotification({
          emailAddress: assigneeEmailRef.current,
          cc: "",
          subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Task Assignment`,
          message: "You have been assigned the '" + formData?.name + "' task."
        })

        if (assigneeOldEmailRef.current != undefined && assigneeOldEmailRef.current != '') {
          emailNotification({
            emailAddress: assigneeOldEmailRef.current,
            cc: "",
            subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Task Assignment Revoked`,
            message: "Task '" + formData?.name + "' has been revoked."
          })
        }
      }

      if (approverEmailRef.current != approverOldEmailRef.current) {
        // Approver
        emailNotification({
          emailAddress: approverEmailRef.current,
          cc: "",
          subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Approve Task Assignment`,
          message: "You have been assigned the approval of the '" + formData?.name + "' task."
        })

        if (approverOldEmailRef.current != undefined && approverOldEmailRef.current != '') {
          emailNotification({
            emailAddress: approverOldEmailRef.current,
            cc: "",
            subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Task Assignment Approval Revoked`,
            message: "The approval of the '" + formData?.name + "' task has been revoked."
          })
        }
      }

      if (managerEmailRef.current != managerOldEmailRef.current) {
        // Manager
        emailNotification({
          emailAddress: managerEmailRef.current,
          cc: "",
          subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Task Management`,
          message: "You have been assigned the management of the '" + formData?.name + "' task."
        })

        if (managerOldEmailRef.current != undefined && managerOldEmailRef.current != '') {
          emailNotification({
            emailAddress: managerOldEmailRef.current,
            cc: "",
            subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Task Management Revoked`,
            message: "The management of the '" + formData?.name + "' task has been revoked."
          })
        }
      }

      // Refetch my data
      await queryClient.refetchQueries([taskQueryKey, {taskId: taskId}])
      notifySuccess()
      setLoading(false)
      setDoDisable(false)
      setTaskUpdated(true)

    } catch (err) {
      setLoading(false)
      setDoDisable(false)
      notifyError()
      console.log('error:', err)
    }
  }


  // Send Email
  const {mutateAsync: emailNotification} = useMutation(sendEmailNotifications, {
    onSuccess: (data, variables, context) => {
      console.log('Email Notification sent')
      // Modify message tag/label
      const tdata = {
        "addLabelIds": [
          taskLabelId.current,
          projectLabelId.current
        ]
      }
      modifyGmailMessage([modifyGmailMessage, {
        messageId: data.result.id,
        data: tdata,
        access_token: gapi?.auth?.getToken()?.access_token
      }])
    },
    onError: (error, variables, context) => {
      console.log('Email Notification failed', error)
    }
  })

  // Google Modify Message
  const {mutateAsync: modifyGmailMessage} = useMutation(gmailModifyMessage, {
    onSuccess: (data, variables, context) => {
      // Each time i send an email i need to hide the form. This way i will
      // ensure(enforce) that the user will click on the Send Email and renew the task label id
      console.log("Gmail label assigned:", data.data.labelIds)
    },
    onError: (error, variables, context) => {
      console.log('Gmail Label create failed.', error)
    }
  });


  // Template Files
  // FETCH Company data in order to extract the Box Folder Ids we need
  const company = useQuery(
    [companyByIdQuery, {companyId: companyId}],
    getCompany)

  const templateFolderItems = useQuery(
    [getListOfFolderItemsKey,
      {
        folderId: company?.data?.attributes?.["templates_box_folderid"],
        accessToken: boxAccess?.data?.attributes['access_token']
      }],
    getListOfFolderItems,
    {
      enabled: !company.isLoading
        && company.isSuccess
        && !!company?.data?.attributes?.["templates_box_folderid"]
    })

  return (
    <Section>
      {taskQuery.isLoading ? <div className="lds-dual-ring"></div> :
        <>
          <div className="flex items-start align-baseline">
            {taskQuery.data?.permissions?.['update']
            && (taskQuery.data?.data?.attributes["gmail_labelid"] == undefined
              || taskQuery.data?.data?.attributes["gmail_labelid"] == '') ?
              <Badge badgeContent={<CreateGmailLabel task={taskQuery.data?.data?.attributes}
                                                     gapi={gapi}
                                                     iconBadge={true}
                                                     setIsLabelCreated={setIsLabelCreated}/>}>
                <h1 className="Section-h1 mr-2 mb-2">Edit Task</h1>
              </Badge>
              : <h1 className="Section-h1">Edit Task</h1>
            }
          </div>
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            {/* NAME */}
            <Field>
              <label htmlFor="name" className="text-tenzingGray">Task Name:</label>
              <input
                id="name"
                name="name"
                type="text"
                disabled={disableCalculation(taskQuery, locked, assigneeId, 'name', props.processMap)}
                className={!errors.name ? "input-text" : 'input-text-error'}
                {...register("name", {required: true})}
                value={formData?.name ?? ""}
                onChange={handleFormChange}
              />
              <FieldInputError item={errors.name}></FieldInputError>
            </Field>

            {/* ASSIGNEE */}
            <Field>
              <label htmlFor="assignee_id" className="text-tenzingGray">Assignee:</label>
              <select
                id="assignee_id"
                name="assignee"
                disabled={disableCalculation(taskQuery, locked, assigneeId, 'assignee')}
                className={!errors.assignee_id ? "input-text" : 'input-text-error'}
                {...register("assignee_id", {required: true})}
                value={formData?.assignee_id ?? formData?.assignee?.id ?? ""}
                onChange={handleFormChange}
              >
                {assigneeOptions}
              </select>
              <FieldInputError item={errors.assignee_id}></FieldInputError>
            </Field>

            {/* MANAGER */}
            <Field>
              <label htmlFor="manager_id" className="text-tenzingGray">Task Manager:</label>
              <select
                id="manager_id"
                name="manager"
                disabled={disableCalculation(taskQuery, locked, assigneeId, 'manager')}
                className={!errors.manager_id ? "input-text" : 'input-text-error'}
                {...register("manager_id", {required: true})}
                value={formData?.manager_id ?? formData?.manager?.id ?? ""}
                onChange={handleFormChange}
              >
                {managerOptions}
              </select>
              <FieldInputError item={errors.manager_id}></FieldInputError>
            </Field>

            {/* APPROVER */}
            <Field>
              <label htmlFor="approver_id" className="text-tenzingGray">Task Approver:</label>
              <select
                id="approver_id"
                name="approver"
                disabled={disableCalculation(taskQuery, locked, assigneeId, 'approver')}
                className={!errors.approver_id ? "input-text" : 'input-text-error'}
                {...register("approver_id", {required: true})}
                value={formData?.approver_id ?? formData?.approver?.id ?? ""}
                onChange={handleFormChange}
              >
                {approverOptions}
              </select>
              <FieldInputError item={errors.approver_id}></FieldInputError>
            </Field>

            {/* DUE DATE */}
            {
              disableCalculation(taskQuery, locked, assigneeId, 'due_date') ?
                <Field>
                  <label htmlFor="due_date" className="text-tenzingGray">Due Date:</label>
                  <input
                    id="due_date"
                    name="due_date"
                    type="text"
                    disabled={true}
                    value={formData?.due_date ? moment(formData.due_date).format('MMMM d, YYYY') : ''}
                    className={!errors.due_date ? "input-text" : 'input-text-error'}
                  />
                </Field> :
                <Field>
                  <label htmlFor="due_date" className="text-tenzingGray">Task Due Date:</label>
                  <Controller
                    label={formatDate(formData?.due_date)}
                    control={control}
                    name='due_date'
                    rules={{required: true}}
                    render={({field}) => (
                      <DatePicker
                        id='due_date'
                        minDate={null}
                        onChange={(date) => {
                          field.onChange(date)
                          // Set the value
                          setValue("due_date", date)
                          // Update the state
                          setFormData({
                            ...formData, ["due_date"]: date
                          })
                        }}
                        selected={field.value}
                        className={!errors.due_date ? "input-text" : 'input-text-error'}
                      />
                    )}
                  />
                  <FieldInputError item={errors.due_date}></FieldInputError>
                </Field>
            }

            {/* CRITICAL */}
            <div className="my-4 flex items-end">
              <p className="text-lg text-tenzingGray">Critical: </p>
              <div>
                <label className="flex items-baseline" htmlFor="critical">
                  <input
                    id="critical"
                    name="critical"
                    type="checkbox"
                    disabled={disableCalculation(taskQuery, locked, assigneeId, 'critical')}
                    className="appearance-none invisible peer"
                    checked={Boolean(formData?.critical ?? false)}
                    onClick={(event) => {
                      // Set the value
                      setValue("critical", event.target.checked)
                      // Update the state
                      setFormData({
                        ...formData, ["critical"]: event.target.checked
                      })
                    }}
                    {...register("critical")}
                  />
                  <span className="w-14 h-8
                      flex items-center
                      flex-shrink-0
                      mr-4 p-1
                      bg-lightGray
                      rounded-full
                      duration-300 ease-in-out
                      peer-checked:bg-tenzingBlue
                      after:w-6
                      after:h-6
                      after:bg-white
                      after:rounded-full
                      after:shadow-md
                      after:duration-300
                      peer-checked:after:translate-x-6
                      "></span>
                </label>
              </div>
            </div>

            {/* STATUS */}
            <Field>
              <label htmlFor="status" className="text-tenzingGray ">Task Status:</label>
              <select
                id="status"
                name="status"
                disabled={disableCalculation(taskQuery, locked, assigneeId, 'status')}
                className={!errors.status ? "input-text" : 'input-text-error'}
                {...register("status", {required: true})}
                value={formData.status ? getNumberStatus(formData.status) : ""}
                onChange={handleFormChange}
              >
                {statusOptions}
              </select>
              <FieldInputError item={errors.status}></FieldInputError>
            </Field>

            {/* IMPACT */}
            <Field>
              <label htmlFor="impact" className="text-tenzingGray">Task Impact:</label>
              <select
                id="impact"
                name="impact"
                disabled={disableCalculation(taskQuery, locked, assigneeId, 'impact')}
                className={!errors.impact ? "input-text" : 'input-text-error'}
                {...register("impact", {required: true})}
                value={formData.impact ?? ""}
                onChange={handleFormChange}
              >
                {impactOptions}
              </select>
              <FieldInputError item={errors.impact}></FieldInputError>
            </Field>


            <section className="mt-4 flex justify-between">
              {/* SUBMIT BUTTON */}
              <Button
                buttonStyle={(loading || doDisable) ? "gray-solid-button m-auto mr-0" : "orange-solid-button m-auto mr-0"}
                disabled={disableCalculation(taskQuery, locked, assigneeId)}>
                {
                  doDisable ?
                    <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                    : <></>
                }
                Save
              </Button>
            </section>
          </form>
        </>
      }

      {/* Template files add */}
      {
        taskQuery.data?.permissions?.['update']
        && !taskQuery.isLoading
        && taskQuery.isSuccess
        && !!taskQuery.data?.data
        && taskQuery?.data?.data?.attributes?.["box_folderid"] != null
        && company?.data?.attributes?.["templates_box_folderid"] != null ?
          <FiFoWrapper boxAccess={boxAccess}
                       boxFolderId={company?.data?.attributes?.["templates_box_folderid"]}
                       parentFolderId={taskQuery?.data?.data?.attributes?.["box_folderid"]}/>
          : null
      }


      {/*/!* Template files add *!/*/}
      {/*{*/}
      {/*  taskQuery.data?.permissions?.['update']*/}
      {/*  && !taskQuery.isLoading*/}
      {/*  && taskQuery.isSuccess*/}
      {/*  && !!taskQuery.data?.data*/}
      {/*  && taskQuery?.data?.data?.attributes?.module_pool == null*/}
      {/*  && taskQuery?.data?.data?.attributes?.["box_folderid"] != null ?*/}
      {/*    <>*/}
      {/*      <h3 className="Section-h1">Select Templates:</h3>*/}
      {/*      {*/}
      {/*        !templateFolderItems.isLoading*/}
      {/*        && templateFolderItems.isSuccess*/}
      {/*        && templateFolderItems.data?.data?.entries?.length > 0 ?*/}
      {/*          templateFolderItems.data?.data?.entries.map(dir => {*/}
      {/*            if (dir.type === 'folder') {*/}
      {/*              return (*/}
      {/*                <>*/}
      {/*                  <MultiSelectBoxfiles key={dir.id}*/}
      {/*                                       boxAccess={boxAccess}*/}
      {/*                                       boxFolderId={dir.id}*/}
      {/*                                       boxFolderName={dir.name}*/}
      {/*                                       parentFolderId={taskQuery?.data?.data?.attributes?.["box_folderid"]}/>*/}
      {/*                </>*/}
      {/*              )*/}
      {/*            }*/}
      {/*          }) : null*/}
      {/*      }*/}
      {/*    </> : null*/}
      {/*}*/}

      {/* Import a Box Form */}
      {
        !props.access_token.isLoading
        && !taskQuery.isLoading
        && taskQuery.isSuccess
        && taskQuery?.data?.data?.attributes != undefined
        && taskQuery?.data?.data?.attributes?.["box_folderid"] != undefined
        && taskQuery?.data?.data?.attributes?.["box_folderid"] != '' ?
          <BoxFileExplorer props={bexpConfig}/>
          : <div>Not linked to Box</div>
      }
    </Section>
  );
}

export default EditTaskForm;
