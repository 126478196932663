import React, {useState, useRef, useEffect} from "react";
import {client as apiClient} from "utils/api";
import {Controller, useForm} from "react-hook-form";
import {useMutation, useQuery, useQueryClient} from "react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";
import {FieldInputError} from "Components/Common/Forms/Errors";
import {
  getProjectUsers,
  updateTask
} from "../../../utils/queries";
import Button from "Components/Common/Button";
import {Field} from "Components/Common/Forms";
import Section from "Components/Common/Section";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {
  projectCriticalTasksIndex,
  projectMyTasks,
  myTasks,
  projectTasksNotPendingIndex,
  googleMailToken,
  projectUsers
} from "../../../utils/queryKeys";
import CircularProgress from "@mui/material/CircularProgress";
import {
  gmailLabelKey,
  gmailCreateLabel,
  gmailModifyMessage,
  sendEmailNotifications
} from '../../../utils/google/Queries/index';
import {getGoogleMailAccessToken} from '../../../utils/queries/index'
import ProjectPicker from '../../Projects/Utils/ProjectPicker'
import {
  disableCalculation,
  impactValues,
  statusValues
} from '../Utils/index'
import {
  getNumberStatus,
  formatDate,
  capitalize
} from "../../../utils/globals";


function Form({
                gapi,
                project = null,
                projectPickerEnabled = false,
                setSelectedProjectId,
                selectedProjectId
              }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({})
  const [selectedProject, setSelectedProject] = useState(null)
  const gmailLabelId = useRef(null)
  const assigneeEmailRef = useRef(null)
  const approverEmailRef = useRef(null)
  const managerEmailRef = useRef(null)
  const queryClient = useQueryClient();
  const navigate = useHistory();

  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  useEffect(() => {
    if (project != undefined
      && !project?.isLoading
      && project?.isSuccess) {
      setSelectedProjectId(project?.data?.data?.id)
      setSelectedProject(project?.data?.data)
    }
  }, [project !== undefined && !project?.isLoading])

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if (googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  // Handle input changes
  const handleFormChange = (e) => {
    if (!["due_date", "critical"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value
      })
      // Set the value
      setValue(e.target.id, e.target.value)
    }

    if (e.target.id === 'assignee_id') {
      assigneeEmailRef.current = e.target.options[e.target.selectedIndex].getAttribute('data-email')
    } else if (e.target.id === 'manager_id') {
      managerEmailRef.current = e.target.options[e.target.selectedIndex].getAttribute('data-email')
    } else if (e.target.id === 'approver_id') {
      approverEmailRef.current = e.target.options[e.target.selectedIndex].getAttribute('data-email')
    }
  }

  // FETCH USER LIST
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const users = !project?.loading && useQuery([projectUsers, {projectId: project?.data?.data?.id}], getProjectUsers)

  const assigneeOptions =
    users.isLoading ? null
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"assigneeindex" + item.attributes.email + item.id}
                data-email={item.attributes.email}
                value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  const managerOptions =
    users.isLoading ? null
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"mangerindex" + item.attributes.email + item.id}
                data-email={item.attributes.email}
                value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  const approverOptions =
    users.isLoading ? null
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"approverindex" + item.attributes.email + item.id}
                data-email={item.attributes.email}
                value={item.id}>{item.attributes.email}</option>
      )
    }, this);


  const impactOptions = impactValues.map((item) => {
    return (
      <option key={"task-add-" + item} value={item}>{capitalize(item)}</option>
    )
  }, this)

  const statusOptions = statusValues.map((item, index) => {
    return (
      <option key={"task-add-" + item} value={index}>{capitalize(item)}</option>
    )
  }, this)

  // Form
  const {isLoading, mutateAsync: sendData} = useMutation(postForm, {
    onSuccess: (data, variables, context) => {
      if (data.status == 201
        && data.request?.response != undefined
        && data.request?.response != '') {

        const resp = JSON.parse(data.request.response)

        const taskLabel = resp.data?.attributes?.name
        const projectLabel = resp.data?.attributes?.project?.['identifier']
        gmailLabelId.current = resp.data?.attributes?.['gmail_labelid']

        // Notify the users
        // Assignee
        emailNotification({
          emailAddress: assigneeEmailRef.current,
          cc: "",
          subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Task Assignment`,
          message: "You have been assigned the '" + formData?.name + "' task."
        })
        // Approver
        emailNotification({
          emailAddress: approverEmailRef.current,
          cc: "",
          subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Approve Task Assignment`,
          message: "You have been assigned the approval of the '" + formData?.name + "' task."
        })
        // Manager
        emailNotification({
          emailAddress: managerEmailRef.current,
          cc: "",
          subject: `[${projectLabel}][${projectLabel}/${taskLabel}] Task Management`,
          message: "You have been assigned the management of the '" + formData?.name + "' task."
        })


        queryClient.refetchQueries([projectTasksNotPendingIndex, {
          projectId: selectedProjectId,
          status: 'not_pending',
          view: 'index'
        }]);
      }
    },
    onError: (error, variables, context) => {
      console.log('task create failed', error)
    }
  });


  const {control, register, handleSubmit, setValue, reset, formState: {errors}} = useForm();

  // Fetch the title from the Notes
  useEffect(() => {
    if (navigate.location.state?.from === "taskQuickNote") {
      setFormData({
        ...formData, ["name"]: JSON.parse(window.localStorage.getItem("quickNotes"))
      })
      // Set the value
      setValue("name", JSON.parse(window.localStorage.getItem("quickNotes")))
    }
  }, [navigate.location.state?.from])

  const notifySuccess = () => toast.success("New Task added successfully!");
  const notifyError = () => toast.error("New task unsuccessful.");

  async function postForm(data) {
    const {name, assignee_id, manager_id, approver_id, impact, due_date, critical, status} = data;
    const formattedDueDate = moment(due_date).utc().format()

    const mgr_id = (manager_id == null || manager_id == undefined || manager_id == '')
      ? selectedProject?.attributes?.manager?.id
      : manager_id;

    return await apiClient.post("/tasks", {
      name: name.trim(),
      due_date: formattedDueDate,
      critical: critical,
      status: parseInt(status), // This is the default, status: in-process
      project_id: parseInt(selectedProjectId),
      impact: impact,
      approver_id: parseInt(approver_id),
      manager_id: parseInt(mgr_id),
      assignee_id: parseInt(assignee_id)
    });
  }

  // Google Modify Message
  const {mutateAsync: modifyGmailMessage} = useMutation(gmailModifyMessage, {
    onSuccess: (data, variables, context) => {
      console.log('Updated message tag', data)
    },
    onError: (error, variables, context) => {
      console.log('Gmail Label create failed.', error)
    }
  });

  // Send Email
  const {mutateAsync: emailNotification} = useMutation(sendEmailNotifications, {
    onSuccess: (data, variables, context) => {
      const projectValue = JSON.parse(window.localStorage.getItem("projectValue"));
      projectValue?.project?.attributes["gmail_labelid"]
      // Modify message tag/label
      const tdata = {
        "addLabelIds": [
          gmailLabelId.current,
          selectedProject?.attributes["gmail_labelid"]
        ]
      }
      modifyGmailMessage([modifyGmailMessage, {
        messageId: data.result.threadId,
        data: tdata,
        access_token: gapi?.auth?.getToken()?.access_token
      }])
    },
    onError: (error, variables, context) => {
      console.log('Email Notification failed', error)
    }
  })

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data);
      await queryClient.refetchQueries([projectTasksNotPendingIndex, {
        projectId: selectedProjectId,
        status: 'not_pending',
        view: 'index'
      }]);
      await queryClient.refetchQueries([projectCriticalTasksIndex, {
        projectId: selectedProjectId,
        status: 'not_pending',
        view: 'index'
      }]);
      await queryClient.refetchQueries([projectMyTasks, {
        projectId: selectedProjectId,
        assigneeId: window.localStorage.getItem("assignee_id")
      }]);
      await queryClient.refetchQueries([myTasks, {
        assigneeId: window.localStorage.getItem("assignee_id")
      }]);

      if (window.localStorage.getItem("quickNotes") != undefined) {
        window.localStorage.removeItem("quickNotes")
      }
      window.dispatchEvent(new Event('storage'))
      notifySuccess();
      reset();
      e.target.reset();
      setLoading(false)
      setDoDisable(false)
      //  Send email to users
    } catch (error) {
      setLoading(false)
      setDoDisable(false)
      notifyError();
      throw new Error(error);
    }
  };

  return (
    <Section>
      <h2 className="Section-h1">Add New Task</h2>
      {
        projectPickerEnabled ?
          <ProjectPicker setSelectedProject={setSelectedProject}
                         setSelectedProjectId={setSelectedProjectId}
          /> : null
      }
      {
        selectedProjectId != undefined ?
          <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            {/* Task Name */}
            <Field>
              <label htmlFor="name" className="text-tenzingGray">Task Name:</label>
              <input
                id="name"
                name="name"
                type="text"
                className={!errors.name ? "input-text" : 'input-text-error'}
                {...register("name", {required: true})}
                value={formData?.name ?? ""}
                onChange={handleFormChange}
              />
              <FieldInputError item={errors.name}></FieldInputError>
            </Field>

            {/* Assignee */}
            <Field>
              <label htmlFor="assignee_id" className="text-tenzingGray">Assignee</label>
              <select
                id="assignee_id"
                placeholder="Assignee"
                {...register("assignee_id", {required: true})}
                className={!errors.assignee_id ? "input-text" : 'input-text-error'}
                value={formData?.assignee_id ?? formData?.assignee?.id ?? ""}
                onChange={handleFormChange}
              >User
                {assigneeOptions}
              </select>
              <FieldInputError item={errors.assignee_id}></FieldInputError>
            </Field>

            {/* Manager */}
            <Field>
              <label htmlFor="manager_id" className="text-tenzingGray">Manager</label>
              <select
                id="manager_id"
                placeholder="Manager"
                {...register("manager_id", {required: true})}
                className={!errors.manager_id ? "input-text" : 'input-text-error'}
                value={formData?.manager_id ?? formData?.manager?.id ?? ""}
                onChange={handleFormChange}
              >User
                {managerOptions}
              </select>
              <FieldInputError item={errors.manager_id}></FieldInputError>
            </Field>

            {/* Approver */}
            <Field>
              <label htmlFor="approver_id" className="text-tenzingGray">Approver</label>
              <select
                id="approver_id"
                placeholder="Approver"
                {...register("approver_id", {required: true})}
                className={!errors.approver_id ? "input-text" : 'input-text-error'}
                value={formData?.approver_id ?? formData?.approver?.id ?? ""}
                onChange={handleFormChange}
              >User
                {approverOptions}
              </select>
              <FieldInputError item={errors.approver_id}></FieldInputError>
            </Field>

            {/* STATUS */}
            <Field>
              <label htmlFor="status" className="text-tenzingGray ">Task Status:</label>
              <select
                id="status"
                name="status"
                className={!errors.status ? "input-text" : 'input-text-error'}
                {...register("status", {required: true})}
                value={formData.status ? getNumberStatus(formData.status) : ""}
                onChange={handleFormChange}
              >
                {statusOptions}
              </select>
              <FieldInputError item={errors.status}></FieldInputError>
            </Field>

            {/* Impact */}
            <Field>
              <label htmlFor="impact" className="text-tenzingGray">Select Impact</label>
              <select
                id="impact"
                placeholder="Impact"
                {...register("impact", {required: true})}
                className={!errors.impact ? "input-text" : 'input-text-error'}
                value={formData.impact ?? ""}
                onChange={handleFormChange}
              >
                {impactOptions}
              </select>
              <FieldInputError item={errors.impact}></FieldInputError>
            </Field>

            {/* Due Date*/}
            <div className="flex justify-between items-baseline">
              <Field>
                <label htmlFor="due_date" className="text-tenzingGray">Due Date</label>
                <Controller
                  defaultValue=""
                  control={control}
                  name='due_date'
                  rules={{required: true}}
                  render={({field}) => (
                    <DatePicker
                      id='due_date'
                      placeholderText='Due Date'
                      minDate={null}
                      onChange={(date) => {
                        field.onChange(date)
                        // Set the value
                        setValue("due_date", date)
                        // Update the state
                        setFormData({
                          ...formData, ["due_date"]: date
                        })
                      }}
                      selected={formData?.due_date ? moment(formData.due_date).toDate() : ''}
                      className={!errors.due_date ? "input-text" : 'input-text-error'}
                    />
                  )}
                />
                <FieldInputError item={errors.due_date}></FieldInputError>
              </Field>
            </div>

            {/* Critical */}
            <Field>
              <div className="flex items-end">
                <p className="text-lg text-tenzingGray">Critical:</p>
                <div>
                  <label className="flex items-baseline" htmlFor="critical">
                    <input
                      id="critical"
                      name="critical"
                      type="checkbox"
                      checked={Boolean(formData?.critical ?? false)}
                      onClick={(event) => {
                        // Set the value
                        setValue("critical", event.target.checked)
                        // Update the state
                        setFormData({
                          ...formData, ["critical"]: event.target.checked
                        })
                      }}
                      className="appearance-none invisible peer"
                      {...register("critical")}
                    />
                    <span className="w-14 h-8
                                flex items-center
                                flex-shrink-0
                                mr-4 p-1
                                bg-lightGray
                                rounded-full
                                duration-300 ease-in-out
                                peer-checked:bg-tenzingBlue
                                after:w-6
                                after:h-6
                                after:bg-white
                                after:rounded-full
                                after:shadow-md
                                after:duration-300
                                peer-checked:after:translate-x-6
                                "></span>
                  </label>
                </div>
              </div>
            </Field>


            <section className="mt-4 flex justify-end">
              <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                      disabled={loading || doDisable}>
                {
                  doDisable ?
                    <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                    : <></>
                }
                Add New
              </Button>
            </section>
          </form>
          : null
      }
    </Section>
  );
}

export default Form;
