import React from 'react'
import
{
  TableCell,
  TableRow,
} from '@mui/material/';
import {
  formatAmount
} from '../../Utils/Contracts'
import {formatDate} from '../../../../utils/globals'
import {getContractBreakdown} from "../../../../utils/queries";
import {contractBreakdown} from "../../../../utils/queryKeys";
import {useQuery} from 'react-query';


const ContractsBreakdownRow = ({
                                 contract,
                                 projectId,
                                 setShowForm,
                                 setContractObject,
                                 permissions
                               }) => {
  const today = new Date().getTime()

  const contractBreakdownQuery = useQuery(
    [contractBreakdown, {contractBreakdownId: contract.id}],
    getContractBreakdown,
    {
      enabled: !!contract && contract.id != undefined && contract.id != null
    })

  return (
    !!contract
    && !contractBreakdownQuery.isLoading
    && contractBreakdownQuery.isSuccess
    && contractBreakdownQuery.data?.permissions != undefined ?
      <TableRow key={"contract-row-" + contract.id.toString()}>
        <TableCell>{contract.attributes.category["name"]}</TableCell>
        <TableCell>{contract.attributes.sub_category["name"]}</TableCell>
        <TableCell>{contract.attributes.category_breakdown["name"]}</TableCell>
        <TableCell>{contract.attributes.line_item["name"]}</TableCell>
        <TableCell>
          {
            contractBreakdownQuery.data?.permissions?.['update'] ?
              <div className="underline text-lightBlue hover:cursor-pointer"
                   onClick={() => {
                     setShowForm('edit_contract'), setContractObject(contract)
                   }}>{contract.id}
              </div>
              : <div>{contract.id}</div>
          }
        </TableCell>
        <TableCell>{contract.attributes["firm_name"]}</TableCell>
        <TableCell>{formatDate(contract.attributes["commitment_date"])}</TableCell>
        <TableCell>{formatAmount(contract.attributes["commitment_value"])}</TableCell>
        <TableCell
          style={{color: `${today > new Date(contract.attributes["coi_expiration"]).getTime() ? 'red' : ""}`}}
        >{formatDate(contract.attributes["coi_expiration"])}</TableCell>
      </TableRow>
      : null
  )
}

export default ContractsBreakdownRow;
