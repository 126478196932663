import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import
{
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import {
  getComparator,
  stableSort
} from '../Utils/index'

function List({
                users,
                setShowEditContact,
                setShowEditUser,
                setShowCreateUser,
                setUserID
              }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event) => {
    setUserID(event.target.dataset.userid)
    setShowEditContact(false)
    setShowEditUser(true)
  }


  return (
    <React.Fragment>
      <Box sx={{width: '100%', borderColor: 'grey'}}>
        <Paper sx={{width: '100%', mb: 2}}>
          <TableContainer>
            <Table stickyHeader>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(users, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user.id.toString()}>
                      <TableCell align="left">{user.id}</TableCell>
                      <TableCell>
                        <Link onClick={handleClick}
                              to="#"
                              data-userid={user.id}
                              className="underline">
                          {user.attributes["email"]}
                        </Link>
                      </TableCell>
                      <TableCell>
                          <span
                            className={user.attributes['status'] === 'removed' ? "bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded capitalize" : ""}>
                            {user.attributes['status']}
                          </span>
                      </TableCell>
                      <TableCell>{user.attributes.user_role['name']}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default List
