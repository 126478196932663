import React, {useEffect, useState} from 'react'
import CircularProgress from "@mui/material/CircularProgress";
import {toast} from "react-toastify";
import {useQueries} from "react-query";
import {cpFileToParent, cpFileToParentKey} from "../../../utils/boxApi/Queries";
import Button from 'Components/Common/Button';

const CopyTemplateBoxFilesTree = ({
                                    boxAccess,
                                    parentFolderId,
                                    templateFilesSelected,
                                    setTemplateFilesSelected
                                  }) => {
  const [postFiles, setPostFiles] = useState(false);

  const fnSuccess = (msg) => {
    toast.success(msg)
  };

  const fnFail = (msg) => {
    toast.error(msg)
  };

  const handleSuccess = (data, msg = "") => {
    if (msg !== "") {
      fnSuccess(`${data.data.name} successfully copied. (${msg})`)
    } else {
      fnSuccess(`${data.data.name} successfully copied.`)
    }
  }

  const handleError = (error) => {
    // This is a name conflict. Push a new version of the file
    if (error.response.status === 409) {
      fnFail(`File ${error.response.data.context_info.conflicts.name} already exists.`)
    } else {
      fnFail(error.response.data.message)
    }
  }

  // Copy all the selected items
  const copyFilesQueries = useQueries(
    templateFilesSelected && templateFilesSelected.map(fileId => {
      return {
        queryKey: [cpFileToParentKey, {
          accessToken: boxAccess?.data?.attributes?.['access_token'],
          fileId: fileId,
          parentId: parentFolderId
        }],
        queryFn: () => cpFileToParent([cpFileToParentKey, {
          accessToken: boxAccess?.data?.attributes?.['access_token'],
          fileId: fileId,
          parentId: parentFolderId
        }]),
        cacheTime: 0,
        retry: 1,
        enabled: (templateFilesSelected?.length > 0 && postFiles),
        onSuccess: (data) => handleSuccess(data, ""),
        onError: (error) => handleError(error),
      }
    })
  )

  const isFetching = copyFilesQueries && copyFilesQueries.some(query => query.isFetching)
  const isSuccess = copyFilesQueries && copyFilesQueries.some(query => query.isSuccess)


  useEffect(() => {
    if (!isFetching) {
      setPostFiles(false)
    }
  }, [isFetching])

  useEffect(() => {
    if (isSuccess && templateFilesSelected.length > 0) {
      setTemplateFilesSelected([])
    }
  }, [isSuccess])

  return (
    <>
      <Button
        type="button"
        onClick={() => setPostFiles(true)}
        buttonStyle={(postFiles) ? "gray-solid-button" : "orange-solid-button"}
        disabled={postFiles}>
        {
          postFiles ?
            <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
            : <></>
        }
        Copy Template
      </Button>
    </>
  )
}

export default CopyTemplateBoxFilesTree;
