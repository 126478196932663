import React, {useState} from "react";
import {statusColor} from "../../../utils/globals";
import moment from "moment";

export const linkTypeValues = [
  {
    name: 'None',
    value: "",
    key: 'none'
  },
  {
    name: 'Start to Start',
    value: "start_to_start",
    key: 'sts'
  },
  {
    name: 'Start to Finish',
    value: "start_to_finish",
    key: 'stf'
  },
  {
    name: 'Finish to Finish',
    value: "finish_to_finish",
    key: 'ftf'
  },
  {
    name: 'Finish to Start',
    value: "finish_to_start",
    key: 'fts'
  }
]


export const activityTypeValues = [
  {
    name: "Pre Design",
    value: "pre_design",
    key: "pde"
  },
  {
    name: "Design Team Selection",
    value: "design_team_selection",
    key: "dts"
  },
  {
    name: "Design Activities",
    value: "design_activities",
    key: "dac"
  },
  {
    name: "Non Design Activities",
    value: "non_design_activities",
    key: "nda"
  },
  {
    name: "Contractor Selection",
    value: "contractor_selection",
    key: "cse"
  },
  {
    name: "Construction Phase",
    value: "construction_phase",
    key: "cop"
  },
]

export const activityTypeOptions = activityTypeValues.map((type) => {
  return (
    <option key={type.key} value={type.value}>{type.name}</option>
  )
})

export const linkTypeOptions = linkTypeValues.map((item) => {
  return (
    <option key={item.key} value={item.value}>{item.name}</option>
  )
})

export const formFields = [
  "name",
  "starts_at",
  "duration",
  "extended_until",
  "extension_approved",
  "starts_at_anticipated",
  "activity_type",
  "link_type",
  "ancestry",
  "user",
  "risk",
  "project",
  "status"
]

export const dateFields = [
  "starts_at",
  "starts_at_anticipated",
  "extended_until",
  "ends_at"
]

// Status options list
export const statusValues = [
  "Not Started",
  "In Progress",
  "Completed"
]

export const isExtensionApproved = (activity) => {
  const ends_at = new Date(activity.attributes["ends_at"])

  // Extended Until is less than the Ends At baseline then it is approved
  // Extended Until has been marked as approved
  if (activity.attributes["extended_until"] != undefined) {
    const extended_until = new Date(activity.attributes["extended_until"])
    if (ends_at.getTime() > extended_until.getTime()
      || !!activity.attributes["extension_approved"]) {
      return true
    }
  }

  return false
}

export const isLessOrEqualToEndsAt = (activity) => {
  const ends_at = new Date(activity.attributes["ends_at"])

  // Extended Until is less than the Ends At baseline then it is approved
  // Extended Until has been marked as approved
  if (activity.attributes["extended_until"] != undefined) {
    const extended_until = new Date(activity.attributes["extended_until"])
    if ((ends_at.getTime() - extended_until.getTime()) >= 0) {
      return true
    }
  }

  return false
}

// I have a flat line when i have an extension and that extension
// is not shorter than my baseline end date
export const hasRisk = (activity) => {
  const ends_at = new Date(activity.attributes["ends_at"])

  if (activity.attributes["extended_until"] != undefined) {
    const extended_until = new Date(activity.attributes["extended_until"])
    return ends_at.getTime() <= extended_until.getTime();
  }

  return false
}

export const calculateActivityHasExtension = (activity) => {
  // Extension
  return activity?.attributes?.extended_until != undefined
    && !isLessOrEqualToEndsAt(activity)
    && hasRisk(activity)
}

export const EditIcon = ({activityType}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         className={`h-5 w-5 invisible ${activityType === undefined ? "appearance-none" : "group-hover:visible"}`}
         viewBox="0 0 20 20" fill="fill-tenzingRed">
      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"/>
      <path fillRule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clipRule="evenodd"/>
    </svg>
  )
}

export const EditIconString = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                 <path d="M0 0h24v24H0z" fill="none"/>
                                 <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                               </svg>`

// Return a string representation of the Project START date
// in format MM/DD/YYYY
export const findStartDate = (activities) => {
  const onlyStartDates = activities.map(activity => {
    return activity.attributes["starts_at"]
  })
  const orderedStartDates = onlyStartDates.sort((a, b) => {
    return moment(Date.parse(a)) - moment(Date.parse(b));
  })
  let calculatedDate = moment(new Date(orderedStartDates[0]));
  // NOTE: In case we need to add days
  calculatedDate.add(1, 'days');
  return calculatedDate.format("MM/DD/YYYY").toString();
}

// Return the duration between two dates
// start and end should represent a date
export const durationInDays = (start, end) => {
  let startDay = moment(new Date(start));
  let endDay = moment(new Date(end));

  return Math.abs(endDay.diff(startDay, 'days'));
}

// Return a string representation of the Project END activities
// in format MM/DD/YYYY
export const findEndDate = (activities) => {
  let onlyEndDates = []

  activities.map(activity => {
    onlyEndDates.push(activity.attributes["ends_at"])

    if (activity.attributes["extended_until"]) {
      onlyEndDates.push(activity.attributes["extended_until"])
    }
    return onlyEndDates
  })

  const orderedEndDates = onlyEndDates.sort((a, b) => {
    return moment(Date.parse(b)) - moment(Date.parse(a));
  })

  let calculatedDate = moment(new Date(orderedEndDates[0]));
  // NOTE: In case we need to add days
  // calculatedDate.add(1, 'days')
  return calculatedDate.format("MM/DD/YYYY").toString();
}

export const calculateItemClasses = (activity) => {
  const styles = `background-color: rgb(112 202 107);
                  border-top-left-radius: 9999px;
                  border-bottom-left-radius: 9999px;
                  ${!hasRisk(activity) || isLessOrEqualToEndsAt(activity) ?
    "border-top-right-radius: 9999px;border-bottom-right-radius: 9999px;"
    : ""}
      `
  return styles
}

// TODO: Refactor in its own file and use https://www.npmjs.com/package/react-mustache-template-component
//       in order to create the element
export const groupLineTemplate = (activity, setActivityToEdit, activityType, permissions) => {
  const wrapper = document.createElement('div');
  const wrapperNameClasses = ['flex', 'space-between'];
  wrapper.classList.add(...wrapperNameClasses)

  const statusSpan = `<span class="w-3 h-3 rounded-full ${statusColor(activity.attributes["status"])}"></span>`
  const durationDiv = `<div class="inline-flex w-11/12 justify-end bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">${activity.attributes["duration"]}</div>`
  const startsAt = activity.attributes['starts_at'] != undefined ? moment(activity.attributes['starts_at']).format("MM/DD/YY") : 'n/a'
  const endsAt = activity.attributes['ends_at'] != undefined ? moment(activity.attributes['ends_at']).format("MM/DD/YY") : 'n/a'
  const startsAtAnticipated = activity.attributes['starts_at_anticipated'] != undefined ? moment(activity.attributes['starts_at_anticipated']).format("MM/DD/YY") : 'n/a'
  const extendedUntil = activity.attributes['extended_until'] != undefined ? moment(activity.attributes['extended_until']).format("MM/DD/YY") : 'n/a'
  const linkedActivity = activity.attributes['ancestry'] != undefined ? activity.attributes['ancestry'] : 'n/a'
  const parentNodeName = activity.attributes['ancestry'] != undefined ? activity.attributes.parent_node['name'] : 'n/a'
  const linkType = activity.attributes['ancestry'] != undefined ? hrLinkTypes(activity.attributes['link_type']) : 'n/a'

  const editActivityIcon = document.createElement('div')
  if (permissions['update']) {
    // Create the Edit Icon
    editActivityIcon.classList.add(...['activity-edit-schedule', 'flex', 'items-center', 'mr-2', 'hover:cursor-pointer'])
    editActivityIcon.dataset.activityid = activity.id
    editActivityIcon.addEventListener('click', function handleClick(event) {
      setActivityToEdit({taskId: event.currentTarget.dataset.activityid, activityType: activityType})
    });
    editActivityIcon.insertAdjacentHTML('afterbegin', EditIconString)
  }
  const activityPrefix = `<div class="flex items-center w-28 tz-tooltip-left" data-line="${activity.attributes['name']} / ID: ${activity.id}"><div class="truncate">${activity.attributes['name']}</div></div>
                          <div class="flex items-center justify-center m-2">${statusSpan}</div>
                          <div class="flex items-center border-r border-lightGray justify-center w-20">${durationDiv}</div>
                          <div class="flex items-center border-r border-lightGray justify-center w-24">${startsAt}</div>
                          <div class="flex items-center border-r border-lightGray justify-center w-24">${startsAtAnticipated}</div>
                          <div class="flex items-center border-r border-lightGray justify-center w-24">${endsAt}</div>
                          <div class="flex items-center border-r border-lightGray justify-center w-24">${extendedUntil}</div>
                          <div class="flex items-center justify-center w-12 tz-tooltip-right" data-line="Parent: ${parentNodeName}" data-link="Link: ${linkType}">${linkedActivity}</div>
                         `
  wrapper.insertAdjacentHTML('afterbegin', activityPrefix);
  if (permissions['update']) {
    wrapper.prepend(editActivityIcon)
  }

  return wrapper
}

export const groupLineHeaders = (id) => {
  const timelineGraph = document.querySelector(`#${id} .vis-timeline`)
  const statusSpan = '<span class="w-3 h-3 rounded-full bg-outlineGray opacity-0"></span>'

  const wrapper = document.createElement('div');
  const wrapperNameClasses = ['absolute', 'flex', 'space-between', 'group-line-headers'];
  wrapper.classList.add(...wrapperNameClasses)

  const headerTitles = `<div class="flex font-bold items-start w-36 truncate">Tasks</div>
                          <div class="flex font-bold items-center justify-center m-2">${statusSpan}</div>
                          <div class="flex font-bold text-center items-center border-r border-transparent justify-center w-20">Duration</div>
                          <div class="flex font-bold text-center items-center border-r border-transparent justify-center w-24">Baseline Start Date</div>
                          <div class="flex font-bold text-center items-center border-r border-transparent justify-center w-24">Anticipated Start Date</div>
                          <div class="flex font-bold text-center items-center border-r border-transparent justify-center w-24">Beaseline End Date</div>
                          <div class="flex font-bold text-center items-center border-r border-transparent justify-center w-24">Anticipated End Date</div>
                          <div class="flex font-bold text-center items-center justify-center w-12">Links</div>
                         `
  wrapper.insertAdjacentHTML('afterbegin', headerTitles);
  if (timelineGraph != undefined) {
    // First remove if exists. and then redraw??
    timelineGraph.appendChild(wrapper)
  }
}

export const groupLineNameTemplate = (name) => {
  const wrapper = document.createElement('span');
  const wrapperNameClasses = ['pr-10'];
  wrapper.insertAdjacentHTML('afterbegin', name);
  wrapper.classList.add(...wrapperNameClasses)
  return wrapper;
}

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}

export const hrLinkTypes = (type) => {
  switch (type) {
    case "start_to_start":
      return "Start to Start"
    case "start_to_finish":
      return "Start to Finish"
    case "finish_to_finish":
      return "Finish to Finish"
    case "finish_to_start":
      return "Finish to Finish"
    default:
      return ""
  }
}
