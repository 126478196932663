import {faker} from '@faker-js/faker';
import makeStyles from '@material-ui/core/styles/makeStyles';

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItems = (count) =>
  Array.from({length: count}, (_, k) => k).map(k => ({
    id: `Item ${k + 1}`,
    primary: faker.commerce.productName(),
    secondary: faker.company.catchPhrase()
  }));


export const getPhaseDefaults = () => {
  return [
    {
      id: 'Item 1',
      primary: 'Pre-design',
      secondary: ''
    },
    {
      id: 'Item 2',
      primary: 'Design Team Selection',
      secondary: ''
    },
    {
      id: 'Item 3',
      primary: 'Design Activities',
      secondary: ''
    },
    {
      id: 'Item 4',
      primary: 'Non Design Activities',
      secondary: ''
    },
    {
      id: 'Item 5',
      primary: 'Description',
      secondary: ''
    },
    {
      id: 'Item 6',
      primary: 'Construction Phase',
      secondary: ''
    }
  ]
}

export const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 16,
    minWidth: 350
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  draggingListItem: {
    background: 'rgb(235,235,235)',
  }
});
