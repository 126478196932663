import React, {useRef} from "react";
import {useMutation} from "react-query";
import {gmailCreateLabel, gmailLabelKey, gmailLabelList} from "../../../utils/google/Queries";
import Button from "../../Common/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useForm} from "react-hook-form";
import {
  Field,
  FieldError,
  ErrorText
} from '../../Common/Forms';
import {createEmailLabel} from "../../../utils/queries";
import {emailLabelAdd} from "../../../utils/queryKeys";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

const LabelForm = ({
                     handleShowModal,
                     gapi,
                     setUpdateRelated,
                     updateRelated
                   }) => {
  const labelName = useRef(null)
  const projectIdent = useRef(null)
  const newLabelId = useRef(null)
  const existingLabelId = useRef(null)
  const params = useParams()
  const projectValue = JSON.parse(window.localStorage.getItem("projectValue") ?? '{}');

  const notifySuccess = (msg) => {
    toast.success(msg);
  }
  const notifyError = (msg) => {
    toast.error(msg)
  }

  // Get the list of Gmail Labels. Iterate over the list and pick the one with the same name
  const {isLoading: isLoadingGetGmail, mutateAsync: getGmailLabelList} = useMutation(gmailLabelList, {
    onSuccess: (data, variables, context) => {
      data.data.labels.forEach(label => {
        if (label.name === labelName.current) {
          existingLabelId.current = label.id
        }
      })

      // Save to database
      const tdata = {
        name: labelName.current,
        gmail_labelid: existingLabelId.current
      }

      // Post the label model
      postEmailLabel([emailLabelAdd, {payload: tdata}])
    },
    onError: (error, variables, context) => {
      console.error('Gmail label list fetch failed.', error.message)
    }
  });

  // Google Create Gmail Label
  const {isLoading: isLoadingPostGmail, mutateAsync: postGmailLabel} = useMutation(gmailCreateLabel, {
    onSuccess: (data, variables, context) => {
      console.log('new label created successfully.')
      notifySuccess(data.data.id.toString() + ' label created.')
      setUpdateRelated(!updateRelated)
      newLabelId.current = data.data.id
      // Update the task
      const tdata = {
        gmail_labelid: data.data.id,
        name: labelName.current
      }

      // Post the label model
      postEmailLabel([emailLabelAdd, {payload: tdata}])
    },
    onError: (error, variables, context) => {
      console.error('Gmail Label creation failed:', error.message)

      if (error.response.status === 409) {
        console.log('Gmail Label already exists:', error.response.data.message)
        notifySuccess('Email Tag already exists')
        // The label already exists. I will fetch the id of the label.
        getGmailLabelList([gmailLabelKey, {
          access_token: gapi?.auth?.getToken()?.access_token
        }])
      } else {
        notifyError('Email Tag creation failed')
      }
    }
  });

  // Create and Store Email Label record in the database
  const {isLoading: isLoadingCreateLabel, mutateAsync: postEmailLabel} = useMutation(createEmailLabel, {
    onSuccess: (data, variables, context) => {
      console.log("Saved label to database")
      notifySuccess(data.attributes.gmail_labelid.toString() + ' label saved.')
    },
    onError: (error, variables, context) => {
      error.response.data.forEach(error => notifyError(error.detail))
      console.log('error response:', error.response.data)
    }
  });

  const {register, handleSubmit, setValue, formState: {errors}} = useForm();

  const onSubmit = async (data, e) => {
    const {name} = data

    if (params['projectId'] != undefined && params['projectId'] != '') {
      const projectCurr = JSON.parse(window.localStorage.getItem("projectValue"));
      projectIdent.current = projectCurr?.project?.attributes["identifier"]
    }

    // Cache the label name
    labelName.current = projectIdent.current != undefined ? projectIdent.current + "/" + name : name
    // This is a free form input text. I will let the user add
    // What fits the use case.
    const gdata = {
      "labelListVisibility": "labelShow",
      "messageListVisibility": "show",
      "name": labelName.current
    }

    // Create the label
    await postGmailLabel([gmailLabelKey, {
      data: gdata,
      access_token: gapi?.auth?.getToken()?.access_token,
    }])
  }

  return (
    <>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="relative p-4 flex-auto">
          <Field>
            <label htmlFor="name" className="text-tenzingGray">Name:</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={() => setValue("name", e.target.value.trim())}
              {...register("name", {required: true})}
              className={!errors.name ? "input-text" : 'input-text-error'}
            />
            <FieldError>
              {errors.name?.type === 'required' &&
                <ErrorText>Name is required</ErrorText>
              }
            </FieldError>
          </Field>
        </div>
        <div className="flex items-center justify-end p-4">
          <button
            className="underline text-gray-400 py-0.5 px-4 m-1"
            onClick={() => handleShowModal(false)}
          >Cancel
          </button>
          <Button
            buttonStyle={(isLoadingPostGmail || isLoadingGetGmail || isLoadingCreateLabel) ? "gray-solid-button" : "orange-solid-button"}
            disabled={(isLoadingPostGmail || isLoadingGetGmail || isLoadingCreateLabel)}>
            {
              (isLoadingPostGmail || isLoadingGetGmail || isLoadingCreateLabel) ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add
          </Button>
        </div>
      </form>
    </>
  )
}

export default LabelForm;
