import React, {useState, useEffect, useContext, useRef} from 'react'
import {Switch, Route, Link, useLocation} from 'react-router-dom'
import Section from 'Components/Common/Section'
import InvoiceForm from 'Components/Costs/Forms/Invoice'
import EditInvoice from 'Components/Costs/Forms/Invoice/EditInvoice'
import EditContract from 'Components/Costs/Forms/ContractBreakdown/EditContract'
import EditLineItem from 'Components/Costs/Forms/LineItem/EditLineItem'
import LineItemForm from 'Components/Costs/Forms/LineItem'
import ContractBreakdownForm from 'Components/Costs/Forms/ContractBreakdown'
import ProformaTable from 'Components/Costs/Tables/ProformaTable'
import InvoicesTable from 'Components/Costs/Tables/InvoicesTable'
import ContractsTable from 'Components/Costs/Tables/ContractsTable'
import {useQuery} from 'react-query'
import {
  allCategories as categoriesQueryKey,
  project as projectQueryKey
} from '../../utils/queryKeys'
import {getBoxAccessToken, getCategories, getProject} from '../../utils/queries'
import {
  costsURL,
  costsContractsURL,
  costsInvoicesURL
} from 'utils/urls'
import {ProjectContext} from "../../context/ProjectContext";
import {boxAccessToken} from "../../utils/queryKeys";
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import moment from 'moment'
import {
  removeClassByPrefix,
  removeClassByPostfix
} from 'utils/globals'

function Index() {
  const {selectedProject, setSelectedProject} = useContext(ProjectContext);
  const [showForm, setShowForm] = useState('');
  const [invoiceObject, setInvoiceObject] = useState({});
  const [contractObject, setContractObject] = useState({});
  const [lineItemObject, setLineItemObject ] = useState({});
  const [refetchData, setRefetchData] = useState(false)
  const [invoicePermissions, setInvoicePermissions] = useState(null); 
  const [lineitemPermissions, setLineitemPermissions] = useState(null); 
  const [contractBreakdownPermissions, setContractBreakdownPermissions] = useState(null); 

  const currentURL = useLocation()
  const printRef = useRef();

  const categories = useQuery(categoriesQueryKey, getCategories)
  const project = useQuery([projectQueryKey, {id: selectedProject.value}], getProject)
  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  const bexpConfig = {
    access_token: boxAccess,
    boxable: project.data,
    root_folder_column: 'cost_box_folderid',
  }

  const forms = {
    line_item: LineItemForm,
    contract_breakdown: ContractBreakdownForm,
    invoice: InvoiceForm,
    edit_invoice: EditInvoice,
    edit_contract: EditContract,
    edit_lineItem: EditLineItem
  }

  const showFormButton = (url) => {
    switch (url) {
      case costsURL(selectedProject.value):
        if (lineitemPermissions
            && lineitemPermissions?.['create']) {
          return (
            <>
              <button className="orange-transparent-button"
                      onClick={() => setShowForm('line_item')}>Add Budget Line
                Item
              </button>
            </>
          )
        }
        break;
      case costsContractsURL(selectedProject.value):
        if (contractBreakdownPermissions
          && contractBreakdownPermissions?.['create']) {
          return (
            <>
              <button className="orange-transparent-button"
                      onClick={() => setShowForm('contract_breakdown')}>Add Contract Breakdown
              </button>
            </>
          )
        }
        break;
      case costsInvoicesURL(selectedProject.value):
        if (invoicePermissions
            && invoicePermissions?.['create']) {
          return (
            <>
              <button className="orange-transparent-button"
                      onClick={() => setShowForm('invoice')}>Add Invoice
              </button>
            </>
          )
        }
        break;
      default:
        console.log('Not found');
    }
  }

  const displayForm = (categories, selectedProject, bexpConfig, invoiceObject, contractObject, lineItemObject) => {
    const FormTag = forms[showForm]
    return <FormTag setShowForm={setShowForm}
                    categories={categories}
                    projectId={selectedProject}
                    bexpConfig={bexpConfig}
                    invoiceObject={invoiceObject}
                    contractObject={contractObject}
                    setRefetchData={setRefetchData}
                    refetchData={refetchData}
                    lineItemObject={lineItemObject}></FormTag>
  }

  const handleDownloadPdf = async () => {
    var HTML_Width = printRef.current.offsetWidth;
    var HTML_Height = printRef.current.offsetHeight;

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // https://github.com/niklasvh/html2canvas/issues/1856
    const canvas = await html2canvas(printRef.current, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      imageTimeout: 5000,
      onclone: (clonedElement) => {
        clonedElement.querySelectorAll('[class*="shadow"]').forEach((elem) => {
          removeClassByPrefix(elem, 'shadow')
        });
        clonedElement.querySelectorAll('[class*="MuiPaper-root"]').forEach((elem) => {
          removeClassByPostfix(elem, 'MuiPaper-root')
        });
      }
    });
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

    pdf.addImage(data, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


    for (var i = 1; i <= totalPDFPages; i++) {
      pdf.addPage(PDF_Width.toString(), PDF_Height.toString());
      pdf.addImage(data, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    }

    pdf.save(`${project.data.data.attributes["identifier"]}_costs-${moment().format('l')}.pdf`);

  };

  // Hide the Form whenever i move to a new tab
  useEffect(() => {
    setShowForm('')
  }, [currentURL.pathname])


  return (
    <div className='flex flex-col order-last xl:flex-row xl:order-first'>
      <div className='w-full'>
        <Section>
          <div className="flex flex-row justify-between">
            <section className="flex flex-row space-x-8 mb-4">
              <Link to={costsURL(selectedProject.value)}
                    className={`pb-2 Section-h1 ${currentURL.pathname === costsURL(selectedProject.value) ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Project Proforma
              </Link>
              <Link to={costsContractsURL(selectedProject.value)}
                    className={`pb-2 Section-h1 ${currentURL.pathname === costsContractsURL(selectedProject.value) ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Project Contracts
              </Link>
              <Link to={costsInvoicesURL(selectedProject.value)}
                    className={`pb-2 Section-h1 ${currentURL.pathname === costsInvoicesURL(selectedProject.value) ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Project Invoices
              </Link>
            </section>
            <div>{showFormButton(currentURL.pathname)}</div>
          </div>
          <div ref={printRef}>
            <Switch>
              <Route path="/projects/:projectId/costs/contracts"
                     render={() => <ProjectContracts categories={categories}
                                                     selectedProject={selectedProject.value}
                                                     setShowForm={setShowForm}
                                                     setContractObject={setContractObject}
                                                     setContractBreakdownPermissions={setContractBreakdownPermissions}/>}/>
              <Route path="/projects/:projectId/costs/invoices"
                     render={() => <ProjectInvoices categories={categories}
                                                    selectedProject={selectedProject.value}
                                                    setShowForm={setShowForm}
                                                    setInvoiceObject={setInvoiceObject}
                                                    setInvoicePermissions={setInvoicePermissions}/>}/>
              <Route path="/projects/:projectId/costs"
                     render={() => <ProjectProforma categories={categories}
                                                    selectedProject={selectedProject.value}
                                                    setLineItemObject={setLineItemObject}
                                                    setShowForm={setShowForm}
                                                    setLineitemPermissions={setLineitemPermissions}
                                                    refetchData={refetchData}/>}/>
            </Switch>
          </div>
          <div className="mt-4 flex justify-end">
            <button data-html2canvas-ignore type="button" className="orange-transparent-button"
                    onClick={handleDownloadPdf}>
              Export as PDF
            </button>
          </div>
        </Section>
      </div>
      <div className='order-first xl:order-last'>
        {showForm ?
          <div className="ml-2">
            {displayForm(categories, selectedProject.value, bexpConfig, invoiceObject, contractObject, lineItemObject)}
          </div>
          : null
        }
      </div>
    </div>
  )
}

export default Index

function ProjectProforma(props) {
  let markup = ''
  {
    props.categories ?
      markup = (
        <section className="border border-outlineGray">
          <ProformaTable categories={props.categories?.data}
                         projectId={props.selectedProject}
                         setLineItemObject={props.setLineItemObject}
                         setShowForm={props.setShowForm}
                         setLineitemPermissions={props.setLineitemPermissions}
                         refetchData={props.refetchData}
          ></ProformaTable>
        </section>
      )
      :
      markup = (<p>No data found!</p>)
  }
  return markup
}

function ProjectContracts(props) {
  let markup = ''
  {
    props.selectedProject ?
      markup = (
        <section className="border border-outlineGray">
          <ContractsTable
            projectId={props.selectedProject}
            setShowForm={props.setShowForm}
            setContractObject={props.setContractObject}
            setContractBreakdownPermissions={props.setContractBreakdownPermissions}
          ></ContractsTable>
        </section>
      )
      :
      markup = (<p>No data found!</p>)
  }
  return markup
}

function ProjectInvoices(props) {
  let markup = ''
  {
    props.selectedProject ?
      markup = (
        <section className="border border-outlineGray">
          <InvoicesTable
            projectId={props.selectedProject}
            setInvoiceObject={props.setInvoiceObject}
            setShowForm={props.setShowForm}
            setInvoicePermissions={props.setInvoicePermissions}
          ></InvoicesTable>
        </section>
      )
      :
      markup = (<p>No data found!</p>)
  }
  return markup
}
