import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import
{
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import {
  getComparator,
  stableSort
} from '../Utils/index'

function List({
                items,
                projectid
              }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  return (
    <React.Fragment>
      <Box sx={{width: '100%', borderColor: 'grey'}}>
        <Paper sx={{width: '100%', mb: 2}}>
          <TableContainer>
            <Table stickyHeader>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(items, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((contact) => (
                    <TableRow key={contact.id.toString()}>
                      <TableCell align="left">{contact.id}</TableCell>
                      <TableCell>
                        {
                          projectid != undefined ?
                          <>{contact.attributes["first_name"] + ' ' + contact.attributes["last_name"]}</>
                          :
                          <Link to={`/contacts/${contact.id}`} className="underline">
                            {contact.attributes["first_name"] + ' ' + contact.attributes["last_name"]}
                          </Link>
                        }
                      </TableCell>
                      <TableCell>{contact.attributes['title']}</TableCell>
                      <TableCell>{contact.attributes["phone"]}</TableCell>
                      <TableCell>{contact.attributes["address"]}</TableCell>
                      <TableCell>{contact.attributes["company"]?.name}</TableCell>
                      <TableCell>
                        {
                          <ul>
                            {contact.attributes['users']?.map((usr) => {
                              return (
                                <li key={usr.id.toString()}>{usr.email}</li>
                              )
                            })}
                          </ul>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default List
