import React from 'react'
import ListItem from '../ListItem'


function List({items}) {

  return(
    <section className="h-[calc(100vh-100px)] overflow-y-scroll">
      {!!items.length ? (
        <ul>
          {items.map(project => (
            <li
              className="gray-border"
              key={project.id.toString()}
              aria-label={project.attributes["name"]}
            >
              <ListItem item={project}/>
            </li>
          ))}
        </ul>
      ) : (
        <p>No projects selected</p>
      )}
    </section>
  )
}

export default List
