import React, {useEffect, useState, useRef} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from 'react-query';
import Section from "Components/Common/Section";
import CalendarForm from "Components/Calendar/Form";
import GoogleCalendar from "Components/Calendar/FullCal";
import {project} from "utils/queryKeys"
import {getProject} from "utils/queries";
import EditCalendarEvent from "../../Components/Calendar/editCalEvent";

function Index() {
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [createCalendar, setCreateCalendar] = useState(false);
  const [showForm, setShowForm] = useState(false); 
  const [currentEvent, setCurrentEvent] = useState(null); 

  const {projectId} = useParams()
  const projectQuery = useQuery([project, {id: projectId}], getProject)

  return (
    <div className='flex flex-col order-last xl:flex-row xl:order-first'>
      <div className="w-full xl:mr-2">
        <GoogleCalendar
          setIsRefreshed={setIsRefreshed}
          isRefreshed={isRefreshed}
          createCalendar={createCalendar}
          setCreateCalendar={setCreateCalendar}
          setShowForm={setShowForm}
          setCurrentEvent={setCurrentEvent}
          />
      </div> 
      {!showForm ? null :
        <div className='order-first xl:order-last'>
          {
            !projectQuery.isLoading
            && projectQuery.isSuccess
            && projectQuery?.data?.data?.attributes?.["google_calendarid"] != undefined
            && projectQuery?.data?.data?.attributes?.["google_calendarid"].trim() != ''
            && !createCalendar ?
              <Section>
              {
                showForm != undefined && showForm === "new_event" ?
                  <CalendarForm calendarId={projectQuery?.data?.data?.attributes?.["google_calendarid"]}
                              setIsRefreshed={setIsRefreshed}
                              isRefreshed={isRefreshed}
                              showForm={showForm}
                              setShowForm={setShowForm}
                />
                : null
              }
              {
                showForm != undefined && showForm === "edit_event" ?              
                  <EditCalendarEvent 
                    calendarId={projectQuery?.data?.data?.attributes?.["google_calendarid"]}
                    currentEvent={currentEvent}
                    setCurrentEvent={setCurrentEvent}
                    setIsRefreshed={setIsRefreshed}
                    isRefreshed={isRefreshed}
                    setShowForm={setShowForm}
                  /> 
                  : null
              }
              </Section>
              : null
          }
        </div>
      }
    </div>
  );
}

export default Index;