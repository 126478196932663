import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {boxAccessToken, project as projectByIdQuery} from "../../../utils/queryKeys";
import {getBoxAccessToken, getProject} from "../../../utils/queries";
import {WideColumn} from "../../Common/Layout/ContentTwoColumn"
import Section from '../../../Components/Common/Section'
import BoxFileExplorer from 'Components/FileExplorer';

import React from "react";

function DesignSchedule({activitiesPermissions}) {
  const {projectId} = useParams();

  const project = useQuery([projectByIdQuery, {id: projectId}], getProject)
  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  const bexpConfig = {
    boxable: project.data,
    root_folder_column: 'design_box_folderid',
    access_token: boxAccess
  }
  return (
    <>
      {
        activitiesPermissions?.['update'] ?
          <WideColumn>
            <Section>
              {
                !project.isLoading
                && project.isSuccess
                && !!project.data?.data ?
                  <BoxFileExplorer props={bexpConfig}/> :
                  null
              }
            </Section>
          </WideColumn>
          : null
      }
    </>
  )
}

export default DesignSchedule
