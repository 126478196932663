import {Tooltip} from "@material-ui/core";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import React from "react";

// Choose and upload a Profile image
const ProfileImagePick = ({processFile}) => {
  return (
    <Tooltip component={'span'} title="Upload Profile Image">
      <div className="bg-blue-400 h-20 w-20 rounded-full flex justify-center items-center hover:cursor-pointer">
        <label htmlFor="profile_image" className="hover:cursor-pointer">
          <UploadFileIcon/>Image
        </label>
        <input style={{display: "none"}}
               id="profile_image"
               type="file"
               onChange={processFile}/>
      </div>
    </Tooltip>
  )
}

export default ProfileImagePick
