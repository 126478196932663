import React, {useState, useRef, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useParams} from "react-router-dom";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toast} from 'react-toastify';
import {client as apiClient} from 'utils/api';
import {Field} from 'Components/Common/Forms';
import Button from "Components/Common/Button";
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {FieldWithError, FormSlider, SelectFieldWithError} from 'Components/Common/Forms/Field';
import {
  activityTypeOptions,
  linkTypeOptions
} from '../../Resources/index'
import {
  projectActivities,
  projectRisks,
  projectUsers
} from "utils/queryKeys";
import {
  getProjectRisks,
  getProjectActivities,
  getProjectUsers
} from "utils/queries";
import CircularProgress from "@mui/material/CircularProgress";
import {capitalize, getNumberStatus} from "../../../../utils/globals";
import {statusValues} from "../../Resources/index";

const NewActivityForm = ({
                           setShowForm,
                           setReloadSchedule
                         }) => {
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const params = useParams();
  const queryClient = useQueryClient();

  const [currentActivityType, setCurrentActivityType] = useState(null)
  const [sliderDisabled, setSliderDisabled] = useState(true)
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const isReadOnly = useRef(false)
  const isAncestryRequired = useRef(false)
  const [endDate, setEndDate] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [durationState, setDurationState] = useState(0)

  const statusOptions = statusValues.map((item, index) => {
    return (
      <option key={"activity" + item + index.toString()} value={index}>{capitalize(item)}</option>
    )
  }, this)

  const notifySuccess = () => toast.success("New Activity added successfully!");
  const notifyError = () => toast.error("New activity unsuccessful.")

  // FETCH PROJECT ACTIVITIES
  const activitiesQuery = useQuery(
    [projectActivities, {projectId: params['projectId']}],
    getProjectActivities)

  let predecessorOptions = []
  predecessorOptions =
    activitiesQuery.isLoading && !currentActivityType ? null :
      activitiesQuery.data?.data?.map(activity => {
        if (activity.attributes["activity_type"] == currentActivityType) {
          return <option key={activity.id} value={activity.id}>{activity.attributes["name"]}</option>
        } else {
          null
        }
      })

  // XXX This is a temporary solution
  useEffect(() => {
    if (startDate != undefined && durationState != undefined) {
      setEndDate(moment(startDate).add(durationState, 'd').format('MM/DD/YYYY'))
    }
  }, [startDate, durationState])

  // FETCH RISKS
  const risks = useQuery(
    [projectRisks, {projectId: params['projectId']}],
    getProjectRisks
  )

  const riskOptions =
    risks.isLoading ? null
      :
      risks?.data?.data?.length
      && risks?.data?.data?.map((item) => {
        return (
          <option key={"activity-add" + item.attributes.name + item.id} value={item.id}>{item.attributes.name}</option>
        )
      }, this);

  // FETCH USER LIST
  const users = useQuery([projectUsers, {projectId: params['projectId']}], getProjectUsers)

  const userOptions =
    users.isLoading ? 'Loading...'
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"activity-add-" + item.attributes.email + item.id} value={item.id}>{item.attributes.email}</option>
      )
    }, this);


  const {isLoading, mutateAsync: sendData} = useMutation(postForm);
  const {register, handleSubmit, setValue, formState: {errors}, control, reset, watch} = useForm();
  const watchLinkType = watch("link_type")

  // You should be able to not include a Start Date for an Activity
  // that has a predesessor link OR if there is a link,
  // ignore the start date
  // TODO: For now we will not apply any validation logic to the frontend.
  //       We will handle everything in the backend. This is a future
  //       improvement
  // isReadOnly.current = watchLinkType === 'start_to_start';

  isAncestryRequired.current = watchLinkType != undefined && watchLinkType != '';

  // Post new Activity
  async function postForm(data) {
    const {
      name,
      starts_at,
      duration,
      starts_at_anticipated,
      extended_until,
      extension_approved,
      activity_type,
      link_type,
      ancestry,
      risk_id,
      user_id,
      status
    } = data

    const formattedStart = moment(starts_at).utc().format()
    const formattedExtension = (extended_until == undefined || extended_until == '')
      ? null : moment(extended_until).utc().format()
    const formattedStartsAtAnticipated = (starts_at_anticipated == undefined || starts_at_anticipated == '')
      ? null : moment(starts_at_anticipated).utc().format()
    const approve = sliderDisabled ? null : !!extension_approved

    return await apiClient.post("/activities", {
      name: name,
      starts_at: formattedStart,
      starts_at_anticipated: formattedStartsAtAnticipated,
      ends_at: null,
      duration: parseInt(durationState),
      extended_until: formattedExtension,
      extension_approved: approve,
      activity_type: activity_type,
      link_type: link_type,
      project_id: parseInt(params["projectId"]),
      risk_id: parseInt(risk_id),
      user_id: user_id == undefined ? null : parseInt(user_id),
      ancestry: ancestry,
      status: parseInt(status)
    })
  }

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.refetchQueries([projectActivities, {projectId: params['projectId']}])
      notifySuccess()
      reset()
      e.target.reset()
      setShowForm(false)
      setLoading(false)
      setDoDisable(false)
      setReloadSchedule(true)
    } catch (error) {
      setLoading(false)
      setDoDisable(false)
      notifyError()
      throw new Error(error)
    }
  }


  return (
    <section className="sticky top-2 p-4 mb-4 bg-white rounded-md shadow-sm w-full min-w-fit">
      <h1 className="Section-h1 mb-4">Add New Activity</h1>
      {activitiesQuery.isLoading ? null :
        <form method="post" onSubmit={handleSubmit(onSubmit)}>

          {/* ACTIVITY NAME */}
          <FieldWithError
            name={"name"}
            label={"Activity Name:"}
            error={errors.name}
            register={register}
            required={true}
          />

          {/*ACTIVITY TYPE*/}
          <Field>
            <label htmlFor="activity_type" className="text-tenzingGray">Activity Type:</label>
            <Controller
              name="activity_type"
              control={control}
              rules={{required: true}}
              render={({field}) => {
                return (
                  <select
                    id="activity_type"
                    onChange={(e) => {
                      setCurrentActivityType(e.target.value)
                      field.onChange(e.target.value)
                    }}
                    className={!errors.activity_type ? "input-text" : 'input-text-error'}>
                    <option>Select Activity...</option>
                    {activityTypeOptions}
                  </select>
                )
              }}
            />
            <FieldInputError item={errors.activity_type}></FieldInputError>
          </Field>

          {/* LINK TYPE */}
          <SelectFieldWithError
            name="link_type"
            label="Link Type:"
            register={register}
            error={errors.link_type}
            required={false}
            options={linkTypeOptions}
          />

          {/*ANCESTRY*/}
          {/* An Predecessor activity is required if we choose a start to start */}
          <SelectFieldWithError
            name="ancestry"
            label="Select Predecessor:"
            register={register}
            error={errors.ancestry}
            required={isAncestryRequired.current}
            options={predecessorOptions}
            emptySelect={true}
            classes={!isAncestryRequired.current ? "hidden" : ""}
            lblClasses={!isAncestryRequired.current ? "hidden" : ""}
          />

          {/* RISK */}
          <Field>
            <label htmlFor="risk_id" className="text-tenzingGray">Select Risk</label>
            <select
              id="risk_id"
              {...register("risk_id", {required: false})}
              className={!errors.risk_id ? "input-text" : 'input-text-error'}
            >User
              <option key="empty-risk_id" value="">-- Select Risk --</option>
              {riskOptions}
            </select>
            <FieldInputError item={errors.risk_id}></FieldInputError>
          </Field>

          {/* STARTS AT */}
          <Field>
            <label htmlFor="starts_at" className="text-tenzingGray">Activity Start Date:</label>
            <Controller
              name='starts_at'
              control={control}
              rules={{required: !isReadOnly.current}}
              render={({field}) => {
                return (
                  <DatePicker
                    id='starts_at'
                    minDate={null}
                    popperPlacement="left"
                    readOnly={isReadOnly.current}
                    onChange={(date) => {
                      field.onChange(date)
                      setStartDate(date)
                    }}
                    selected={field.value}
                    className={!errors.starts_at ? "input-text" : 'input-text-error'}
                  />
                )
              }}
            />
            <FieldInputError item={errors.starts_at}></FieldInputError>
          </Field>

          {/* DURATION */}
          <FieldWithError
            name="duration"
            label="Duration (in days):"
            type={"number"}
            error={errors.duration}
            register={register}
            required={true}
            handleFormChange={(ev) => {
              setDurationState(ev.target.value)
              setValue(ev.target.id, ev.target.value)
            }}
          />

          {/* ENDS AT */}
          <Field>
            <label htmlFor="ends_at" className="text-tenzingGray">Activity End Date:</label>
            <input
              id="ends_at"
              name="ends_at"
              type="text"
              disabled={true}
              className={!errors.ends_at ? "input-text" : 'input-text-error'}
              {...register("ends_at", {required: false})}
              value={endDate}
            />
          </Field>


          {/* STARTS AT ANTICIPATED*/}
          <Field>
            <label htmlFor="starts_at_anticipated" className="text-tenzingGray">Activity Anticipated Start Date:</label>
            <Controller
              name='starts_at_anticipated'
              control={control}
              rules={{required: !isReadOnly.current}}
              render={({field}) => {
                return (
                  <DatePicker
                    id='starts_at_anticipated'
                    minDate={null}
                    popperPlacement="left"
                    readOnly={isReadOnly.current}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    className={!errors.starts_at ? "input-text" : 'input-text-error'}
                  />
                )
              }}
            />
            <FieldInputError item={errors.starts_at}></FieldInputError>
          </Field>

          {/* EXTENDED UNTIL */}
          <Field>
            <label htmlFor="extended_until" className="text-tenzingGray">Activity Anticipated End Date:</label>
            <Controller
              name='extended_until'
              control={control}
              render={({field}) => (
                <DatePicker
                  id='extended_until'
                  minDate={null}
                  popperPlacement="left"
                  onChange={(date) => {
                    field.onChange(date)
                    if (date != undefined && date.toString().trim() != '') {
                      setSliderDisabled(false)
                    } else {
                      setSliderDisabled(true)
                    }
                  }}
                  selected={field.value}
                  className={!errors.extended_until ? "input-text" : 'input-text-error'}
                />
              )}
            />
            <FieldInputError item={errors.extended_until}></FieldInputError>
          </Field>

          {/* EXTENSION APPROVED */}
          {sliderDisabled ? null :
            <FormSlider
              name="extension_approved"
              label="Extension Approved"
              control={control}
              register={register}
            />
          }

          {/* USER ID */}
          <Field>
            <label htmlFor="user_id" className="text-tenzingGray">Responsible (Optional):</label>
            <select
              id="user_id"
              placeholder="Assigned To"
              {...register("user_id", {required: false})}
              className={!errors.user_id ? "input-text" : 'input-text-error'}
            >User
              <option value="">-- Select --</option>
              {userOptions}
            </select>
            <FieldInputError item={errors.user_id}></FieldInputError>
          </Field>

          {/* STATUS */}
          <SelectFieldWithError
            name="status"
            label="Activity Status:"
            register={register}
            error={errors.status}
            required={false}
            options={statusOptions}
            emptySelect={true}
          />

          <section className="flex justify-between">
            <button onClick={() => setShowForm(false)} className="align-middle mt-6 gray-transparent-button">Cancel
            </button>
            <Button buttonStyle={(loading || doDisable) ? "mt-6 gray-solid-button" : "mt-6 orange-solid-button"}
                    type="submit"
                    disabled={loading || doDisable}>
              {
                doDisable ?
                  <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                  : <></>
              }
              Save New Activity
            </Button>
          </section>
        </form>
      }
    </section>
  )
}

export default NewActivityForm;

