import React, {useState} from 'react'
import FiFoNode from "./FiFoNode";
import CopyTemplateBoxFilesTree from "./CopyTemplateBoxFilesTree";
import {useQuery} from "react-query";
import {
  getFolderInfo, getFolderInfoKey
} from '../../../utils/boxApi/Queries'
import FolderIcon from "@mui/icons-material/Folder";
import BoxTenzingNode from './BoxTenzingNode'

const FiFoWrapper = ({
                       boxAccess,
                       boxFolderId,
                       parentFolderId
                     }) => {
  const [templateFilesSelected, setTemplateFilesSelected] = useState([]);

  // Fetch folder info
  const folderInfo = useQuery(
    [getFolderInfoKey,
      {
        folderId: boxFolderId,
        accessToken: boxAccess?.data?.attributes?.['access_token']
      }],
    getFolderInfo,
    {
      enabled: !!boxAccess?.data?.attributes?.['access_token']
        && !!boxFolderId
    })

  return (
    <>
      {
        !folderInfo.isLoading
        && folderInfo.isSuccess
        && !!folderInfo.data.data ?
          <>
            <section className="mt-4 flex justify-between items-center">
              <h3 className="Section-h1 mb-2">Select Templates:</h3>
              {
                templateFilesSelected.length > 0
                && parentFolderId != null ?
                  <CopyTemplateBoxFilesTree boxAccess={boxAccess}
                                            parentFolderId={parentFolderId}
                                            templateFilesSelected={templateFilesSelected}
                                            setTemplateFilesSelected={setTemplateFilesSelected}/>
                  : null
              }
            </section>
            <section className="xl:w-[25em]">
              <BoxTenzingNode boxFileId={boxFolderId}
                              boxFileName={folderInfo?.data?.data?.name}
                              treeProps={{
                                content: folderInfo?.data?.data?.name,
                                type: <FolderIcon />
                              }}>
              <FiFoNode boxAccess={boxAccess}
                        boxFolderId={boxFolderId}
                        setTemplateFilesSelected={setTemplateFilesSelected}
                        templateFilesSelected={templateFilesSelected}/>
              </BoxTenzingNode>
            </section>
          </>
          : null
      }

    </>
  )
}

export default FiFoWrapper;
