import React, {useContext, useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import Section from 'Components/Common/Section'
import List from 'Components/Projects/IndexView/List'
import
  ContentTwoColumn, {WideColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import {allProjects} from "../../utils/queryKeys";
import {getProjects} from "../../utils/queries";
import {ProjectContext} from "../../context/ProjectContext";
import {useHistory} from "react-router-dom";

function Index({status}) {
  const {selectedProject, setSelectedProject} = useContext(ProjectContext);

  const navigate = useHistory();
  const queryClient = useQueryClient();

  const [resetQuery, setResetQuery] = useState(false)
  const queryKey = status != undefined ?
    [allProjects, {status: status}] : [allProjects]

  // Get Contacts
  const projects = useQuery(
    queryKey,
    getProjects,
    {
      cacheTime: resetQuery ? 0 : 300000,
      staleTime: resetQuery ? 0 : 300000
    })

  useEffect(() => {
    if (resetQuery) {
      queryClient.refetchQueries([allProjects]);
      queryClient.refetchQueries([allProjects, {status: 'archived'}]);
      setResetQuery(false)
      setSelectedProject({...selectedProject, resetQuery: true})
    }
  }, [resetQuery])

  // Show a spinner while loading
  if (projects.isLoading) {
    return (<span className="block lds-dual-ring"></span>)
  }

  return (
    <ContentTwoColumn>
      <WideColumn>
        <Section>
          <div className="flex justify-between mb-2">
            <h1 className="Section-h1">Projects</h1>
            <div data-html2canvas-ignore className="flex">
              {
                projects.data?.permissions?.['create']
                || projects.data?.permissions?.['superuser']
                || projects.data?.permissions?.['project_manager'] ?
                  <button className="orange-transparent-button"
                          onClick={() => navigate.push("/projects/new")}>
                    <h3 className="flex items-center">Create New Project</h3>
                  </button> : null
              }
            </div>
          </div>
          {
            projects.data?.data?.length > 0 ?
              <List items={projects?.data?.data}
                    permissions={projects?.permissions}
                    setResetQuery={setResetQuery}/>
              : <p>No Projects Available</p>
          }
        </Section>
      </WideColumn>
    </ContentTwoColumn>
  )
}

export default Index
