import {googleGmailClient} from "../Api";
import {b64Encode, constructEmailBody} from "../../../Components/Correspondence/Utils/Index";

// GET mail list
// Response template:
// {
//   "messages": [
//     {
//       object (Message)
//     }
//   ],
//   "nextPageToken": string,
//   "resultSizeEstimate": integer
// }
// NOTE: List of messages. Note that each message resource contains only an id and a threadId.
//       Additional message details can be fetched using the messages.get method.
export const gmailGetListKey = ":who/messages"
export const gmailGetList = async ({queryKey}) => {
  const [_, params] = queryKey

  googleGmailClient.defaults.headers['Authorization'] = `Bearer ${params.access_token}`;
  googleGmailClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.messages
  }];

  let query_params = "?"
  if (params.queue) {
    query_params += "q=" + params.queue
  }
  if (!!params.labelIds) {
    const filteredLabelIds = params.labelIds.filter((lbl) => lbl);
    if (filteredLabelIds.length > 0) {
      const labelParams = 'labelIds=' + filteredLabelIds.join('&labelIds=')
      if (query_params.length > 1) {
        query_params += '&' + labelParams
      } else {
        query_params += labelParams
      }
    }
  }
  const extra_params = (query_params != "?" ? query_params : "")

  const response = await googleGmailClient.get(params.userId + "/messages" + extra_params)
  return response.data
}

// Get Email details by id
export const gmailGetEmailKey = ":who/messages/:id"
export const gmailGetMailById = async ({queryKey}) => {
  const [_, params] = queryKey

  googleGmailClient.defaults.headers['Authorization'] = `Bearer ${params.access_token}`;
  googleGmailClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }];

  const queryParamList = [
    "format=" + params.format
  ]

  const queryParams = "?" + queryParamList.join('&')
  const response = await googleGmailClient.get(params.userId + "/messages/" + params.mailId + queryParams)
  return response.data
}

// Get Attachments of single Email
export const gmailGetAttachmentKey = ":who/messages/:id/attachments/:id"
export const gmailGetAttachment = async ({queryKey}) => {
  const [_, params] = queryKey

  googleGmailClient.defaults.headers['Authorization'] = `Bearer ${params.access_token}`;
  googleGmailClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }];

  const url = params.userId + "/messages/" + params.mailId + "/attachments/" + params.attachmentId
  const response = await googleGmailClient.get(url)
  return response.data
}

// Create Label
// Request body example
// {
//   "labelListVisibility": "labelShow",
//   "messageListVisibility": "show",
//   "name": "ioigoume_label",
//   "color": {
//     "backgroundColor": "",
//     "textColor": ""
//   }
// }
export const gmailLabelKey = ":userId/labels"
export const gmailCreateLabel = async (queryKey) => {
  const [_, params] = queryKey

  googleGmailClient.defaults.headers['Authorization'] = `Bearer ${params.access_token}`;
  googleGmailClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.error ? responseData.error : responseData
  }];

  let user = 'me'
  if (params.user != undefined
    && params.user !== '') {
    user = params.user
  }

  return await googleGmailClient.post(`/${user}/labels`,
    params.data
  )
}

// Get the list of gmail labels
export const gmailLabelList = async (queryKey) => {
  const [_, params] = queryKey

  googleGmailClient.defaults.headers['Authorization'] = `Bearer ${params.access_token}`;
  googleGmailClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.error ? responseData.error : responseData
  }];

  let user = 'me'
  if (params.user != undefined
    && params.user !== '') {
    user = params.user
  }

  return await googleGmailClient.get(`/${user}/labels`)
}

export const gmailLabelByIdKey = ":userId/labels/:id"
export const gmailLabelById = async (queryKey) => {
  const [_, params] = queryKey

  googleGmailClient.defaults.headers['Authorization'] = `Bearer ${params.access_token}`;
  googleGmailClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.error ? responseData.error : responseData
  }];

  let user = 'me'
  if (params.user != undefined
    && params.user !== '') {
    user = params.user
  }

  return await googleGmailClient.get(`/${user}/labels/${params.labelId}`)
}


// {
//   "addLabelIds": [
//   string
// ],
//   "removeLabelIds": [
//   string
// ]
// }
export const gmailModifyMessageKey = ":userId/messages/:messageId/modify"
export const gmailModifyMessage = async (queryKey) => {
  const [_, params] = queryKey

  googleGmailClient.defaults.headers['Authorization'] = `Bearer ${params.access_token}`;
  googleGmailClient.defaults.transformResponse = [(data) => {
    const responseData = JSON.parse(data)
    return responseData.error ? responseData.error : responseData
  }];

  let user = 'me'
  if (params.user != undefined
    && params.user !== '') {
    user = params.user
  }

  return await googleGmailClient.post(`/${user}/messages/${params.messageId}/modify`, params.data)
}

// Send an Email
export const sendEmailNotifications = async (data) => {
  const {emailAddress, cc, subject, message} = data
  const fromEmail = window.gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail()

  const mailData = {
    from: fromEmail,
    to: emailAddress,
    cc: cc,
    subject: subject,
    message: message
  }

  const rawdata = constructEmailBody(mailData)
  const encodedMessage = b64Encode(rawdata)
  return await gapi.client.gmail.users.messages.send({
    userId: 'me',
    resource: {
      raw: encodedMessage
    }
  })
}
