import React, {useState} from 'react';
import {useMutation} from "react-query";
import {deepCopyPhase} from "../../../utils/queries";
import {toast} from "react-toastify";

const DuplicatePhase = ({
                          phaseId,
                          phaseQuery,
                          onModulesConfigUpdate,
                          setPhaseCopied,
                          locked
                        }) => {
  const handleClick = (event) => {
    const tdata = {
      order: phaseQuery.data?.data?.attributes?.order,
      name: phaseQuery.data?.data?.attributes?.phase_pool?.name
    }

    clonePhase([deepCopyPhase, {phaseId: parseInt(phaseId), data: tdata}])
  }

  const notifySuccess = () => toast.success("Duplication completed!");
  const notifyError = () => toast.error("Duplication failed.")

  // Update Task
  const {isLoading: duplicatePhaseLoading, mutateAsync: clonePhase} = useMutation(deepCopyPhase, {
    onSuccess: (data, variables, context) => {
      // I need to reload the whole grid
      setPhaseCopied(true)
      notifySuccess()
    },
    onError: (error, variables, context) => {
      console.log('Failed to deep copy Phase.', error)
      notifyError()
    }
  });

  if (phaseQuery.isLoading
    || duplicatePhaseLoading) {
    onModulesConfigUpdate(false)
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <>
      {
        phaseQuery.data?.permissions?.['update']
        && !locked
        && !phaseQuery.isLoading
        && phaseQuery.isSuccess
        && phaseQuery.data?.data?.attributes?.task == null
        && phaseQuery.data?.data?.attributes?.process_modules?.length > 0 ?
          <div className="bg-tenzingOrange
                          flex
                          text-white
                          cursor-pointer
                          text-center
                          justify-center
                          font-bold"
               data-phasename={phaseQuery.data?.data?.attributes?.phase_pool?.name}
               onClick={handleClick}>
            CLONE
          </div>
          : null
      }
    </>
  )
}

export default DuplicatePhase;
