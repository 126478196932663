import moment from "moment";


export const formatDate = (date) => {
  if (date == undefined || date === '') {
    return ''
  }
  return moment(date).toDate()
}

export const formatAmount = (amount) => {
  return `$${parseFloat(amount || 0).toLocaleString('en-US')}`
}

export const defaultValues = {
  line_item: '',
  contract_breakdown: "",
  invoice_date: null,
  amount: "",
  number: "",
  recommended_status_date: null,
  approval_status: ""
}

export const headCells = [
  {
    id: 'category',
    numeric: false,
    disablePadding: true,
    label: 'Category',
  },
  {
    id: 'subCategory',
    numeric: false,
    disablePadding: true,
    label: 'Sub Category',
  },
  {
    id: 'categoryBreakdown',
    numeric: false,
    disablePadding: false,
    label: 'Category Breakdown',
  },
  {
    id: 'line_item',
    numeric: true,
    disablePadding: true,
    label: 'Line Item'
  },
  {
    id: 'contract_breakdown',
    numeric: false,
    disablePadding: false,
    label: 'Contract Breakdown',
  },
  {
    id: 'number',
    numeric: true,
    disablePadding: true,
    label: 'Invoice #',
  },
  {
    id: 'approval_status',
    numeric: false,
    disablePadding: false,
    label: 'Approval Status',
  },
  {
    id: 'invoice_date',
    numeric: false,
    disablePadding: false,
    label: 'Invoice Date',
  },
  {
    id: 'recommended_approval_date',
    numeric: false,
    date: true,
    disablePadding: false,
    label: 'Recommended Approval Date',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
  },
];

export const formFields = [
  'amount_cents',
  'number',
  'approval_status',
  'recommended_status_date',
  'project',
  'contract_breakdown',
  'invoice_date'
]
