import React from 'react'
import { Link } from 'react-router-dom'

const BadgeSquare = ({ children, taskCount, link="#" }) => {

  return (
    <Link to={link} className="p-3 bg-darkGray h-40 col-span-2">
      <h2 className="p-1 text-white text-center border border-transparent border-b-tenzingGray">{children}</h2>
      <h3 className="pt-3 text-center text-6xl text-tenzingOrange">
        {taskCount}
      </h3>
    </Link>
  )
}

export default BadgeSquare;
