import React, {useState, useEffect} from 'react'
import {client as apiClient} from 'utils/api';
import {useQuery} from 'react-query'
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import Button from 'Components/Common/Button';
import {parseISO} from 'date-fns';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {
  Field
} from 'Components/Common/Forms';
import {
  allUsers,
  contact as contactQuery,
  user as userQuery
} from "../../../utils/queryKeys";
import {getUser} from "../../../utils/queries";
import {fields} from '../Utils/index'
import CircularProgress from "@mui/material/CircularProgress";

function EditUserForm({
                        userId,
                        contactId,
                        setShowEditContact,
                        setShowEditUser
                      }) {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({})
  const {register, handleSubmit, setValue, setError, reset, formState: {errors}} = useForm();

  const notifySuccess = () => toast.success("User updated!");
  const notifyError = () => toast.error("User update failed.")

  const user = useQuery(
    [userQuery, {userId: userId}],
    getUser,
    {
      enabled: userId != undefined,
      cacheTime: 0,
      staleTime: 0
    }
  )

  useEffect(() => {
    if (user.data?.data?.attributes?.id > 0) {
      setFormData(user.data?.data?.attributes)
      // Update the form values
      fields.forEach((fd) => {
        if (fd === "due_date") {
          setValue(fd, parseISO(user.data?.data?.attributes[fd]))
        } else if (["contact", "user_role", "company"].includes(fd)) {
          setValue(fd + '_id', user.data?.data?.attributes[fd]?.id)
        } else {
          setValue(fd, user.data?.data?.attributes[fd])
        }
      })
    }
  }, [!user.isLoading && user.isSuccess])

  // Update all fields
  const handleFormChange = (e) => {
    setFormData({
      ...formData, [e.target.id]: e.target.value
    })
    // Set the value
    setValue(e.target.id, e.target.value)
  }

  async function patchForm(data) {
    // Unpack values to variable
    const {email, status, password} = data
    // Make the request and wait for the response
    if (password !== undefined && password.trim() !== '') {
      return await apiClient.put("/users/" + userId, {
        "user": {
          status: status.trim(),
          email: email.trim(),
          password: password.trim()
        }
      })
    } else {
      return await apiClient.put("/users/" + userId, {
        "user": {
          status: status.trim(),
          email: email.trim()
        }
      })
    }
  }

  const {isLoading: patchUserLoading, mutateAsync: sendData} = useMutation(patchForm);

  const onSubmit = async (data, e) => {
    try {
      await sendData(data)
      queryClient.refetchQueries([userQuery, {userId: userId}])
      queryClient.refetchQueries([contactQuery, {id: contactId}])
      queryClient.refetchQueries([allUsers, {contactId: contactId}])
      setShowEditContact(true)
      setShowEditUser(false)
      notifySuccess()
    } catch (err) {
      notifyError()
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  // Loading
  if (user.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  // Dataset failed to load or empty
  if (!user.isSuccess || user.data == undefined) {
    return null
  }

  return (
    <form method="put" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

      <Field>
        <label htmlFor="email" className="text-tenzingGray">Email Address</label>
        <input
          id="email"
          type="text"
          placeholder="email"
          {...register("email", {
            required: true,
            pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Invalid email format."}
          })}
          value={formData.email ?? ""}
          onChange={handleFormChange}
          className={!errors.email ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.email}></FieldInputError>
      </Field>

      <Field>
        <label htmlFor="password" className="text-tenzingGray">Password</label>
        <input
          id="password"
          type="password"
          placeholder="password"
          {...register("password", {
            required: false,
            pattern: {value: /^.{6,}$/, message: "Password should be at least 6 characters long."}
          })}
          value={formData.password ?? ""}
          onChange={handleFormChange}
          className={!errors.password ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.password}></FieldInputError>
      </Field>

      <Field>
        <label htmlFor="status" className="text-tenzingGray">Status</label>
        <select
          id="status"
          name="status"
          {...register("status", {required: true})}
          className={!errors.status ? "input-text" : 'input-text-error'}
          value={formData.status ?? ""}
          onChange={handleFormChange}
        >
          <option value="active">Active</option>
          <option value="removed">Removed</option>
        </select>
        <FieldInputError item={errors.status}></FieldInputError>
      </Field>

      <div className="flex items-center justify-end p-4">
        <button
          className="underline text-gray-400 py-0.5 px-4 m-1"
          onClick={() => {
            setShowEditContact(true)
            setShowEditUser(false)
          }}
        >Cancel
        </button>
        <Button
          buttonStyle={(patchUserLoading || user.isLoading) ? "gray-solid-button" : "orange-solid-button"}
          disabled={(patchUserLoading || user.isLoading)}>
          {
            (patchUserLoading || user.isLoading) ?
              <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
              : <></>
          }
          Update
        </Button>
      </div>
    </form>
  )
}

export default EditUserForm
