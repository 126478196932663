import React, {useState, useEffect, useRef, useContext} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  gmailGetEmailKey,
  gmailGetListKey,
  gmailGetMailById, gmailModifyMessage
} from "../../../utils/google/Queries";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Collapse from '@mui/material/Collapse';
import DownloadBtn from "./DownloadBtn";
import ReadMail from "./ReadMail";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {CorrespondenceContext} from "../../../context/CorrespondenceContext";
import {formatDate, formatDateWithTime} from 'utils/globals'
import {
  Avatar,
  parseEmailList
} from '../Utils/Index'
import BadgeLabel from "./BadgeLabel";
import EmailHeaderBox from "./EmailHeaderBox"
import {calcIcon} from "../../NavBar/Resources";

const InboxLine = ({
                     mailListItem,
                     gapi,
                     tabType,
                     setShowForm,
                     setShowSendEmail,
                     setSelectedMessages,
                     selectedMessages,
                     setUpdateEmailList,
                     updateEmailList
                   }) => {
  const [_, setCorrespondenceContext] = useContext(CorrespondenceContext);
  const [dataLoaded, setDataLoaded] = useState(false)
  const [isUnRead, setIsUnRead] = useState(false)
  const [collapseToggle, setCollapseToggle] = useState(false)
  const subject = useRef(null)
  const from = useRef(null)
  const cc = useRef(null)
  const when = useRef(null)
  const to = useRef(null)
  const threadId = useRef(mailListItem.threadId)
  const messageID = useRef(null)
  const bodyID = useRef(null)
  // Attachment list will be a list of key:value pairs
  // key: attachment id
  // value: attachment name
  const attachments = useRef([])
  const bodyText = useRef([])
  const bodyRaw = useRef([])
  const queryClient = useQueryClient();

  const emailBody = useQuery([gmailGetEmailKey, {
      mailId: mailListItem.id,
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      format: 'full'
    }],
    gmailGetMailById,
    {
      cacheTime: 0, // NOTE: We need to implement pagination
      staleTime: 0,
      enabled: (!!mailListItem && gapi.auth !== undefined && gapi.auth.getToken() !== undefined)
    }
  )

  const emailRawBody = useQuery([gmailGetEmailKey, {
      mailId: mailListItem.id,
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      format: 'raw'
    }],
    gmailGetMailById,
    {
      cacheTime: 0, // NOTE: We need to implement pagination
      staleTime: 0
    }
  )

  useEffect(() => {
    queryClient.refetchQueries([gmailGetListKey, {
      mailId: mailListItem.id,
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      format: 'full'
    }]);
    queryClient.refetchQueries([gmailGetEmailKey, {
      mailId: mailListItem.id,
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      format: 'raw'
    }]);
  }, [updateEmailList])

  useEffect(() => {
    if (emailRawBody.isSuccess && !!emailRawBody.data && emailRawBody.data?.raw) {
      bodyRaw.current = emailRawBody.data.raw
    }
  }, [emailRawBody.isLoading, !!emailRawBody.data])

  useEffect(() => {
    // Get basic information
    if (!!emailBody.data && emailBody.data?.payload?.headers?.length > 0) {
      const headers = emailBody.data.payload.headers
      headers.forEach((item) => {
        if (item.name === "Subject") {
          subject.current = item.value
        } else if (item.name === "From") {
          from.current = item.value
        } else if (item.name === "Date") {
          when.current = item.value
        } else if (item.name === "Message-ID") {
          messageID.current = item.value
        } else if (item.name === "Cc") {
          cc.current = item.value
        } else if (item.name === "To") {
          to.current = item.value
        }
      })
    }

    if (!!emailBody.data
      && !!emailBody.data) {
      bodyID.current = emailBody.data.id
      if (emailBody.data.labelIds?.length > 0) {
        setIsUnRead(emailBody.data.labelIds.includes('UNREAD'))
      }
    }

    if (!!emailBody.data && !!emailBody.data?.payload) {
      // Get Attachments information
      if (emailBody.data.payload?.parts?.length > 0) {
        const parts = emailBody.data.payload.parts
        attachments.current = []
        parts.forEach((part) => {
          if (part.body?.attachmentId != undefined) {
            attachments.current.push({
              id: part.body.attachmentId,
              name: part.filename,
              mime: part.mimeType
            })
          } else {
            if (part.mimeType === "multipart/alternative") {
              bodyText.current = part.parts
            } else {
              // Currently i will only handle the plain Text MIME type
              bodyText.current = [...bodyText.current, part]
            }
          }
        })
      } else if (emailBody.data?.payload?.parts == undefined) {
        console.log('emailBody', emailBody.data)
        // This is an email send with only one Mime type, either text/plain or text/html
        bodyText.current = [emailBody.data.payload]
      }
      setDataLoaded(true)
    }
  }, [emailBody.isLoading, !!emailBody.data])

  useEffect(() => {
    if (isUnRead && collapseToggle) {
      const tdata = {
        "removeLabelIds": [
          "UNREAD"
        ]
      }
      modifyGmailMessage([modifyGmailMessage, {
        messageId: bodyID.current,
        data: tdata,
        access_token: gapi?.auth?.getToken()?.access_token
      }])
    }
  }, [collapseToggle])

  // Reply action applies only to inbox emails
  const replyAction = () => {
    setShowForm(false)
    setShowSendEmail(true)
    setCorrespondenceContext({
      subject: subject.current,
      from: from.current,
      cc: null,
      messageID: messageID.current,
      messageRawForward: null,
      attachmentsForward: null,
      reply: true,
      forward: false,
      reply_all: false
    })
  }

  const replyAllAction = () => {
    setShowForm(false)
    setShowSendEmail(true)
    setCorrespondenceContext({
      subject: subject.current,
      from: from.current,
      cc: cc.current,
      messageID: messageID.current,
      messageRawForward: null,
      attachmentsForward: null,
      reply: true,
      forward: false,
      reply_all: true
    })
  }

  const forwardAction = () => {
    setShowForm(false)
    setShowSendEmail(true)
    setCorrespondenceContext({
      subject: "FWD:" + subject.current,
      from: null,
      cc: null,
      messageID: null,
      messageRawForward: bodyRaw.current,
      attachmentsForward: null,
      reply: false,
      forward: true,
      reply_all: null
    })
  }

  // Google Modify Message
  const {mutateAsync: modifyGmailMessage} = useMutation(gmailModifyMessage, {
    onSuccess: (data, variables, context) => {
      console.log('Updated message tag', data)
      setIsUnRead(false)
      queryClient.invalidateQueries([gmailGetListKey, {
        mailId: mailListItem.id,
        userId: "me",
        format: 'full'
      }]);
    },
    onError: (error, variables, context) => {
      console.log('Gmail Label remove failed.', error)
    }
  });


  const icon = {
    closed: 'ArrowDropDown',
    opened: 'ArrowDropUp'
  }

  // On check add the id to the table, on uncheck remove it
  const handleCheckedChange = (event) => {
    if (event.target.checked) {
      setSelectedMessages([...selectedMessages, bodyID.current])
    } else {
      setSelectedMessages(selectedMessages.filter(msg => msg !== bodyID.current))
    }
  }

  if (!dataLoaded) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <>
      <li key={mailListItem.id}
          className="inline-flex w-full border-b">
        {
          Array.isArray(selectedMessages) ?
            <input
              id={`checkbox-${mailListItem.id}`}
              type="checkbox"
              checked={selectedMessages?.includes(bodyID.current)}
              className="mt-2 mr-2 w-4 h-4 text-orange-500 bg-gray-100 border-gray-300 rounded focus:ring-orange-500 focus:ring-0"
              onChange={handleCheckedChange}
            />
            : null
        }
        <div
          className={`inline-flex ${(!collapseToggle && isUnRead) ? "bg-tenzingGray text-white" : ""} ${collapseToggle ? "bg-gray-100" : ""} w-full border-l-2 p-3 space-y-4 border-transparent hover:text-gray-800 hover:bg-gray-100`}
          onClick={() => setCollapseToggle(!collapseToggle)}
        >
          {/* Attachment */}
          <div className="flex justify-start items-center w-10">
            {calcIcon(icon, collapseToggle)}
          </div>
          <div className="w-full">
            {/* From and Date */}
            <div className="flex flex-row items-center space-x-2">
              <Avatar/>
              <strong className="flex-grow text-sm">{from.current}
                <BadgeLabel gapi={gapi} emailBody={emailBody}/>
              </strong>
              <div className="flex">
                <div className={Object.keys(attachments.current).length > 0 ? '' : 'hidden'}>
                  <AttachFileIcon/>
                </div>
                <div className="text-sm w-20 ml-4">
                  {formatDateWithTime(when.current)}
                </div>
              </div>
            </div>

            {/* Subject*/}
            <div className="flex flex-row items-center space-x-1">
              <div className="flex-grow truncate text-xs">{subject.current}</div>
            </div>
          </div>
        </div>
      </li>
      {
        <Collapse in={collapseToggle}>
          {
            tabType === 'inbox' ?
              <div className="flex justify-center items-center font-light">
                <Tooltip component={'span'} title="Reply">
                  <IconButton onClick={replyAction} size="small" className="mr-2" aria-label="reply">
                    <ReplyIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip component={'span'} title="Reply All">
                  <IconButton onClick={replyAllAction} size="small" className="mr-2" aria-label="replyall">
                    <ReplyAllIcon/>
                  </IconButton>
                </Tooltip>
              </div>
              : null
          }
          <EmailHeaderBox from={from.current}
                          to={to.current}
                          cc={cc.current}
                          subject={subject.current}
                          when={when.current}/>
          <div className="p-5 font-light border border-b-0 border-gray-200 text-gray-800">
            <ReadMail mimetypes={bodyText.current}/>
            {
              dataLoaded
              && Object.keys(attachments.current).length > 0 ?
                <div className="mb-4 w-1/12 border border-b-1 border-gray-200 text-gray-800"></div> : null
            }
            {
              dataLoaded
              && Object.keys(attachments.current).length > 0
              && attachments.current.map((file) => {
                return (
                  <DownloadBtn key={file.id + "download_attachment"}
                               mailItem={mailListItem}
                               file={file}
                  />
                )
              })
            }
          </div>
        </Collapse>
      }
    </>
  )
}

export default InboxLine;
