import React, {useEffect, useState, useRef} from 'react';
import {useParams, Link, useHistory} from "react-router-dom";
import {useQuery, useQueryClient} from 'react-query';
import Section from 'Components/Common/Section';
import ProcessMapOverview from 'Components/Process';
import Schedule from 'Components/Schedule';
import TaskOverview from 'Components/Tasks';
import RiskOverview from 'Components/Risks';
import BoxFileExplorer from 'Components/FileExplorer';
import {
  getBoxAccessToken,
  getCompanyStats,
  getProject
} from "../../utils/queries";
import {
  boxAccessToken,
  companyStats,
  project as projectKey
} from "../../utils/queryKeys";
import CostSummary from 'Components/Projects/ListItem/CostSummary';
import ScheduleSummary from 'Components/Projects/ListItem/ScheduleSummary';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GoogleCalendar from "Components/Calendar/FullCal";
import ContentTwoColumn, {NarrowColumn, WideColumn} from "Components/Common/Layout/ContentTwoColumn"
import TaskForm from 'Components/Tasks/Form';
import RiskForm from 'Components/Risks/Form';
import CreateProjectGLabel from './Resources/CreateProjectGLabel'
import {formatDate, removeClassByPrefix} from 'utils/globals'
import {gapi} from "gapi-script";
import {DiscoveryDocs, SCOPES, REACT_APP_CLIENT_ID} from "../../utils/google/Auth";
import Badge from '@mui/material/Badge';
import QuickNote from "Components/QuickNotes"
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import moment from 'moment'


function Index() {
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [isLabelCreated, setIsLabelCreated] = useState(false);
  const [createCalendar, setCreateCalendar] = useState(false);
  const [showForm, setShowForm] = useState(null)
  const [riskPersmissions, setRiskPersmissions] = useState(null)
  const [tasksPersmissions, setTasksPersmissions] = useState(null)
  const [activitiesPermissions, setActivitiesPermissions] = useState(null)
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const printRef = useRef();

  const navigate = useHistory();
  const queryClient = useQueryClient();

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  // Get the project stats
  const companyStatsQuery = useQuery(
    [companyStats, {companyId: companyId}],
    getCompanyStats,
    {
      retry: false,
      retryOnMount: false
    }
  );

  const {projectId} = useParams()
  const project = useQuery(
    [projectKey, {id: projectId}],
    getProject
  )

  const boxAccess = useQuery(
    [boxAccessToken],
    getBoxAccessToken)


  // Fetch the project after the label is created
  if (isLabelCreated) {
    queryClient.refetchQueries([projectKey, {id: projectId}]);
    setIsLabelCreated(false)
  }

  // Update the local storage with the fresh project data we just fetched
  if (!project.isLoading
    && project.isSuccess
    && !!project.data?.data
    && project.data?.data?.attributes != undefined) {
    let project_value = JSON.parse(window.localStorage.getItem("projectValue"));
    // Add the entire project object in the localstorage
    window.localStorage.removeItem("projectValue");
    project_value['project'] = project.data.data
    window.localStorage.setItem("projectValue", JSON.stringify(project_value));
  }

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    var HTML_Width = printRef.current.offsetWidth;
    var HTML_Height = printRef.current.offsetHeight;

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // https://github.com/niklasvh/html2canvas/issues/1856
    const canvas = await html2canvas(printRef.current, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      imageTimeout: 5000,
      onclone: (clonedElement) => {
        clonedElement.querySelectorAll('[class*="shadow"]').forEach((elem) => {
          removeClassByPrefix(elem, 'shadow')
        });
      }
    });
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

    pdf.addImage(data, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


    for (var i = 1; i <= totalPDFPages; i++) {
      pdf.addPage(PDF_Width.toString(), PDF_Height.toString());
      pdf.addImage(data, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    }

    pdf.save(`${project.data?.data?.attributes?.["name"]}-${moment().format('l')}.pdf`);
  };

  const bexpConfig = {
    boxable: project.data,
    access_token: boxAccess
  }

  if (project.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <ContentTwoColumn>
      <WideColumn orderFirst={true}>
        <div ref={printRef}>
          <Section>
            {
              <>
                <div className="flex items-start align-baseline">
                  {
                    project.data?.permissions?.['update']
                    && (project.data.data.attributes["gmail_labelid"] == undefined
                      || project.data.data.attributes["gmail_labelid"].trim() == '') ?
                      <Badge badgeContent={<CreateProjectGLabel project={project}
                                                                gapi={gapi}
                                                                setIsLabelCreated={setIsLabelCreated}/>}>
                        <h1 className="Section-h1 mr-2 mb-2">{project.data?.data?.attributes?.["name"]}</h1>
                      </Badge>
                      : <h1 className="Section-h1 mr-2 mb-2">{project.data?.data?.attributes?.["name"]}</h1>
                  }
                  {
                    project.data.data.attributes["completed"] ?
                      <h2 className="ml-3 trueGreen-rounded-badge py-2 text-sm">Completed: <span
                        className="pl-1">{formatDate(project.data?.data?.attributes?.["activity_ends_at"])}</span>
                      </h2>
                      :
                      <h2 className="ml-3 gray-transparent-rounded-badge py-2 text-sm">Completion Date: <span
                        className="pl-1">{formatDate(project.data?.data?.attributes?.["activity_ends_at"])}</span>
                      </h2>
                  }
                  <h2 className="gray-transparent-rounded-badge py-2 text-sm">Authorized Budget: <span
                    className="pl-1 font-bold">{`$${parseFloat(project.data?.data?.attributes?.["approved_budget"]).toLocaleString('en-US')}`}</span>
                  </h2>
                  <button data-html2canvas-ignore type="button"
                          className="orange-solid-button border border-tenzingOrange" onClick={handleDownloadPdf}>
                    Export as PDF
                  </button>
                  <button className="orange-transparent-button float-right"
                          onClick={() => navigate.push(`/companies/${companyId}/users?projectid=${projectId}`)}>
                    <h3 className="flex items-center">Add Members</h3>
                  </button>
                </div>
                <div className="flex flex-row p-2 border border-outlineGray">
                  {
                    project.data?.permissions?.['update']
                    && (project.data.data.attributes["cloudinary_url"] == undefined
                      || project.data.data.attributes["cloudinary_url"].trim() == '') ?
                      <div className="bg-blue-400 flex flex-row justify-center items-center w-1/4">
                        <Link to={`/projects/${projectId}/image`}>
                          <UploadFileIcon/>Image
                        </Link>
                      </div> :
                      <div className="w-1/4 aspect-w-4 aspect-h-1"><img className="object-contain"
                                                                        src={project.data?.data?.attributes?.["cloudinary_url"]}/>
                      </div>
                  }
                  <div className="p-6">
                    <h2 className="uppercase font-semibold">
                      Description - (id: {project.data?.data?.attributes?.["identifier"]} )
                    </h2>
                    <p>{project.data?.data?.attributes?.["description"]}</p>
                    <h2 className="uppercase backgroundGray font-semibold">Location</h2>
                    <h2 className="backgroundGray">{project.data?.data?.attributes?.["location"]}</h2>
                    <div  className="flex flex-col mt-2">
                      <Link
                        underline="hover"
                        className="hover:cursor-pointer underline"
                        to={`/contacts/${project.data?.data?.attributes?.manager?.['contact_id']}`}>Manager: {project.data?.data?.attributes?.manager?.['email']}</Link>
                      <Link
                        underline="hover"
                        className="hover:cursor-pointer"
                        to={`/contacts/${project.data?.data?.attributes?.approver?.['contact_id']}`}>Approver: {project.data?.data?.attributes?.approver?.['email']}</Link>
                    </div>
                    <div className="pt-5 grid grid-cols-2 gap-x-10">
                      <ScheduleSummary projectID={projectId}/>
                      <CostSummary project={project.data?.data}/>
                    </div>
                  </div>
                </div>
              </>
            }
          </Section>
          <Section>
            <Schedule title="Project Schedule"
                      view="project_home"
                      setActivitiesPermissions={setActivitiesPermissions}
                      activitiesPermissions={activitiesPermissions}/>
          </Section>

          <ProcessMapOverview/>

          <Section>
            <div className="mb-4 flex justify-between">
              <h1 className="Section-h1">Project Tasks</h1>
              {
                tasksPersmissions?.['create'] ?
                  <button data-html2canvas-ignore className='orange-solid-button'
                          onClick={() => setShowForm("task")}>Add New
                  </button>
                  : null
              }
            </div>
            <TaskOverview setTasksPersmissions={setTasksPersmissions}
                          columnsToHide={["buttons"]}/>
          </Section>

          <Section>
            <div className="mb-4 flex justify-between">
              <h1 className="Section-h1">Project Risks</h1>
              {
                riskPersmissions?.['create'] ?
                  <button data-html2canvas-ignore className='orange-solid-button'
                          onClick={() => setShowForm("risk")}>Add New
                  </button>
                  : null
              }
            </div>
            <RiskOverview setRiskPersmissions={setRiskPersmissions}
                          columnsToHide={["buttons"]}/>
          </Section>

          <Section height="h-screen">
            <div className="h-5/6">
              <h1 className="Section-h1">Project Files</h1>
              {
                !project.isLoading
                && project.isSuccess
                && !!project.data?.data ?
                  <BoxFileExplorer props={bexpConfig}/> : null
              }
            </div>
          </Section>

        </div>
      </WideColumn>
      <NarrowColumn>
        <GoogleCalendar
          isRefreshed={isRefreshed}
          setIsRefreshed={setIsRefreshed}
          createCalendar={createCalendar}
          setCreateCalendar={setCreateCalendar}
          showDay={true}
        />
        {
          showForm != undefined && showForm === "task" ?
            <TaskForm gapi={gapi}
                      project={project}
                      setSelectedProjectId={setSelectedProjectId}
                      selectedProjectId={selectedProjectId}/> : null
        }
        {
          showForm != undefined && showForm === "risk" ?
            <RiskForm/> : null
        }
        <QuickNote projectId={project.data?.data?.id}/>

      </NarrowColumn>
    </ContentTwoColumn>
  )
}

export default Index


