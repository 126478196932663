import React, {useEffect, useState, useRef} from 'react'
import {Link, useLocation} from 'react-router-dom'
import
{
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import MailIcon from '@mui/icons-material/Mail';
import {formatDate, statusColor} from "../../../utils/globals"
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import {
  getComparator,
  stableSort
} from '../Utils/index'
import CreateGmailLabel from "../../Common/Google/CreateGmailLabel";
import {DiscoveryDocs, REACT_APP_CLIENT_ID, SCOPES} from "../../../utils/google/Auth";
import AddFileLink from "../../../Components/BoxDropZone/AddFileLink"
import {gapi} from "gapi-script";
import {
  googleMailToken
} from "../../../utils/queryKeys";
import {useQuery} from "react-query";
import {
  getGoogleMailAccessToken
} from "../../../utils/queries";

function List({
                items,
                projectName,
                setShowSendEmail,
                setShowForm,
                setObjectLabelId,
                setObjectLabelName,
                setDropZoneVisibility,
                setDropZoneObject,
                setSelectedLabels,
                columnsToHide
              }) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [isAuthorized, setIsAuthorized] = useState(false)


  const location = useLocation()
  const pathName = useRef(location.pathname)

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSendEmailClick = (event) => {
    setSelectedLabels([
      event.currentTarget.dataset.labelid,
      event.currentTarget.dataset.projectglabelid
    ])
    setShowSendEmail(true)
    setShowForm(false)
    setDropZoneVisibility(false)
    setObjectLabelId(event.currentTarget.dataset.labelid)
    setObjectLabelName(event.currentTarget.name)
  }


  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if (googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  return (
    <React.Fragment>
      {items?.length < 0 ? <div>No Tasks to display</div> :
        <Box sx={{width: '100%', borderColor: 'grey'}}>
          <Paper sx={{width: '100%', mb: 2}}>
            <TableContainer>
              <Table stickyHeader>
                <EnhancedTableHead
                  projectName={projectName}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columnsToHide={columnsToHide}
                />
                <TableBody>
                  {stableSort(items, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((task) => {
                      if (task.attributes) {
                        task = task.attributes
                      }
                      return (
                        <TableRow key={task.id}>
                          <TableCell>
                      <span
                        className={`inline-block align-middle w-5 h-5 rounded-full mr-2 ${statusColor(task["status"])}`}></span>
                          </TableCell>
                          {!projectName || projectName === 'undefined' ?
                            null :
                            <TableCell>{task?.["project"].name}</TableCell>
                          }
                          <TableCell>{task.id}</TableCell>
                          <TableCell><Link tasksid={task.id}
                                           to={`/tasks/${task.id}?label=${task['gmail_labelid']}&label=${task?.project?.['gmail_labelid']}`}
                                           className="underline">{task["name"].substring(0, 40)}</Link></TableCell>
                          <TableCell>{task["assignee"] ? task["assignee"]["email"] : ''}</TableCell>
                          <TableCell>{task["due_date"] ? formatDate(task["due_date"]) : ''}</TableCell>
                          <TableCell>{task["critical"] ? 'Yes' : 'No'}{task["impact"] ? " (" + task["impact"] + ")" : ""}</TableCell>
                          {
                            !pathName.current.includes('home') ?
                              <TableCell align='center'>
                                {task['gmail_labelid'] == undefined
                                || task['gmail_labelid'] == '' ?
                                  <CreateGmailLabel object={task}
                                                    objectType="task"
                                                    gapi={gapi}/> :
                                  <>
                                    <AddFileLink object={task}
                                                 setShowSendEmail={setShowSendEmail}
                                                 setShowForm={setShowForm}
                                                 setDropZoneVisibility={setDropZoneVisibility}
                                                 setDropZoneObject={setDropZoneObject}/>
                                    <Link
                                      component="button"
                                      name={task["name"]}
                                      variant="body2"
                                      className={isAuthorized ? '' : 'text-gray-400'}
                                      style={{pointerEvents: isAuthorized ? '' : 'none'}}
                                      data-labelid={task['gmail_labelid']}
                                      data-projectglabelid={task?.['project']?.['gmail_labelid']}
                                      onClick={handleSendEmailClick}
                                    >
                                      <div className="flex flex-col items-center mr-4">
                                        <MailIcon fontSize="small"/>
                                        <u>Send Email</u>
                                      </div>
                                    </Link>
                                  </>
                                }
                              </TableCell>
                              : null
                          }
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<TablePagination*/}
            {/*  rowsPerPageOptions={[5, 10, 25, 50]}*/}
            {/*  component="div"*/}
            {/*  count={items.length}*/}
            {/*  rowsPerPage={rowsPerPage}*/}
            {/*  page={page}*/}
            {/*  onPageChange={handleChangePage}*/}
            {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>*/}
          </Paper>
        </Box>
      }
    </React.Fragment>
  )
}

export default List
