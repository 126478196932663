import {boxClient, boxClientUpload} from "../index"

//curl -i -X POST "https://upload.box.com/api/2.0/files/content" \
//      -H "Authorization: Bearer <ACCESS_TOKEN>" \
//      -H "Content-Type: multipart/form-data" \
//      -F attributes='{"name":"Contract.pdf", "parent":{"id":"11446498"}}' \
//      -F file=@<FILE_NAME>

export const uploadFileKey = "/files/content"
export const uploadFilePost = async (queryKey) => {
  const [_, params] = queryKey

  const formData = new FormData();
  const metadata = {
    name: params.file.name,
    parent: {
      id: params.box_folderid
    }
  }
  formData.append('attributes', JSON.stringify(metadata));
  formData.append('file', params.file);


  boxClientUpload.defaults.headers['Authorization'] = `Bearer ${params.accessToken.access_token}`;
  return await boxClientUpload.post("files/content", formData)
}

export const uploadFileVersionKey = "/files/:id/content"
export const uploadFileVersionPost = async (queryKey) => {
  const [_, params] = queryKey

  const formData = new FormData();
  const metadata = {
    name: params.file.name,
    parent: {
      id: params.box_folderid
    }
  }
  formData.append('attributes', JSON.stringify(metadata));
  formData.append('file', params.file);


  boxClientUpload.defaults.headers['Authorization'] = `Bearer ${params.accessToken.access_token}`;
  return await boxClientUpload.post(`files/${params.fileId}/content`, formData)
}

export const getListOfFolderItemsKey = "/folders/:id/items"
export const getListOfFolderItems = async ({queryKey}) => {
  const [_, params] = queryKey

  boxClient.defaults.headers['Authorization'] = `Bearer ${params.accessToken}`;

  return await boxClient.get(`folders/${params.folderId}/items`)
}

// curl -i -X POST "https://api.box.com/2.0/files/12345/copy" \
//      -H "Authorization: Bearer <ACCESS_TOKEN>" \
//      -H "Content-Type: application/json" \
//      -d '{
//        "parent": {
//          "id": "123"
//        }
//      }'
export const cpFileToParentKey = "/files/:id/copy"
export const cpFileToParent = async (queryKey) => {
  const [_, params] = queryKey

  boxClient.defaults.headers['Authorization'] = `Bearer ${params.accessToken}`;
  const data = {
    parent: {
      id: params.parentId
    }
  }

  return await boxClient.post(`files/${params.fileId}/copy`, data)
}

export const getFolderInfoKey = "/folders/:id/"
export const getFolderInfo = async ({queryKey}) => {
  const [_, params] = queryKey

  boxClient.defaults.headers['Authorization'] = `Bearer ${params.accessToken}`;

  return await boxClient.get(`folders/${params.folderId}`)
}
