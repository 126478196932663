import React, {useEffect} from 'react'
import
{
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow
} from '@mui/material/';
import {useQuery} from 'react-query';
import {
  headCells,
  formatAmount
} from '../Utils/Contracts'
import {getProjectContractBreakdowns} from "../../../utils/queries";
import {projectContractBreakdowns} from "../../../utils/queryKeys";
import ContractsBreakdownRow from "../Components/Tables/contractsBreakdownRow"

function ContractsTable({
                          projectId,
                          setContractObject,
                          setShowForm,
                          setContractBreakdownPermissions
                        }) {
  // Get the contracts
  const contracts = useQuery(
    [projectContractBreakdowns, {projectId: projectId}],
    getProjectContractBreakdowns
  )

  useEffect(() => {
    if (contracts.data?.permissions != undefined
        && contracts.data?.permissions != null) {
      setContractBreakdownPermissions(contracts.data?.permissions)
    }
  }, [!!contracts.data?.permissions])



  return (
    !contracts.isLoading
    && contracts.isSuccess
    && contracts.data?.data != undefined ?
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, idx) => (
                <TableCell
                  key={headCell.id + idx.toString()}
                  align={headCell.id ? 'left' : 'center'}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.data.data.map((contract) => (
              <ContractsBreakdownRow contract={contract}
                                     projectId={projectId}
                                     setShowForm={setShowForm}
                                     setContractObject={setContractObject}
                                     permissions={contracts.data.permissions}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer> : null
  )
}

export default ContractsTable;
