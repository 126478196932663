import axios from "axios"

const googleCalendarClient = axios.create({
  baseURL: 'https://www.googleapis.com/calendar/v3/calendars',
  headers: {
    // Authorization: `Bearer ${gapi?.auth?.getToken()?.access_token}`,
    // "Content-Type": "multipart/form-data"
  },
  transformResponse: [(data) => {
    const responseData = JSON.parse(data)
    return responseData.error ? responseData.error : responseData
  }],
})

const googleGmailClient = axios.create({
  baseURL: 'https://gmail.googleapis.com/gmail/v1/users',
  headers: {
    // Authorization: `Bearer ${gapi?.auth?.getToken()?.access_token}`,
    // "Content-Type": "multipart/form-data"
  },
  transformResponse: [(data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData
  }],
})


export {
  googleCalendarClient,
  googleGmailClient
}

