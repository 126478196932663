import React, {useState, useEffect} from 'react'
import Button from "@mui/material/Button";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from "@mui/material/Menu";
import Profile from "./index.js"

function ProfileLink() {
  const [username, setUsername] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const menuButtonClicked = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const menuDropDownClosed = () => {
    setAnchorEl(null)
  }

  const customDropdownButtonStyles = {
    color: 'white',
    textTransform: 'none',
    fontFamily: 'Raleway, sans-serif',
    backgroundColor: 'transparent',
    borderRadius: '0px',
    borderBottom: '0.25em solid transparent',
    fontSize: '1rem',
    marginBottom: '1px'
  }

  useEffect(() => {
    const sn = window.localStorage.getItem("sn")
    setUsername(sn ?? 'Profile')
  }, [])

  return (
    <>
      <Button
        id={"profile-basic-menu-button"}
        aria-controls={"profile-basic-menu-button"}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={menuButtonClicked}
        sx={customDropdownButtonStyles}
      >
        <AccountCircleIcon className="mr-2" />{username}
      </Button>
      <Menu
        id={"profileButton"}
        anchorEl={anchorEl}
        open={open}
        onClose={menuDropDownClosed}
        sx={{ '& .MuiPaper-root': { left: "unset !important", right: "0" } }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Profile 
            setAnchorEl={setAnchorEl}
        />
      </Menu>
    </>
  )
}

export default ProfileLink;
