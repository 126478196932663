import React, {useState, useRef} from 'react'
import {
  Field
} from '../../../Common/Forms';

// derivedObject is the Contract or Invoice that contains the associated categories, subcategories, etc
// which i will use in order to recreate my data
const CategorySelector = ({
                            categories,
                            notifyCategoryBreakdown,
                            derivedObject
                          }) => {
  // Get a reference to the elements
  const categoryRef = useRef(null)
  const subCategoryRef = useRef(null)
  const categoryBreakDownRef = useRef(null)
  // Add the selected values in the state

  const [selectedCategory, setSelectedCategory] = useState(
    derivedObject != null ?
      categories.data.filter(cat => cat.id == derivedObject?.attributes?.category?.id)?.shift()
      : null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    selectedCategory?.attributes?.sub_categories != null ?
    selectedCategory?.attributes?.sub_categories?.data.filter(sub => sub.id == derivedObject?.attributes?.sub_category?.id)?.shift()
    : null);
  const [selectedCategoryBreakdown, setSelectedCategoryBreakdown] = useState(
    selectedSubCategory?.attributes?.category_breakdowns != null ?
    selectedSubCategory?.attributes?.category_breakdowns?.data?.filter(break_down => break_down.id == derivedObject?.attributes?.category_breakdown?.id)?.shift()
    : null);

  // Default values
  const emptyCategory = {id: '', attributes: {sub_categories: {data: []}}}
  const emptySubCategory = {id: '', attributes: {category_breakdowns: {data: []}}}
  const emptyCategoryBreakDown = {id: '', attributes: {}}

  // Category Change handler
  const handleCategoryChange = () => {
    const categoryObj = (categoryRef.current?.value == undefined || categoryRef.current?.value === '')
      ? emptyCategory
      : JSON.parse(categoryRef?.current?.options[categoryRef?.current?.selectedIndex]?.getAttribute('data-category'))
    setSelectedCategory(categoryObj)

    // Each time i pick a Category i have to reset the children dropdowns
    setSelectedSubCategory(null)
    setSelectedCategoryBreakdown(null)
    notifyCategoryBreakdown(null)
  }

  // Sub-Category Change handler
  const handleSubCategoryChange = () => {
    const subCategoryObj = (subCategoryRef.current?.value == undefined || subCategoryRef.current?.value === '')
      ? emptySubCategory
      : JSON.parse(subCategoryRef?.current?.options[subCategoryRef?.current?.selectedIndex]?.getAttribute('data-subcategory'))
    setSelectedSubCategory(subCategoryObj)

    // Each time i pick a Category i have to reset the children dropdowns
    setSelectedCategoryBreakdown(null)
    notifyCategoryBreakdown(null)
  }

  // Category Break Down
  const handlecategoryBreakDownChange = () => {
    const categoryBreakDownObj = (categoryBreakDownRef.current?.value == undefined || categoryBreakDownRef.current?.value === '')
      ? emptyCategoryBreakDown
      : JSON.parse(categoryBreakDownRef?.current?.options[categoryBreakDownRef?.current?.selectedIndex]?.getAttribute('data-category-breakdown'))
    setSelectedCategoryBreakdown(categoryBreakDownObj)
    notifyCategoryBreakdown(categoryBreakDownObj)
  }

  return (
    <section>
      {/* Category */}
      <Field>
        <label htmlFor="category" className="text-tenzingGray">Category:</label>
        <select ref={categoryRef}
                id="category"
                disabled={derivedObject?.attributes?.category?.id > 0}
                className="input-select"
                value={selectedCategory?.id ?? ''}
                onChange={handleCategoryChange}>
          <option value=""> -- Select --</option>
          {!categories.isLoading
            && categories.data.map((category) => {
              return (
                <option key={'category-selector-id-' + category.id.toString()}
                        data-category={JSON.stringify(category)}
                        value={category.id}>
                  {category.attributes["name"]}
                </option>
              )
            })}
        </select>
      </Field>

      {/* Sub Category */}
      <Field>
        <label htmlFor="sub_category" className="text-tenzingGray">Sub-Category:</label>
        <select ref={subCategoryRef}
                id="sub_category"
                className="input-select"
                disabled={derivedObject?.attributes?.sub_category?.id > 0}
                value={selectedSubCategory?.id ?? ''}
                onChange={handleSubCategoryChange}>
          <option defaultValue="">Sub-category</option>
          {selectedCategory
            && selectedCategory?.attributes?.sub_categories?.data.map((subCategory) => {
              return (
                <option key={'sub-category-selector-id-' + subCategory.id.toString()}
                        data-subcategory={JSON.stringify(subCategory)}
                        value={subCategory.id}>
                  {subCategory.attributes["name"]}
                </option>
              )
            })}
        </select>
      </Field>

      {/* Category Break Down */}
      <Field>
        <label htmlFor="category_breakdown" className="text-tenzingGray">Category Breakdown:</label>
        <select ref={categoryBreakDownRef}
                id="category_breakdown"
                className="input-select"
                disabled={derivedObject?.attributes?.category_breakdown?.id > 0}
                value={selectedCategoryBreakdown?.id ?? derivedObject?.attributes?.category_breakdown?.["id"] ?? ''}
                onChange={handlecategoryBreakDownChange}>
          <option defaultValue="">Category Breakdown</option>
          {selectedSubCategory
            && selectedSubCategory.attributes.category_breakdowns.data.map((categoryBreakdown) => {
              return (
                <option key={'category-breakdown-selector-id-' + categoryBreakdown.id.toString()}
                        data-category-breakdown={JSON.stringify(categoryBreakdown)}
                        value={categoryBreakdown.id}>
                  {categoryBreakdown.attributes["name"]}
                </option>
              )
            })}
        </select>
      </Field>
    </section>
  )
}

export default CategorySelector;
