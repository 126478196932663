import React from 'react'
import {toast} from 'react-toastify';
import
{
  TableCell,
  TableRow,
} from '@mui/material/';
import {
  formatAmount
} from '../../Utils/Invoice'
import {deleteInvoice} from 'utils/queries'
import {useQueryClient, useMutation, useQuery} from 'react-query';
import {
  displayStatusBadge,
  getRemainingStatuses
} from '../Utils';
import {
  invoice as deleteInvoiceKey,
  projectInvoices
} from 'utils/queryKeys'
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import StatusButton from './StatusButton'
import {formatDate} from 'utils/globals'
import {invoice as invoiceQueryKey} from "../../../../utils/queryKeys";
import {getInvoice} from "../../../../utils/queries";

const InvoiceRow = ({
                      invoice,
                      projectId,
                      setShowForm,
                      setInvoiceObject,
                      permissions
                    }) => {
  const queryClient = useQueryClient();
  const {mutateAsync: delRow} = useMutation(deleteInvoice, {
    onSuccess: (data, variables, context) => {
      notifySuccess()
      queryClient.refetchQueries([projectInvoices, {projectId: projectId}])
    },
    onError: (error, variables, context) => {
      console.log('invoice delete failed.', error)
      notifyError()
    }
  });


  const invoiceQuery = useQuery(
    [invoiceQueryKey, {invoiceId: invoice.id}],
    getInvoice,
    {
      enabled: !!invoice && invoice.id != undefined && invoice.id != null
    })


  const notifySuccess = () => toast.success("Invoice deleted successfully!");
  const notifyError = () => toast.error("There was an error deleting the invoice.")

  return (
    <>
      {
        !!invoice
        && !invoiceQuery.isLoading
        && invoiceQuery.isSuccess
        && invoiceQuery.data?.permissions != undefined ?
          <TableRow key={invoice.id}>
            <TableCell>{invoice.attributes["category"]["name"]}</TableCell>
            <TableCell>{invoice.attributes["sub_category"]["name"]}</TableCell>
            <TableCell>{invoice.attributes["category_breakdown"]["name"]}</TableCell>
            <TableCell>{invoice.attributes["line_item"]["name"]}</TableCell>
            <TableCell>{invoice.attributes["contract_breakdown"]["firm_name"]}</TableCell>
            <TableCell>
              {
                invoiceQuery.data?.permissions?.['update'] ?
                  <div className="underline text-lightBlue hover:cursor-pointer" onClick={() => {
                    setShowForm('edit_invoice'), setInvoiceObject(invoice)
                  }}>{invoice.attributes["number"]}</div>
                  : <div>{invoice.attributes["number"]}</div>
              }
            </TableCell>
            <TableCell>{displayStatusBadge(invoice.attributes["approval_status"])}</TableCell>
            <TableCell>{formatDate(invoice.attributes["invoice_date"])}</TableCell>
            <TableCell>{formatDate(invoice.attributes["recommended_status_date"])}</TableCell>
            <TableCell>{formatAmount(invoice.attributes["amount"])}</TableCell>
            <TableCell>
              {
                permissions['edit'] && getRemainingStatuses(invoice.attributes["approval_status"])
                  .map((action, idx) => {
                    return (
                      <span key={idx.toString() + "-invoices-table-" + action + "-" + invoice.id.toString()}>
                        <StatusButton status={action}
                                      invoiceId={invoice.id}
                                      projectId={projectId}/>
                      </span>
                    )
                  })
              }

              {
                permissions['delete'] ?
                  <Button color={"error"}
                          size="small"
                          type="button"
                          onClick={() => delRow([deleteInvoiceKey, {invoiceId: invoice.id}])}
                          startIcon={<DeleteIcon/>}>
                    Delete
                  </Button>
                  : null
              }
            </TableCell>
          </TableRow> :
        <TableRow><TableCell>No data to display</TableCell></TableRow>
      }
    </>
  )
}

export default InvoiceRow; 
