import React, {useEffect, useRef} from "react";
import {useQuery} from "react-query";
import moment from 'moment'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import {getCompanyProjects} from "utils/queries";
import {
  companyProjects
} from "utils/queryKeys"
import {
  formatEvents
} from "utils/google/Auth"
import CircularProgress from '@mui/material/CircularProgress'
import {toast} from "react-toastify"
import {googleCalendarClient} from 'utils/google/Api'
import {Tooltip} from '@material-ui/core';
import {googleCalendarToken} from "../../utils/queryKeys";
import {getGoogleCalendarAccessToken} from "../../utils/queries";


function CalendarDay({
                       isRefreshed
                     }) {
  const allEvents = useRef([]);
  const dayCalendarRef = useRef()
  const weekCalendarRef = useRef()
  const calendarList = useRef([])

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const projectsQuery = useQuery(
    [companyProjects, {companyId: companyId}],
    getCompanyProjects)

  const googleCalendarQuery = useQuery(
    [googleCalendarToken, {companyId: companyId}],
    getGoogleCalendarAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  const notifyError = (calid) => {
    toast.error("Event Collection Failed.");
    console.log('Calendar id: ' + calid)
  }

  // Get Reference to the Calendar API
  const getDayCalendarApi = () => {
    const {current: calendarDayDom} = dayCalendarRef;
    return calendarDayDom ? calendarDayDom.getApi() : null;
  }

  const getWeekCalendarApi = () => {
    const {current: calendarWeekDom} = weekCalendarRef;
    return calendarWeekDom ? calendarWeekDom.getApi() : null;
  }

  useEffect(() => {
    if (!projectsQuery.loading
      && !!projectsQuery.data?.data
      && !googleCalendarQuery.isLoading
      && googleCalendarQuery?.data?.access_token != undefined
      && projectsQuery.data?.data?.length > 0) {
      fetchCalendarInfofromProject()
      if (calendarList.current.length) {
        // Fill the Calendar
        getCalendarEvents()
      }
    }
  }, [projectsQuery.data?.data,
    projectsQuery.loading,
    projectsQuery.fetching,
    googleCalendarQuery.loading,
    googleCalendarQuery.data,
    isRefreshed])

  const fetchCalendarInfofromProject = () => {
    calendarList.current = []
    projectsQuery.data?.data?.forEach(project => {
      if (project.attributes?.["google_calendarid"] != undefined
        && project.attributes?.["google_calendarid"]?.trim() != '') {
        calendarList.current.push({
          id: project.attributes["google_calendarid"],
          color: '#' + project.attributes["color_identifier"]
        })
      }
    })
  }

  const getCalendarEvents = () => {
    let eventList = []
    const dayCalendarApi = getDayCalendarApi()
    const weekCalendarApi = getWeekCalendarApi()
    // First remove all the events
    dayCalendarApi.getEvents().forEach((event) => {
      event.remove()
    })
    weekCalendarApi.getEvents().forEach((event) => {
      event.remove()
    })
    if (!googleCalendarQuery.isLoading
      && googleCalendarQuery.isSuccess
      && googleCalendarQuery.data?.access_token != undefined
      && calendarList.current.length > 0) {
      googleCalendarClient.defaults.headers['Authorization'] = `Bearer ${googleCalendarQuery?.data?.access_token}`;
      calendarList.current.map((calinfo) => {
        googleCalendarClient.get(calinfo.id + "/events?orderBy=startTime&singleEvents=true")
          .then((data) => {
            if (data.data.items) {
              eventList = []
              allEvents.current = []
              eventList.push(...formatEvents(data.data.items, calinfo.color))
              allEvents.current.push(...formatEvents(data.data.items, calinfo.color))
            }
            return eventList
          })
          .then(myevents => {
            // Add the new ones
            myevents.forEach((event) => {
              dayCalendarApi.addEvent(event)
              weekCalendarApi.addEvent(event)
            })
          })
          .catch((err) => {
            notifyError(calinfo.id)
            console.log(err)
          })
      })
    }
  }

  function renderInnerContent(innerProps) {
    return (
      <div className='fc-event-main-frame shadow shadow-slate-200'>
        {innerProps.timeText &&
          <div className='fc-event-time'>{innerProps.timeText}</div>
        }
        <div className='fc-event-title-container'>
          <div className='fc-event-title fc-sticky'>
            {innerProps.event.title || <Fragment>&nbsp;</Fragment>}
          </div>
        </div>
      </div>
    );
  }

  const displayEventDetails = (calEvent) => {
    const startDate = new Date(calEvent.startStr)
    const endDate = new Date(calEvent.endStr)
    return (
      <div>
        <h1>{calEvent.title}</h1>
        <h2>Starts At: {moment(startDate).format("lll")}</h2>
        <h2>Ends At: {moment(endDate).format("lll")}</h2>
        {calEvent.extendedProps.attendees ?
          <h2>Attendees: {calEvent.extendedProps.attendees.map(person => `${person.email}(${person.responseStatus}) `)}</h2>
          : null
        }
      </div>
    )
  }

  return (
    <div className="calendar">
      {
        projectsQuery.isLoading && calendarList.current.length == 0 ?
          <div><CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>Calendar events are
            Loading...</div>
          :
          <div
            key={allEvents}
            className="flex flex-col space-y-2">
            <div className="gray-border mx-0">
              <h2 className="">Calendars:</h2>
              <div>
                {projectsQuery.data?.data?.map(proj => {
                  const keyColor = "#" + proj.attributes["color_identifier"]
                  return (
                    <div
                      key={proj.attributes["color_identifier"].toString() + "-" + proj.attributes["id"] + "-" + proj.attributes["google_calendarid"]}
                      className="flex space-x-1">
                          <span style={{backgroundColor: `${keyColor}`}}
                                className={`inline-block align-middle w-5 h-5 rounded-full mr-2 `}></span>
                      <h3>{proj.attributes["name"]}</h3>
                    </div>
                  )
                })}
              </div>
            </div>
            <FullCalendar
              ref={weekCalendarRef}
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              eventLimit={3}
              // events={allEvents.current}
              eventDisplay={'block'}
              headerToolbar={{
                left: 'prev',
                center: "title",
                right: "next"
              }}
              eventDidMount={(info) => {
                info.el.style.borderWidth = '0px 0px 0px 4px';
                info.el.style.overflow = 'hidden'
              }}
              eventContent={(info) => {
                return (
                  <Tooltip
                    component={'span'}
                    arrow
                    title={displayEventDetails(info.event)}
                    placement={"left"}
                  >{renderInnerContent(info)}</Tooltip>
                )
              }}
            />
            <FullCalendar
              ref={dayCalendarRef}
              plugins={[dayGridPlugin, timeGridPlugin]}
              initialView="timeGridDay"
              eventDidMount={(info) => {
                info.el.style.borderWidth = '0px 0px 0px 4px';
                info.el.style.overflow = 'hidden'
              }}
            />
          </div>
      }
    </div>
  );
}

export default CalendarDay;
