import React, {useState, useEffect} from "react";
import {useQuery} from "react-query";
import {
  gmailGetAttachmentKey,
  gmailGetAttachment
} from "../../../utils/google/Queries";
import {b64toBlob} from '../Utils/Index'
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DownloadBtn = ({
                       mailItem,
                       file
                     }) => {
  const [startDownload, setStartDownload] = useState(false)

  // I only want to start downloading as soon as i click on the button Icon. Not on
  // element load.
  const downloadAttachment = useQuery(
    [gmailGetAttachmentKey,
      {
        mailId: mailItem.id,
        userId: "me",
        access_token: gapi?.auth?.getToken()?.access_token,
        attachmentId: file.id
      }],
    gmailGetAttachment,
    {enabled: startDownload}
  )

  useEffect(() => {
    if (downloadAttachment.isSuccess
        && !!downloadAttachment.data
        && startDownload) {
      const dataBase64Rep = downloadAttachment.data.replace(/-/g, '+').replace(/_/g, '/')
      const urlDownload = b64toBlob(dataBase64Rep, file.mime, downloadAttachment.size)
      // Create blob link to download
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', file.name);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setStartDownload(false)
    }
  }, [downloadAttachment.loading, downloadAttachment.data, startDownload])

  return (
    <>
      <div className="inline-flex
                      flex-col
                      flex-wrap
                      items-center
                      mr-2">
        <button type="button"
                onClick={() => setStartDownload(true)}
                className="w-fit
                           hover:text-tenzingOrange
                           hover:border-tenzingOrange
                           border
                           border-tenzingGrayLight
                           bg-white
                           text-tenzingGrayLight
                           focus:ring-2
                           focus:outline-none
                           focus:ring-tenzingOrange
                           font-medium
                           rounded-lg
                           text-sm
                           p-2.5
                           text-center
                           inline-flex
                           items-center
                           mr-2">
          <FileDownloadIcon/>
          <span className="sr-only">{file.name}</span>
        </button>
        <label className="flex-grow truncate text-xs">{file.name}</label>
      </div>
    </>
  )
}

export default DownloadBtn;
