import React from "react";

const InboxHtml = ({emailsList}) => {
  return (
    <div className="flex-auto overflow-y-auto">
      {emailsList}
    </div>
  );
};

export default InboxHtml;


