import React, {useState} from 'react'
import {
  Field,
  FieldError,
  ErrorText
} from 'Components/Common/Forms';
import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {phasePoolModulePool} from "../../../../utils/queryKeys";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '../../../Common/Button';
import { client as apiClient } from '../../../../utils/api';


const CustomModuleFields = ({ handleShowModal, currentPhasePoolId }) => {
  const [doDisable, setDoDisable] = useState(false);
  const queryClient = useQueryClient();


  const {register, handleSubmit, reset, formState: {errors}} = useForm();

  const notifySuccess = () => toast.success("Custom Module added successfully!");
  const notifyError = () => toast.error("Custom Module add failed.")

  async function postForm(data) {
    const {name, description, uid} = data

    return await apiClient.post("/module_pools", {
      name: name,
      description: description,
      uid: uid,
      phase_pool_id: currentPhasePoolId
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postForm);

  const onSubmit = async (data, e) => {
    try {
      setDoDisable(true)
      await sendData(data)
      handleShowModal(false)
      notifySuccess()
      setDoDisable(false)
      reset()
      e.target.reset()
      await queryClient.refetchQueries([phasePoolModulePool, {phasePoolId: currentPhasePoolId}]);
    } catch (error) {
      handleShowModal(false)
      setDoDisable(false)
      notifyError()
      throw new Error(error)
    }
  }


  return (
    <>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="relative p-4 flex-auto">
          <Field>
            <label htmlFor="name" className="text-tenzingGray">Name:</label>
            <input
              id="name"
              type="text"
              {...register("name", {required: true})}
              className={!errors.name ? "input-text" : 'input-text-error'}
            />
            <FieldError>
              {errors.name?.type === 'required' &&
                <ErrorText>Name is required</ErrorText>
              }
            </FieldError>
          </Field>

          <Field>
            <label htmlFor="description" className="text-tenzingGray">Description:</label>
            <input
              id="description"
              type="text"
              {...register("description", {required: false})}
              className={!errors.description ? "input-text" : 'input-text-error'}
            />
          </Field>

          <Field>
            <label htmlFor="uid" className="text-tenzingGray">UID:</label>
            <input
              id="uid"
              type="text"
              {...register("uid", {required: true})}
              className={!errors.uid ? "input-text" : 'input-text-error'}
            />
            <FieldError>
              {errors.uid?.type === 'required' &&
                <ErrorText>UID is required</ErrorText>
              }
            </FieldError>
          </Field>
        </div>
        <div className="flex items-center justify-end p-4">
          <button
            className="underline text-gray-400 py-0.5 px-4 m-1"
            onClick={() => handleShowModal(false)}
          >Cancel
          </button>
          <Button buttonStyle={(isLoading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={(isLoading || doDisable)}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add
          </Button>
        </div>
      </form>
    </>
  );
}

export default CustomModuleFields;
