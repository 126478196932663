import React from 'react'
import Tree from 'react-animated-tree'

const config = open => ({
  from: {height: 0, opacity: 0, transform: 'translate3d(20px,0,0)'},
  to: {
    height: open ? 'auto' : 0,
    opacity: open ? 1 : 0,
    transform: open ? 'translate3d(0px,0,0)' : 'translate3d(20px,0,0)',
  },
})

const BoxTree = (props) => {
  return (
    <Tree {...props} springConfig={config} />
  )
}

export default BoxTree;
