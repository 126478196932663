import React, {useRef} from 'react'
import {Field} from 'Components/Common/Forms';
import {updateProject} from "../../../utils/queries";
import {useMutation} from "react-query";
import {toast} from "react-toastify";

const SliderLock = ({
                      locked,
                      setLocked,
                      project
                    }) => {
  const statusBuffer = useRef(null);

  // Update Project
  const {isLoading: isLoadingProjectUpdate, mutateAsync: patchProject} = useMutation(updateProject, {
    onSuccess: (data, variables, context) => {
      notifySuccess("Process Map locked.")
      setLocked(statusBuffer.current)
    },
    onError: (error, variables, context) => {
      notifyError("Process Map lock failed.")
      console.log("Process Map lock failed", error)
    }
  });

  const notifySuccess = (msg) => {
    toast.success(msg);
  }
  const notifyError = (msg) => {
    toast.error(msg)
  }

  if (isLoadingProjectUpdate) {
    return (<div className="lds-dual-ring"></div>)
  }

  const handleChange = (event) => {
    statusBuffer.current = event.target.checked

    // Update the project
    const tdata = {
      process_map_locked: event.target.checked
    }

    patchProject([updateProject, {projectId: parseInt(project.data?.data?.id), data: tdata}])
  }

  return (
    <>
      {
        !project.isLoading && project.isSuccess ?
          <Field>
            <div className="flex items-end">
              <p className="text-lg text-stratosGray">{locked ? 'Unlock ' : 'Lock'} Map:</p>
              <div>
                <label className="flex items-baseline" htmlFor="lockMap">
                  <input
                    id="lockMap"
                    name="lockMap"
                    type="checkbox"
                    disabled={isLoadingProjectUpdate}
                    checked={Boolean(locked)}
                    className="appearance-none invisible peer"
                    onChange={handleChange}
                  />
                  <span className="w-14 h-8
                      flex items-center
                      flex-shrink-0
                      mr-4 p-1
                      bg-lightGray
                      rounded-full
                      duration-300 ease-in-out
                      peer-checked:bg-tenzingBlue
                      after:w-6
                      after:h-6
                      after:bg-white
                      after:rounded-full
                      after:shadow-md
                      after:duration-300
                      peer-checked:after:translate-x-6
                      "></span>
                </label>
              </div>
            </div>
          </Field>
          : null
      }
    </>
  )
}

export default SliderLock;
