import React, {useState, useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import Section from 'Components/Common/Section'
import Button from 'Components/Common/Button';
import UserCheckList from 'Components/Users/CheckBoxList'
import {toast} from "react-toastify";
import
  ContentTwoColumn, {WideColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import {
  companyUsers,
  assignProjectTeams
} from "../../utils/queryKeys";
import {
  getCompanyUsers,
  postAssignProjectTeam
} from "../../utils/queries";
import {useHistory, useLocation} from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";

function Index() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const companyId = parseInt(window.localStorage.getItem("company_id"))

  const navigate = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const projectId = queryParams.get('projectid')

  if (projectId == undefined || projectId == "") {
    toast.info("Project Not provided.")
    navigate.push("/")
  }

  // Get Contacts
  const users = useQuery(
    [companyUsers, {companyId: companyId}],
    getCompanyUsers, {
      retry: 0,
    })

  useEffect(() => {
    if(!users.isLoading && users.isSuccess) {
      let project_team = []
      for(const usr of users?.data) {
        if(usr.attributes['project_team_ids'].includes(parseInt(projectId))) {
          project_team.push(usr.id)
        }
      }

      setSelectedUsers(project_team)
    }
  }, [!users.isLoading && users.isSuccess && !users.isFetching])

  const handleUserProjectPool = () => {
    setLoading(true)
    const tdata = {
      "project_team": {
        userlist: JSON.stringify(selectedUsers),
        project_id: projectId
      }
    }
    bulkAssignProjectTeam([assignProjectTeams, {companyId: parseInt(companyId), payload: tdata}])
  }

  const {mutateAsync: bulkAssignProjectTeam} = useMutation(postAssignProjectTeam, {
    onSuccess: (data, variables, context) => {
      setLoading(false)
      toast.success("Assign project team succeeded")
    },
    onError: (error, variables, context) => {
      setLoading(false)
      console.error("Failed to assign project team", error.message)
      toast.error("Assign project Team failed")
    }
  });

  if (users.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <ContentTwoColumn>
      <WideColumn>
        {
          users.data?.length > 0 ?
            <Section>
              <div className="flex justify-between mb-2">
                <h1 className="Section-h1">Users</h1>
                <Button buttonStyle={loading ? "gray-solid-button" : "orange-transparent-button"}
                        onClick={handleUserProjectPool}
                        disabled={loading}>
                  {
                    loading ?
                      <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                      : <></>
                  }
                  Assign To Project Team
                </Button>
              </div>
              <UserCheckList users={users.data}
                             projectId={projectId}
                             setSelectedUsers={setSelectedUsers}
                             selectedUsers={selectedUsers}/>
            </Section>
            : null
        }
      </WideColumn>
    </ContentTwoColumn>
  )
}

export default Index
