import React, {useState} from 'react'
import {useForm} from 'react-hook-form';
import Button from 'Components/Common/Button';
import Section from 'Components/Common/Section'
import CategorySelector from '../../Components/CategorySelector';
import {toast} from 'react-toastify';
import {
  Field,
  FieldError,
  ErrorText
} from 'Components/Common/Forms';
import {client as apiClient} from 'utils/api';
import {useMutation, useQueryClient} from 'react-query';
import {
  projectLineItems as lineItemsQueryKey
} from '../../../../utils/queryKeys';
import {defaultValues} from "../../Utils/LineItem";
import CircularProgress from "@mui/material/CircularProgress";
import ContentExplorer from 'box-ui-elements/es/elements/content-explorer';
import {IntlProvider} from "react-intl";
import {box_host_name_upload as uploadHostName} from "../../../../utils/globals";
import BoxFileExplorer from "../../../FileExplorer";

function Form({
                setShowForm,
                categories,
                projectId,
                bexpConfig
              }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategoryBreakdown, notifyCategoryBreakdown] = useState({});
  const [formData, setFormData] = useState(defaultValues)
  const [requestOpenWith, setRequestOpenWith] = useState(false)
  const [boxFilePath, setBoxFilePath] = useState('')
  const [boxFileName, setBoxFileName] = useState('')

  const queryClient = useQueryClient();

  const notifyBoxSuccess = () => toast.success("File uploaded successfully!");
  const notifySuccess = () => toast.success("New Budget Line Item added successfully!");
  const notifyError = () => toast.error("New Budget Line Item unsuccessful.")

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["invoice_date", "recommended_status_date"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value.trim()
      })
      // Set the value
      setValue(e.target.id, e.target.value.trim())
    }
  }

  const {mutateAsync: sendData} = useMutation(postForm);

  async function postForm(data) {
    const {
      name,
      approved_budget,
      anticipated_cost
    } = data

    return await apiClient.post("/line_items", {
      name: name,
      approved_budget_cents: parseInt(approved_budget) * 100,
      anticipated_cost_cents: parseInt(anticipated_cost) * 100,
      category_breakdown_id: parseInt(selectedCategoryBreakdown?.id),
      project_id: parseInt(projectId)
    })
  }

  const {register, handleSubmit, setValue, reset, formState: {errors}} = useForm(defaultValues);
  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.refetchQueries([lineItemsQueryKey])
      notifySuccess()
      reset(defaultValues)
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
    } catch (error) {
      setLoading(false)
      setDoDisable(false)
      notifyError();
      throw new Error(error)
    }
  }

  if (projectId == undefined) {
    return null
  }

  return (
    <Section>
      <h2 className="Section-h1 mb-4">Add New Budget Item</h2>
      <CategorySelector categories={categories} notifyCategoryBreakdown={notifyCategoryBreakdown}/>
      <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

        {/* NAME */}
        <Field>
          <input
            id="name"
            type="text"
            placeholder="Name"
            onChange={handleFormChange}
            {...register("name", {required: true})}
            className={!errors.name ? "input-text" : 'input-text-error'}
          />
          <FieldError>
            {errors.name?.type === 'required' &&
              <ErrorText>Name is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* APPROVED BUDGET */}
        <Field>
            <div className="currency-wrap">
              <input
                id="approved_budget"
                type="text"
                placeholder="Approved Budget"
                onChange={handleFormChange}
                {...register("approved_budget", {required: true})}
                className={`pl-4 ${!errors.approved_budget ? "input-text" : "input-text-error"}`}
              />
            </div>
          <FieldError>
            {errors.approved_budget?.type === 'required' &&
              <ErrorText>Approved budget is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* ANTICIPATED COST */}
        <Field>
            <div className="currency-wrap">
              <input
                id="anticipated_cost"
                type="text"
                placeholder="Anticipated Cost"
                onChange={handleFormChange}
                {...register("anticipated_cost", {required: true})}
                className={`pl-4 ${!errors.anticipated_cost_cents ? "input-text" : "input-text-error"}`}
              />
            </div>
          <FieldError>
            {errors.anticipated_cost?.type === 'required' &&
              <ErrorText>Anticipated cost is required</ErrorText>
            }
          </FieldError>
        </Field>

        <section className="mt-4 mb-4">
          <Button buttonStyle="gray-transparent-button" type="button"
                  onClick={() => setShowForm(false)}>Cancel</Button>
          <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={loading || doDisable}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add New
          </Button>
          <Button variant="text"
                  buttonStyle="text-gray-600 float-right"
                  type="button"
                  onClick={() => {
                    reset(defaultValues)
                  }}
          >Clear</Button>
        </section>
      </form>

      {/* Import a Box Form */}
      {
        bexpConfig.access_token.isLoading
        || bexpConfig.boxable.isLoading
        || bexpConfig.boxable?.data?.attributes == undefined ? "" :
          (bexpConfig.boxable?.data?.attributes.hasOwnProperty("cost_box_folderid")
            && (bexpConfig.boxable?.data?.attributes["cost_box_folderid"] == undefined
              || bexpConfig.boxable?.data?.attributes["cost_box_folderid"] == "")) ?
            <div>Not linked to Box</div> :
            <BoxFileExplorer props={bexpConfig}/>
      }
    </Section>
  );
}

export default Form
