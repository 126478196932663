import React, {useState, useContext, useEffect, useRef} from "react";
import Section from '../../../Components/Common/Section'
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient, useQuery} from "react-query";
import {Field} from 'Components/Common/Forms';
import Button from 'Components/Common/Button';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {toast} from "react-toastify";
import {
  gmailGetListKey, gmailModifyMessage,
  gmailLabelKey, gmailLabelList
} from "../../../utils/google/Queries";
import CircularProgress from "@mui/material/CircularProgress";
import {DropzoneArea} from "material-ui-dropzone";
import {CorrespondenceContext} from "../../../context/CorrespondenceContext";
import {
  b64Encode,
  constructEmailBody,
  emailContext,
  defaultFormValues,
  constructForwardEmailBody,
  parseSubject,
  mergeArrays
} from '../Utils/Index'

// NOTE: Replying to an email with a label preserves the label since it is part of the thread
function SendEmail({
                     gapi,
                     objectLabelId,
                     setShowSendEmail,
                     setShowForm,
                     objectLabelName,
                     selectedLabels,
                     labelListUpdated
                   }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newFiles, setNewFiles] = useState([])
  const [dropZoneVisibility, setDropZoneVisibility] = useState(false)
  // How to unmount from dom to force file cleaning
  // https://github.com/Yuvaleros/material-ui-dropzone/issues/9
  const [zoneKey, setZoneKey] = useState(0)
  const [correspondenceContext, setCorrespondenceContext] = useContext(CorrespondenceContext);
  const listOfLabels = useRef({})
  const subjectListOfLabels = useRef([])
  const projectValue = JSON.parse(window.localStorage.getItem("projectValue") ?? '{}');

  // XXX Since we need to update the form with the correspondence value we need to preced the initialization
  //     of the form here
  const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm(defaultFormValues);

  // Fetch all the email tags
  useEffect(() => {
    if (gapi?.auth?.getToken()?.access_token) {
      getGmailLabelList([gmailLabelKey, {
        access_token: gapi?.auth?.getToken()?.access_token
      }])
    }
  }, [gapi?.auth?.getToken()?.access_token, labelListUpdated])

  const {isLoading: isLoadingGetGmail, mutateAsync: getGmailLabelList} = useMutation(gmailLabelList, {
    onSuccess: (data, variables, context) => {
      let tmpOb = {}
      data.data.labels.forEach(label => {
        tmpOb[label.name] = label.id
      })
      listOfLabels.current = tmpOb
    },
    onError: (error, variables, context) => {
      console.error('Gmail label list fetch failed.', error.message)
    },
    cacheTime: 0
  });

  // Reply handler
  useEffect(() => {
    if (correspondenceContext.reply) {
      reset({
        emailAddress: correspondenceContext.from,
        subject: correspondenceContext.subject,
        cc: "",
        message: ""
      });
    }

    if (correspondenceContext.forward) {
      // console.log('forward:', correspondenceContext)
      reset({
        subject: correspondenceContext.subject,
        emailAddress: "",
        cc: "",
        message: ""
      });
    }

    if (correspondenceContext.reply_all) {
      // console.log('reply_all:', correspondenceContext)
      reset({
        subject: correspondenceContext.subject,
        emailAddress: correspondenceContext.from,
        cc: correspondenceContext.cc,
        message: ""
      });
    }
  }, [...Object.values(correspondenceContext)])

  // DROP ZONE FILES
  const handleDZFilesChange = (files, event) => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Each file has a prefix. We need to remove it in order to have valid base64 Blob
        const readerResultPrefix = "data:" + file.type + ";base64,"
        const base64String = reader.result.replace(readerResultPrefix, "")
        // Append every new file to the state variable
        setNewFiles([...newFiles, {
          name: file.name,
          type: file.type,
          content: base64String
        }])
      };
    })
  }

  const handleDZfileDelete = (file, index) => {
    setNewFiles(newFiles => newFiles.filter((fw) => fw.name !== file.name));
  }

  const queryClient = useQueryClient();

  // Google Modify Message
  const {mutateAsync: modifyGmailMessage} = useMutation(gmailModifyMessage, {
    onSuccess: (data, variables, context) => {
      // Each time i send an email i need to hide the form. This way i will
      // ensure(enforce) that the user will click on the Send Email and renew the task label id
      setShowSendEmail(false)
      setShowForm(true)
    },
    onError: (error, variables, context) => {
      console.log('Gmail Label create failed.', error)
    }
  });

  const {isLoading, mutateAsync: sendData} = useMutation(postForm);

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["due_date"].includes(e.target.id)) {
      // Set the value
      setValue(e.target.id, e.target.value)
    }
  }

  async function postForm(data) {
    const {emailAddress, cc, subject, message} = data
    const fromEmail = window.gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail()

    let fsubject = '';
    if (selectedLabels?.length > 0) {
      if (projectValue?.project?.attributes["identifier"]) {
        fsubject += `[${projectValue['project']['attributes']['identifier']}]`
        if (objectLabelName) {
          // Create the nested tag
          fsubject += `[${projectValue['project']['attributes']['identifier']}/${objectLabelName}]`
        }
      } else if (objectLabelName) {
        fsubject += `[${objectLabelName}]`
      }
    }
    fsubject += ` ${subject}`

    subjectListOfLabels.current = parseSubject(fsubject, listOfLabels)
    // When i send an email i will prepend the subject with the label id by default
    const mailData = {
      from: fromEmail,
      to: emailAddress,
      cc: cc,
      subject: fsubject,
      message: message
    }


    // Parse the mailData.subject here and get all the square bracket labels
    // Make a request to fetch the label ids if exist
    // Append the id in the selectLabals list

    let rawdata = null
    if (correspondenceContext.forward) {
      rawdata = constructForwardEmailBody(mailData, newFiles, correspondenceContext)
    } else {
      rawdata = constructEmailBody(mailData, newFiles, correspondenceContext)
    }

    const encodedMessage = b64Encode(rawdata)
    return await gapi.client.gmail.users.messages.send({
      userId: 'me',
      resource: {
        raw: encodedMessage
      }
    })
  }

  const notifySuccess = () => toast.success("Email Sent");
  const notifyError = () => toast.error("Email Failed");

  async function onSubmit(data, e) {
    try {
      setLoading(true)
      setDoDisable(true)
      const response = await sendData(data)
      reset(defaultFormValues)
      notifySuccess()
      queryClient.invalidateQueries([gmailGetListKey, {
        userId: "me",
        access_token: gapi?.auth?.getToken()?.access_token,
        queue: 'in:sent',
        labelIds: selectedLabels
      }]);

      // Add Tags only if i have tags to apply
      // Modify message tag/label
      if (selectedLabels?.length > 0 || subjectListOfLabels.current.length > 0) {
        const tdata = {
          "addLabelIds": mergeArrays(selectedLabels, subjectListOfLabels.current)
        }

        modifyGmailMessage([modifyGmailMessage, {
          messageId: response.result.id,
          data: tdata,
          access_token: gapi?.auth?.getToken()?.access_token
        }])
      }

      setNewFiles([])
      setDropZoneVisibility(false)
      window.setTimeout(() => {
        setZoneKey(zoneKey + 1)
      }, 0)
      setLoading(false)
      setDoDisable(false)
      setCorrespondenceContext(emailContext)
    } catch (error) {
      notifyError()
      setNewFiles([])
      setDropZoneVisibility(false)
      window.setTimeout(() => {
        setZoneKey(zoneKey + 1)
      }, 0)
      setLoading(false)
      setDoDisable(false)
      throw new Error(error)
    }
  }

  // todo: Add an info bar or a tool tip that will inform the user that the
  // task email always get the label name as a subject prefix
  return (
    gapi?.auth != undefined && gapi?.auth?.getToken() != undefined ?
      <Section>
        {/* Send Form*/}
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center">
            <h1 className='Section-h1 mb-2'>Send Email</h1>
            {
              selectedLabels?.length != 0 && projectValue?.project?.attributes["identifier"] ?
                <span className="h-6 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                  {projectValue['project']['attributes']['identifier']}
                </span>
                : null
            }
            {
              selectedLabels?.length !== 0 && objectLabelName ?
                <span className="h-6 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                  {objectLabelName}
                </span>
                : null
            }
            {
              selectedLabels?.length == 0 ?
                <span className="ml-4 text-tenzingRed decoration-2">
                No Email Tag found
              </span>
                : null
            }
          </div>
          {/* EMAIL ADDRESS */}
          <Field>
            <input
              id="emailAddress"
              readOnly={correspondenceContext.reply}
              type="text"
              placeholder="Email Address"
              {...register("emailAddress", {required: true})}
              className={!errors.emailAddress ? "input-text" : 'input-text-error'}
              onChange={handleFormChange}
            />
            <FieldInputError item={errors.emailAddress}></FieldInputError>
          </Field>
          {/* CC: */}
          <Field>
            <input
              id="cc"
              type="text"
              placeholder="CC:"
              {...register("cc", {required: false})}
              className={!errors.cc ? "input-text" : 'input-text-error'}
              onChange={handleFormChange}
            />
            <FieldInputError item={errors.cc}></FieldInputError>
          </Field>
          {/* SUBJECT */}
          <Field>
            <input
              id="subject"
              type="text"
              placeholder="[label#1]...[label#N] Subject Text"
              readOnly={correspondenceContext.reply || correspondenceContext.forward}
              placeholder="Subject"
              {...register("subject", {required: true})}
              className={!errors.subject ? "input-text" : 'input-text-error'}
              onChange={handleFormChange}
            />
            <FieldInputError item={errors.subject}></FieldInputError>
          </Field>
          {/* MESSAGE */}
          <Field>
            <textarea
              id="message"
              rows="3"
              placeholder="Message"
              {...register("message", {required: true})}
              className={!errors.message ? "h-40 input-text" : 'h-40 input-text-error'}
              onChange={handleFormChange}
            />
            <FieldInputError item={errors.message}></FieldInputError>
          </Field>
          <section className="flex justify-between">
            <span className="flex">
              <Button buttonStyle="orange-transparent-button"
                      type="button"
                      onClick={() => setDropZoneVisibility(!dropZoneVisibility)}
              >Upload Files</Button>
              <Button type="submit"
                      buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                      disabled={loading || doDisable}>
                {
                  doDisable ?
                    <CircularProgress size="1rem" color="inherit"/>
                    : <></>
                }
                <span className="ml-2">Send</span>
              </Button>
            </span>
            <Button variant="text"
                    buttonStyle="text-gray-600 float-right"
                    type="button"
                    onClick={() => {
                      reset(defaultFormValues)
                      setCorrespondenceContext({...correspondenceContext, ["forward"]: false})
                      setCorrespondenceContext({...correspondenceContext, ["reply"]: false})
                      setShowSendEmail(false)
                      setShowForm(true)
                      setNewFiles([])
                      setDropZoneVisibility(false)
                      window.setTimeout(() => {
                        setZoneKey(zoneKey + 1)
                      }, 0)
                    }}
            >Close</Button>
          </section>
        </form>

        {/*  DropZone*/}
        <div className={`flex justify-between items-baseline ${dropZoneVisibility ? '' : ' hidden'}`}>
          <div style={{width: '100%'}} className="mb-4 flex flex-col">
            <section className="mt-4 flex justify-between items-center mb-2">
              <label htmlFor="add_files" className="text-tenzingGray">Drop your files HERE</label>
            </section>
            <DropzoneArea
              key={zoneKey}
              acceptedFiles={
                ['image/*',
                  'video/*',
                  'application/pdf',
                  '.doc',
                  '.docx',
                  '.xls',
                  '.xlsx',
                  '.csv',
                  '.tsv',
                  '.ppt',
                  '.pptx',
                  '.pages',
                  '.odt',
                  '.rtf']}
              onDrop={handleDZFilesChange}
              onDelete={handleDZfileDelete}
              showFileNames
              dropzoneText="Attachments"
              showAlerts={false}
              filesLimit={20}
            />
          </div>
        </div>
      </Section>
      : null
  );
}

export default SendEmail;
