import React from "react";

export const getPhaseStatusColor = (status) => {
  if(status == undefined) {
    return null
  }

  switch (status) {
    case "pending":
    case 0:
      return "bg-lightGray"
    case "in_process":
    case 1:
      return "bg-tenzingBlue"
    case "completed":
    case 2:
      return "bg-trueGreen"
  }
}

export const getPhaseNameStatusColor = (status) => {
  switch (status) {
    case 0:
      return "text-xs text-lightGray"
    case 1:
    case 2:
      return "text-xs text-tenzingOrange"
  }
}

export const moduleFields = ["subname", "order"];
