export const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Contact #',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Contact Name',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone Number',
  },
  {
    id: 'address',
    numeric: true,
    disablePadding: false,
    label: 'Address',
  },
  {
    id: 'company',
    numeric: true,
    disablePadding: false,
    label: 'Company Name',
  },
  {
    id: 'users',
    numeric: false,
    disablePadding: false,
    label: 'Email(s)',
  },
];

export const fields = ["first_name", "last_name", "title", "phone", "address"];

export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "name") {
    if (b.attributes["last_name"] < a.attributes["last_name"]) {
      return -1;
    }
    if (b.attributes["last_name"] > a.attributes["last_name"]) {
      return 1;
    }
    return 0;
  } else {
    if (b.attributes[orderBy] < a.attributes[orderBy]) {
      return -1;
    }
    if (b.attributes[orderBy] > a.attributes[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
