import * as React from 'react';
import DraggableListItem from '../DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import AddIcon from '@mui/icons-material/Add';
import Button from '../../Common/Button';

const DraggableList = ({type, onDeleteItem, duplicateItem, items, onDragEnd, handleShowModal}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem onDeleteItem={onDeleteItem} duplicateItem={duplicateItem} item={item} index={index} key={item.id}/>
            ))}
            {provided.placeholder}

            {/* This is a conditional list item used to add new entries*/}
            {
              ['Module','Step'].includes(type) ?
                <Paper
                  className="mb-4"
                  style={{ color: "gray" }}
                >
                  <ListItem>
                    <Button onClick={handleShowModal}>
                      <ListItemAvatar>
                        <AddIcon/>
                      </ListItemAvatar>
                      <ListItemText primary={`Add Custom ${type}`}/>
                    </Button>
                  </ListItem>
                </Paper>
                : <></>
            }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableList;
