export const defaultValues = {
  name: "",
  approved_budget: "",
  anticipated_cost: "",
  category_breakdown: ""
}

export const formFields = [
  'name',
  'approved_budget_cents',
  'anticipated_cost_cents',
  'project',
  'category_breakdown'
]
