import React, {useState, useRef} from 'react'
import {Link, useLocation} from 'react-router-dom'
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import
{
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import {
  getComparator,
  stableSort,
  rankColor
} from '../Utils/index'
import {formatDate, statusColor} from 'utils/globals'
import AddFileLink from "../../BoxDropZone/AddFileLink";
import CreateGmailLabel from "../../Common/Google/CreateGmailLabel";
import MailIcon from "@mui/icons-material/Mail";

function List({
                items,
                projectName,
                gapi,
                setShowSendEmail,
                setShowForm,
                setObjectLabelId,
                setObjectLabelName,
                setDropZoneVisibility,
                setDropZoneObject,
                setSelectedLabels,
                columnsToHide
              }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');

  const location = useLocation()
  const pathName = useRef(location.pathname)


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSendEmailClick = (event) => {
    setSelectedLabels([
      event.currentTarget.dataset.labelid,
      event.currentTarget.dataset.projectglabelid
    ])
    setShowSendEmail(true)
    setShowForm(false)
    setDropZoneVisibility(false)
    setObjectLabelId(event.currentTarget.dataset.labelid)
    setObjectLabelName(event.currentTarget.name)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {
        items?.length > 0 ?
          <Box sx={{width: '100%', borderColor: 'grey'}}>
            <Paper sx={{width: '100%', mb: 2}}>
              <TableContainer>
                <Table stickyHeader>
                  <EnhancedTableHead
                    projectName={projectName}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    columnsToHide={columnsToHide}
                  />
                  <TableBody>
                    {stableSort(items, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((risk) => {
                        const ID = risk.id
                        if (risk.attributes) {
                          risk = risk.attributes
                        }

                        return (
                          <TableRow key={ID}>
                            <TableCell>
                          <span
                            className={`inline-block align-middle w-5 h-5 rounded-full mr-2 ${rankColor(risk["rank"])}`}></span>
                            </TableCell>
                            {!projectName || projectName === 'undefined' ?
                              null :
                              <TableCell>{risk["project"].name}</TableCell>
                            }
                            <TableCell align="left">{ID}</TableCell>
                            <TableCell>
                              <Link
                                to={`/risks/${ID}?label=${risk['gmail_labelid']}&label=${risk?.project?.['gmail_labelid']}`}
                                className="underline">
                                {risk["name"]}
                              </Link>
                            </TableCell>
                            <TableCell>{risk['user'] ? risk['user']["email"] : ""}</TableCell>
                            <TableCell>{formatDate(risk["due_date"])}</TableCell>
                            <TableCell>{risk["impact"]}</TableCell>
                            <TableCell>{risk["rank"]}</TableCell>
                            <TableCell>
                      <span
                        className={`inline-block align-middle w-5 h-5 rounded-full mr-2 ${statusColor(risk["status"])}`}></span>
                            </TableCell>
                            <TableCell>{risk["critical"] ? 'yes' : 'no'}</TableCell>
                            {
                              !pathName.current.includes('home') ?
                                <TableCell align='center'>
                                  {risk['gmail_labelid'] == undefined
                                  || risk['gmail_labelid'] == '' ?
                                    <CreateGmailLabel object={risk}
                                                      objectType="risk"
                                                      gapi={gapi}/> :
                                    <>
                                      <AddFileLink object={risk}
                                                   setShowSendEmail={setShowSendEmail}
                                                   setShowForm={setShowForm}
                                                   setDropZoneVisibility={setDropZoneVisibility}
                                                   setDropZoneObject={setDropZoneObject}/>
                                      <Link
                                        component="button"
                                        name={risk["name"]}
                                        variant="body2"
                                        className={(gapi?.auth != undefined && gapi?.auth?.getToken() != undefined) ? '' : 'text-gray-400'}
                                        style={{pointerEvents: (gapi?.auth != undefined && gapi?.auth?.getToken() != undefined) ? '' : 'none'}}
                                        data-labelid={risk['gmail_labelid']}
                                        data-projectglabelid={risk['project']['gmail_labelid']}
                                        onClick={handleSendEmailClick}
                                      >
                                        <div className="flex flex-col items-center mr-4">
                                          <MailIcon fontSize="small"/>
                                          <u>Send Email</u>
                                        </div>
                                      </Link>
                                    </>
                                  }
                                </TableCell>
                                : null
                            }
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/*<TablePagination*/}
              {/*  rowsPerPageOptions={[5, 10, 25, 50]}*/}
              {/*  component="div"*/}
              {/*  count={items.length}*/}
              {/*  rowsPerPage={rowsPerPage}*/}
              {/*  page={page}*/}
              {/*  onPageChange={handleChangePage}*/}
              {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
              {/*/>*/}
            </Paper>
          </Box>
          : <div>No Risks</div>
      }
    </>
  )
}

export default List
