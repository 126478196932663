import { TroubleshootRounded } from '@mui/icons-material'
import React from 'react'

/*
  Use in conjuction with ./Columns/{Wide|Narrow}

  ex: import { Wide as ColumnWide, Narrow as ColumnNarrow } from './Columns' #use absolute path
  ...
  <ContentTwoColumn>
    <ColumnWide>
      whatever content needs to be in the larger wide column
    </ColumnWide>
    <ColumnNarrow>
      whatever content needs to be in the smaller narrow column
    </ColumnNarrow>
  </ContentTwoColumn>
*/

function ContentTwoColumn({children}) {

  return(
    <>
      <div className='grid-container' > 
        {children}
      </div>
    </>
  )
}
// fixme: For now i am going to wrap for screens smaller than 1280px. This corresponds to xl.
//        We need to hide the menu on the left first. As soon as i have this fixed i will
//        revisit this fix and change xl to md
const WideColumn = ({children, orderFirst}) => {

  return(
    <>
      <div className={`grid-item-wide ${orderFirst ? "order-first" : "order-last"} xl:order-first`}>
        {children}
      </div>
    </>
  )
}

const NarrowColumn = ({children, hidden=false}) => {
  
  return(
    <>
      <div className={`${hidden ? "hidden" : "grid-item-narrow"}`}>
        {children}
      </div>
    </>
  )
}

export default ContentTwoColumn;
export { WideColumn, NarrowColumn }
