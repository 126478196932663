/*
/**
* Helpful Mirage Links
*
* https://miragejs.com/docs/getting-started/introduction/
* https://miragejs.com/docs/workflow-tips/
* https://dev.to/alexgurr/turning-a-react-app-into-a-pwa-with-offline-detection-service-workers-and-theming-142i
*
*/

import Rails from '@rails/ujs'
import {isNull} from 'lodash'
import {createServer, Model, JSONAPISerializer, Response} from 'miragejs'

export function makeMirageServer({environment = 'test'}) {
  return createServer({
    environment,
    serializers: {
      application: JSONAPISerializer,
    },
    models: {
      // project: Model,
      // risk: Model,
      // phases: Model,
      // components: Model,
      // steps: Model,
      // tasks: Model,
      //  contacts: Model,
      // schedule: Model,
      //  users: Model,
      // userRoles: Model,
      //  activities: Model,
    },

    routes() {

      this.passthrough('https://api.box.com/**')
      this.passthrough('https://upload.box.com/**')
      this.passthrough('https://upload.app.box.com/**')
      this.passthrough('https://stratos-staging.s3.us-east-1.amazonaws.com/**')
      this.passthrough('https://api.cloudinary.com/v1_1/**')
      this.passthrough('https://www.googleapis.com/calendar/**')
      this.passthrough('https://gmail.googleapis.com/gmail/**')
      this.passthrough('https://dev.stratos.picl.co.uk/**')

      // this.post('/api/activities', (schema, request) => {
      //   let attrs = JSON.parse(request.requestBody)
      //   const data = schema.db.activities.insert(attrs)
      //   return data
      // })

      // this.patch("/api/activities/:id", function(schema, request) {
      //   let id = request.params.id
      //   let changes = JSON.parse(request.requestBody)
      //   const data = schema.db.activities.update(id, changes)
      //   return data
      // })

      // this.get('/api/users', (schema, request) => {
      //   return schema.users.all()
      // })

      // this.get('/api/user_roles', (schema, request) => {
      //   return schema.userRoles.all()
      // })

      // this.get('/api/contacts', (schema, request) => {
      //   return schema.contacts.all()
      // })

      //  this.get('/api/tasks', (schema, request) => {
      //    return schema.tasks.all()
      //  })
      //
      // this.get('/api/tasks/critical', (schema, request) => {
      //   return schema.tasks.where({critical: true})
      // })
      //
      // this.get('/api/tasks/mine', (schema, request) => {
      //   return schema.tasks.where({assignee_id: 1})
      // })
      //
      //  this.get('/api/phases/:id/components/:id/steps/:id/tasks', (schema, request) => {
      //    return schema.tasks.where({stepId: request.params.id})
      //  })
      //
      //  this.post("/api/tasks", function(schema, request) {
      //    let attrs = JSON.parse(request.requestBody)
      //    const data = schema.db.tasks.insert(attrs)
      //    return data
      //  }
      //  )
      //
      //
      // this.get("/api/tasks/:id", (schema, request) => {
      //   return schema.tasks.find(request.params.id)
      // })
      //
      //
      // this.patch("/api/tasks/:id", (schema, request) => {
      //  let id = request.params.id
      //  let changes = JSON.parse(request.requestBody)
      //
      //  const data = schema.db.tasks.update(id, changes)
      //  return data
      // })


      //---Task routes complete---

      // this.get("/api/risks", (schema, request) => {
      //   return schema.risks.all()
      // })
      //
      // this.get("/api/risks/:id", (schema, request) => {
      //   return schema.risks.find(request.params.id)
      // })
      //
      // this.patch("/api/risks/:id", function(schema, request) {
      //   let id = request.params.id
      //   let changes = JSON.parse(request.requestBody)
      //
      //   const data = schema.db.risks.update(id, changes)
      //   return data
      // })
      //
      // this.post("/api/risks", function(schema, request) {
      //   let attrs = JSON.parse(request.requestBody)
      //   const data = schema.db.risks.insert(attrs)
      //   return data
      // }
      // )

      //---Risk routes complete---

      // this.get("/api/phases", (schema, request) => {
      //   return schema.phases.all()
      // })
      //
      // this.get("/api/phases/:id/components", (schema, request) => {
      //   return schema.components.where({phaseid: request.params.id})
      // })

      // this.get('/api/steps', (schema, request) => {
      //   return schema.steps.all()
      // })
      //
      // this.get("/api/phases/:id/components/:id/steps", (schema, request) => {
      //   return schema.steps.where({processComponentId: request.params.id})
      // })
      //
      // this.post("/api/steps", (schema, request) => {
      //   return schema.steps.create(JSON.parse(request.requestBody))
      // })

      // this.get('/api/schedule', (schema, request) => {
      //   return schema.schedule.all()
      // })


      // --- Process Map Routes Complete ---


      this.passthrough()
    },
    seeds(server) {

      //USERS -----
      // server.create('user', {
      //   id: 1,
      //   companyId: 1,
      //   contactId: 1,
      //   userRoleId: 1,
      //   email: 'owner@example.com',
      // }),

      // server.create('user', {
      //   id: 7,
      //   companyId: 1,
      //   contactId: 1,
      //   userRoleId: 1,
      //   email: 'owner2@example.com',
      // }),

      // server.create('user', {
      //   id: 2,
      //   companyId: 2,
      //   contactId: 2,
      //   userRoleId: 2,
      //   email: 'owner@example.com',
      // }),

      // server.create('user', {
      //   id: 3,
      //   companyId: 3,
      //   contactId: 3,
      //   userRoleId: 3,
      //   email: 'director@example.com',
      // }),

      // server.create('user', {
      //   id: 4,
      //   companyId: 1,
      //   contactId: 4,
      //   userRoleId: 4,
      //   email: 'admin@example.com',
      // }),

      // server.create('user', {
      //   id: 5,
      //   companyId: 2,
      //   contactId: 5,
      //   userRoleId: 5,
      //   email: 'admin1@example.com',
      // }),

      //USER ROLES -------
      // server.create('user_role', {
      //   id: 1,
      //   name: "Owner (Client)",
      //   company: ""
      // }),
      //   server.create('user_role', {
      //     id: 2,
      //     name: "Director",
      //     company: ""
      //   }),
      //   server.create('user_role', {
      //     id: 3,
      //     name: "Consultant",
      //     company: ""
      //   }),
      //   server.create('user_role', {
      //     id: 4,
      //     name: "Architect / Engineer",
      //     company: ""
      //   }),
      //   server.create('user_role', {
      //     id: 5,
      //     name: "Contractor",
      //     company: ""
      //   })

      //CONTACTS ------
      // server.create('contact', {
      //   id: 1,
      //   firstName: 'Julia',
      //   lastName: 'Iwinski',
      //   title: 'Owner',
      //   phone: '111-111-1111'
      // }),

      // server.create('contact', {
      //   id: 2,
      //   firstName: 'Ivan',
      //   lastName: 'Marino',
      //   title: 'Owner',
      //   phone: '111-111-1111'
      // }),

      // server.create('contact', {
      //   id: 3,
      //   firstName: 'John',
      //   lastName: 'Doe',
      //   title: 'Director',
      //   phone: '222-222-2222'
      // }),

      // server.create('contact', {
      //   id: 4,
      //   firstName: 'JG',
      //   lastName: 'Iwin',
      //   title: 'Director',
      //   phone: '222-222-2222'
      // }),

      // server.create('contact', {
      //   id: 5,
      //   firstName: 'ZT',
      //   lastName: 'Marco',
      //   title: 'Consultant',
      //   phone: '333-333-3333'
      // }),

      // server.create('contact', {
      //   id: 6,
      //   firstName: 'ABC',
      //   lastName: 'Johnson',
      //   title: 'Consultant',
      //   phone: '333-333-3333'
      // }),

      //ACTIVITIES ----
      // server.create('activity', {
      //   activityId: 1,
      //   name: 'Project Definition',
      //   startsAt: "3/22/22",
      //   endsAt: "5/12/22",
      //   extendedUntil: "",
      //   delayedUntil: "5/21/22",
      //   riskId: 1
      // }),

      // server.create('activity', {
      //   activityId: 2,
      //   name: 'Design',
      //   startsAt: "12/30/22",
      //   endsAt: "3/12/23",
      //   extendedUntil: null,
      //   delayedUntil:  "4/15/23",
      //   riskId: 2
      // }),

      // server.create('activity', {
      //   activityId: 3,
      //   name: "Bid / Award",
      //   startsAt: "6/2/22",
      //   endsAt: "11/12/22",
      //   extendedUntil: null,
      //   delayedUntil:  "12/12/22",
      //   riskId: 1
      // }),

      // server.create('activity', {
      //   activityId: 4,
      //   name: "Pre-Construction",
      //   startsAt: "11/2/22",
      //   endsAt: "1/12/23",
      //   extendedUntil: "2/2/23",
      //   delayedUntil:  "12/12/22",
      //   riskId: 1
      // }),

      // server.create('activity', {
      //   activityId: 5,
      //   name: "Construction",
      //   startsAt: "3/2/23",
      //   endsAt: "7/4/23",
      //   extendedUntil: "",
      //   delayedUntil:  "",
      //   riskId: null
      // })

      //--- Risks ---
      // server.create('risk', {
      //   project_id: 1,
      //   id: 1,
      //   name: 'Steel Shortage',
      //   description: 'Risk 1 description',
      //   due_date: '07/28/2033',
      //   assigned: 'admin@example.com',
      //   impact: 'Cost',
      //   rank: 'High'
      // }),

      // -------- PHASES ---------
      // server.create('phase', {
      //   id: 10,
      //   name: 'PREDESIGN',
      //   value: 'pre_design',
      //   project_id: 1
      // })
      // server.create('phase', {
      //   id: 20,
      //   name: 'DESIGN TEAM SELECTION',
      //   value: 'design_team_selection',
      //   project_id: 1
      // })
      // server.create('phase', {
      //   id: 30,
      //   name: 'DESIGN ACTIVITIES',
      //   value: 'design_activities',
      //   project_id: 1
      // })
      // server.create('phase', {
      //   id: 40,
      //   name: 'NON-DESIGN ACTIVITIES',
      //   value: 'non_design_activities',
      //   project_id: 1
      // })
      // server.create('phase', {
      //   id: 50,
      //   name: 'CONTRACTOR SELECTION',
      //   value: 'contractor_selection',
      //   project_id: 1
      // })
      // server.create('phase', {
      //   id: 60,
      //   name: 'CONSTRUCTION PHASE',
      //   value: 'construction_phase',
      //   project_id: 1
      // })

      //
      //
      //
      // ALL PHASES ARE COMPLETE
      //
      //
      //

// -------- MODS ---------
// PREDESIGN > components/mods --- COMPLETE
//       server.create('component', {
//         id: 1010,
//         uid: 1010,
//         name: 'Need Identification',
//         phaseid: 10
//       })
//
//       server.create('component', {
//         id: 1020,
//         uid: 1020,
//         name: 'Need Validation',
//         phaseid: 10
//       })
//
//       server.create('component', {
//         id: 1030,
//         uid: 1030,
//         name: 'Project Definition',
//         phaseid: 10
//       })

//DESIGN TEAM SELECTION > components/mods --- COMPLETE
//       server.create('component', {
//         id: 2010,
//         uid: 2010,
//         name: 'IDENTIFY DESIGN TEAM & CONTRACTUAL GROUPING',
//         phaseid: 20
//       })
//
//       server.create('component', {
//         id: 2020,
//         uid: 2020,
//         name: 'TEAM MEMBER SELECTION PROCESS',
//         phaseid: 20
//       })
//       server.create('component', {
//         id: 2030,
//         uid: 2030,
//         name: 'FEE NEGOTIATION FOR EACH TEAM MEMBER',
//         phaseid: 20
//       })

// DESIGN ACTIVITIES > components/mods --- COMPLETE
//       server.create('component', {
//         id: 3010,
//         uid: 3010,
//         name: 'INITIAL DESIGN TEAM MEETING',
//         phaseid: 30
//       })
//
//       server.create('component', {
//         id: 3020,
//         uid: 3020,
//         name: 'SCHEMATIC DESIGN',
//         phaseid: 30
//       })
//
//       server.create('component', {
//         id: 3030,
//         uid: 3030,
//         name: 'SD REVIEW AND APPROVAL',
//         phaseid: 30
//       })
//
//       server.create('component', {
//         id: 3040,
//         uid: 3040,
//         name: 'DESIGN DEVELOPMENT',
//         phaseid: 30
//       })
//
//       server.create('component', {
//         id: 3050,
//         uid: 3050,
//         name: 'DD REVIEW AND APPROVAL',
//         phaseid: 30
//       })
//
//       server.create('component', {
//         id: 3060,
//         uid: 3060,
//         name: 'CONSTRUCTION DOCUMENTS',
//         phaseid: 30
//       })
//
//       server.create('component', {
//         id: 3070,
//         uid: 3070,
//         name: 'CONSTRUCTION DOCUMENT REVIEW AND APPROVAL',
//         phaseid: 30
//       })

// NON-DESIGN ACTIVITIES > components/mods --- COMPLETE
//       server.create('component', {
//         id: 4010,
//         uid: 4010,
//         name: 'PROJECT ENVIRONMENT OVERVIEW',
//         phaseid: 40
//       })
//
//       server.create('component', {
//         id: 4020,
//         uid: 4020,
//         name: 'EXTERNAL PARTICIPANTS SELECTION OF MANAGEMENT',
//         phaseid: 40
//       })
//
//       server.create('component', {
//         id: 4030,
//         uid: 4030,
//         name: 'OWNERS INFLUENCERS',
//         phaseid: 40
//       })
//
//       server.create('component', {
//         id: 4040,
//         uid: 4040,
//         name: 'OTHER EXTERNAL INFLUENCERS',
//         phaseid: 40
//       })

// CONTRACTOR SELECTION > components/mods --- COMPLETE
//       server.create('component', {
//         id: 5010,
//         uid: 5010,
//         name: 'SELECTION OF BIDDERS',
//         phaseid: 50
//       })
//
//       server.create('component', {
//         id: 5015,
//         uid: 5015,
//         name: 'CONTRACTOR BIDDING',
//         phaseid: 50
//       })
//
//       server.create('component', {
//         id: 5020,
//         uid: 5020,
//         name: 'CONTRACT NEGOTIATIONS',
//         phaseid: 50
//       })
//
//       server.create('component', {
//         id: 5030,
//         uid: 5030,
//         name: 'PERMITS',
//         phaseid: 50
//       })
//
//       server.create('component', {
//         id: 5040,
//         uid: 5040,
//         name: 'INITIAL PROJECT MEETING',
//         phaseid: 50
//       })

//CONSTRUCTION PHASE > components/mods --- COMPLETE

      // server.create('component', {
      //   id: 6010,
      //   uid: 6010,
      //   name: 'POST-AWARD PRE-CONSTRUCTION',
      //   phaseid: 60
      // })
      //
      // server.create('component', {
      //   id: 6020,
      //   uid: 6020,
      //   name: 'ONGOING PROJECT MANAGEMENT',
      //   phaseid: 60
      // })
      //
      // server.create('component', {
      //   id: 6030,
      //   uid: 6030,
      //   name: 'PROJECT CLOSEOUT AND OCCUPANCY',
      //   phaseid: 60
      // })

      //
      //
      //
      // ALL COMPONENTS ARE COMPLETE
      //
      //
      //


// -------- STEPS ---------     
// step for Mod: 1010 Need Identification --- COMPLETE
//      server.create('step', {
//        id: 101010,
//        name: 'Need Identification',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1010,
//      })
//
// //steps for Mod: 1020 Need Validation --- COMPLETE
//      server.create('step', {
//        id: 102010,
//        name: 'Assemble Owner Need Validation Team',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1020,
//      })
//
//      server.create('step', {
//        id: 102020,
//        name: 'Owner Need Assessment',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1020,
//      })
//
// // steps for Mod: 1030 Project Definition --- COMPLETE
//      server.create('step', {
//        id: 103010,
//        name: 'Assemble Project Definition Team',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1030,
//      })
//
//      server.create('step', {
//        id: 103020,
//        name: 'Project Modeling (Identify)' ,
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1030,
//      })
//
//      server.create('step', {
//        id: 103025,
//        name: 'Project Modeling (Document)',
//        starts_at: ' 31/12/22',
//        ends_at: ' 31/12/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1030,
//      })
//
//
//      server.create('step', {
//        id: 103030,
//        name: 'Definition of Optimum Scope',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1030,
//      })
//
//      server.create('step', {
//        id: 103040,
//        name: 'Approve Project Definition',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 1030,
//      })
//
//      // ALL STEPS AND COMPONENTS FOR PREDESIGN ARE ADDED
//
// // steps for Mod: 2010 Identify Design Team and Contractual Grouping --- COMPLETE
//      server.create('step', {
//        id: 201010,
//        name: 'Identify Required Consultants',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2010,
//      })
//
//      server.create('step', {
//        id: 201020,
//        name: 'Identify Required Contractor Participation',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2010,
//      })
//
//      server.create('step', {
//        id: 201030,
//        name: 'Delivery Systems Contractual Groupings',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2010,
//      })
//
// // steps for Mod: 2020 Team Member Selection Process --- COMPLETE
//      server.create('step', {
//        id: 202010,
//        name: 'Estabish Criteria',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2020,
//      })
//
//      server.create('step', {
//        id: 202020,
//        name: 'Develop Request for Qualifications',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2020,
//      })
//
//      server.create('step', {
//        id: 202030,
//        name: 'Advertise/Issue Request for Qualifications',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2020,
//      })
//
//      server.create('step', {
//        id: 202040,
//        name: 'Ranking Process',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2020,
//      })
//
//      server.create('step', {
//        id: 202050,
//        name: 'Interview Process',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2020,
//      })
//
// // steps for Mod: 2030 Fee Negotiation for Each Team Member --- COMPLETE
//      server.create('step', {
//        id: 203010,
//        name: 'Address Major Issues',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2030,
//      })
//
//      server.create('step', {
//        id: 203020,
//        name: 'Entering into Fee Agreement',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2030,
//      })
//
//      server.create('step', {
//        id: 203030,
//        name: 'Legal Review',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2030,
//      })
//
//      server.create('step', {
//        id: 203040,
//        name: 'Contract Finalization',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2030,
//      })
//
//      server.create('step', {
//        id: 203050,
//        name: 'Contract Signature',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 2030,
//      })
//
//      // ALL STEPS AND COMPONENTS FOR DESIGN TEAM SELECTION ARE ADDED
//
// // steps for Mod: 3010 Intial Design Team Meeting --- COMPLETE
//
//      server.create('step', {
//        id: 301010,
//        name: 'Restate Owner Project Goals',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3010,
//      })
//
//      server.create('step', {
//        id: 301020,
//        name: 'Focus on Risk',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3010,
//      })
//
//      server.create('step', {
//        id: 301030,
//        name: 'Overview of Project Management Plan',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3010,
//      })
//
//      server.create('step', {
//        id: 301040,
//        name: 'Review Communication Process',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3010,
//      })
//
//      server.create('step', {
//        id: 301050,
//        name: 'Review Critical Design Controllers',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3010,
//      })
//
//      server.create('step', {
//        id: 301060,
//        name: 'Review Design Influencers',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3010,
//      })
//
// // steps for Mod: 3020 Design Team Selection --- COMPLETE
//
//      server.create('step', {
//        id: 302010,
//        name: 'Ongoing Design Meetings',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3020,
//      })
//
//      server.create('step', {
//        id: 302020,
//        name: 'Ongoing Project Control',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3020,
//      })
//
//      server.create('step', {
//       id: 302025,
//       name: ' ',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 3020,
//     })
//
// // steps for Mod: 3030 SD Review and Approval --- COMPLETE
//      server.create('step', {
//        id: 303010,
//        name: 'Design Submittal Review',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3030,
//      })
//
//      server.create('step', {
//        id: 303020,
//        name: 'Final Review Meeting With Owner Signoff',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3030,
//      })
//
//      server.create('step', {
//        id: 303030,
//        name: 'Notice to Proceed with Next Phase of Design',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3030,
//      })
//
// // steps for Mod: 3040 Design Development --- COMPLETE
//
//      server.create('step', {
//        id: 304010,
//        name: 'Ongoing Design Meetings',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3040,
//      })
//
//      server.create('step', {
//        id: 304020,
//        name: 'Ongoing Project Control',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3040,
//      })
//
//      server.create('step', {
//       id: 304025,
//       name: ' ',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 3040,
//     })
//
// // steps for Mod: 3050 Review and Approval --- COMPLETE
//
//      server.create('step', {
//        id: 305010,
//        name: 'Design Submittal Review',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3050,
//      })
//
//      server.create('step', {
//        id: 305020,
//        name: 'Final Review Meeting With Owner Signoff',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3050,
//      })
//
//      server.create('step', {
//        id: 305030,
//        name: 'Notice to Proceed with Next Phase of Design',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3050,
//      })
//
// // steps for Mod: 3060 Construction Documents --- COMPLETE
//
//      server.create('step', {
//        id: 306010,
//        name: 'Ongoing Design Meetings',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3060,
//      })
//
//      server.create('step', {
//        id: 306020,
//        name: 'Ongoing Project Control',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3060,
//      })
//
//      server.create('step', {
//       id: 306025,
//       name: ' ',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 3060,
//     })
//
// // steps for Mod: 3070 CD Review and Approval --- COMPLETE
//
//      server.create('step', {
//        id: 307010,
//        name: 'Design Submittal Review',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3070,
//      })
//
//      server.create('step', {
//        id: 307020,
//        name: 'Final Review Meeting With Owner Signoff',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3070,
//      })
//
//      server.create('step', {
//        id: 307030,
//        name: 'Notice to Proceed with Next Phase of Design',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 3070,
//      })
//
// // steps for Mod: 4010 Project Environment Overview --- COMPLETE
//
//      server.create('step', {
//        id: 401010,
//        name: 'Genderal Economic and Market Condition Overview',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4010,
//      })
//
//      server.create('step', {
//        id: 401020,
//        name: 'Recognition/Monitoring of Unforeseeable Acts of God. Labor Strikes etc',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4010,
//      })
//
// // steps for Mod: 4020 External Participants-Selection of Management --- COMPLETE
//
//      server.create('step', {
//        id: 402010,
//        name: 'Non-Construction Participants',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4020,
//      })
//
//      server.create('step', {
//        id: 402020,
//        name: 'Outside Consultants',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4020,
//      })
//
// // steps for Mod: 4030 Owner's Influencers --- COMPLETE
//
//      server.create('step', {
//        id: 403010,
//        name: 'Other Project Influencers (Staff, Finance, Legal, etc)',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4030,
//      })
//
//      server.create('step', {
//        id: 403020,
//        name: 'Government',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4030,
//      })
//
//      server.create('step', {
//        id: 403030,
//        name: 'Community',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4030,
//      })
//
// // steps for Mod: 4040 Other External Influencers --- COMPLETE
//
//      server.create('step', {
//        id: 404010,
//        name: 'Adjacent Owners',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4040,
//      })
//
//      server.create('step', {
//        id: 404020,
//        name: 'Utilities',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 4040,
//      })
//
// // steps for Mod: 5010 Selection of Bidders --- COMPLETE
//
//      server.create('step', {
//        id: 501010,
//        name: 'Establish Criteria',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5010,
//      })
//
//      server.create('step', {
//        id: 501020,
//        name: 'Develop Request for Qualifications',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5010,
//      })
//
//      server.create('step', {
//        id: 501030,
//        name: 'Advertise and Issue Request for Qualifications',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5010,
//      })
//
//      server.create('step', {
//        id: 501040,
//        name: 'Ranking Process',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5010,
//      })
//
//      server.create('step', {
//        id: 501050,
//        name: 'Interview Process',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5010,
//      })
//
//      server.create('step', {
//        id: 501060,
//        name: 'Select Qualified Bidders',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5010,
//      })
//
// // steps for Mod: 5015 Contractor Bidding --- COMPLETE
//
//      server.create('step', {
//        id: 501510,
//        name: 'Issue Bid Documents',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5015,
//      })
//
//      server.create('step', {
//        id: 501520,
//        name: 'Pre-Bid Meeting',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5015,
//      })
//
//      server.create('step', {
//        id: 501530,
//        name: 'Addenda',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5015,
//      })
//
//      server.create('step', {
//        id: 501540,
//        name: 'Bids Due',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5015,
//      })
//
//      server.create('step', {
//        id: 501550,
//        name: 'Analyze Bids',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5015,
//      })
//
// // steps for Mod: 5020 Contractor Negotiations --- COMPLETE
//
//      server.create('step', {
//        id: 502010,
//        name: 'Scope Review',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5020,
//      })
//
//      server.create('step', {
//        id: 502020,
//        name: 'Negotiation Final Costs',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5020,
//      })
//
//      server.create('step', {
//        id: 502030,
//        name: 'Prepare Contract',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5020,
//      })
//
//      server.create('step', {
//        id: 502040,
//        name: 'Contract Review',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5020,
//      })
//
//      server.create('step', {
//        id: 502050,
//        name: 'Finalize and Execute Contract',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5020,
//      })
//
// // steps for Mod: 5030 Permits --- COMPLETE
//
//      server.create('step', {
//        id: 503010,
//        name: 'Permit Application',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5030,
//      })
//
//      server.create('step', {
//        id: 503020,
//        name: 'Permit Application Format',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5030,
//      })
//
//      server.create('step', {
//        id: 503030,
//        name: 'Permit Denial',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5030,
//      })
//
//      server.create('step', {
//        id: 503040,
//        name: 'Expediting the Permit Process',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5030,
//      })
//
//      server.create('step', {
//        id: 503050,
//        name: 'Obtain Permit',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5030,
//      })
//
// // steps for Mod: 5040 Initial Project Meeting --- COMPLETE
//
//      server.create('step', {
//        id: 504010,
//        name: 'Initial Project Meeting',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 5040,
//      })
//
// // steps for Mod: 6010 Post-Award Pre-Construction --- COMPLETE
//
//      server.create('step', {
//        id: 601010,
//        name: 'Mobilization',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6010,
//      })
//
//      server.create('step', {
//        id: 601020,
//        name: 'Subcontractor Selection and Approval',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6010,
//      })
//
//      server.create('step', {
//       id: 601025,
//       name: '',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 6010,
//     })
//
//      server.create('step', {
//        id: 601030,
//        name: 'Commence Construction',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6010,
//      })
//
// // steps for Mod: 6020 Ongoing Project Management --- COMPLETE
//
//      server.create('step', {
//        id: 602010,
//        name: 'Interim Project Monitoring',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6020,
//      })
//
//      server.create('step', {
//       id: 602015,
//       name: 'Interim Meetings, Walkthroughs, & Reporting',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 6020,
//     })
//
//     server.create('step', {
//        id: 602020,
//        name: 'Payment Process',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6020,
//      })
//
//      server.create('step', {
//       id: 602025,
//       name: 'Process Payment',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 6020,
//     })
//
//
//      server.create('step', {
//        id: 602030,
//        name: 'Change Order Process (Identify)',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6020,
//      })
//
//       server.create('step', {
//         id: 602035,
//         name: '',
//         starts_at: '11/11/22',
//         ends_at: '12/11/22',
//         completed_at: null,
//         updated_at: null,
//         user_role_id: 10,
//         processComponentId: 6020,
//       })
//
//       server.create('step', {
//         id: 602036,
//         name: 'Change Order of Process',
//         starts_at: '11/11/22',
//         ends_at: '12/11/22',
//         completed_at: null,
//         updated_at: null,
//         user_role_id: 10,
//         processComponentId: 6020,
//       })
//
//       server.create('step', {
//         id: 602037,
//         name: '',
//         starts_at: '11/11/22',
//         ends_at: '12/11/22',
//         completed_at: null,
//         updated_at: null,
//         user_role_id: 10,
//         processComponentId: 6020,
//       })
//
//
// // steps for Mod: 6030: Project Closeout and Occupancy --- COMPLETE
//
//      server.create('step', {
//        id: 603010,
//        name: '',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6030,
//      })
//      server.create('step', {
//       id: 603011,
//       name: '',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 6030,
//     })
//     server.create('step', {
//       id: 603012,
//       name: 'Project Closeout',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 6030,
//     })
//     server.create('step', {
//       id: 603013,
//       name: '',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 6030,
//     })
//     server.create('step', {
//       id: 603014,
//       name: '',
//       starts_at: '11/11/22',
//       ends_at: '12/11/22',
//       completed_at: null,
//       updated_at: null,
//       user_role_id: 10,
//       processComponentId: 6030,
//     })
//
//      server.create('step', {
//        id: 603020,
//        name: 'Occupancy',
//        starts_at: '11/11/22',
//        ends_at: '12/11/22',
//        completed_at: null,
//        updated_at: null,
//        user_role_id: 10,
//        processComponentId: 6030,
//      })
//
//
// Tasks for Step: 101010 Need Identification > Mod: 1010 Need Identification
// server.create('task',{
//  uid: 10101001,
//  Status: 3, Shape: 1,
//  Impact: 2,
//  Ranking: 2,
//  name: 'Identify Project Need',
//  due_date: '11/22/33',
//  critical: true,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 101010,
//  step_name: 'Need Identification',
//  template_file_id: 'file1',
//  task_manager: 1,
//  previousTasks: 0,
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 1,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10101002,
//  Status: 3, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Appoint OSG',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 101010,
//  step_name: 'Need Identification',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10101001,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// // Tasks for Step: 102010 Assemble Owner Need Validation Team > Mod: 1020 Need Validation
// server.create('task',{
//  uid: 10201001,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Assemble Owner Need Validation Team',
//  //yes this task name is the same as its step name ^^
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 102010,
//  step_name: 'Assemble Owner Need Validation Team',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: 0,
// created_at: 'NULL',
// updated_at: 'NULL',
// project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// //Tasks for Step: 102020 Owner Need Assessment  > Mod: 1020 Need Validation
// server.create('task',{
//  uid: 10202001,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Review source of need perception',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 5,
//  assignee: 'Contractor',
//  stepId: 102020,
//  step_name: 'Owner Need Assessment',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10201001,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10202002,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Test need addressing decision factors',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 5,
//  assignee: 'Contractor',
//  stepId: 102020,
//  step_name: 'Owner Need Assessment',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//       parentId: 10202001,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
// created_at: 'NULL',
// updated_at: 'NULL',
// project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10202003,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Determine & weight decision factors',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 5,
//  assignee: 'Contractor',
//  stepId: 102020,
//  step_name: 'Owner Need Assessment',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//      parentId: 10202002,
//      startAnchor: "bottom",
//      endAnchor: "top"
//
//    },
//   ],
// created_at: 'NULL',
// updated_at: 'NULL',
// project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10202004,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Establish a need validation benchmark',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 5,
//  assignee: 'Contractor',
//  stepId: 102020,
//  step_name: 'Owner Need Assessment',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//      parentId: 10202003,
//      startAnchor: "bottom",
//      endAnchor: "top"
//
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
// task_approved_by: 2,
// phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10202005,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Recommend for or against preparation of project definition',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 5,
//  assignee: 'Contractor',
//  stepId: 102020,
//  step_name: 'Owner Need Assessment',
//  template_file_id: 'file1',
//   previousTasks: [
//    {
//       parentId: 10202004,
//       startAnchor: "bottom",
//       endAnchor: "top"
//
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_manager: 3,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10202006,
//  Status: 3, Shape: 2,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Authorize project definition',
//  due_date: '11/22/33',
//  critical: true,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 102020,
//  step_name: 'Owner Need Assessment',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//      parentId:10202005,
//      startAnchor: "right",
//      endAnchor: "right"
//    },
//   ],
// created_at: 'NULL',
// updated_at: 'NULL',
// project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// // Tasks for Step: 103010 Assemble Project Definition Team > Mod: 1030 Project Definition
// server.create('task',{
//  uid: 10301001,
//  Status: 3, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Assemble Internal Project Definition Team',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 103010,
//  step_name: 'Assemble Project Definition Team',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10301002,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10301002,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Identify expertise required for project definition',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103010,
//  step_name: 'Assemble Project Definition Team',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: 0,
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10301003,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Qualify & retain Outside Team Members',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103010,
//  step_name: 'Assemble Project Definition Team',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//       parentId: 10301002,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// // Tasks for Step: 103020 Project Modeling (Identify) > Mod: 1030 Project Definition
// server.create('task',{
//  uid: 10302001,
//  Status: 1,
//  shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Review & Accept project definition',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 103020,
//  step_name: 'Project Modeling (Identify)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10301001,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//       parentId: 10302507,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10302002,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Identify Needs',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103020,
//  step_name: 'Project Modeling (Identify)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//      parentId: 10302001,
//      startAnchor: "bottom",
//      endAnchor: "top"
//   },
//   {
//      parentId: 10302507,
//      startAnchor: "left",
//      endAnchor: "right"
//    },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10302003,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Feasibility',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103020,
//  step_name: 'Project Modeling (Identify)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//      parentId: 10302002,
//      startAnchor: "bottom",
//      endAnchor: "top"
//   },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10302004,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Identify external project influencers',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103020,
//  step_name: 'Project Modeling (identify)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//      parentId: 10302003,
//      startAnchor: "bottom",
//      endAnchor: "top"
//   }
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10302005,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Identify non-design & construction project participants',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103020,
//  step_name: 'Project Modeling (Identify)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//      parentId: 10302004,
//      startAnchor: "bottom",
//      endAnchor: "top"
//   },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10302006,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Identify project intangibles & logistical issues',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103020,
//  step_name: 'Project Modeling (Identify)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10302005,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//    {
//       parentId: 10302512,
//       startAnchor: "left",
//       endAnchor: "right"
//    },
//    {
//     parentId: 10302013,
//     startAnchor: "top",
//     endAnchor: "bottom"
//  },
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//   uid: 10302013,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Perform Project Programming & Feasibility Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 3,
//   assignee: 'Consultant',
//   stepId: 103020,
//   step_name: 'Project Modeling (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:[
//     {
//       parentId: 10301003,
//       startAnchor: "bottom",
//       endAnchor: "left"
//     },
//     {
//       parentId: 10302014,
//       startAnchor: "top",
//       endAnchor: "bottom"
//     },
//    ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'pre_design'
//  })
//
//  server.create('task',{
//   uid: 10302014,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Perform Project Programming & Feasibility Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 4,
//   assignee: 'Architect / Engineer',
//   stepId: 103020,
//   step_name: 'Project Modeling (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: [
//     {
//       parentId: 10301003,
//       startAnchor: "bottom",
//       endAnchor: "left"
//     },
//    ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'pre_design'
//  })
//
// // Tasks for Step: 103025 Project Modeling (Document) > Mod: 1030 Project Definition
// server.create('task',{
//  uid: 10302507,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Create Documentation',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103025,
//  step_name: 'Project Modeling (Document)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//       parentId: 10302002,
//       startAnchor: "right",
//       endAnchor: "left"
//    }
//   ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10302508,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Cost Model',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103025,
//  step_name: 'Project Modeling (Document)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10302507,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    }
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10302509,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Schedule',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103025,
//  step_name: 'Project Modeling (Document)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10302508,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    }
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10302510,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Risk Strategy',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103025,
//  step_name: 'Project Modeling (Document)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//       parentId: 10302509,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    }
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10302511,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Delivery System',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103025,
//  step_name: 'Project Modeling (Document)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10302510,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    }
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10302512,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Support Project Definition Activities as needed',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 3,
//  assignee: 'Consultant',
//  stepId: 103025,
//  step_name: 'Project Modeling (Document)',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//       parentId: 10302006,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//       parentId: 10302511,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// //Tasks for Step: 103030 Definition of Optimum Scope > Mod Project Definition
// server.create('task',{
//  uid: 10303001,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Document project definition',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 2,
//  assignee: 'Director',
//  stepId: 103030,
//  step_name: 'Definition of Optimum Scope',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10302001,
//       startAnchor: "right",
//       endAnchor: "top"
//    },
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// //Tasks for Step: 103040 Approve Project Definition > Mod Project Definition
// server.create('task',{
//  uid: 10304001,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Review all information and reports by Owner',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 103040,
//  step_name: 'Approve Project Definition',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks:[
//    {
//       parentId: 10303001,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
// server.create('task',{
//  uid: 10304002,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Accept that the goals will fulfill the identified need',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 103040,
//  step_name: 'Approve Project Definition',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10304001,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10304003,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Decide to implement the project',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 103040,
//  step_name: 'Approve Project Definition',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10304002,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//
// server.create('task',{
//  uid: 10304004,
//  Status: 1, Shape: 1,
//  Impact: 1,
//  Ranking: 1,
//  name: 'Send notification to proceed',
//  due_date: '11/22/33',
//  critical: false,
//  assignee_id: 1,
//  assignee: 'Owner (Client)',
//  stepId: 103040,
//  step_name: 'Approve Project Definition',
//  template_file_id: 'file1',
//  task_manager: 3,
//  previousTasks: [
//    {
//       parentId: 10304003,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//  ],
//  created_at: 'NULL',
//  updated_at: 'NULL',
//  project_id: 1,
//  task_approved_by: 2,
//  phase: 'pre_design'
// })
//
//  //Tasks for Step: 201010 Approve Project Definition > Mod Project Definition
//  server.create('task',{
//   uid: 20101001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Required Consultants',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 201010,
//   step_name: 'Identify Required Consultants',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//Identify Necessary Contractors for Delivery System
//  server.create('task',{
//   uid: 20102001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Necessary Contractors for Delivery System',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 201020,
//   step_name: 'Identify Required Contractor Participation',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20101001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20103001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify other specialist organization for delivery system',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 201030,
//   step_name: 'Delivery Systems Contractual Groupings',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20102001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
// //Tasks for Step: 202010 Approve Project Definition > Mod Project Definition
// server.create('task',{
//   uid: 20201001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review and Approve Contractual Grouping & Selection Criteria',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 202010,
//   step_name: 'Establish Criteria',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20201002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20201002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Selection Criteria',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202010,
//   step_name: 'Establish Criteria',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20202001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Approve Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 202020,
//   step_name: 'Develop Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20202002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20202002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Develop Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202020,
//   step_name: 'Develop Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20201001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20203000,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Note: Private vs. Public',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202030,
//   step_name: 'Advertise & Issue Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//
//  server.create('task',{
//   uid: 20203001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Advertise for Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202030,
//   step_name: 'Advertise & Issue Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20202001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20203002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Issue Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202030,
//   step_name: 'Advertise & Issue Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20203001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20204001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ranking Review Meeting with Owner',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 202040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20204003,
//        startAnchor: "right",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//
//
//  server.create('task',{
//   uid: 20204002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive RFQ’s',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20203002,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20204003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Summarize RFQ’s',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20204002,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20204004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schedule Interviews & notify short listed',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20204001,
//        startAnchor: "left",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20204005,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Send notification letter to non-selected respondents as to the status',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20204004,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20205001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct interviews',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 202050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20205004,
//        startAnchor: "right",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20205002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Select Design Team Member',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 202050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20205001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20205003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Send notification to all interviewees',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 202050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20205002,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20205004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Prepare for interviews',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 202050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20204004,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
// // Steps for 020-030 "Fee Negotiation for Each Team Member"
//
// server.create('task',{
//   uid: 20301001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify draft contract including special conditions',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203010,
//   step_name: 'Address Major Issues',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20301002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Outline scope definition statement',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203010,
//   step_name: 'Address Major Issues',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20301003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Finalize deliverables under the agreement',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203010,
//   step_name: 'Address Major Issues',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20301004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'List any exclusions',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203010,
//   step_name: 'Address Major Issues',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20302001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & review proposals (including comments on contract)',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203020,
//   step_name: 'Entering into Fee Agreement',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20301001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//        parentId: 20301002,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//        parentId: 20301003,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//        parentId: 20301004,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20303001,
//   Status: 1,
//   shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Owner to agree on contract terms with design team member?',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 203030,
//   step_name: 'Legal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20303002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20303002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Negotiate contract including fees',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203030,
//   step_name: 'Legal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20303001,
//        startAnchor: "left",
//        endAnchor: "left"
//     },
//     {
//       parentId: 20302001,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20304001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Forward final contract to consultant',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203040,
//   step_name: 'Contract Finalization',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20303001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//  server.create('task',{
//   uid: 20305001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Sign contract by all parties',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 203050,
//   step_name: 'Contract Signature',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 20304001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_team_selection'
//  })
//
//
// //  3010 "Initial Design Team Meeting"
//
//  server.create('task',{
//   uid: 30101001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Confirm Owner Project Goals',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 301010,
//   step_name: 'Restate Owner Project Goals',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30101002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30101002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Define for the design team the criteria for a successful project',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301010,
//   step_name: 'Restate Owner Project Goals',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30102001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Hold risk management meeting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301020,
//   step_name: 'Focus on Risk',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30101001,
//        startAnchor: "right",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30103001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Establish the team and explain roles',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301030,
//   step_name: 'Overview of Project Management Plan',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30102001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30103002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Discuss and agree on master schedule & deliverable',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301030,
//   step_name: 'Overview of Project Management Plan',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30103001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
//  server.create('task',{
//   uid: 30104001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Establish the project communication method',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301040,
//   step_name: 'Review Communication Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30103001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30104002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify & establish key meetings',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301040,
//   step_name: 'Review Communication Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30104001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30105001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review the critical design controllers',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301050,
//   step_name: 'Review Critical Design Controllers',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30104002,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30106001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review critical design influencers',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 301060,
//   step_name: 'Review Design Influencers',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30105001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
// //  3020 "Schematic Design (SD)"
//
// server.create('task',{
//   uid: 30201001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct and document regular project design meeting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 302010,
//   step_name: 'Ongoing Design Meetings',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
//  server.create('task',{
//   uid: 30202001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Cost Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 302020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30201001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30202002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schedule Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 302020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30201001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30202003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Risk Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 302020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30201001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30202004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Value Engineering',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 302020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30201001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30202005,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 302020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30202503,
//        startAnchor: "left",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30202501,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review Interim Project Update',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 302025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30202502,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30202502,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Interim Project Update',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 302025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: [
//     {
//        parentId: 30202001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//       parentId: 30202002,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//       parentId: 30202003,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//     {
//       parentId: 30202004,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30202503,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 302025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30202005,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
// //  3030 "SD Review and Approval"
//
// server.create('task',{
//   uid: 30301001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & Review Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 303010,
//   step_name: 'Design Submittal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30301002,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 303010,
//   step_name: 'Design Submittal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30302001,
//   Status: 1,
//   shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Owner to Review and Approve Design Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 303020,
//   step_name: 'Final Review Meeting with Owner Signoff',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30301001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30302002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Revise Design Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 303020,
//   step_name: 'Final Review Meeting with Owner Signoff',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30302001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30303001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Send Notice to Proceed with Next Design Phase',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 303030,
//   step_name: 'Notice to Proceed with Next Phase of Design',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30302001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
//
// //  3040 "Schematic Design (DD)"
//
// server.create('task',{
//   uid: 30401001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct and document regular project design meeting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 304010,
//   step_name: 'Ongoing Design Meetings',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
//  server.create('task',{
//   uid: 30402001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Cost Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 304020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30401001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30402002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schedule Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 304020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30401001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30402003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Risk Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 304020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30401001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30402004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Value Engineering',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 304020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30401001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30402005,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 304020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30402503,
//        startAnchor: "left",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30402501,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review Interim Project Update',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 304025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30402502,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30402502,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Interim Project Update',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 304025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: [
//     {
//        parentId: 30402001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//       parentId: 30402002,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//       parentId: 30402003,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//     {
//       parentId: 30402004,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30402503,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 304025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30402005,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
// //***********&* */
// //  3070 "CD Review and Approval"
//
// server.create('task',{
//   uid: 30501001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & Review Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 305010,
//   step_name: 'Design Submittal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30501002,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 305010,
//   step_name: 'Design Submittal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30502001,
//   Status: 1,
//   shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Owner to Review and Approve Design Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 305020,
//   step_name: 'Final Review Meeting with Owner Signoff',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30501001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30502002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Revise Design Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 305020,
//   step_name: 'Final Review Meeting with Owner Signoff',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30502001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30503001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Send Notice to Proceed with Next Design Phase',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 305030,
//   step_name: 'Notice to Proceed with Next Phase of Design',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30502001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
// //  3060 "Schematic Design (CD)"
//
// server.create('task',{
//   uid: 30601001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct and document regular project design meeting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 306010,
//   step_name: 'Ongoing Design Meetings',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
//  server.create('task',{
//   uid: 30602001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Cost Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 306020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30601001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30602002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schedule Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 306020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30601001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30602003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Risk Review',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 306020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30601001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30602004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Value Engineering',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 306020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30601001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30602005,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 306020,
//   step_name: 'Ongoing Project Control',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30602503,
//        startAnchor: "left",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30602501,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review Interim Project Update',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 306025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30602502,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30602502,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Interim Project Update',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 306025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: [
//     {
//        parentId: 30602001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//       parentId: 30602002,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//       parentId: 30602003,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//     {
//       parentId: 30602004,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30602503,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 306025,
//   step_name: ' ',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 30602005,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//
//
// //  3070 "DD Review and Approval"
//
// server.create('task',{
//   uid: 30701001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & Review Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 307010,
//   step_name: 'Design Submittal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30701002,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schematic Design Architect Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 307010,
//   step_name: 'Design Submittal Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30702001,
//   Status: 1,
//   shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Owner to Review and Approve Design Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 307020,
//   step_name: 'Final Review Meeting with Owner Signoff',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30701001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30702002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Revise Design Submittal',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 307020,
//   step_name: 'Final Review Meeting with Owner Signoff',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30702001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
//
//  server.create('task',{
//   uid: 30703001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Send Notice to Proceed with Next Design Phase',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 307030,
//   step_name: 'Notice to Proceed with Next Phase of Design',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 30702001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'design_activities'
//  })
// //  4010 "Project Environment Overview"
//
// server.create('task',{
//   uid: 40101001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Perform market Survey',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 401010,
//   step_name: 'General Economic and Market Condition Overview',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
//  server.create('task',{
//   uid: 40102001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing monitoring of potential unforeseeable issues',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 401020,
//   step_name: 'Recognition/Monitoring of Unforeseeable Acts of God. Labor Strikes etc',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:    [
//     {
//        parentId: 40101001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
// //  4020 "External ParticipantsSelection of Management"
//
// server.create('task',{
//   uid: 40201001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Develop Appropriate Criteria for the Analysis and Selection of Non-Construction Participants',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 402010,
//   step_name: 'Non-Construction Participants',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
//  server.create('task',{
//   uid: 40202001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Develop List of Outside Consultants Required',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 402020,
//   step_name: 'Outside Consultants',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:    [
//     {
//        parentId: 40201001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
// //  4030 "External ParticipantsSelection of Management"
//
// server.create('task',{
//   uid: 40301001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Coordinate Project with Internal Departments',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 403010,
//   step_name: 'Other Project Influencers (Staff, Finance, Legal etc)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
//  server.create('task',{
//   uid: 40302001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Coordinate the Project with Government Authorities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 403020,
//   step_name: 'Government',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:    [
//     {
//        parentId: 40301001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
//  server.create('task',{
//   uid: 40303001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Coordinate the Project with Community Members',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 403030,
//   step_name: 'Community',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:    [
//     {
//        parentId: 40302001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
// //  4020 "External ParticipantsSelection of Management"
//
// server.create('task',{
//   uid: 40401001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Coordinate the Project with Adjacent Owners',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 404010,
//   step_name: 'Adjacent Owners',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
//  server.create('task',{
//   uid: 40402001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Coordinate the Project with Utility Providers',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 404020,
//   step_name: 'Utilities',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:    [
//     {
//        parentId: 40401001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'non_design_activities'
//  })
//
// //  5010 "Selection of Bidders"
//
// server.create('task',{
//   uid: 50101001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review and Approve Contractual Grouping & Selection Criteria',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 501010,
//   step_name: 'Establish Criteria',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50101002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50101002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Selection Criteria',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501010,
//   step_name: 'Establish Criteria',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50102001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Approve Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 501020,
//   step_name: 'Develop Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50102002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50102002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Develop Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501020,
//   step_name: 'Develop Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50101001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50103000,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Note: Private vs. Public',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501030,
//   step_name: 'Advertise & Issue Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50103001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Advertise for Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501030,
//   step_name: 'Advertise & Issue Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50102001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50103002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Issue Request for Qualifications',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501030,
//   step_name: 'Advertise & Issue Request for Qualifications',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50103001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50104001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ranking Review Meeting with Owner',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 501040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50104002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50104002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & Summarize RFQs',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50103002,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50104003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Schedule Interviews & notify short listed',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50104001,
//        startAnchor: "right",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50104004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Send notification letter to non-selected respondents as to the status',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501040,
//   step_name: 'Ranking Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50104003,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50105001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Send notification letter to non-selected respondents as to the status',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 501050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50105002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50105002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Select Design Team Member',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 501050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50105003,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50105003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct Interviews',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 501050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50105004,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50105004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Prepare for interviews',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 501050,
//   step_name: 'Interview Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:   [
//     {
//        parentId: 50104003,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//
//  // Steps for 050-020 "Contract Negotiations"
//
// server.create('task',{
//   uid: 50201001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify draft contract including special conditions',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502010,
//   step_name: 'Scope Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50201002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Outline scope definition statement',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502010,
//   step_name: 'Scope Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50201003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Finalize deliverables under the agreement',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502010,
//   step_name: 'Scope Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50201004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'List any exclusions',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502010,
//   step_name: 'Scope Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50202001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & review proposals (including comments on contract)',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502020,
//   step_name: 'Negotiate Final Costs',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50201001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//        parentId: 50201002,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//        parentId: 50201003,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//     {
//        parentId: 50201004,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50203001,
//   Status: 1,
//   shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Owner to agree on contract terms with design team member?',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 502030,
//   step_name: 'Prepare Contract',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50203002,
//        startAnchor: "top",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50203002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Negotiate contract including fees',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502030,
//   step_name: 'Prepare Contract',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50203001,
//        startAnchor: "left",
//        endAnchor: "left"
//     },
//     {
//       parentId: 50202001,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50204001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Forward final contract to consultant',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502040,
//   step_name: 'Contract Review',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50203001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50205001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Sign contract by all parties',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 502050,
//   step_name: 'Finalize and Execute Contract',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50204001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
// // Steps for 050-030 "Permits"
//
// server.create('task',{
//   uid: 50301001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Confirm Permit Application Process',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503010,
//   step_name: 'Permit Application',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50302001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify & Review with Authorities Permit Application Format',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503020,
//   step_name: 'Permit Application Format',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50301001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50302002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Contractor Submit Permit Application',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 503020,
//   step_name: 'Permit Application Format',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50302001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//     {
//       parentId: 50303004,
//       startAnchor: "bottom",
//       endAnchor: "right"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50303001,
//   Status: 1,
//   shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Permit Denied?',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503030,
//   step_name: 'Permit Denial',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50302002,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50303002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Request reasons for denial',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503030,
//   step_name: 'Permit Denial',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50303001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50303003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify party responsible for changes',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503030,
//   step_name: 'Permit Denial',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50303002,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50303004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Complete Changes',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503030,
//   step_name: 'Permit Denial',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50303003,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50304001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Expedite the permit',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503040,
//   step_name: 'Expediting the permit Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50303001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//  server.create('task',{
//   uid: 50305001,
//   Status: 2, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Obtain Permit',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 503050,
//   step_name: 'Obtain Permit',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 50304001,
//        startAnchor: "right",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
// // Steps for 050-040 "Permits"
//
// server.create('task',{
//   uid: 50401001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Prepare for project kick-off meeting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 504010,
//   step_name: 'Initial Project Meeting',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
// server.create('task',{
//   uid: 50401002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct project kickoff meeting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 504010,
//   step_name: 'Initial Project Meeting',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: [
//     {
//        parentId: 50401001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'contractor_selection'
//  })
//
//
// // Steps for 060-010 "Permits"
//
//  server.create('task',{
//   uid: 60101001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Pre-Construction Meeting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 601010,
//   step_name: 'Mobilization',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60101000,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Note: Deliverables Communication Protocols Schedules Meetings Responsibilities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 601010,
//   step_name: 'Mobilization',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60101002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Obtain Pre-Construction List from Contractor & Architect',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 601010,
//   step_name: 'Mobilization',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60101001,
//        startAnchor: "left",
//        endAnchor: "left"
//     },
//     {
//       parentId: 60101004,
//       startAnchor: "left",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60101003,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Note: Sub List, Schedule of Values, Project Schedule, Site utilization Plan, and Submittal schedule',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 601010,
//   step_name: 'Mobilization',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60101004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Distribute “Issued for Construction Drawings"',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 601010,
//   step_name: 'Mobilization',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60101005,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Mobilization',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 601010,
//   step_name: 'Mobilization',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60101002,
//        startAnchor: "right",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
// // 601020
//  server.create('task',{
//   uid: 60102001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review Subcontractors Bid',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 601020,
//   step_name: 'Subcontractor Selection & Approval',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60102504,
//        startAnchor: "left",
//        endAnchor: "bottom"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
// // 601020
//  server.create('task',{
//   uid: 60102501,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review & Approve Subcontractors',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 601025,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60102001,
//        startAnchor: "top",
//        endAnchor: "left"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60102502,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Prepare Subcontractors Bid Packages',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 601025,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60102501,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60102503,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Qualify & Select Subcontractors',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 601025,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60102502,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60102504,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Recommend Subcontractors to Owner',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 601025,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60102503,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60102505,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Execute Agreement with Subcontractors',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 601025,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60102501,
//        startAnchor: "right",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60103001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Execute Agreement with Subcontractors',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 601030,
//   step_name: 'Commence Construction',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60103002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ground Breaking',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 601030,
//   step_name: 'Commence Construction',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: [
//     {
//        parentId: 60103001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//     {
//       parentId: 60101002,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60103003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Commence Construction',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 601030,
//   step_name: 'Commence Construction',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: [
//     {
//        parentId: 60103002,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//     {
//       parentId: 60101004,
//       startAnchor: "right",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
// // Steps for 060-020 "Ongoing Project Management"
//
// server.create('task',{
//   uid: 60201000,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Project Monitoring Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60201010,
//       startAnchor: "left",
//       endAnchor: "left"
//    },
//     {
//        parentId: 60201500,
//        startAnchor: "left",
//        endAnchor: "right"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
// server.create('task',{
//   uid: 60201001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Monitor Schedule',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201000,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Monitor Cost',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201001,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Monitor Risk',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201002,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Monitor Quality',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201003,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201005,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Monitor Submittals',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201004,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201006,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'FF&E Planning',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201005,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201007,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Security Planning',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201006,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201008,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'IT Planning',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201007,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201009,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Moving Planning & Coordination',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//        parentId: 60201008,
//        startAnchor: "bottom",
//        endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201010,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602010,
//   step_name: 'Interim Project Monitoring',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:[
//     {
//       parentId: 60201504,
//       startAnchor: "left",
//       endAnchor: "right"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201500,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Meetings, Walkthroughs, & Reporting',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602015,
//   step_name: 'Interim Meetings, Walkthroughs, & Reporting',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60201000,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201501,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct Site Walkthrough',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602015,
//   step_name: 'Interim Meetings, Walkthroughs, & Reporting',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60201500,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201502,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct Weekly Project Meetings',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602015,
//   step_name: 'Interim Meetings, Walkthroughs, & Reporting',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60201501,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201503,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Prepare periodic Project Report',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602015,
//   step_name: 'Interim Meetings, Walkthroughs, & Reporting',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60201502,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60201504,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602015,
//   step_name: 'Interim Meetings, Walkthroughs, & Reporting',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60201500,
//       startAnchor: "left",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60202004,
//     startAnchor: "left",
//     endAnchor: "right"
//     },
//     {
//       parentId: 60201010,
//       startAnchor: "right",
//       endAnchor: "left"
//       },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60202001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Process Payment',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 602020,
//   step_name: 'Process Payment',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60202002,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60202002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review Pencil Draw',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Consultant',
//   stepId: 602020,
//   step_name: 'Process Payment',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60202003,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60202003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Prepare Pencil Draw',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602020,
//   step_name: 'Process Payment',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60202004,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60202004,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602020,
//   step_name: 'Process Payment',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60201504,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60202502,
//     startAnchor: "left",
//     endAnchor: "right"
//  },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60202501,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive Payment',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602025,
//   step_name: 'Process Payment',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60202001,
//       startAnchor: "right",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60202502,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602025,
//   step_name: 'Process Payment',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60202004,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60203006,
//     startAnchor: "left",
//     endAnchor: "right"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203001,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 602030,
//   step_name: 'Change Order Process (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203002,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602030,
//   step_name: 'Change Order Process (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203003,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Consultant',
//   stepId: 602030,
//   step_name: 'Change Order Process (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203004,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 602030,
//   step_name: 'Change Order Process (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203005,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Identify Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602030,
//   step_name: 'Change Order Process (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203006,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602030,
//   step_name: 'Change Order Process (Identify)',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:
//    [
//     {
//       parentId: 60203506,
//       startAnchor: "left",
//       endAnchor: "right"
//    },
//    {
//     parentId: 60202502,
//     startAnchor: "right",
//     endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203501,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Close Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602035,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203601,
//       startAnchor: "left",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203502,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & Log Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602035,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203001,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//     {
//       parentId: 60203002,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//     {
//       parentId: 60203003,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//     {
//       parentId: 60203004,
//       startAnchor: "right",
//       endAnchor: "left"
//     },
//     {
//       parentId: 60203005,
//       startAnchor: "right",
//       endAnchor: "left"
//       },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203503,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Investigate Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602035,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203504,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Investigate Design Effect for Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 602035,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203503,
//       startAnchor: "bottom",
//       endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203505,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Price Change Condition',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602035,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203504,
//       startAnchor: "bottom",
//       endAnchor: "top"
//     },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203506,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602035,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:
//   [
//     {
//       parentId: 60203006,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60203603,
//     startAnchor: "left",
//     endAnchor: "right"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203601,
//   Status: 1,
//   shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review & Approve Changes',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 602036,
//   step_name: 'Change Order Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203602,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203602,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Develop Change Order Recommendation to Owner',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 602036,
//   step_name: 'Change Order Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203505,
//       startAnchor: "right",
//       endAnchor: "bottom"
//    },
//    ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203603,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602036,
//   step_name: 'Change Order Process',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203506,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60203704,
//     startAnchor: "left",
//     endAnchor: "right"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//
//  server.create('task',{
//   uid: 60203701,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Log Change Order',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 602037,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203601,
//       startAnchor: "right",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203702,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Issue Design Change',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 602037,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203701,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60203703,
//   Status: 1, Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Proceed with Change',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602037,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203702,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60203704,
//   status: 1, shape: 2,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 602037,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60203603,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301001,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Owner add Punch List Items',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 603010,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301002,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'OSG Collect & Track Punch List Items',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603010,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301001,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//    {
//     parentId: 60301003,
//     startAnchor: "top",
//     endAnchor: "bottom"
//  },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60301003,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'A/E add Punch List Items',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Architect / Engineer',
//   stepId: 603010,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301004,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Ongoing Construction Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 603010,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks: 0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301101,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Prepare Closeout Matrix',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603011,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301102,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Follow Up on Punch List Completion with Contractor',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603011,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301002,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301103,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Complete Punch List Items',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 603011,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301102,
//       startAnchor: "bottom",
//       endAnchor: "top"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301201,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Review Closeout Status',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 603012,
//   step_name: 'Project Closeout',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301202,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301202,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Update Closeout Matrix & Report to Owner',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603012,
//   step_name: 'Project Closeout',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301203,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301203,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive & Distribute Closeout Documents',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603012,
//   step_name: 'Project Closeout',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301101,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60301204,
//     startAnchor: "top",
//     endAnchor: "bottom"
//  },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301204,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Submit Closeout Documents',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 603012,
//   step_name: 'Project Closeout',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  0,
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60301301,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Verify Completion of Closeout Requirements',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603013,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301202,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301302,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Verify Completion of Punch List Items',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603013,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301102,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//
//  server.create('task',{
//   uid: 60301303,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Receive Certificate of Occupancy',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603013,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301304,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301304,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Secure Certificate of Occupancy',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 603013,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301305,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301305,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Complete All Deliverables & Obtain Certificate of Occupancy',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 603013,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301004,
//       startAnchor: "bottom",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60301103,
//     startAnchor: "bottom",
//     endAnchor: "left"
//    },
//    {
//     parentId: 60301204,
//     startAnchor: "bottom",
//     endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301401,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Process Final Pay Application',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 603014,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301402,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301402,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Verify Pay Application & Completion of All Deliverables & Recommend Final Payment',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603014,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301301,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//    {
//     parentId: 60301302,
//     startAnchor: "right",
//     endAnchor: "left"
//    },
//    {
//     parentId: 60301303,
//     startAnchor: "right",
//     endAnchor: "left"
//    },
//    {
//     parentId: 60301403,
//     startAnchor: "top",
//     endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60301403,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Submit Final Pay Application',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Contractor',
//   stepId: 603014,
//   step_name: '',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301304,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60302001,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Moving & Occupancy',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Owner (Client)',
//   stepId: 603020,
//   step_name: 'Occupancy',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60302002,
//       startAnchor: "top",
//       endAnchor: "bottom"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })
//
//  server.create('task',{
//   uid: 60302002,
//   Status: 1,
//   Shape: 1,
//   Impact: 1,
//   Ranking: 1,
//   name: 'Conduct Occupancy meetings & Coordinate Occupancy Activities',
//   due_date: '11/22/33',
//   critical: false,
//   assignee_id: 1,
//   assignee: 'Director',
//   stepId: 603020,
//   step_name: 'Occupancy',
//   template_file_id: 'file1',
//   task_manager: 3,
//   previousTasks:  [
//     {
//       parentId: 60301402,
//       startAnchor: "right",
//       endAnchor: "left"
//    },
//   ],
//   created_at: 'NULL',
//   updated_at: 'NULL',
//   project_id: 1,
//   task_approved_by: 2,
//   phase: 'construction_phase'
//  })


    }
  })
}
