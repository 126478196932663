import React, {useEffect} from "react"
import {useMutation} from "react-query";
import {gmailLabelKey, gmailLabelList} from "../../../utils/google/Queries";
import {
  createTagForList,
  defaultLabels
} from "../Utils/Index"
import LabelIcon from '@mui/icons-material/Label';
import AssignEmailLabelButton from "./AssignEmailLabelButton"
import Link from '@mui/material/Link';
import {useParams} from "react-router-dom";

function LabelList({
                     isAuthorized,
                     gapi,
                     updateRelated,
                     setNumOfLabels,
                     selectedMessages,
                     selectedLabels,
                     setUpdateEmailList,
                     updateEmailList,
                     setSelectedLabels
                   }) {
  const params = useParams()
  const keyColor = "#ccc"
  let authenticationCondition = isAuthorized && gapi?.auth?.getToken() != undefined
  const projectCurr = JSON.parse(window.localStorage.getItem("projectValue") ?? '{}')
  const projectIdentifier = params['projectId'] != undefined && params['projectId'] != '' && projectCurr?.project?.attributes?.identifier

  // Get the list of Gmail Labels. Iterate over the list and pick the one with the same name
  const {isLoading, isSuccess, data: labels, mutateAsync: getGmailLabelList} = useMutation(gmailLabelList, {
    onSuccess: (data, variables, context) => {
      // setNumOfLabels(data.data.labels?.length)
    },
    onError: (error, variables, context) => {
      console.error('Gmail label list fetch failed.', error.message)
    }
  });

  useEffect(() => {
    if (isAuthorized && gapi?.auth?.getToken() != undefined) {
      getGmailLabelList([gmailLabelKey, {
        access_token: gapi?.auth?.getToken()?.access_token
      }])
    }
  }, [authenticationCondition, updateRelated])

  const handleClick = (event) => {
    if (params['projectId'] != undefined && params['projectId'] != '') {
      setSelectedLabels([
        projectCurr?.project?.attributes["gmail_labelid"],
        event.target.dataset.labelid
      ])
    } else {
      setSelectedLabels([ event.target.dataset.labelid ])
    }
  }

  console.log('selectedLabels', selectedLabels)

  const shouldRenderTag = (label) => {
    if(!!projectIdentifier
       && !label.name.includes(projectIdentifier)) {
      return false
    }

    return !defaultLabels.includes(label.id)
  }

  return (
    <>
      {
        !isLoading && isSuccess && labels?.data?.labels?.length > 0 ?
          <div className="gray-border mx-0 p-0 h-80 overflow-y-auto">
            {
              labels.data.labels.map(label => {
                if (shouldRenderTag(label)) {
                  return (
                    <div
                      key={label.id.toString() + '-' + Math.floor(Math.random() * 10000).toString()}
                      className={`inline-flex items-center justify-between py-1 px-2 w-full hover:bg-gray-100 ${selectedLabels.includes(label.id) ? 'bg-tenzingOrangeLight' : ''}`}>
                      <div
                        data-labelid={label.id.toString()}
                        className="flex space-x-1">
                        <LabelIcon style={{color: label?.color?.backgroundColor}}/>
                        {/* For the company level remove the forward slash and make the project name a badge */}
                        <Link onClick={handleClick}
                              underline="hover"
                              className="hover:cursor-pointer"
                              color="inherit">
                          {/*
                            We can not apply the project Color to the labels because we do not have a project instance in our response
                            We need to make one more request to make that work but it is an overkill and will cause too much overload.
                           */}
                          <h3 data-labelid={label.id.toString()}>{createTagForList(label.name, (selectedLabels.length > 0))}</h3>
                        </Link>
                      </div>
                      <AssignEmailLabelButton label={label}
                                              isAuthorized={isAuthorized}
                                              gapi={gapi}
                                              selectedMessages={selectedMessages}
                                              selectedLabels={selectedLabels}
                                              setUpdateEmailList={setUpdateEmailList}
                                              updateEmailList={updateEmailList}/>
                    </div>
                  )
                }
              })
            }
          </div>
          : null
      }
    </>
  )
}

export default LabelList;
