
export const homeURL = () => {
    return '/home';
}

export const processMapURL = () => {
    return '/process'; 
}

export const projectProcessMapPhaseURL = (projectId, phaseId) => {
    return `/projects/${projectId}/phases/${phaseId}`;
}

export const processMapPreDesignURL = () => {
    return '/process/pre_design'
}

export const processMapDesignTeamURL = () => {
    return '/process/design_team'; 
}

export const processMapDesignActivitiesURL = () => {
    return '/process/design_activities'; 
}

export const processMapNonDesignActivitiesURL = () => {
    return '/process/non_design_activities'; 
}

export const processMapContractorURL = () => {
    return '/process/contractor_section'; 
}

export const processMapConstructionURL = () => {
    return '/process/construction_phase'; 
}

// Risks

export const risksURL = (id) => {
    return `/projects/${id}/risks`;
}

// Contacts



// Activities/Schedule

export const scheduleURL = (id) => {
    return `/projects/${id}/schedule`; 
}

export const scheduleOwnersURL = (id) => {
    return `/projects/${id}/schedule/owners`
}

export const scheduleDesignURL = (id) => {
    return `/projects/${id}/schedule/design`
}

export const scheduleContractorURL = (id) => {
    return `/projects/${id}/schedule/contractor`
}

// Costs

export const costsURL = (id) => {
    return `/projects/${id}/costs`
}

export const costsContractsURL = (id) => {
  return `/projects/${id}/costs/contracts`
}

export const costsInvoicesURL = (id) => {
  return `/projects/${id}/costs/invoices`
}

// Create Project

export const createProject = () => {
    return '/projects/new'
}

export const assignManager = (id) => {
    return '/projects/${id}/manager/first'
}

export const assignApprover = (id) => {
    return '/projects/${id}/approver/first'
}

export const uploadImage = (id) => {
    return '/projects/${id}/image'
}

// Global

export const selfUrl = () => {
    return '#'
}
