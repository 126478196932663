import React, {useEffect, useState} from 'react';
import moment from 'moment'
import {Link} from 'react-router-dom';
import {useQuery} from 'react-query';
import {scheduleURL} from 'utils/urls'
import ProgressBar from 'Components/Common/ProgressBar';
import {projectActivities, project as projectQueryKey} from 'utils/queryKeys'
import {getProjectActivities} from 'utils/queries'
import {getProject} from "utils/queries";
import WarningIcon from '@mui/icons-material/Warning';
import {Tooltip} from '@material-ui/core';
import {companyStats as companyStatsQuery} from "../../../utils/queryKeys";
import {getCompanyStats} from "../../../utils/queries";
import {
  calculateActivityHasExtension,
  isExtensionApproved
} from "../../Schedule/Resources"

function ScheduleSummary({
                           projectID,
                           isSmall
                         }) {

  const [scheduleStatus, setScheduleStatus] = useState("")
  const [showWarning, setShowWarning] = useState(false)
  const project = useQuery(
    [projectQueryKey, {id: projectID}],
    getProject)

  const activities = useQuery(
    [projectActivities, {projectId: projectID, category: 'all'}],
    getProjectActivities,
    {
      cacheTime: 0,
      staleTime: 0
    }
  )

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  // Get the project stats
  const companyStats = useQuery(
    [companyStatsQuery, {companyId: companyId}],
    getCompanyStats,
    {
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      retryOnMount: false
    }
  );

  // Find the Activities that are un approved and render a warning sign
  const findUnapprovedExtensions = () => {
    return activities.data.data.find(activity => {
      calculateActivityHasExtension(activity)
      && isExtensionApproved(activity) === false ? setShowWarning(true) : null
    })
  }

  const getProgressEndDate = () => {
    if (anticEndDate == null || extensionApproval !== true) {
      return endDate
    } else if (new Date(anticEndDate) > new Date(endDate)) {
      return anticEndDate
    } else if (new Date(endDate) > new Date(anticEndDate)) {
      return endDate
    }
  }

  let headingStyle = "text-xl font-light";
  if (isSmall) {
    headingStyle = "text-xs font-semibold";
  }

  let startDate = !project.isLoading && project.isSuccess && project.data?.data?.attributes?.["activity_starts_at"]
  let endDate = !project.isLoading && project.isSuccess && project.data?.data?.attributes?.["activity_ends_at"]
  let anticEndDate = !project.isLoading && project.isSuccess && project.data?.data?.attributes?.["activity_extended_until"]
  let extensionApproval = !project.isLoading && project.isSuccess && project.data?.data?.attributes?.["activity_extended_until_approved"]
  const end = getProgressEndDate()

  const calculateProgress = () => {
    let startDay = moment(new Date(startDate));
    let today = moment(new Date())
    let endDay = moment(new Date(end));
    let totalProjectDays = Math.abs(startDay.diff(endDay, 'days'));
    let elapsedProjectDays = Math.abs(startDay.diff(today, 'days'));
    return Math.round((elapsedProjectDays / totalProjectDays) * 100)
  }


  // Calculate the behind schedule projects
  // Calculate the projects that have an activity which is extended but not approved
  useEffect(() => {
    if (companyStats.isSuccess == true
      && companyStats?.data?.included?.behind_schedule_projects?.data?.map(project => parseInt(project.id))?.includes(parseInt(projectID))) {
      setScheduleStatus("Behind Schedule")
    } else if (project?.data?.data?.attributes?.['completed']) {
      setScheduleStatus("Completed")
    } else {
      setScheduleStatus("On Schedule")
    }

    if (activities.isSuccess == true
      && activities.data.data.length > 0) {
      // Render a warning sign if there are unapproved extensions.
      findUnapprovedExtensions()
    }
  }, [activities.isFetching || project.isFetching || companyStats.isFetching])

  if (activities.isLoading || companyStats.isLoading || project.isLoading) {
    return null
  }

  if (!activities.data.data.length) {
    return (<p>Schedule not available.</p>)
  }

  return (
    <section>
      <h4 className={`text-left ${headingStyle} inline-block text-lightGray`}>
        Baseline Date vs.<br/> Anticipated End Date
      </h4>
      <ProgressBar progress={`${calculateProgress()}%`}
                   isOver={100 <= calculateProgress()}
                   isSmall={isSmall}
                   isCompleted={scheduleStatus === "Completed"}/>
      <div className="flex flex-col justify-end">
        <div className="flex">
          <p className="text-xs text-lightGray">Baseline:&nbsp;</p>
          <p className="text-xs text-tenzingGrayLight">{moment(endDate).format("l")}</p>
        </div>
        <div className="flex">
          <p className="text-xs text-lightGray">Anticipated:&nbsp;</p>
          <p
            className="text-xs text-tenzingGrayLight">{anticEndDate ? moment(anticEndDate).format("l") : "N/A"}</p>
        </div>
      </div>
      <Link
        className={(scheduleStatus === "On Schedule" || scheduleStatus === "Completed") ? "text-trueGreen text-xs" : "text-tenzingRed text-xs"}
        to={scheduleURL(projectID)}>
        {scheduleStatus}
      </Link>
      {showWarning
      && scheduleStatus !== "Completed" ?
        <Tooltip
          component={'span'}
          arrow
          title={"Activity extensions waiting approval"}
          placement={"right"}>
          <Link to={scheduleURL(projectID)}><WarningIcon style={{color: "#FFD72E", height: "20px"}}/></Link>
        </Tooltip>
        : null}
    </section>
  )

}

export default ScheduleSummary
