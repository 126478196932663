import React, {useState, useEffect} from 'react';
import Collapsible from 'Components/Common/Collapsible'
import
{
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControlLabel,
  Switch,
  TableHead,
} from '@mui/material';
import {useQuery, useQueryClient} from 'react-query';
import {
  getComparator,
  stableSort,
  formatAmount,
  sum,
  filterRows,
  getRows
} from '../Utils/Proforma'
import EnhancedTableHead from '../Components/Proforma'
import {projectLineItems} from "../../../utils/queryKeys";
import {getProjectLineItems} from "../../../utils/queries";

function ProformaTable({
                         categories,
                         projectId,
                         setShowForm,
                         setLineItemObject,
                         setLineitemPermissions,
                         refetchData
                       }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const queryClient = useQueryClient()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const lineItems = useQuery(
    [projectLineItems, {projectId: projectId}],
    getProjectLineItems,
    {enabled: !!projectId}
  )

  useEffect(() => {
    queryClient.refetchQueries([projectLineItems, {projectId: projectId}])
  }, [refetchData])

  const rows = getRows(lineItems)

  let totalProformaSums = {
    approvedBudget: 0,
    anticipatedCost: 0,
    varianceFromBudget: 0,
    committedToDate: 0,
    expendituresToDate: 0,
    remainingBalance: 0
  }

  useEffect(() => {
    if (lineItems.data?.permissions != undefined
      && lineItems.data?.permissions != null) {
      setLineitemPermissions(lineItems.data?.permissions)
    }

  }, [!lineItems.isLoading && lineItems.isSuccess && !!lineItems.data?.permissions])

  return (
    <>
      {categories != undefined
        && categories.map((category) => {
          let categoryRows = filterRows(rows, category)
          return (
            <>
              <Collapsible open={true} title={category.attributes.name} key={category.id} isProforma={true}>
                {
                  category.attributes.sub_categories.data.length ?
                    category.attributes.sub_categories.data.map((subCategory) => {
                      let filteredRows = filterRows(rows, category, subCategory)

                      const key = `${projectId}-${category.id}-${subCategory.id}`

                      totalProformaSums.approvedBudget += sum(filteredRows, 'approvedBudget')
                      totalProformaSums.anticipatedCost += sum(filteredRows, 'anticipatedCost')
                      totalProformaSums.varianceFromBudget += sum(filteredRows, 'varianceFromBudget')
                      totalProformaSums.committedToDate += sum(filteredRows, 'committedToDate')
                      totalProformaSums.expendituresToDate += sum(filteredRows, 'expendituresToDate')
                      totalProformaSums.remainingBalance += sum(filteredRows, 'remainingBalance')

                      return (
                        <Collapsible open={true} title={subCategory.attributes.name} key={subCategory.id}
                                    subCategory={true}
                                    isProforma={true}>
                          <Box sx={{width: '100%'}} key={`box-${key}`}>
                            <Paper sx={{width: '100%', mb: 2}}>
                              <TableContainer>
                                <Table
                                  sx={{minWidth: 700}}
                                  aria-labelledby="tableTitle"
                                  size={'small'}
                                >
                                  <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                  />
                                  <TableBody>
                                    {
                                      filteredRows.length ?
                                        stableSort(filteredRows, getComparator(order, orderBy))
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((row) => {
                                            const isItemSelected = isSelected(row.name);
                                            return (
                                              <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.name)}
                                                aria-checked={isItemSelected}
                                                key={`${key}-${row.id}`}
                                                selected={isItemSelected}
                                              >
                                                <TableCell align="right">{row.categoryBreakdown.name}</TableCell>
                                                <TableCell align="right">{row.name}</TableCell>
                                                <TableCell>
                                                  {
                                                    lineItems.data?.permissions?.['update'] ?
                                                      <div
                                                        className="underline text-center text-lightBlue hover:cursor-pointer"
                                                        onClick={() => {
                                                          setShowForm('edit_lineItem')
                                                          setLineItemObject(row.lineItemObject)
                                                        }}>{row.id}
                                                      </div>
                                                      : <div>{row.id}</div>
                                                  }
                                                </TableCell>
                                                <TableCell align="right">{formatAmount(row.approvedBudget)}</TableCell>
                                                <TableCell align="right">{formatAmount(row.anticipatedCost)}</TableCell>
                                                <TableCell
                                                  align="right">{formatAmount(row.varianceFromBudget)}</TableCell>
                                                <TableCell
                                                  style={{color: `${row.committedToDate > row.approvedBudget ? 'red' : ""}`}}
                                                  align="right"
                                                >{formatAmount(row.committedToDate)}</TableCell>
                                                <TableCell
                                                  align="right">{formatAmount(row.expendituresToDate)}</TableCell>
                                                <TableCell align="right">{formatAmount(row.remainingBalance)}</TableCell>
                                              </TableRow>
                                            );
                                          }).concat(
                                          <TableRow>
                                            <TableCell style={{fontWeight: "bold"}} align="right"
                                                      colSpan="2">TOTAL:</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell style={{fontWeight: "bold"}}
                                                      align="right">{formatAmount(sum(filteredRows, 'approvedBudget'))}</TableCell>
                                            <TableCell style={{fontWeight: "bold"}}
                                                      align="right">{formatAmount(sum(filteredRows, 'anticipatedCost'))}</TableCell>
                                            <TableCell style={{fontWeight: "bold"}}
                                                      align="right">{formatAmount(sum(filteredRows, 'varianceFromBudget'))}</TableCell>
                                            <TableCell
                                              style={{
                                                color: `${sum(filteredRows, 'committedToDate') > sum(filteredRows, 'approvedBudget') ? 'red' : ""}`,
                                                fontWeight: "bold"
                                              }}
                                              align="right"
                                            >{formatAmount(sum(filteredRows, 'committedToDate'))}</TableCell>
                                            <TableCell style={{fontWeight: "bold"}}
                                                      align="right">{formatAmount(sum(filteredRows, 'expendituresToDate'))}</TableCell>
                                            <TableCell style={{fontWeight: "bold"}}
                                                      align="right">{formatAmount(sum(filteredRows, 'remainingBalance'))}</TableCell>
                                          </TableRow>
                                        )
                                        :
                                        <TableRow>
                                          <TableCell align="center" colSpan="8"><p key={`noResults-${key}`}>No Budget Line
                                            Items</p></TableCell>
                                        </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              {/* <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          /> */}
                            </Paper>
                          </Box>
                        </Collapsible>
                      )
                    })
                    :
                    <p>No Sub-categories</p>
                }
              </Collapsible>
              <section>
                <Table 
                  sx={{backgroundColor: "#F7F8FA"}} 
                  size={'small'}>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                      isTotal={true}
                    />
                  <TableBody>
                    <TableRow>
                      <TableCell style={{fontWeight: "bold"}} align="right"
                                colSpan="3">CATEGORY TOTAL:</TableCell>
                      <TableCell style={{fontWeight: "bold"}}
                                align="right">{formatAmount(sum(categoryRows, 'approvedBudget'))}</TableCell>
                      <TableCell style={{fontWeight: "bold"}}
                                align="right">{formatAmount(sum(categoryRows, 'anticipatedCost'))}</TableCell>
                      <TableCell style={{fontWeight: "bold"}}
                                align="right">{formatAmount(sum(categoryRows, 'varianceFromBudget'))}</TableCell>
                      <TableCell
                        style={{
                          color: `${sum(categoryRows, 'committedToDate') > sum(categoryRows, 'approvedBudget') ? 'red' : ""}`,
                          fontWeight: "bold"
                        }}
                        align="right"
                      >{formatAmount(sum(categoryRows, 'committedToDate'))}</TableCell>
                      <TableCell style={{fontWeight: "bold"}}
                                align="right">{formatAmount(sum(categoryRows, 'expendituresToDate'))}</TableCell>
                      <TableCell style={{fontWeight: "bold"}}
                                align="right">{formatAmount(sum(categoryRows, 'remainingBalance'))}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </section>
            </>
          )
        })}
      <section>
        <Box sx={{width: '100%'}} key="proformaTotals">
          <TableContainer>
            <Table
              sx={{minWidth: 700, backgroundColor: "#EBEBEB"}}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                isTotal={true}
                isProformaTotal={true}
              />
              <TableBody>
                <TableRow>
                  <TableCell style={{fontWeight: "bold"}} align="right" colSpan="3">PROFORMA TOTALS:</TableCell>
                  <TableCell style={{fontWeight: "bold"}}
                             align="right">{formatAmount(totalProformaSums.approvedBudget)}</TableCell>
                  <TableCell style={{fontWeight: "bold"}}
                             align="right">{formatAmount(totalProformaSums.anticipatedCost)}</TableCell>
                  <TableCell style={{fontWeight: "bold"}}
                             align="right">{formatAmount(totalProformaSums.varianceFromBudget)}</TableCell>
                  <TableCell
                    style={{
                      color: `${totalProformaSums.committedToDate > totalProformaSums.approvedBudget ? 'red' : ""}`,
                      fontWeight: "bold"
                    }}
                    align="right"
                  >{formatAmount(totalProformaSums.committedToDate)}</TableCell>
                  <TableCell style={{fontWeight: "bold"}}
                             align="right">{formatAmount(totalProformaSums.expendituresToDate)}</TableCell>
                  <TableCell style={{fontWeight: "bold"}}
                             align="right">{formatAmount(totalProformaSums.remainingBalance)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </section>
    </>
  )
}

export default ProformaTable;

