import React, {useEffect, useState} from "react";
import {useQueries} from "react-query";
import {
  gmailLabelById,
  gmailLabelByIdKey
} from "../../../utils/google/Queries";
import {
  defaultLabels
} from "../Utils/Index"

const BadgeLabel = ({
                      gapi,
                      emailBody
                    }) => {
  const [emailLabelIds, setEmailLabelIds] = useState([])


  useEffect(() => {
    if (!!emailBody.data && !!emailBody.data && emailBody.data.labelIds?.length > 0) {
      setEmailLabelIds(emailBody.data.labelIds.filter(label_id => !defaultLabels.includes(label_id)))
    }
  }, [emailBody.isLoading, !!emailBody.data])

  const labelNamesQueries = useQueries(
    emailLabelIds && emailLabelIds.map(labelId => {
      return {
        queryKey: [gmailLabelByIdKey, {
          labelId: labelId,
          access_token: gapi?.auth?.getToken()?.access_token
        }],
        queryFn: () => gmailLabelById(
          [gmailLabelByIdKey, {
            labelId: labelId,
            access_token: gapi?.auth?.getToken()?.access_token
          }]),
      }
    })
  )

  const isLoading = labelNamesQueries.some(query => query.isLoading)

  return (
    <>
      {
        isLoading ? null :
          <>
            {labelNamesQueries.map(query => {
              return (
                query.isSuccess ?
                <span key={query.data.data.id + "-gmail-label-id-badge"}
                      className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                  {query.data.data.name}
                </span> : null
              )
            })}
          </>
      }
    </>
  )
}

export default BadgeLabel;
