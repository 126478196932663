import React, {useEffect, useState} from 'react'
import {client as apiClient} from 'utils/api';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {projectPhase} from "../../../utils/queryKeys";
import {getPhase} from "../../../utils/queries";
import {moduleFields} from "../Utils";
import Button from '../../../Components/Common/Button';
import Section from 'Components/Common/Section';
import {Field} from 'Components/Common/Forms';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {parseISO} from "date-fns";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

function EditPhase({
                      currentPhaseId,
                      locked,
                      setPhaseUpdated
                    }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({})
  const {register, handleSubmit, setValue, setError, reset, formState: {errors}} = useForm();
  const queryClient = useQueryClient();

  const phaseQuery = useQuery(
    [projectPhase, {phaseId: currentPhaseId}],
    getPhase, {
      enabled: currentPhaseId != undefined
    })

  useEffect(() => {
    if (phaseQuery.data?.data?.attributes?.id > 0) {
      setFormData(phaseQuery.data.data.attributes)
      // Update the form values
      moduleFields.forEach((fd) => {
        if (fd === "due_date") {
          setValue(fd, parseISO(phaseQuery.data.data.attributes[fd]))
        } else if (["task", "project", "phase_pool"].includes(fd)) {
          setValue(fd + '_id', phaseQuery.data.data.attributes[fd]?.id)
        } else {
          setValue(fd, phaseQuery.data.data.attributes[fd])
        }
      })
    }
  }, [
    !phaseQuery.isLoading
    && phaseQuery.isSuccess
    && phaseQuery.data?.data?.attributes?.id > 0
    && currentPhaseId != undefined
    && currentPhaseId // The currentPhaseId is the one that changes and triggers the useEffect hook
  ])

  const handleFormChange = (e) => {
    setFormData({
      ...formData, [e.target.id]: e.target.value
    })
    // Set the value
    setValue(e.target.id, e.target.value)
  }

  const notifySuccess = () => toast.success("Phase updated!");
  const notifyError = () => toast.error("Phase update failed.")

  async function patchPhase(data) {
    // Unpack values to variable
    const {subname, order} = data
    // Make the request and wait for the response
    return await apiClient.patch("/phases/" + currentPhaseId, {
      subname: subname.trim(),
      // order: order
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(patchPhase);

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.invalidateQueries([projectPhase, {phaseId: currentPhaseId}])
      notifySuccess()
      reset()
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
      setPhaseUpdated(true)
    } catch (err) {
      notifyError()
      setLoading(false)
      setDoDisable(false)
      setPhaseUpdated(false)
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  if (phaseQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    !phaseQuery.isLoading
    && phaseQuery.isSuccess
    && phaseQuery.data?.data?.attributes?.phase_pool?.id != undefined
    && phaseQuery.data?.data?.attributes?.task?.id == undefined ?
      <Section>
        <div className="flex items-start align-baseline">
          <h1 className="Section-h1 mr-2 mb-2">Edit Phase</h1>
        </div>
        <form method="put" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

          <Field>
            <label htmlFor="subname" className="text-tenzingGray">Sub-Name:</label>
            <input
              id="subname"
              type="text"
              disabled={(locked ?? false) || !phaseQuery.data?.permissions?.['update']}
              placeholder="Sub-Name"
              {...register("subname", {
                required: true,
                pattern: {value: /[A-Za-z]{2}/, message: "Required string with minimum two Characters"}
              })}
              value={formData.subname ?? ""}
              onChange={handleFormChange}
              className={!errors.subname ? "input-text" : 'input-text-error'}
            />
            <FieldInputError item={errors.subname}></FieldInputError>
          </Field>

          <section className="mt-4 flex justify-end">
            {/* SUBMIT BUTTON */}
            <Button
              buttonStyle={(loading || doDisable) ? "gray-solid-button m-auto mr-0" : "orange-solid-button m-auto mr-0"}
              disabled={loading || doDisable || locked || !phaseQuery.data?.permissions?.['update']}>
              {
                doDisable ?
                  <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                  : <></>
              }
              Save
            </Button>
          </section>
        </form>
      </Section>
      : null

  )

}

export default EditPhase;
