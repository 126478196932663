import React from "react";
import {Field} from 'Components/Common/Forms';


// Choose and upload a project image
const ProjectImagePick = ({processFile}) => {
  const handleClick = (e) => {
    console.log('files', e.target.files)
  }

  return (
    <div>
      <Field>
        <label htmlFor="image" className="text-tenzingGray">Pick Project Image</label>
        <input type="file"
               onClick={handleClick}
               onChange={processFile}/>
      </Field>
    </div>
  )
}

export default ProjectImagePick
