import React from 'react'
import {useQuery} from 'react-query';
import Section from 'Components/Common/Section'
import BoxFileExplorer from 'Components/FileExplorer';
import {
  boxAccessToken,
  project as projectByIdQuery,
  company as companyByIdQuery
} from "../../utils/queryKeys";
import {
  getBoxAccessToken,
  getProject,
  getCompany
} from "../../utils/queries";
import DocumentTitle from '../../Components/Documents/DocumentTitle'
import {useParams} from "react-router-dom";


function Index() {
  const params = useParams();
  const companyId = parseInt(window.localStorage.getItem("company_id"))

  const project = useQuery(
    [projectByIdQuery, {id: params['projectId']}],
    getProject,
    {enabled: !!params['projectId']}
  )

  const company = useQuery(
    [companyByIdQuery, {companyId: companyId}],
    getCompany,
    {enabled: !!!params['projectId']}
  )

  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  const bexpConfig = {
    boxable: !!params['projectId'] ? project?.data : company,
    access_token: boxAccess
  }

  if (company.isLoading || project.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <Section height="h-screen">
      <div className="h-5/6">
        <DocumentTitle object={!!params['projectId'] ? project?.data : company}/>
        <BoxFileExplorer props={bexpConfig}/>
      </div>
    </Section>
  )
}

export default Index
