import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom"
import Section from 'Components/Common/Section'
import Form from 'Components/Risks/Form'
import List from 'Components/Risks/List'
import {useQuery} from 'react-query'
import {projectRisks as projectRisks, googleMailToken} from 'utils/queryKeys'
import {getProjectRisks as getProjectRisks, getGoogleMailAccessToken} from 'utils/queries'
import {RisksContext} from "../../context/RisksContext"
import QuickNote from "Components/QuickNotes"
import
  ContentTwoColumn, {WideColumn, NarrowColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import DropZone from "../../Components/BoxDropZone/DropZone";
import {DiscoveryDocs, REACT_APP_CLIENT_ID, SCOPES} from "../../utils/google/Auth";
import {project as projectKey} from "../../utils/queryKeys";
import {getProject} from "../../utils/queries";
import {gapi} from "gapi-script";
import SendEmail from "../../Components/Correspondence/Inbox/SendEmail";
import {emailContext} from "../../Components/Correspondence/Utils/Index";
import {CorrespondenceContext} from "../../context/CorrespondenceContext";

function Index() {
  const [selectedRisks, setSelectedRisk] = useState({});
  const [objectLabelId, setObjectLabelId] = useState(null)
  const [objectLabelName, setObjectLabelName] = useState("")
  const [dropZoneVisibility, setDropZoneVisibility] = useState(false)
  const [dropZoneObject, setDropZoneObject] = useState("")
  const [showForm, setShowForm] = useState(true)
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [selectedLabels, setSelectedLabels] = useState([])
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [correspondenceContext, setCorrespondenceContext] = useState(emailContext);
  const params = useParams()

  // Get all the risks
  const risks = useQuery(
    [projectRisks, {projectId: params['projectId']}],
    getProjectRisks
  )

  const project = useQuery(
    [projectKey, {id: params.projectId}],
    getProject
  )

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  return (
    <>
      {risks.isLoading || project.isLoading
        ? <div className="lds-dual-ring"></div>
        : <ContentTwoColumn>
          <RisksContext.Provider value={{selectedRisks, setSelectedRisk}}>
            <WideColumn>
              <Section>
                <h1 className="Section-h1">Project Risks</h1>
                <List items={risks.data?.data}
                      gapi={gapi}
                      setShowSendEmail={setShowSendEmail}
                      setShowForm={setShowForm}
                      setObjectLabelId={setObjectLabelId}
                      setObjectLabelName={setObjectLabelName}
                      setDropZoneVisibility={setDropZoneVisibility}
                      setDropZoneObject={setDropZoneObject}
                      setSelectedLabels={setSelectedLabels}/>
              </Section>
            </WideColumn>
            <NarrowColumn hidden={!risks.data?.permissions?.['create']}>
              {
                risks.data?.permissions?.['create'] && showForm ?
                  <>
                    <Form gapi={gapi} project={project}/>
                    <QuickNote projectId={project?.data?.data?.id}/>
                  </>
                  : null
              }
              {showSendEmail
              && isAuthorized
              && objectLabelId ?
                <CorrespondenceContext.Provider value={[correspondenceContext, setCorrespondenceContext]}>
                  <SendEmail
                    gapi={gapi}
                    objectLabelId={objectLabelId}
                    setShowSendEmail={setShowSendEmail}
                    setShowForm={setShowForm}
                    objectLabelName={objectLabelName}
                    selectedLabels={selectedLabels}
                    labelListUpdated={false}/>
                </CorrespondenceContext.Provider>
                : null}
              {
                dropZoneVisibility ?
                  <DropZone dropZoneObject={dropZoneObject}
                            setDropZoneVisibility={setDropZoneVisibility}
                            setShowSendEmail={setShowSendEmail}
                            setShowForm={setShowForm}/> : null
              }
            </NarrowColumn>
          </RisksContext.Provider>
        </ContentTwoColumn>
      }
    </>
  )
}

export default Index;
