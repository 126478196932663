import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Paper from '@material-ui/core/Paper';
import ActionButton from "../Actions/ActionButton";

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)'
  }
});


const DraggableListItem = ({
                             onDeleteItem,
                             duplicateItem,
                             item,
                             index
                           }) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <Paper className="mb-4">
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={
              snapshot.isDragging ? classes.draggingListItem : ''
            }
          >
            {/* Draggable Icon */}
            <ListItemAvatar>
              <DragHandleIcon/>
            </ListItemAvatar>
            {/* List text */}
            <ListItemText primary={item.primary} secondary={item.secondary}/>
            {/* Action Button */}
            <ActionButton onDeleteItem={onDeleteItem}
                          duplicateItem={duplicateItem}
                          item={item}/>
          </ListItem>
        </Paper>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
