import {Box, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {headCells} from "../../Utils/Proforma";
import {visuallyHidden} from "@mui/utils";
import React from "react";

function EnhancedTableHead({
                             numSelected,
                             order,
                             orderBy,
                             onRequestSort,
                             rowCount,
                             isTotal, 
                             isProformaTotal
                           }) {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isTotalLabelSx = {
    color: "transparent",
    "&:hover": {
      color: "transparent",
      cursor: "default"
    },
    "&:focus": {
      color: "transparent",
    },
  }

  const isTotalCellSx = {
    borderBottom: 'unset'
  }

  const displayHeaderCSS = (id) => {

    if(isTotal && isProformaTotal){
      switch (id) {
        case "categoryBreakdown":
          return isTotalLabelSx
        case "name":
          return isTotalLabelSx
        case "itemId":
          return isTotalLabelSx
      }
    } else if (isTotal && !isProformaTotal){
      return isTotalLabelSx
    }
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.id ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={isTotal ? isTotalCellSx : null}
            >
              <TableSortLabel
                active={orderBy === headCell.id && !isTotal}
                hideSortIcon={isTotal}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={displayHeaderCSS(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
