import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import PhaseToTask from "./phaseToTask";
import DuplicatePhase from "./duplicatePhase";
import {useQuery, useQueryClient} from "react-query";
import {projectPhase} from "../../../utils/queryKeys";
import {getPhase} from "../../../utils/queries";
import {getPhaseStatusColor} from "../Utils";

const PhaseBox = ({
                    id,
                    phase_pool_id,
                    currentPhaseId,
                    phaseClick,
                    children,
                    setAutoSelectMod,
                    projectId,
                    onPhaseConfigUpdate,
                    onModulesConfigUpdate,
                    setCurrentTask,
                    setCurrentTaskId,
                    setTasksConfig,
                    setPhaseUpdated,
                    phaseUpdated,
                    setModuleUpdated,
                    moduleUpdated,
                    setPhaseCopied,
                    phaseCopied,
                    locked,
                    taskUpdated,
                    setTaskUpdated
                  }) => {
  const currentURL = useLocation();
  const queryClient = useQueryClient();
  const [taskCreated, setTaskCreated] = useState(false)

  let phaseRef = useRef()

  const phaseQuery = useQuery(
    [projectPhase, {phaseId: id}],
    getPhase, {
      retry: 0,
      cacheTime: (phaseCopied || taskCreated || phaseUpdated) ? 0 : 300000,
      staleTime: (phaseCopied || taskCreated || phaseUpdated) ? 0 : 300000,
    })

  // Why we use this???
  useEffect(() => {
    if ((currentURL.pathname === '/phases/' + id) && !currentPhaseId) {
      phaseRef.current.click()
      setAutoSelectMod(true)
    }
    onPhaseConfigUpdate(false)
  }, [])

  useEffect(() => {
    if (phaseCopied || phaseUpdated || taskCreated || moduleUpdated) {
      queryClient.refetchQueries([projectPhase, {phaseId: currentPhaseId}])
      setTaskCreated(false)
      setModuleUpdated(false)
    }
  }, [phaseCopied || phaseUpdated || taskCreated || moduleUpdated])

  useEffect(() => {
    if (taskUpdated) {
      queryClient.refetchQueries([projectPhase, {phaseId: currentPhaseId}])
      setTaskUpdated(false)
    }
  }, [taskUpdated])

  return (
    <div>
      {
        currentPhaseId === id
          ? <>
            <PhaseToTask phaseId={currentPhaseId}
                         phaseQuery={phaseQuery}
                         onModulesConfigUpdate={onModulesConfigUpdate}
                         setCurrentTask={setCurrentTask}
                         setCurrentTaskId={setCurrentTaskId}
                         setTasksConfig={setTasksConfig}
                         setTaskCreated={setTaskCreated}
                         locked={locked}/>
            <DuplicatePhase phaseId={currentPhaseId}
                            phaseQuery={phaseQuery}
                            onModulesConfigUpdate={onModulesConfigUpdate}
                            setPhaseCopied={setPhaseCopied}
                            locked={locked}/>
          </>
          : null
      }
      {
        !phaseQuery.isLoading
        && phaseQuery.isSuccess
        && phaseQuery?.data?.data?.attributes != null ?
          <Link to={`/projects/${projectId}/phases/${id}`}
                className="flex flex-col items-center relative z-0">
            <span onClick={() => phaseClick(id, phase_pool_id, phaseQuery)}
                  ref={phaseRef}
                  className={`
                          block
                          p-3
                          h-16
                          w-32
                          z-10
                          text-lg
                          text-center
                          font-heading
                          leading-none
                          flex
                          items-center
                          justify-center
                          uppercase
                          shadow-sm
                          ${currentPhaseId === id ? "bg-darkGray text-white" : `${getPhaseStatusColor(phaseQuery?.data?.data?.attributes?.['status'])} text-white`}
                          `}>
              <h2>
                {phaseQuery?.data?.data?.attributes?.phase_pool['name'] + (phaseQuery?.data?.data?.attributes?.['subname'] != undefined ? "-" + phaseQuery?.data?.data?.attributes?.['subname'] : "")}
              </h2>
            </span>
            {
              phaseQuery?.data?.data?.attributes?.task == null
              && phaseQuery?.data?.data?.attributes?.process_modules?.length > 0 ?
                <span
                  className={currentPhaseId === id ? "h-6 w-6 inline-block absolute -bottom-3 bg-tenzingOrange rotate-45 z-0" : ""}>
                </span>
                : null
            }
          </Link>
          : null
      }
    </div>
  )
}

export default PhaseBox;
