import React, {useState, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form';
import Button from '../../../Common/Button';
import Section from '../../../Common/Section';
import CategorySelector from '../../Components/CategorySelector'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import {client as apiClient} from '../../../../utils/api';
import {
  Field,
  FieldError,
  ErrorText
} from 'Components/Common/Forms';
import {useMutation, useQueryClient, useQuery} from 'react-query';
import {
  projectContractBreakdowns,
  projectInvoices,
  company as companyByIdQuery,
} from '../../../../utils/queryKeys';
import {
  getCompany,
  getProjectContractBreakdowns
} from '../../../../utils/queries';
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment/moment";
import {
  formatDate,
  formFields
} from '../../Utils/Invoice'
import {statusToNum} from '../../Components/Utils/index'
import {parseISO} from "date-fns";
import BoxFileExplorer from "../../../FileExplorer";
import FiFoWrapper from "../../../../Components/Common/BoxTree/FiFoWrapper"
import {getListOfFolderItems, getListOfFolderItemsKey} from "../../../../utils/boxApi/Queries";

function EditInvoice({
                       setShowForm,
                       categories,
                       projectId,
                       bexpConfig,
                       invoiceObject
                     }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategoryBreakdown, notifyCategoryBreakdown] = useState({});

  const defaultValues = {
    contract_breakdown_id: invoiceObject.attributes["contract_breakdown"]["id"],
    invoice_date: invoiceObject.attributes["invoice_date"],
    amount: invoiceObject.attributes["amount"],
    number: invoiceObject.attributes["number"],
    recommended_status_date: invoiceObject.attributes["recommended_status_date"],
    approval_status: statusToNum(invoiceObject.attributes["approval_status"])
  }
  const [formData, setFormData] = useState(defaultValues)

  const {control, register, handleSubmit, setValue, reset, formState: {errors}} = useForm(defaultValues);

  useEffect(() => {
    // Update the form values
    formFields.forEach((fd) => {
      if (['recommended_status_date', 'invoice_date'].includes(fd)) {
        setValue(fd, parseISO(invoiceObject.attributes[fd]))
      } else if (["project", "contract_breakdown"].includes(fd)) {
        setValue(fd + '_id', invoiceObject.attributes[fd]?.id)
      } else if (fd == "approval_status") {
        setValue(fd, statusToNum(invoiceObject.attributes[fd]))
      } else {
        setValue(fd, invoiceObject.attributes[fd])
      }
    })
  }, [invoiceObject?.attributes])

  const queryClient = useQueryClient();

  const notifySuccess = () => toast.success("Invoice updated successfully!");
  const notifyError = () => toast.error("Update Invoice unsuccessful.")

  const contractBreakdowns = useQuery(
    [projectContractBreakdowns, {
      projectId: projectId,
      line_item_id: invoiceObject.attributes?.line_item?.id
    }],
    getProjectContractBreakdowns,
    {enabled: !!projectId && !!invoiceObject.attributes?.line_item?.id}
  );

  const {mutateAsync: sendData} = useMutation(putForm);

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["invoice_date", "recommended_status_date"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value
      })
      // Set the value
      setValue(e.target.id, e.target.value)
    }
  }

  async function putForm(data) {
    const {
      contract_breakdown_id,
      invoice_date,
      amount,
      number,
      recommended_status_date,
      approval_status
    } = data

    return await apiClient.put("/invoices/" + invoiceObject.id, {
      amount_cents: parseInt(amount) * 100, // Manually transform to cents
      recommended_status_date: moment(recommended_status_date).utc().format(),
      invoice_date: moment(invoice_date).utc().format(),
      contract_breakdown_id: parseInt(contract_breakdown_id),
      approval_status: parseInt(approval_status),
      number: number,
      project_id: parseInt(projectId)
    })
  }

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.refetchQueries([projectInvoices, {projectId: projectId}])
      notifySuccess()
      reset(defaultValues)
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
    } catch (error) {
      setLoading(false)
      setDoDisable(false)
      notifyError();
      throw new Error(error)
    }
  }

  // Template Files
  // FETCH Company data in order to extract the Box Folder Ids we need
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const company = useQuery(
    [companyByIdQuery, {companyId: companyId}],
    getCompany)

  // const templateFolderItems = useQuery(
  //   [getListOfFolderItemsKey,
  //     {
  //       folderId: company?.data?.attributes?.["templates_box_folderid"],
  //       accessToken: bexpConfig?.access_token?.data?.attributes['access_token']
  //     }],
  //   getListOfFolderItems,
  //   {
  //     enabled: !company.isLoading
  //       && company.isSuccess
  //       && !!company?.data?.attributes?.["templates_box_folderid"]
  //   })

  if (bexpConfig.access_token.isLoading
    || company.isLoading
    // || templateFolderItems.isLoading
    || contractBreakdowns.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  if (projectId == undefined) {
    return null
  }

  return (
    <Section>
      <h2 className="Section-h1 mb-4">Edit Invoice #{invoiceObject.attributes["number"]}</h2>
      {/* Select the Category */}
      <CategorySelector categories={categories}
                        notifyCategoryBreakdown={notifyCategoryBreakdown}
                        derivedObject={invoiceObject}/>

      {/* Line Item */}
      <Field>
        <label htmlFor="line_item" className="text-tenzingGray">Line Item:</label>
        <input
          id="line_item"
          name="line_item"
          type="text"
          disabled={true}
          className="input-text"
          value={invoiceObject.attributes?.line_item?.name}
        />
      </Field>

      {/* Create my Form */}
      <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        {/* CONTRACT BREAKDONW */}
        <Field>
          <label htmlFor="contract_breakdown_id" className="text-tenzingGray">Contract Breakdown:</label>
          <select id="contract_breakdown_id"
                  name="contract_breakdown_id"
                  className="input-select"
                  value={formData.contract_breakdown_id}
                  onChange={handleFormChange}
          >
            <option defaultValue="Contract Breakdown">Contract Breakdown</option>
            {contractBreakdowns
              && !contractBreakdowns.isLoading
              && contractBreakdowns.data?.data != undefined
              && contractBreakdowns.data?.data?.map((contractBreakdown) => {
                return (
                  <option key={'invoice-form-contract-breakdown-id-' + contractBreakdown.id.toString()}
                          value={contractBreakdown.id}>
                    {contractBreakdown.attributes["firm_name"]}
                  </option>
                )
              })}
          </select>
        </Field>

        {/* INVOICE DATE */}
        <Field>
          <label htmlFor="invoice_date" className="text-tenzingGray">Invoice Date:</label>
          <Controller
            control={control}
            name='invoice_date'
            rules={{required: true}}
            render={({field}) => (
              <DatePicker
                id='invoice_date'
                minDate={null}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("invoice_date", date)
                  // Update the state
                  setFormData({
                    ...formData, ["invoice_date"]: date
                  })
                }}
                selected={formatDate(formData.invoice_date)}
                className={!errors.invoice_date ? "input-text" : 'input-text-error'}
              />
            )}
          />
          <FieldError>
            {errors.invoice_date?.type === 'required' &&
              <ErrorText>Invoice date is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* AMOUNT */}
        <Field>
          <label htmlFor="invoice_date" className="text-tenzingGray">Invoice Amount:</label>
          <div className="currency-wrap">
            <input
              id="amount"
              name="amount"
              type="text"
              {...register("amount", {required: true})}
              className={`pl-4 ${!errors.amount ? "input-text" : "input-text-error"}`}
              onChange={handleFormChange}
              value={formData.amount}
            />
          </div>
          <FieldError>
            {errors.amount?.type === 'required' &&
              <ErrorText>Invoice amount is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* NUMBER */}
        <Field>
          <label htmlFor="number" className="text-tenzingGray">Invoice Number:</label>
          <input
            id="number"
            type="text"
            {...register("number", {required: true})}
            className={!errors.number ? "input-text" : 'input-text-error'}
            onChange={handleFormChange}
            value={formData.number}
          />
          <FieldError>
            {errors.number?.type === 'required' &&
              <ErrorText>Invoice number is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* RECOMMENDED STATUS DATE */}
        <Field>
          <label htmlFor="recommended_status_date" className="text-tenzingGray">Recommended Status Date:</label>
          <Controller
            control={control}
            name='recommended_status_date'
            rules={{required: true}}
            render={({field}) => (
              <DatePicker
                id='recommended_status_date'
                minDate={null}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("recommended_status_date", date)
                  // Update the state
                  setFormData({
                    ...formData, ["recommended_status_date"]: date
                  })
                }}
                selected={formatDate(formData.recommended_status_date)}
                className={!errors.recommended_status_date ? "input-text" : 'input-text-error'}
              />
            )}
          />
          <FieldError>
            {errors.recommended_status_date?.type === 'required' &&
              <ErrorText>Recommended status date is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* APPROVAL */}
        <Field>
          <label htmlFor="approval_status" className="text-tenzingGray">Approval Status:</label>
          <select id="approval_status"
                  name="approval_status"
                  className="input-select"
                  value={formData.approval_status}
                  onChange={handleFormChange}
          >
            <option value=""> -- Select --</option>
            <option value="0">Pending</option>
            <option value="1">Approved</option>
            <option value="2">Rejected</option>
            <option value="3">Paid</option>
          </select>
        </Field>

        <section className="flex justify-between mt-4 mb-4">
                <span className="flex">
                  <Button buttonStyle="gray-transparent-button"
                          type="button"
                          onClick={() => setShowForm(false)}>Cancel</Button>
                  <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                          disabled={loading || doDisable}>
                    {
                      doDisable ?
                        <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                        : <></>
                    }
                    Save
                  </Button>
                </span>
          <Button variant="text"
                  buttonStyle="text-gray-600"
                  type="button"
                  onClick={() => {
                    reset(defaultValues)
                  }}
          >Clear</Button>
        </section>
      </form>

      {/*/!* Template files add *!/*/}
      {/*<h3 className="Section-h1">Select Templates:</h3>*/}
      {/*{*/}
      {/*  bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != null*/}
      {/*  && !templateFolderItems.isLoading*/}
      {/*  && templateFolderItems.isSuccess*/}
      {/*  && templateFolderItems.data?.data?.entries?.length > 0 ?*/}
      {/*    templateFolderItems.data?.data?.entries.map(dir => {*/}
      {/*      if (dir.type === 'folder') {*/}
      {/*        return (*/}
      {/*          <>*/}
      {/*            <MultiSelectBoxfiles key={dir.id}*/}
      {/*                                 boxAccess={bexpConfig.access_token}*/}
      {/*                                 boxFolderId={dir.id}*/}
      {/*                                 boxFolderName={dir.name}*/}
      {/*                                 parentFolderId={bexpConfig.boxable?.data?.attributes["cost_box_folderid"]}/>*/}
      {/*          </>*/}
      {/*        )*/}
      {/*      }*/}
      {/*    }) : null*/}
      {/*}*/}

      {/* Template files add */}
      {
        !bexpConfig.access_token.isLoading
        && bexpConfig.access_token.isSuccess
        && bexpConfig.boxable?.data?.attributes != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != ''
        && company?.data?.attributes?.["templates_box_folderid"] != null ?
          <FiFoWrapper boxAccess={bexpConfig.access_token}
                       boxFolderId={company?.data?.attributes?.["templates_box_folderid"]}
                       parentFolderId={bexpConfig.boxable?.data?.attributes["cost_box_folderid"]}/>
          : null
      }

      {/* Import a Box Form */}
      {
        !bexpConfig.access_token.isLoading
        && bexpConfig.access_token.isSuccess
        && bexpConfig.boxable?.data?.attributes != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != '' ?
          <BoxFileExplorer props={bexpConfig}/> : <div>Not linked to Box</div>
      }
    </Section>
  );
}

export default EditInvoice; 
