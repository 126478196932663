import React, {useState, useEffect, useRef} from 'react'
import {client as apiClient} from 'utils/api';
import {useParams} from "react-router-dom"
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import {useQuery} from 'react-query';
import Button from 'Components/Common/Button';
import {
  projectUsers,
  risk as riskQueryKey,
  boxAccessToken,
  company as companyByIdQuery
} from 'utils/queryKeys';
import {
  getRisk,
  getBoxAccessToken,
  getCompany,
  getProjectUsers
} from 'utils/queries'
import {parseISO} from 'date-fns';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {Field} from 'Components/Common/Forms';
import moment from 'moment';
import BoxFileExplorer from '../../../Components/FileExplorer';
import MultiSelectBoxfiles from "../../FileExplorer/MultiSelectBoxfiles";
import FiFoWrapper from "../../../Components/Common/BoxTree/FiFoWrapper";
import {getListOfFolderItems, getListOfFolderItemsKey} from "../../../utils/boxApi/Queries";
import {
  getNumberStatus,
  formatDate,
  capitalize
} from "../../../utils/globals";
import {
  disableCalculation,
  impactValues,
  statusValues
} from '../Utils/index'

function UpdateOrDeleteRiskForm() {
  const [formData, setFormData] = useState({})
  const projectId = useRef(null)
  const [riskBoxFolderId, setRiskBoxFolderId] = useState(null);
  const {control, register, handleSubmit, setValue, setError, clearErrors, reset, formState: {errors}} = useForm();


  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["due_date", "critical"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value.trim()
      })
      // Set the value
      setValue(e.target.id, e.target.value.trim())
    }
  }

  // Fetch Risk

  const {riskId} = useParams()

  const formFields = [
    "name", "user", "project", "due_date", "impact", "rank", "critical", "status"
  ]

  const risk = useQuery(
    [riskQueryKey, {id: riskId}],
    getRisk)

  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)
  const bexpConfig = {
    boxable: risk.data,
    root_folder_column: 'box_folderid',
    access_token: boxAccess
  }

  useEffect(() => {
    if (!risk.isLoading && risk.isSuccess) {
      // Update the state
      setFormData(risk.data.data.attributes)
      // Update the form values
      formFields.forEach((fd) => {
        if (fd === "due_date") {
          setValue(fd, parseISO(risk.data.data.attributes[fd]))
        } else if (["user", "project"].includes(fd)) {
          setValue(fd + '_id', risk.data.data.attributes[fd]?.id)
          if (fd == "project") {
            projectId.current = risk.data.data.attributes[fd]?.id
          }
        } else if (fd === "status") {
          setValue(fd, getNumberStatus(risk.data?.data?.attributes["status"]))
        } else {
          setValue(fd, risk.data.data.attributes[fd])
        }
      })
      setRiskBoxFolderId(risk.data.data.attributes['box_folderid'])
    }
  }, [!risk.isLoading
  && !risk.isFetching
  && risk.isSuccess])

  // FETCH USER LIST
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const users = !risk.isLoading && useQuery([projectUsers, {projectId: risk.data?.data?.attributes['project']?.id}], getProjectUsers)

  const userOptions =
    users.isLoading ? 'Loading...'
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"riskedit" + item.attributes.email + item.id} value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

  const impactValues = [
    "cost",
    "time",
    "other"
  ]

  const impactOptions = impactValues.map((item) => {
    return (
      <option key={"riskedit" + item + riskId} value={item}>{capitalize(item)}</option>
    )
  }, this)

  const rankValues = [
    "low",
    "medium",
    "high"
  ]

  const rankOptions = rankValues.map((item) => {
    return (
      <option key={"riskedit" + item + riskId} value={item}>{capitalize(item)}</option>
    )
  }, this)

  const statusOptions = statusValues.map((item, index) => {
    return (
      <option key={"risk" + item + riskId} value={index}>{capitalize(item)}</option>
    )
  }, this)

  // FORM

  const queryClient = useQueryClient();


  const notifySuccess = () => toast.success("Risk updated successfully");
  const notifyError = () => toast.error("There was an error updating the risk");

  async function patchForm(data) {
    // Unpack values to variable
    const {name, project_id, user_id, due_date, impact, rank, critical, status} = data
    // format my date
    const formattedDueDate = moment(formData.due_date).utc().format()

    // Make the request and wait for the response
    return await apiClient.patch('/risks/' + riskId, {
      name: name,
      project_id: parseInt(projectId.current),
      user_id: parseInt(user_id),
      status: parseInt(status), // This is the default, status: pending
      due_date: formattedDueDate,
      impact: impact,
      rank: rank,
      critical: critical
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(patchForm);

  const onSubmit = async (data, e) => {
    try {
      await sendData(data)
      await queryClient.refetchQueries([riskQueryKey, {id: riskId}])
      notifySuccess()
      reset()
      e.target.reset()
    } catch (err) {
      notifyError()
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  // Template Files
  // FETCH Company data in order to extract the Box Folder Ids we need
  const company = useQuery(
    [companyByIdQuery, {companyId: companyId}],
    getCompany)

  const templateFolderItems = useQuery(
    [getListOfFolderItemsKey,
      {
        folderId: company?.data?.attributes?.["templates_box_folderid"],
        accessToken: boxAccess?.data?.attributes['access_token']
      }],
    getListOfFolderItems,
    {
      enabled: !company.isLoading
        && company.isSuccess
        && !!company?.data?.attributes?.["templates_box_folderid"]
    })

  return (
    <>
      {risk.isLoading
        ? <div className="lds-dual-ring"></div> :
        <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

          {/* NAME */}
          <Field>
            <label htmlFor="name" className="text-tenzingGray">Risk Name</label>
            <input
              label="Risk Name"
              id="name"
              type="text"
              placeholder="Risk Name"
              {...register("name", {required: true})}
              className={!errors.name ? "input-text" : 'input-text-error'}
              value={formData.name ?? ""}
              onChange={handleFormChange}
            />
            <FieldInputError item={errors.name}></FieldInputError>
          </Field>

          {/* ASSIGNEE */}
          <Field>
            <label htmlFor="user_id" className="text-tenzingGray">User</label>
            <select
              id="user_id"
              placeholder="Assigned To"
              {...register("user_id", {required: true})}
              className={!errors.user_id ? "input-text" : 'input-text-error'}
              value={formData.user_id ?? formData.user?.id ?? ""}
              onChange={handleFormChange}
            >
              {userOptions}
            </select>
            <FieldInputError item={errors.user_id}></FieldInputError>
          </Field>

          {/* DUE DATE */}
          <div className="flex justify-between items-baseline">
            <Field>
              <label htmlFor="due_date" className="text-tenzingGray">Due Date</label>
              <Controller
                label={formatDate(formData.due_date)}
                control={control}
                name='due_date'
                rules={{required: false}}
                render={({field}) => (
                  <DatePicker
                    id='due_date'
                    placeholderText={formatDate(formData.due_date)}
                    minDate={null}
                    onChange={(date) => {
                      field.onChange(date)
                      // Set the value
                      setValue("due_date", date)
                      // Update the state
                      setFormData({
                        ...formData, ["due_date"]: date
                      })
                    }}
                    selected={moment(formData.due_date).toDate()}
                    className={!errors.due_date ? "input-text" : 'input-text-error'}
                  />
                )}
              />
              <FieldInputError item={errors.due_date}></FieldInputError>
            </Field>
          </div>

          {/* IMPACT */}
          <Field>
            <label htmlFor="impact" className="text-tenzingGray">Select Impact</label>
            <select
              label="Impact"
              id="impact"
              placeholder="Impact"
              {...register("impact", {required: true})}
              className={!errors.impact ? "input-text" : 'input-text-error'}
              value={formData.impact ?? ""}
              onChange={handleFormChange}
            >
              {impactOptions}
            </select>
            <FieldInputError item={errors.impact}></FieldInputError>
          </Field>

          {/* RANK */}
          <Field>
            <label htmlFor="rank" className="text-tenzingGray">Select Rank</label>
            <select
              label="Rank"
              id="rank"
              placeholder="Rank"
              {...register("rank", {required: true})}
              className={!errors.rank ? "input-text" : 'input-text-error'}
              value={formData.rank ?? ""}
              onChange={handleFormChange}
            >
              {rankOptions}
            </select>
            <FieldInputError item={errors.rank}></FieldInputError>
          </Field>

          {/* CRITICAL */}
          <Field>
            <div className="flex items-end">
              <p className="text-lg text-tenzingGray">Critical:</p>
              <div>
                <label className="flex items-baseline" htmlFor="critical">
                  <input
                    id="critical"
                    name="critical"
                    type="checkbox"
                    checked={Boolean(formData.critical)}
                    className="appearance-none invisible peer"
                    onClick={(event) => {
                      // Set the value
                      setValue("critical", event.target.checked)
                      // Update the state
                      setFormData({
                        ...formData, ["critical"]: event.target.checked
                      })
                    }}
                    {...register("critical")}
                  />
                  <span className="w-14 h-8
                                flex items-center
                                flex-shrink-0
                                mr-4 p-1
                                bg-lightGray
                                rounded-full
                                duration-300 ease-in-out
                                peer-checked:bg-tenzingBlue
                                after:w-6
                                after:h-6
                                after:bg-white
                                after:rounded-full
                                after:shadow-md
                                after:duration-300
                                peer-checked:after:translate-x-6
                                "></span>
                </label>
              </div>
            </div>
          </Field>

          {/* STATUS */}
          <Field>
            <label htmlFor="status" className="text-tenzingGray ">Risk Status:</label>
            <select
              id="status"
              name="status"
              className={!errors.status ? "input-text" : 'input-text-error'}
              {...register("status", {required: true})}
              value={formData.status ? getNumberStatus(formData.status) : ""}
              onChange={handleFormChange}
            >
              {statusOptions}
            </select>
            <FieldInputError item={errors.status}></FieldInputError>
          </Field>

          <section className="mt-4 flex justify-end">
            <Button buttonStyle="orange-solid-button" type="submit" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </section>
        </form>
      }

      {/*/!* Template files add *!/*/}
      {/*<h3 className="Section-h1">Select Templates:</h3>*/}
      {/*{*/}
      {/*  riskBoxFolderId != null*/}
      {/*  && !templateFolderItems.isLoading*/}
      {/*  && templateFolderItems.isSuccess*/}
      {/*  && templateFolderItems.data?.data?.entries?.length > 0 ?*/}
      {/*    templateFolderItems.data?.data?.entries.map(dir => {*/}
      {/*      if (dir.type === 'folder') {*/}
      {/*        return (*/}
      {/*          <>*/}
      {/*            <MultiSelectBoxfiles key={dir.id}*/}
      {/*                                 boxAccess={boxAccess}*/}
      {/*                                 boxFolderId={dir.id}*/}
      {/*                                 boxFolderName={dir.name}*/}
      {/*                                 parentFolderId={riskBoxFolderId}/>*/}
      {/*          </>*/}
      {/*        )*/}
      {/*      }*/}
      {/*    }) : null*/}
      {/*}*/}


      {/* Template files add */}
      {
        risk.data?.permissions?.['update']
        && !risk.isLoading
        && risk.isSuccess
        && !!risk.data?.data
        && risk?.data?.data?.attributes?.["box_folderid"] != null
        && company?.data?.attributes?.["templates_box_folderid"] != null ?
          <FiFoWrapper boxAccess={boxAccess}
                       boxFolderId={company?.data?.attributes?.["templates_box_folderid"]}
                       parentFolderId={risk?.data?.data?.attributes?.["box_folderid"]}/>
          : null
      }

      {/* Import a Box Form */}
      {
        (risk.isLoading || bexpConfig.access_token.isLoading) ? null :
          risk.data?.data?.attributes?.["box_folderid"] == undefined ?
            <div>Not linked to Box</div> :
            <BoxFileExplorer props={bexpConfig}/>
      }
    </>
  )
}

export default UpdateOrDeleteRiskForm
