import React from 'react'
import Tooltip from "@mui/material/Tooltip";


const NodeContent = ({item}) => {
  return (
    <Tooltip
      component={'span'}
      arrow
      title={item.name}
      placement={"left"}
    >
      <span data-fname={item.name}>
        {item.name}
      </span>
    </Tooltip>
  )
}

export default NodeContent
