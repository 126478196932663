import React from 'react'
import {
  getTaskColor,
  getTaskShape
} from "../../../utils/globals"

const TaskBox = ({
                   task,
                   setCurrentTask,
                   setCurrentTaskId,
                   setTasksConfig,
                   currentTaskId,
                   taskUpdated,
                   setTaskUpdated
                 }) => {
  const taskClick = (id, task) => {
    setCurrentTaskId(id)
    setCurrentTask(task)
    setTasksConfig(true)
    setTaskUpdated(false)
  }

  const classes = `block
                  cursor-pointer
                  text-center border
                  border-darkGray
                  ${getTaskShape(1)}
                  ${(currentTaskId === task.id && !taskUpdated) ? 'bg-darkGray text-white'
                                              : getTaskColor(task.attributes["status"] ? task.attributes["status"] : 1)}`

  return (
    <span
      key={"process_task-" + task.id.toString()}
      id={task.id}
      onClick={() => taskClick(task.id, task)}
      style={{justifySelf: "center"}}
      className={classes}
    >{task.attributes["name"]}</span>
  )
}

export default TaskBox;
