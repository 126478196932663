import React, {useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {client as apiClient} from 'utils/api';
import {useQueryClient} from 'react-query'
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {homeURL} from 'utils/urls';
import Button from 'Components/Common/Button';
import {Field} from 'Components/Common/Forms';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import CircularProgress from '@mui/material/CircularProgress'
import {allProjects} from "../../../utils/queryKeys";

function Form({gapi}) {
  const queryClient = useQueryClient();
  const navigate = useHistory();
  const newProjectId = useRef(null)
  const [doDisable, setDoDisable] = useState(false);

  // Form
  const {register, handleSubmit, reset, setError, formState: {errors}} = useForm();

  const notifySuccess = (msg) => {
    toast.success(msg);
  }
  const notifyError = (msg) => {
    toast.error(msg)
  }

  async function postForm(data) {
    const {name, identifier, location, description} = data

    return await apiClient.post("/projects", {
      name: name,
      description: description,
      identifier: identifier,
      location: location
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postForm, {
    onSuccess: (data, variables, context) => {
      if (data.request?.response != undefined
        && data.request?.response != '') {
        const resp = JSON.parse(data.request.response)
        newProjectId.current = resp.data.id

        queryClient.refetchQueries([allProjects])
        setDoDisable(false)
        navigate.push("/projects/" + newProjectId.current + "/manager/first")
      }
    },
    onError: (error, variables, context) => {
      notifyError("Project creation failed.")
      setDoDisable(false)
    }
  });

  const onSubmit = async (data, e) => {
    try {
      setDoDisable(true)
      const response = await sendData(data)
      notifySuccess("Project saved successfully!")
      reset()
      e.target.reset()
    } catch (err) {
      setDoDisable(false)
      notifyError("Project save failed")
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  return (
    <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      {/*NAME*/}
      <Field>
        <label htmlFor="name" className="text-tenzingGray">Project Name</label>
        <input
          id="name"
          type="text"
          placeholder="Project Name"
          {...register("name", {required: true})}
          className={!errors.name ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.name}></FieldInputError>
      </Field>

      {/*IDENTIFIER*/}
      <Field>
        <label htmlFor="identifier" className="text-tenzingGray">Project Identifier</label>
        <input
          id="identifier"
          type="text"
          placeholder="Project Identifier"
          {...register("identifier", {required: true})}
          className={!errors.identifier ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.identifier}></FieldInputError>
      </Field>

      {/*LOCATION*/}
      <Field>
        <label htmlFor="location" className="text-tenzingGray">Project Location</label>
        <input
          id="location"
          type="text"
          placeholder="Project Location"
          {...register("location", {required: true})}
          className={!errors.location ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.location}></FieldInputError>
      </Field>

      {/*DESCRIPTION*/}
      <Field>
        <label htmlFor="description" className="text-tenzingGray">Project Description</label>
        <textarea
          id="description"
          type="text"
          placeholder="Project Description"
          {...register("description", {required: true})}
          className={!errors.description ? "input-text" : 'input-text-error'}
        />
        <FieldInputError item={errors.description}></FieldInputError>
      </Field>

      <section className="mt-4 flex justify-end">
        <Link to={homeURL()}>
          <Button buttonStyle={"underline text-tenzingGray py-0.5 px-4 m-1"}>Cancel</Button>
        </Link>
        <Button buttonStyle={"orange-solid-button"}
                disabled={isLoading || doDisable}>
          {
            doDisable ?
              <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
              : <></>
          }
          Save & Continue
        </Button>
      </section>
    </form>
  )
}

export default Form;



