import React from 'react';
import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from 'react-query';
import {toast} from 'react-toastify';
import {Field} from 'Components/Common/Forms';
import {
  companyCloudinary
} from '../../../utils/queryKeys';
import {
  updateProject,
  getCompanyCloudinary
} from '../../../utils/queries'
import CldUploadRestApi from '../../CldUploadRestApi'


function ImageForm(props) {
  const navigate = useHistory();

  // Pass this function to the child
  const assignCloudinaryUrl = (url) => {
    const tdata = {
      cloudinary_url: url
    }

    patchProject([updateProject, {projectId: parseInt(props?.props?.projectId), data: tdata}])
  }

  // Update Project
  const {isLoading: isLoadingProjectUpdate, mutateAsync: patchProject} = useMutation(updateProject, {
    onSuccess: (data, variables, context) => {
      notifySuccess()
      navigate.push("/projects/" + props?.props?.projectId + "/process")
    },
    onError: (error, variables, context) => {
      notifyError()
    }
  });

  const companyId = parseInt(window.localStorage.getItem("company_id"))


  // FETCH Cloudinary Config
  const cloudinary_config = useQuery(
    [companyCloudinary, {companyId: companyId}],
    getCompanyCloudinary,
    {
      refetchOnWindowFocus: false,
      retryOnMount: false
    })

  const notifySuccess = () => {
    toast.success("Project Image URL saved.")
  }

  const notifyError = () => {
    toast.error("Project Image URL save failed.")
  }


  if (cloudinary_config.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }


  return (

    <>
      <CldUploadRestApi assignCloudinaryUrl={assignCloudinaryUrl}
                        config={cloudinary_config?.data?.attributes}/>
    </>
  )
}

export default ImageForm



