import React from 'react'
import * as MuiIcons from "@mui/icons-material";

export const sidebarNoProjectConfig = [
  {
    name: "Projects",
    route: "/projects",
    icon: "GridView"
  },
  {
    name: "Archived Projects",
    route: "/projects/archived",
    icon: "Inventory"
  },
  {
    name: "Contacts",
    route: "/contacts",
    icon: "Contacts"
  },
  // {
  //   name: "Calendar",
  //   route: "/calendar",
  //   icon: "CalendarToday"
  // }
]

// Calculate the Menu Icon Dynamically
export const calcIcon = (icon) => {
  if (icon == undefined) {
    return null
  }
  if (typeof icon === 'string') {
    return React.createElement(MuiIcons[icon])
  }
}
