import React, {useState, useEffect} from "react";
import {client as apiClient} from '../../../../utils/api';
import {
  useStyles,
  reorder
} from '../../../../utils/toolBox';
import DraggableList from '../../../Draggable/DraggableList';
import Section from '../../../../Components/Common/Section'
import Button from '../../../../Components/Common/Button';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  phaseModulesSetup,
  phasePoolModulePool,
  projectPhaseModules
} from "../../../../utils/queryKeys";
import {useQuery, useQueryClient} from "react-query";
import {getPhasePoolsModulePools} from "../../../../utils/queries";
import Modal from "../../../Common/Modal"
import CustomModuleFields from "../CustomModuleFields"


const ModuleDragList = ({
                          currentPhasePoolId,
                          setModuleUpdated
                        }) => {
  const [doDisable, setDoDisable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const queryClient = useQueryClient();
  const params = useParams()

  // Pass this function to the modal
  const handleShowModal = (onoff) => {
    setShowModal(onoff)
  }

  const moduleQuery = useQuery(
    [phasePoolModulePool, {phasePoolId: currentPhasePoolId}],
    getPhasePoolsModulePools
  )

  // As soon as we get the data we need to transmogrify them into drag list compatible format
  const module_list =
    moduleQuery.isLoading ? []
      : moduleQuery.data?.data?.length && moduleQuery.data?.data?.map((item) => {
      return (
        {
          key: `Module ${item.id}`,
          id: `${item.id}`,
          primary: item.attributes.name,
          secondary: item.attributes.description
        }
      )
    }, this);

  // Load the Module Pool
  useEffect(() => {
    // check whether data exists
    if (!moduleQuery.loading && !!moduleQuery.data?.data) {
      setItems(module_list)
    }
  }, [moduleQuery.data?.data, moduleQuery.loading])


  const onDragEnd = ({destination, source}) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };

  // Delete Mods from list
  const onDeleteItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  }

  const duplicateItem = (id) => {
    // todo
    console.log('item duplicated')
  }

  const resetMods = () => {
    setItems(module_list)
  }

  const notifySuccess = () => toast.success("Modules added successfully!");
  const notifyError = () => toast.error("Adding Modules failed.")

  const {handleSubmit, formState: {errors}} = useForm();

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      // Post to modules
      await apiClient.post(phaseModulesSetup, {
        list: items,
        phase_id: params['id']
      }).then((response) => {
        notifySuccess()
        setLoading(false)
        setDoDisable(false)
        setModuleUpdated(true)
        // Reload Process Modules
        queryClient.refetchQueries([projectPhaseModules, {phaseId: params['id'], projectId: params['projectId']}]);
      }).catch((error) => {
        notifyError()
        setLoading(false)
        setDoDisable(false)
      });

    } catch (err) {
      notifyError()
      setLoading(false)
      setDoDisable(false)
      throw new Error(err)
    }
  }

  return (
    <Section>
      <div className="flex justify-between mb-4 xl:w-80">
        <h1 className="Section-h1">Modules</h1>
        <div className="flex flex-row">
          <Button onClick={() => resetMods()} buttonStyle={"orange-transparent-button"}>
            Reset
          </Button>
          <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                    disabled={loading || doDisable}>
              {
                doDisable ?
                  <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                  : <></>
              }
              Add All
            </Button>
          </form>
        </div>
      </div>
      <div className={useStyles.root}>
        {
          moduleQuery.isLoading ?
            <div className="lds-dual-ring"></div>
            :
            <div className={useStyles.flexPaper}>
              <DraggableList type="Module"
                             onDeleteItem={onDeleteItem}
                             duplicateItem={duplicateItem}
                             items={items}
                             onDragEnd={onDragEnd}
                             handleShowModal={handleShowModal}
              />
            </div>
        }
      </div>

      {/* Modal for the Custom Modal fields */}
      <Modal mdlTitle="Add Custom Module"
             buttonStyle=""
             content={CustomModuleFields({handleShowModal, currentPhasePoolId})}
             btnLabel=""
             handleShowModal={handleShowModal}
             showModal={showModal}
      />
    </Section>
  );

}

export default ModuleDragList;

