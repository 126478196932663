import React, {useEffect, useState} from 'react'
import {useQuery} from "react-query";
import {Link, useLocation, Switch, Route} from "react-router-dom";
import RiskList from 'Components/Risks/List'
import TaskList from '../../../Components/Tasks/List/List'
import {getCompanyStats, getMyTasks, getGoogleMailAccessToken} from "utils/queries";
import {companyStats, myTasks, googleMailToken} from "utils/queryKeys";
import {gapi} from "gapi-script";
import {DiscoveryDocs, REACT_APP_CLIENT_ID, SCOPES} from "../../../utils/google/Auth";
import Section from '../../../Components/Common/Section';
import SendEmail from "../../../Components/Correspondence/Inbox/SendEmail";
import DropZone from "../../../Components/BoxDropZone/DropZone";
import {emailContext} from "../../../Components/Correspondence/Utils/Index"
import {CorrespondenceContext} from "../../../context/CorrespondenceContext";
import
  ContentTwoColumn, {WideColumn, NarrowColumn}
  from '../../../Components/Common/Layout/ContentTwoColumn'
import Form from '../../../Components/Tasks/Form';
import QuickNote from "../../../Components/QuickNotes"

const LateRisks = () => {
  const [showForm, setShowForm] = useState(true)
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [objectLabelId, setObjectLabelId] = useState(null)
  const [objectLabelName, setObjectLabelName] = useState("")
  const [dropZoneVisibility, setDropZoneVisibility] = useState(false)
  const [dropZoneObject, setDropZoneObject] = useState("")
  const [selectedLabels, setSelectedLabels] = useState([])
  const [correspondenceContext, setCorrespondenceContext] = useState(emailContext);
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [isAuthorized, setIsAuthorized] = useState(false)


  const currentURL = useLocation()
  const companyId = parseInt(window.localStorage.getItem("company_id"))

  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  const statsQuery = useQuery(
    [companyStats, {companyId: companyId}],
    getCompanyStats,
    {
      retry: false,
      retryOnMount: false
    }
  );

  const userTaskQuery = useQuery(
    [myTasks, {
      assigneeId: window.localStorage.getItem("assignee_id"),
      status: 'not_pending'
    }],
    getMyTasks)


  let flaggedRisksQuery = !statsQuery.isLoading && !!statsQuery?.data?.data && statsQuery.data.included.red_flagged.data
  let lateTasksQuery = !statsQuery.isLoading && !!statsQuery?.data?.data && statsQuery.data.included.late_tasks.data
  let myTasksQuery = !userTaskQuery.isLoading && !!userTaskQuery.data?.data && userTaskQuery.data?.data

  if (statsQuery.isLoading
    || userTaskQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  if (!flaggedRisksQuery || !lateTasksQuery || !myTasksQuery) {
    return null
  }

  return (
    <ContentTwoColumn>
      <WideColumn>
        <Section>
          <div className="flex flex-row justify-between">
            <section className="flex flex-row space-x-8 mb-4">
              <Link to={`/companies/${companyId}/risks`}
                    className={`Section-h1 ${currentURL.pathname === `/companies/${companyId}/risks` ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Flagged Risks
              </Link>
              <Link to={`/companies/${companyId}/tasks`}
                    className={`Section-h1 ${currentURL.pathname === `/companies/${companyId}/tasks` ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Late Tasks
              </Link>
              <Link to={`/companies/${companyId}/tasks/mine_all`}
                    className={`Section-h1 ${currentURL.pathname === `/companies/${companyId}/tasks/mine_all` ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                All My Tasks
              </Link>
            </section>
          </div>
          <Switch>
            <Route exact path={`/companies/${companyId}/risks`}
                   component={() => (<RiskList items={flaggedRisksQuery}
                                               projectName={true}
                                               gapi={gapi}
                                               setShowSendEmail={setShowSendEmail}
                                               setShowForm={setShowForm}
                                               setObjectLabelId={setObjectLabelId}
                                               setObjectLabelName={setObjectLabelName}
                                               setDropZoneVisibility={setDropZoneVisibility}
                                               setDropZoneObject={setDropZoneObject}
                                               setSelectedLabels={setSelectedLabels}/>)}/>
            <Route exact path={`/companies/${companyId}/tasks`}
                   component={() => (<TaskList items={lateTasksQuery}
                                               projectName={true}
                                               gapi={gapi}
                                               setShowSendEmail={setShowSendEmail}
                                               setShowForm={setShowForm}
                                               setObjectLabelId={setObjectLabelId}
                                               setObjectLabelName={setObjectLabelName}
                                               setDropZoneVisibility={setDropZoneVisibility}
                                               setDropZoneObject={setDropZoneObject}
                                               setSelectedLabels={setSelectedLabels}/>)}/>
            <Route exact path={`/companies/${companyId}/tasks/mine_all`}
                   component={() => (<TaskList items={myTasksQuery}
                                               projectName={true}
                                               gapi={gapi}
                                               setShowSendEmail={setShowSendEmail}
                                               setShowForm={setShowForm}
                                               setObjectLabelId={setObjectLabelId}
                                               setObjectLabelName={setObjectLabelName}
                                               setDropZoneVisibility={setDropZoneVisibility}
                                               setDropZoneObject={setDropZoneObject}
                                               setSelectedLabels={setSelectedLabels}/>)}/>
          </Switch>
        </Section>
      </WideColumn>
      <NarrowColumn hidden={!userTaskQuery.data?.permissions?.['create']}>
        {
          userTaskQuery.data?.permissions?.['create'] && showForm ?
            <>
              <Form gapi={gapi}
                    projectPickerEnabled={"true"}
                    setSelectedProjectId={setSelectedProjectId}
                    selectedProjectId={selectedProjectId}
              />
              <QuickNote projectId={selectedProjectId}/>
            </>
            : null
        }
        {showSendEmail
        && isAuthorized
        && objectLabelId ?
          <CorrespondenceContext.Provider value={[correspondenceContext, setCorrespondenceContext]}>
            <div className='xl:min-w-[32em]'><SendEmail gapi={gapi}
                                                        objectLabelId={objectLabelId}
                                                        setShowSendEmail={setShowSendEmail}
                                                        setShowForm={setShowForm}
                                                        objectLabelName={objectLabelName}
                                                        selectedLabels={selectedLabels}
                                                        labelListUpdated={false}/>
            </div>
          </CorrespondenceContext.Provider> : null}
        {
          dropZoneVisibility ?
            <div className='xl:min-w-[32em]'><DropZone dropZoneObject={dropZoneObject}
                                                       setDropZoneVisibility={setDropZoneVisibility}
                                                       setShowSendEmail={setShowSendEmail}
                                                       setShowForm={setShowForm}/></div> : null
        }
      </NarrowColumn>
    </ContentTwoColumn>
  )
}

export default LateRisks;



