import React, {useRef} from 'react'
import {Link} from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function AddFileLink({
                       object,
                       setShowSendEmail,
                       setShowForm,
                       setDropZoneVisibility,
                       setDropZoneObject
                     }) {
  const addFileLink = useRef(null)
  let displayDropzoneTimer = null


  // Show the dropzone
  const handleDragOver = (event) => {
    const updateState = (event) => {
      const object = JSON.parse(addFileLink.current.getAttribute('data-object'))
      setDropZoneObject(object)
      setShowSendEmail(false)
      setShowForm(false)
      setDropZoneVisibility(true)
    }
    displayDropzoneTimer = setTimeout(updateState, 500, event)
  }

  const handleDragOut = (event) => {
    if (displayDropzoneTimer) {
      clearTimeout(displayDropzoneTimer)
    }
  }

  return (
    <>
      <Link
        to="#"
        ref={addFileLink}
        component="button"
        size="small"
        data-object={JSON.stringify(object)}
        variant="body2"
        onDragOver={handleDragOver}
        onDragLeave={handleDragOut}
        onClick={() => {
          const object = JSON.parse(addFileLink.current.getAttribute('data-object'))
          setDropZoneObject(object)
          setShowSendEmail(false)
          setShowForm(false)
          setDropZoneVisibility(true)
        }}
      >
        <div className="flex flex-col items-center mr-4">
          <AddCircleIcon fontSize="small"/>
          <u>Add File</u>
        </div>
      </Link>
    </>
  )
}

export default AddFileLink
