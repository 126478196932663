// Base64 to Blob (file Download)
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  var blob = new Blob(byteArrays, {type: contentType})
  return URL.createObjectURL(blob)
}

// Base64 Decode
export const b64Decode = (data) => {
  const dataBase64Rep = data.replace(/-/g, '+').replace(/_/g, '/')
  return atob(dataBase64Rep)
}

//Base64 Encode
export const b64Encode = (data) => {
  return btoa(data)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

export const constructForwardEmailBody = (mail, attachments = [], correspondenceContext = null) => {
  // todo: Add extra attachments. In order to add extra attachments i have to
  //       1. parse the decoded body and find the boundary
  //       2. Construct the new attachments using the constructAttachments function
  //       3. Append the new attachments at the end of the existing ones
  const nl = "\n"
  let boundary = ""
  const boundaryRegex = /boundary="(.*?)"/;
  const emailBody = b64Decode(correspondenceContext.messageRawForward)
    .replace(/(?<=\n)To:\s?(.*)(?=\r?\n)/, ("To: " + mail.to))
    .replace(/(?<=\n)Bcc:\s?(.*)(?=\r?\n)/, "")
    .replace(/(?<=\n)Cc:\s?(.*)(?=\r?\n)/, "")

  // const boundaryParts = emailBody.match(boundaryRegex)
  // if(boundaryParts != undefined && boundaryParts.length > 0) {
  //    boundary = boundaryParts[1]
  // }

  return emailBody
}


export const constructEmailBody = (mail, attachments = [], correspondenceContext = null) => {
  const boundary = "EbUwu6rySbiob0qOwVpxEbUwu6rySbiob0qOwVpx";
  const nl = "\n";
  let str = []
  str.push("MIME-Version: 1.0")
  // This is a reply message
  // todo: Add the parent mail body as quotation manually
  if (correspondenceContext?.messageID != undefined
    && correspondenceContext?.messageID !== '') {
    str.push("References: " + correspondenceContext?.messageID)
    str.push("In-Reply-To: " + correspondenceContext?.messageID)
  }
  str.push("From: " + mail.from)
  str.push("To: " + mail.to)
  str.push("Subject: " + mail.subject)
  str.push("Cc: " + mail.cc)
  str.push("Content-Type: multipart/related; boundary=" + boundary + nl)
  str.push("--" + boundary)
  str.push("Content-Type: text/html; charset=UTF-8")
  str.push("Content-Transfer-Encoding: 7bit" + nl)
  str.push(mail.message)
  str = str.concat(constructAttachments(attachments, boundary, nl))

  return str.join(nl);
}

const constructAttachments = (attachments, boundary, nl) => {
  let str = []
  if (Array.isArray(attachments)) {
    for (let i = 0; i < attachments.length; i++) {
      if (i < attachments.length - 1) {
        str[str.length - 1] = str[str.length - 1] + nl;
      }
      str.push("--" + boundary)
      str.push("Content-Type: " + attachments[i].type + "; name=" + attachments[i].name)
      str.push("Content-Disposition: attachment; filename=" + attachments[i].name)
      str.push("Content-Transfer-Encoding: base64" + nl)
      str.push(attachments[i].content)
      if (i === attachments.length - 1) {
        str.push(nl);
        str.push("--" + boundary + "--");
      }
    }
  }

  return str
}

// These are the default values of the email context
export const emailContext = {
  subject: null,
  from: null,
  cc: null,
  messageID: null,
  messageRawForward: null,
  attachmentsForward: null,
  reply: false,
  forward: false,
  reply_all: false
}

// Default Send Email Form values
export const defaultFormValues = {
  emailAddress: "",
  cc: "",
  subject: "",
  message: ""
}

export const getPlainTextQuoteBody = (mimetypes) => {
  if (mimetypes == undefined) {
    return ""
  }
  let text = ""
  mimetypes.forEach(datatype => {
    if (datatype.mimeType === "text/plain") {
      text = b64Decode(datatype.body.data)
    }
  })

  text = text.split("\n").map(line => {
    line = line.replace(/[\r\n]/gm, '');
    line = ">> " + line
    return line
  }).join("\n")

  return text;
}

export const Avatar = () => {
  return (
    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
  )
}

export const parseEmailList = (csvEmailList) => {
  const email_list = csvEmailList.split(',').map(email => {
    if (email.includes('<')) {
      const lastIndex = email.lastIndexOf(' ');
      return email.slice(lastIndex + 1).replace(/[<>]/g, '')
    } else {
      return email
    }
  }).join(',')

  return email_list
}


export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 500,
  },
  indeterminateColor: {
    color: "#E66B19"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

export const inboxEmails = (id, location = null, queryParams = null) => {
  if (location != undefined) {
    if (queryParams != undefined) {
      return location + '/inbox?' + queryParams
    } else {
      return location + '/inbox'
    }
  } else if (!!id) {
    return '/projects/' + id.toString() + '/correspondence/inbox'
  } else {
    return '/correspondence/inbox'
  }
}

export const sentEmails = (id, location = null, queryParams = null) => {
  if (location != undefined) {
    if (queryParams != undefined) {
      return location + '/sent?' + queryParams
    } else {
      return location + '/sent'
    }
  } else if (!!id) {
    return '/projects/' + id.toString() + '/correspondence/sent'
  } else {
    return '/correspondence/sent'
  }
}

export const readEmails = (id, location = null, queryParams = null) => {
  if (location != undefined) {
    if (queryParams != undefined) {
      return location + '/read?' + queryParams
    } else {
      return location + '/read'
    }
  } else if (!!id) {
    return '/projects/' + id.toString() + '/correspondence/read'
  } else {
    return '/correspondence/read'
  }
}

export const unReadEmails = (id, location = null, queryParams = null) => {
  if (location != undefined) {
    if (queryParams != undefined) {
      return location + '/unread?' + queryParams
    } else {
      return location + '/unread'
    }
  } else if (!!id) {
    return '/projects/' + id.toString() + '/correspondence/unread'
  } else {
    return '/correspondence/unread'
  }
}

export const defaultCorrespondence = (id, location = null, queryParams = null) => {
  if (location != undefined) {
    if (queryParams != undefined) {
      return location + '?' + queryParams
    } else {
      return location
    }
  }
  if (!!id) {
    return '/projects/' + id.toString() + '/correspondence'
  } else {
    return '/correspondence'
  }
}

export const cssActive = " bg-tenzingGrayLight text-white active"
export const cssNonActive = " bg-white hover:text-white hover:bg-tenzingOrange"

export const RefreshIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
         strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
    </svg>
  )
}


export const defaultLabels = [
  "CHAT",
  "INBOX",
  "SPAM",
  "TRASH",
  "UNREAD",
  "STARRED",
  "IMPORTANT",
  "SENT",
  "DRAFT",
  "CATEGORY_PERSONAL",
  "CATEGORY_SOCIAL",
  "CATEGORY_PROMOTIONS",
  "CATEGORY_UPDATES",
  "CATEGORY_FORUMS"]

export const parseSubject = (msgSubject, listOfLabels) => {
  const regex = /\[(.*?)\]/g;
  var m;
  let labelNames = [];
  while ((m = regex.exec(msgSubject)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      if (listOfLabels.current[match.slice(1, -1)] != undefined) {
        labelNames.push(listOfLabels.current[match.slice(1, -1)])
      }
    });
  }

  return labelNames
}

// Merge array2 to array1 and remove duplicates
export const mergeArrays = (array1, array2) => {
  const mergeArr = [...array1, ...array2]
  return [...new Set(mergeArr)]
}


// Break the tag path name to names and create the tag friendly name
export const createTagForList = (labelName, skip = false) => {
  if (labelName.includes("/")) {
    let tagArray = labelName.split("/")
    const tagLen = tagArray.length
    const shortName = tagArray.splice((tagLen - 1), 1)

    // We only want to return the name and skip the path
    if (skip) {
      return shortName
    }


    const badgeList = tagArray.map((tagName) => {
      return ( <span key={Math.floor(Math.random() * 1000)}
                     className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                   {tagName}
               </span> )
    }, this)

    return (
      <>
        {shortName}
        {badgeList}
      </>
    )

  }

  return labelName
}
