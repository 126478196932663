import React, {createElement, useRef} from 'react'
import Button from "@mui/material/Button";
import * as MuiIcons from "@mui/icons-material";
import {
  displayStatusButton,
  statusToNum
} from '../Utils'
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {
  invoice as updateInvoiceKey,
  projectInvoices
} from '../../../../utils/queryKeys'
import {updateInvoice} from '../../../../utils/queries'


function StatusButton({
                        status,
                        invoiceId,
                        projectId
                      }) {
  const buttonRef = useRef(null)
  const queryClient = useQueryClient();
  const {mutateAsync: patchInvoice} = useMutation(updateInvoice, {
    onSuccess: (data, variables, context) => {
      notifySuccess()
      queryClient.refetchQueries([projectInvoices, {projectId: projectId}])
    },
    onError: (error, variables, context) => {
      console.log('Update Status Failed.', error)
      notifyError()
    }
  });

  const handleClick = () => {
    const status = buttonRef.current.getAttribute('data-status')
    const data = {
      approval_status: parseInt(statusToNum(status))
    }
    patchInvoice([updateInvoiceKey, {invoiceId: invoiceId, data: data}])
  }


  const notifySuccess = () => toast.success("Invoice status changed!");
  const notifyError = () => toast.error("There was an error changing the status of the invoice.")


  return (
    <>
      <Button size="small"
              type="button"
              data-status={status}
              ref={buttonRef}
              onClick={handleClick}
              startIcon={createElement(MuiIcons[displayStatusButton(status).icon])}>
        {displayStatusButton(status).label}
      </Button>
    </>
  )
}

export default StatusButton;
