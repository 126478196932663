import React from "react";

export const headCells = [
  {
    id: 'Select',
    numeric: false,
    disablePadding: false,
    label: 'Task Status',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Task #',
  },
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Task Name',
  },
  {
    id: 'assigned',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
  },
  {
    id: 'due_date',
    numeric: false,
    date: true,
    disablePadding: false,
    label: 'Due Date',
  },
  {
    id: 'critical',
    Boolean: true,
    disablePadding: false,
    label: 'Critical Issue',
  },
  {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: '',
  }
];


