import React, {useContext} from 'react'
import MenuItem from "@mui/material/MenuItem";
import {ProjectContext} from "../../context/ProjectContext";
import {useHistory} from "react-router-dom";

function NestedMenuItems({
                           projects,
                           setAnchorEl,
                           menuItem
                         }) {
  const {selectedProject, setSelectedProject} = useContext(ProjectContext);

  const navigate = useHistory();


  const handleChange = (project, event) => {
    setSelectedProject(project);
    window.localStorage.removeItem("projectValue");
    // Add the entire project object in the localstorage
    project['project'] = JSON.parse(event.target.dataset.projectdata)
    window.localStorage.setItem("projectValue", JSON.stringify(project));
    navigate.push('/projects/' + project.value + '/home');
    setAnchorEl(null);
  }

  return (
    <>
      {
        !projects.isLoading
        && projects.isSuccess
        && projects.data.data.length > 0
        && menuItem.type === 'nested'  ?
          <>
            {
              projects?.data?.data?.map((item) => {
                let projectObject = {
                  value: item.id,
                  label: item.attributes.name
                }
                return (
                  <MenuItem
                    key={"nav-button-item" + item.id + menuItem.name}
                    id={"nav-button-item" + item.id + menuItem.name}
                    data-projectdata={JSON.stringify(item)}
                    onClick={(e) => handleChange(projectObject, e)}
                    sx={{'&.MuiMenuItem-root': {"&:hover": {backgroundColor: '#E66B19'}}}}
                  >{item.attributes.name}</MenuItem>
                )
              })
            }
          </>
          :
          <>
            {/* No Projects exist */}
            <MenuItem
              key={"nav-button-item" + item.id + menuItem.name}
              id={"nav-button-item" + item.id + menuItem.name}
              sx={{'&.MuiMenuItem-root': {"&:hover": {backgroundColor: '#E66B19'}}}}
            >No Project</MenuItem>
          </>
      }
    </>
  )
}

export default NestedMenuItems;


