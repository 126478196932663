import React, {useEffect, useState, useRef} from "react";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import moment from 'moment'
import {Tooltip} from '@material-ui/core';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import {gapi} from 'gapi-script'
import {toast} from "react-toastify";
import EventIcon from '@mui/icons-material/Event';
import CircularProgress from "@mui/material/CircularProgress";
import Section from "Components/Common/Section";
import Button from "Components/Common/Button";
import SmallCalendar from "Components/Calendar/SmallCal"
import {
  DiscoveryDocs,
  formatEvents,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "utils/google/Auth";
import {client as apiClient} from "utils/api";
import {project} from "utils/queryKeys"
import {getProject} from "utils/queries";
import {googleCalendarClient} from 'utils/google/Api';
import {getGoogleCalendarAccessToken} from "../../utils/queries";
import {googleCalendarToken} from "../../utils/queryKeys";


const GoogleCalendar = ({
                          isRefreshed,
                          setIsRefreshed,
                          createCalendar,
                          setCreateCalendar,
                          showDay,
                          setShowForm, 
                          setCurrentEvent
                        }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [doDisable, setDoDisable] = useState(false);
  const [newCalendarID, setNewCalendarID] = useState(null);

  const allEvents = useRef([]);
  const monthCalendarRef = useRef();
  const dayCalendarRef = useRef();
  const companyId = parseInt(window.localStorage.getItem("company_id"))

  const {projectId} = useParams()
  const queryClient = useQueryClient();
  const projectQuery = useQuery([project, {id: projectId}], getProject)

  const googleCalendarQuery = useQuery(
    [googleCalendarToken, {companyId: companyId}],
    getGoogleCalendarAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  const notifyError = (err) => {
    // The calendar id we used does not exist.
    if (err?.response != undefined
      && err.response.status == 404) {
      setCreateCalendar(true)
    }
    toast.error("Unable to fulfill Google Request.");
  }

  const notifySuccessCalendar = (msg) => {
    toast.success(msg);
  }

  const notifyErrorCalendar = (msg) => {
    toast.error(msg);
  }

  const getMonthCalendarApi = () => {
    const {current: calendarMonthDom} = monthCalendarRef;
    return calendarMonthDom ? calendarMonthDom.getApi() : null;
  }

  let getDayCalendarApi = null

  if (showDay) {
    getDayCalendarApi = () => {
      const {current: calendarDayDom} = dayCalendarRef;
      return calendarDayDom ? calendarDayDom.getApi() : null;
    }
  }

  useEffect(() => {
    if (!projectQuery.loading
      && !!projectQuery.data?.data
      && isAuthorized
      && projectQuery?.data?.data?.id != undefined) {
      // Do we have a calendar that we can use?
      if (projectQuery?.data?.data?.attributes["google_calendarid"] == undefined
        || projectQuery?.data?.data?.attributes["google_calendarid"] == '') {
        setCreateCalendar(true)
      } else {
        setCreateCalendar(false)
        queryClient.invalidateQueries([googleCalendarToken, {companyId: companyId}])
        getCalendarEvents()
      }
    }
  }, [projectQuery?.data?.data, projectQuery.loading, isAuthorized, isRefreshed])

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleCalendarQuery.isLoading
        && googleCalendarQuery.isSuccess
        && !googleCalendarQuery.isFetching) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleCalendarQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleCalendarQuery?.data?.access_token})
            setIsAuthorized(true)
          }
        })
      }

      gapi?.load('client', gstart)

    }
  }, [!googleCalendarQuery.isLoading && googleCalendarQuery.isSuccess && !googleCalendarQuery.isFetching])

  const getCalendarEvents = () => {
    let eventList = []
    const monthCalendarApi = getMonthCalendarApi()
    // First remove all the events
    monthCalendarApi.getEvents().forEach((event) => {
      event.remove()
    })
    const dayCalendarApi = showDay ? getDayCalendarApi() : null;
    !!dayCalendarApi && dayCalendarApi.getEvents().forEach((event) => {
      event.remove()
    })

    if (gapi?.auth != undefined
      && gapi?.auth?.getToken() != undefined
      && !googleCalendarQuery.isLoading
      && googleCalendarQuery.isSuccess
      && !googleCalendarQuery.isFetching
      && (projectQuery?.data?.data?.attributes["google_calendarid"] != undefined
        || projectQuery?.data?.data?.attributes["google_calendarid"] != "")) {
      const cal_color = projectQuery?.data?.data?.attributes["color_identifier"] ?
        '#' + projectQuery?.data?.data?.attributes["color_identifier"] : "#89CFF0"
      googleCalendarClient.defaults.headers['Authorization'] = `Bearer ${gapi?.auth?.getToken()?.access_token}`;
      googleCalendarClient.get(projectQuery?.data?.data?.attributes["google_calendarid"] + "/events?orderBy=startTime&singleEvents=true")
        .then((data) => {
          if (data.data.items) {
            eventList = []
            allEvents.current = []
            eventList.push(...formatEvents(data.data.items, cal_color))
            allEvents.current.push(...formatEvents(data.data.items, cal_color))
          }
          return eventList
        })
        .then(myevents => {
          // Add the new ones
          myevents.forEach((event) => {
            monthCalendarApi.addEvent(event)
            if (showDay && dayCalendarApi != undefined) {
              dayCalendarApi.addEvent(event)
            }
          })
        })
        .catch((err) => {
          console.error('error:', err)
          notifyError(err)
        })
    }
  }


  // Save the calendar id
  useEffect(() => {
    if (newCalendarID != undefined && newCalendarID != '') {
      apiClient.patch("/projects/" + parseInt(projectId), {
        google_calendarid: newCalendarID
      })
        .then((response) => {
          queryClient.refetchQueries([project, {id: projectId}]);
          setDoDisable(false)
          setNewCalendarID(null)
          notifySuccessCalendar("Updated Project with Calendar info.")
        })
        .catch((error) => {
          console.log('error:', error)
          setDoDisable(false)
          setNewCalendarID(null)
          notifyErrorCalendar("Failed to Update Project with Calendar info")
        });
    }
  }, [newCalendarID]);

  const {handleSubmit, formState: {errors}} = useForm();

  async function postForm(data) {
    googleCalendarClient.defaults.headers['Authorization'] = `Bearer ${gapi?.auth?.getToken()?.access_token}`
    return await googleCalendarClient.post("/", {
      summary: projectQuery?.data?.data?.attributes["name"]
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postForm);

  const onSubmit = async (data, e) => {
    try {
      setDoDisable(true)
      const response = await sendData(data)
      notifySuccessCalendar("Created Calendar.")
      if (response?.data?.id) {
        setNewCalendarID(response?.data?.id)
      }
    } catch (err) {
      setDoDisable(false)
      notifyErrorCalendar("Calendar Creation Failed.")
      console.error(err)
      throw new Error(err)
    }
  }

  function renderInnerContent(innerProps) {
    return (
      <div className='fc-event-main-frame shadow shadow-slate-200'>
        {innerProps.timeText &&
          <div className='fc-event-time'>{innerProps.timeText}</div>
        }
        <div className='fc-event-title-container'>
          <div className='fc-event-title fc-sticky'>
            {innerProps.event.title || <Fragment>&nbsp;</Fragment>}
          </div>
        </div>
      </div>
    );
  }

  const displayEventDetails = (calEvent) => {
    const startDate = new Date(calEvent.startStr)
    const endDate = new Date(calEvent.endStr)

    return (
      <div>
        <h1 className="text-md">{calEvent.title}</h1>
        <h2>Starts At: {moment(startDate).format("lll")}</h2>
        <h2>Ends At: {moment(endDate).format("lll")}</h2>
        {calEvent.extendedProps.attendees ?
          <h2>Attendees: {calEvent.extendedProps.attendees.map(person => `${person.email}(${person.responseStatus}) `)}</h2>
          : null
        }
      </div>
    )
  }

  const handleCalendarChange = (e) => {
    let month = new Date(e)
    let calendar = monthCalendarRef.current.getApi()
    calendar.gotoDate(month)
  }

  const displayEditForm = (calEvent) => {
    setCurrentEvent(calEvent)
    setShowForm("edit_event")
  }


  return (
    <section>
      <section className="flex justify-between items-center p-4 mb-4 bg-white rounded-md shadow-sm w-full min-w-fit">
        <div>
          <h1 className="Section-h1">Project Calendar</h1>
          {/*{*/}
          {/*  isAuthorized && gapi?.auth?.getToken() != undefined ?*/}
          {/*    <span*/}
          {/*      className="bg-gray-200 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2">*/}
          {/*      {window.gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail()}*/}
          {/*    </span> : null*/}
          {/*}*/}
        </div>
        {
          isAuthorized ?
            <div style={{height: "100%"}} className="flex">
              {
                !createCalendar ?
                  <Button
                    onClick={() => setIsRefreshed(!isRefreshed)}
                    buttonStyle={"mr-2 p-2 bg-white text-[#757575] text-sm shadow-sm shadow-[#757575] rounded-sm bg-[#FFFFFF]"}>
                    <span className="mr-1"><RefreshIcon/></span>
                    Refresh
                  </Button>
                  :
                  <form method="post" onSubmit={handleSubmit(onSubmit)}>
                    <Button
                      disabled={doDisable}
                      buttonStyle={"mr-2 p-2 bg-white text-[#757575] text-sm shadow-sm shadow-[#757575] rounded-sm bg-[#FFFFFF]"}>
                          <span className="mr-1">
                          {doDisable ? <CircularProgress size="1rem" color="inherit"/>
                            : <EventIcon/>
                          }
                          </span>
                      Create Calendar
                    </Button>
                  </form>
              }
            </div> : null
        }
      </section>
      {projectQuery.isLoading ? <div className="lds-dual-ring"></div> :
        <Section>
          <div className="flex space-x-2">
            <div className="flex flex-col">
              {!showDay || showDay === undefined ?
                <div className="w-64 h-full">
                  <SmallCalendar handleCalendarChange={handleCalendarChange}/>
                </div>
                : null
              }
            </div>
            <div className="w-full">
              <FullCalendar
                ref={monthCalendarRef}
                plugins={[dayGridPlugin, timeGridPlugin]}
                initialView="dayGridMonth"
                eventDisplay={'block'}
                gotoDate={'2018-06-01'}
                customButtons={{
                  addEvent: {
                    text: 'Add Event',
                    click: function(){
                      setShowForm("new_event")
                    }
                  }
                }}
                eventDidMount={(info) => {
                  info.el.style.borderWidth = '0px 0px 0px 4px';
                  info.el.style.overflow = 'hidden'
                }}
                eventClick={(info) => displayEditForm(info.event)}
                headerToolbar={!showDay || showDay === undefined ?
                  {
                    left: 'title',
                    center: 'prev next',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay today addEvent'
                  } :
                  {
                    left: 'prev',
                    center: "title",
                    right: "next"
                  }}
                eventContent={(info) => {
                  return (
                    <Tooltip
                      arrow
                      title={displayEventDetails(info.event)}
                      placement={"left"}
                    >{renderInnerContent(info)}</Tooltip>
                  )
                }}
              />
            </div>
          </div>
          <div className="mt-2">
            {!showDay || showDay === undefined ? null :
              <FullCalendar
                ref={dayCalendarRef}
                plugins={[dayGridPlugin, timeGridPlugin]}
                initialView="timeGridDay"
                events={allEvents.current}
                eventDisplay={'block'}
                eventDidMount={(info) => {
                  info.el.style.borderWidth = '0px 0px 0px 4px';
                }}
              />
            }
          </div>
        </Section>
      }
    </section>
  );
};

export default GoogleCalendar;

const RefreshIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
         strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
    </svg>
  )
}
