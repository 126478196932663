import React, {useState, useEffect} from 'react';

function CurrentProjectPhase({projectStatsQuery}) {
  const [lastPhase, setLastPhase] = useState("Project Not Started")
  useEffect(() => {
    if (!!projectStatsQuery.data?.data
        && projectStatsQuery.data?.data?.length > 0) {

      // Ordering will be descending
      const orderedPhaseList = projectStatsQuery.data.data.sort(function orderByOrderNum(a, b) {
          return b.order - a.order;
        }
      )

      console.log('orderedPhaseList', orderedPhaseList)

      orderedPhaseList.every((phase) => {
        if(phase.status != 'pending') {
          setLastPhase(phase.name)
          return false
        }
        return true
      })
    }
  }, [projectStatsQuery.data?.data])

  return (
    <>
      <h4 className="gray-transparent-rounded-badge">{lastPhase}</h4>
    </>
  )
}

export default CurrentProjectPhase;
