import React, {useRef, useState} from 'react'
import
{
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import LabelIcon from '@mui/icons-material/Label';
import ReportIcon from '@mui/icons-material/Report';
import {useMutation} from "react-query";
import {
  gmailCreateLabel,
  gmailLabelList,
  gmailLabelKey,
} from "../../../utils/google/Queries";
import {updateProject} from "../../../utils/queries";
import {toast} from "react-toastify";
import Tooltip from "@mui/material/Tooltip";

function CreateProjectGLabel({
                               project,
                               gapi,
                               setIsLabelCreated
                             }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const existingProjectLabelId = useRef(null)

  // Google Create Gmail Label
  const {mutateAsync: postGmailLabel} = useMutation(gmailCreateLabel, {
    onSuccess: (data, variables, context) => {
      // Update the project
      const tdata = {
        gmail_labelid: data.data.id
      }

      patchProject([updateProject, {projectId: parseInt(project?.data?.data?.id), data: tdata}])
    },
    onError: (error, variables, context) => {
      if (error.response.status === 409) {
        console.log('Gmail Label already exists:', error.response.data.message)

        // The label already exists. I will fetch id of the label.
        // Create the label
        getGmailLabelList([gmailLabelKey, {
          access_token: gapi?.auth?.getToken()?.access_token
        }])
      } else {
        console.error('Gmail Label creation failed:', error.message)
        notifyError("Project Gmail label creation failed.")
      }
    }
  });

  // Get the list of Gmail Labels. Iterate over the list and pick the one with the same name
  const {mutateAsync: getGmailLabelList} = useMutation(gmailLabelList, {
    onSuccess: (data, variables, context) => {
      data.data.labels.forEach(label => {
        if (label.name === project.data?.data?.attributes['identifier']) {
          existingProjectLabelId.current = label.id
        }
      })

      // Update the project
      const tdata = {
        gmail_labelid: existingProjectLabelId.current
      }

      patchProject([updateProject, {projectId: parseInt(project?.data?.data?.id), data: tdata}])
    },
    onError: (error, variables, context) => {
      console.error('Gmail label list fetch failed.', error.message)
      notifyError("Project tag list fetch failed.")
    }
  });

  // Update Project
  const {mutateAsync: patchProject} = useMutation(updateProject, {
    onSuccess: (data, variables, context) => {
      setIsLabelCreated(true)
      notifySuccess("Project tag saved.")
      //  I am not sure if i need to refetch something on success here
      setLoading(false)
      setDoDisable(false)
    },
    onError: (error, variables, context) => {
      notifyError("Project tag save failed.")
      setDoDisable(false)
      console.log("project tag save failed", error)
    }
  });

  const notifySuccess = (msg) => {
    toast.success(msg);
  }
  const notifyError = (msg) => {
    setLoading(false)
    setDoDisable(false)
    toast.error(msg)
  }

  const handleClick = () => {
    setLoading(true)
    setDoDisable(true)
    const gdata = {
      "labelListVisibility": "labelShow",
      "messageListVisibility": "show",
      "name": project?.data?.data?.attributes?.['identifier']
    }

    // Create the label
    postGmailLabel([gmailLabelKey, {
      data: gdata,
      access_token: gapi?.auth?.getToken()?.access_token,
    }])
  }

  return (
    <Tooltip title="Create Email Label">
      <IconButton type="button"
                  sx={
                    (loading || doDisable) ? {
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                      marginRight: "0.5em",
                      backgroundColor: "#ccc"
                    } : {
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                      backgroundColor: "#E00000",
                      marginRight: "0.5em",
                      "&:hover": {
                        color: "#E00000",
                        backgroundColor: "#fff",
                      },
                    }}
                  disabled={loading || doDisable}
                  onClick={handleClick}
                  aria-label="label">
        <ReportIcon/>
      </IconButton>
    </Tooltip>
  )
}

export default CreateProjectGLabel;

