import React, {useState, useContext, useEffect} from 'react'
import {ProjectContext} from "../../context/ProjectContext";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavbarList from '../../Components/NavBar/NavbarList';
import ProfileLink from '../../Components/Profile/ProfileLink';


function Navbar({}) {
  const {selectedProject, setSelectedProject} = useContext(ProjectContext);
  // This is only for mobile and for the entire NavBar
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem("projectValue")) {
      let retrieved = window.localStorage.getItem("projectValue")
      setSelectedProject(JSON.parse(retrieved));
    }
  }, [])

  return (
    <nav className="relative pt-4 pr-4 bg-darkGray text-white flex justify-between items-end xl:justify-start">
    <div className="mb-2 ml-1">
      <img src="/tenzing-logo-color.png" className="h-24"/>
    </div>
    <div className="pb-2 flex lg:hidden">
      {!isOpen ? <MoreVertIcon onClick={() => setOpen(true)}/> :
        <>
          <ul className={`${isOpen ? "flex flex-col" : "space-x-10 ml-3 pt-5 z-20"} `}>
            <NavbarList />
            <ProfileLink /> 
          </ul>
          <CloseIcon onClick={() => setOpen(false)}/>
        </>
      }
    </div>
    <div className="hidden lg:flex lg:justify-between w-full">
      <ul className={`${isOpen ? "flex flex-col" : "space-x-10 ml-3 pt-5 z-20"} `}>
        <NavbarList />
      </ul>
      <div className="pt-5 content-center">
         <ProfileLink /> 
      </div>
    </div>
  </nav>
  )
}

export default Navbar

