import React, {useEffect, useState} from "react";
import Section from "Components/Common/Section";
import {useHistory} from "react-router-dom";

function QuickNote({
                     projectId
                   }) {

  const [note, setNote] = useState("")
  const navigate = useHistory();

  const saveOnChange = (e) => {
    setNote(e.target.value)
    window.localStorage.setItem("quickNotes", JSON.stringify(e.target.value))
  }

  const handleSaveNote = (e) => {
    e.preventDefault()
    if (e.currentTarget.value === "task") {
      navigate.push('/projects/' + projectId + '/tasks', {from: 'taskQuickNote'});
    } else if (e.currentTarget.value === "risk") {
      navigate.push('/projects/' + projectId + '/risks', {from: 'riskQuickNote'});
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem("quickNotes")) {
      setNote(JSON.parse(window.localStorage.getItem("quickNotes")))
    }
    window.addEventListener('storage', () => {
      setNote("")
    })
  }, [])

  return (
    <Section>
      <h1 className="Section-h1 mb-3">Quick Notes</h1>
      <form className="flex flex-col items-end">
            <textarea
              id="quickNote"
              name="quickNote"
              value={note}
              onChange={(e) => saveOnChange(e)}
              className="m-y-3 w-full h-24 mb-2 p-2"
            ></textarea>
        <div className="flex m-0">
          <button type="submit" value="task" onClick={(e) => handleSaveNote(e)}
                  className={`${!projectId || projectId == null ? "disabled-button" : "orange-solid-button"}`}
                  disabled={!projectId || projectId == null ? true : false}>Save as Task
          </button>
          <button type="submit" value="risk" onClick={(e) => handleSaveNote(e)}
                  className={`${!projectId || projectId == null ? "disabled-button mr-0" : "orange-solid-button mr-0"}`}
                  disabled={!projectId || projectId == null ? true : false}>Save as Risk
          </button>
        </div>
      </form>
    </Section>
  );
}

export default QuickNote;
