import {Box, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import React from "react";
import {headCells} from '../Utils/index'

function EnhancedTableHead({
                             projectName,
                             order,
                             orderBy,
                             onRequestSort,
                             columnsToHide = []
                           }) {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        {
          !projectName || projectName === undefined ? headCells.filter(headcell => {
              if (headcell.label !== 'Project'
                  && !columnsToHide.includes(headcell.id)) {
                return headcell
              }
            }).map(cell => {
              return (
                <TableCell
                  key={cell.id}
                  align={cell.id ? 'left' : 'center'}
                  sortDirection={orderBy === cell.id ? order : false}
                  width={cell.width ? cell.width : 'auto'}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={createSortHandler(cell.id)}
                  >
                    {cell.label}
                    {orderBy === cell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>)
            }) :
            headCells.filter(headcell => {
              if (!columnsToHide.includes(headcell.id)) {
                return headcell
              }
            }).map((headCell) => {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.id ? 'left' : 'center'}
                  width={headCell.width ? headCell.width : 'auto'}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              )
            })
        }
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
