import React, {useState} from "react";
import Section from "../../../Components/Common/Section";
import Modal from "../../Common/Modal";
import LabelForm from "./LabelForm"
import LabelList from "./LabelList"

function LabelListBox({
                        isAuthorized,
                        gapi,
                        selectedMessages,
                        setUpdateEmailList,
                        updateEmailList,
                        setSelectedLabels,
                        selectedLabels,
                        setUpdateRelated,
                        updateRelated
                      }) {
  const [showModal, setShowModal] = useState(false)
  const [numOfLabels, setNumOfLabels] = useState(0)

  // Pass this function to the modal
  const handleShowModal = (onoff) => {
    setShowModal(onoff)
  }

  return (
    <>
      <Section>
        <div className="flex justify-between items-center">
          <h1 className="Section-h1">Labels</h1>
          {/*<h1 className="Section-h1">Labels <i>#{numOfLabels}</i></h1>*/}
          {/*Modal for the Custom Modal fields */}
          <Modal mdlTitle="Create New Label"
                 buttonStyle="orange-solid-button"
                 content={
                   LabelForm({
                     handleShowModal,
                     gapi,
                     setUpdateRelated,
                     updateRelated
                   })
                 }
                 btnLabel="Create"
                 handleShowModal={handleShowModal}
                 showModal={showModal}
          />
        </div>
        <LabelList isAuthorized={isAuthorized}
                   gapi={gapi}
                   updateRelated={updateRelated}
                   setNumOfLabels={setNumOfLabels}
                   selectedMessages={selectedMessages}
                   setUpdateEmailList={setUpdateEmailList}
                   updateEmailList={updateEmailList}
                   setSelectedLabels={setSelectedLabels}
                   selectedLabels={selectedLabels}/>
      </Section>
    </>
  )
}

export default LabelListBox;
