import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {useQuery} from 'react-query';
import BadgeCircle from '../../Common/Badges/BadgeCircle';
import CostSummary from 'Components/Projects/ListItem/CostSummary';
import ScheduleSummary from 'Components/Projects/ListItem/ScheduleSummary';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {getMyProjectTasks, getProjectStats} from "utils/queries";
import {projectMyTasks, projectStats} from "utils/queryKeys";
import {ProjectContext} from "../../../context/ProjectContext";
import CurrentProjectPhase from "./CurrentProjectPhase";

function ListItem({item}) {
  const projectID = item.id
  const {selectedProject, setSelectedProject} = useContext(ProjectContext);

  const projectStatsQuery = useQuery(
    [projectStats, {projectId: projectID}],
    getProjectStats,
    {
      retry: false,
      retryOnMount: false
    }
  )

  const handleChange = (project, event) => {
    setSelectedProject(project);
    window.localStorage.removeItem("projectValue");
    // Add the entire project object in the localstorage
    project['project'] = JSON.parse(event.currentTarget.dataset.projectdata)
    window.localStorage.setItem("projectValue", JSON.stringify(project));
  }

  let projectObject = {
    value: projectID,
    label: item.attributes["name"]
  }

  const myTasks = useQuery(
    [projectMyTasks, {
      projectId: projectID,
      assigneeId: window.localStorage.getItem("assignee_id")
    }],
    getMyProjectTasks)

  const myCriticalTasks = !myTasks.isLoading
                          && myTasks.isSuccess
                          && myTasks.data?.data?.filter(task => task.attributes["critical"] === true)

  return (
    <article className="grid grid-cols-6 gap-3">
      {/*https://res.cloudinary.com/codii/image/upload/v1655300693/construction_stratos_zg3mrd.jpg*/}
      {
        (item.attributes["cloudinary_url"] == undefined || item.attributes["cloudinary_url"].trim() == '') ?
          <div className="bg-blue-400 flex flex-row justify-center items-center">
            <Link to={`/projects/${projectID}/image`}>
              <UploadFileIcon/>Image
            </Link>
          </div> :
          <div className="aspect-w-4 aspect-h-1"><img className="object-contain"
                                                      src={item.attributes["cloudinary_url"]}/></div>
      }
      <section className="flex flex-col items-center justify-center">
        <h3 className="text-xl text-darkGray font-semibold text-center">{item.attributes['name']}</h3>
        <CurrentProjectPhase projectStatsQuery={projectStatsQuery}/>
      </section>
      <ScheduleSummary projectID={projectID}
                       isSmall={true}/>
      <CostSummary project={item} isSmall={true}/>
      {myTasks.isLoading ? "" : <BadgeCircle item={myCriticalTasks}/>}
      <div className="flex flex-col justify-center">
        <Link
          className="text-sm underline orange-semibold"
          to={`/projects/${projectID}/home`}
          data-projectdata={JSON.stringify(item)}
          onClick={(e) => handleChange(projectObject, e)}>
          <h3>View Full Project</h3>
        </Link>
      </div>
    </article>
  )
}

export default ListItem
