import React, {useEffect} from 'react';
import {useMutation} from "react-query";
import {deepCopyProcessModule} from "../../../utils/queries";
import {toast} from "react-toastify";

const DuplicateProcessModule = ({
                                  moduleId,
                                  moduleQuery,
                                  setStepsConfig,
                                  setDuplicateProcessModuleIsLoading,
                                  setModuleCopied,
                                  locked
                                }) => {
  const handleClick = (event) => {
    const tdata = {
      order: moduleQuery.data?.data?.attributes?.order,
      name: moduleQuery.data?.data?.attributes?.module_pool?.name
    }

    cloneProcessModule([deepCopyProcessModule, {moduleId: parseInt(moduleId), data: tdata}])
  }

  const notifySuccess = () => toast.success("Duplication completed!");
  const notifyError = () => toast.error("Duplication failed.")

  // Update Task
  const {
    isLoading: duplicateProcessModuleLoading,
    mutateAsync: cloneProcessModule
  } = useMutation(deepCopyProcessModule, {
    onSuccess: (data, variables, context) => {
      // I need to reload the whole grid
      setDuplicateProcessModuleIsLoading(false)
      setModuleCopied(true)
      notifySuccess()
    },
    onError: (error, variables, context) => {
      console.log('Failed to Deep Copy Process Module.', error)
      setModuleCopied(true)
      setDuplicateProcessModuleIsLoading(false)
      notifyError()
    }
  });

  if (duplicateProcessModuleLoading) {
    setDuplicateProcessModuleIsLoading(true)
    setStepsConfig(false)
  }

  if (moduleQuery.isLoading
    || duplicateProcessModuleLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <>
      {
        moduleQuery.data?.permissions?.['update']
        && !locked
        && !moduleQuery.isLoading
        && moduleQuery.isSuccess
        && moduleQuery.data?.data?.attributes?.task == null
        && moduleQuery.data?.data?.attributes?.process_steps?.length > 0 ?
          <div className="bg-tenzingOrange
                          relative
                          z-30
                          flex
                          text-white
                          cursor-pointer
                          text-center
                          justify-center
                          font-bold"
               data-modulename={moduleQuery.data?.data?.attributes?.module_pool?.name}
               onClick={handleClick}>
            CLONE
          </div>
          : null
      }
    </>
  )
}

export default DuplicateProcessModule;
