import React, {useState, useRef} from 'react'
import Xarrow from "react-xarrows";

const DrawArrows = ({
                      tasks,
                      width,
                      height
                    }) => {

  // console.log('width', width)
  // console.log('height', height)
  const [forceUpdate, setForceUpdate] = useState(false)

  useRef(() => {
    setForceUpdate(!forceUpdate)
  }, [width, height])

  return (tasks.map((task, i) => {

    const predecessorTasks = task.attributes.process_task_pool?.parent_tasks

    if (Array.isArray(predecessorTasks)) {
      return predecessorTasks.map(predecessorTask => {

        // Get a reference to the parent element
        const parentTask = tasks.find(obj => obj.attributes.process_task_pool.id === predecessorTask.parent_id);

        if (parentTask == undefined) {
          return null;
        }

        // let startXOffset = -5
        // let endXOffset = -5
        // If line is not crossing a Step Column and it is a Bottom to top or vice versa, then make it straight (grid)
        // If line is crossing a Step Column and it is a Bottom to top or vice versa, then make it curved (smooth)
        let crossingColumns = (parentTask.attributes.process_step.step_pool_id != task.attributes.process_step.step_pool_id)

        let curShowHead = true
        let curShowTail = false

        if (crossingColumns) {
          return (
            <Xarrow
              key={parentTask.id.toString() + "-" + task.id.toString()}
              start={parentTask.id}
              end={task.id}
              headSize={6} // 3
              // tailSize={3} // 3
              showHead={curShowHead}
              showTail={curShowTail}
              strokeWidth={1}
              path="smooth"
              color="black"
              curveness={0.65}
              _cpx1Offset={15} // Beginning for Arrow
              _cpx2Offset={15} // End for Arrow
              _extendSVGcanvas={30}
              startAnchor={predecessorTask.start_anchor}
              endAnchor={predecessorTask.end_anchor}
            />
          )
          // For top to bottom and bottom too top that cross step Columns, the style needs to be curved rather than straight
        } else if (predecessorTask.start_anchor == "bottom"
          || predecessorTask.start_anchor == "top"
          || predecessorTask.end_anchor == "top"
          || predecessorTask.end_anchor == "bottom") {
          return (
            <Xarrow
              key={parentTask.id.toString() + "-" + task.id.toString()}
              start={parentTask.id}
              end={task.id}
              headSize={6}
              // tailSize={3} // 3
              showHead={curShowHead}
              showTail={curShowTail}
              strokeWidth={1}
              path="grid"  // smooth
              color="black"
              curveness={0}
              _cpx1Offset={0} // Beginning for Arrow
              _cpx2Offset={0} // End for Arrow
              _extendSVGcanvas={20}
              startAnchor={predecessorTask.start_anchor}
              endAnchor={predecessorTask.end_anchor}

            />
          )

        } else if (predecessorTask.start_anchor == "left"
          && predecessorTask.end_anchor == "left") {
          return (
            <Xarrow
              key={parentTask.id.toString() + "-" + task.id.toString()}
              start={parentTask.id}
              end={task.id}
              headSize={6}
              // tailSize={3} // 3
              showHead={curShowHead}
              showTail={curShowTail}
              strokeWidth={1}
              path="smooth"
              color="black"
              curveness={0.75}
              _cpx1Offset={-35} // Beginning for Arrow
              _cpx2Offset={-35} // End for Arrow
              _extendSVGcanvas={20}
              startAnchor={predecessorTask.start_anchor}
              endAnchor={predecessorTask.end_anchor}

            />
          )

        } else {
          return (
            <Xarrow
              key={parentTask.id.toString() + "-" + task.id.toString()}
              start={parentTask.id}
              end={task.id}
              headSize={6}
              // tailSize={3} // 3
              showHead={curShowHead}
              ShowTail={curShowTail}
              strokeWidth={1}
              path="smooth"
              color="black"
              curveness={0.75}
              _cpx1Offset={35} // Beginning for Arrow
              _cpx2Offset={35} // End for Arrow
              _extendSVGcanvas={20}
              startAnchor={predecessorTask.start_anchor}
              endAnchor={predecessorTask.end_anchor}
            />
          )
        }
      })
    }
  }))
}

export default DrawArrows;
