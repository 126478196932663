import React from "react";

export const headCells = [
  {
    id: 'Select',
    numeric: false,
    disablePadding: false,
    label: 'Task Status',
  },
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Task #',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Task Name',
  },
  {
    id: 'assigned',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
  },
  {
    id: 'due_date',
    numeric: false,
    date: true,
    disablePadding: false,
    label: 'Due Date',
  },
  {
    id: 'critical',
    Boolean: true,
    disablePadding: false,
    label: 'Critical Issue',
  },
  {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "assigned") {
    if (b.attributes["assignee"].email < a.attributes["assignee"].email) {
      return -1;
    }
    if (b.attributes["assignee"].email > a.attributes["assignee"].email) {
      return 1;
    }
    return 0;
  } else {
    if (b.attributes[orderBy] < a.attributes[orderBy]) {
      return -1;
    }
    if (b.attributes[orderBy] > a.attributes[orderBy]) {
      return 1;
    }
    return 0;
  }

}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Impact Option List
export const impactValues = [
  "cost",
  "time",
  "other"
]

// Status options list
export const statusValues = [
  "Pending",
  "In process",
  "Completed"
]

// Calculate Field Disable
export const disableCalculation = (object, locked, user_id, field_name, isProcessMap) => {
  if (!!isProcessMap && field_name === 'name') {
    return true
  }

  if (object.data?.permissions?.['superuser']
      || object.data?.permissions?.['project_manager']
  ) {
    return false
  }

  if (!!locked) {
    return true
  }

  if(object.data?.permissions?.['update']
     || object.data?.permissions?.['manage']
     || object.data?.data?.data?.project?.manager_id == user_id) {
    return false
  }
}
