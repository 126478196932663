import React, {useState} from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteAction from './DeleteAction';
import DuplicateAction from './DuplicateAction';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ActionButton = ({
                        onDeleteItem,
                        duplicateItem,
                        item
                      }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const menuButtonClicked = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const menuDropDownClosed = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        id={"draggable-list-actions"}
        aria-controls={"draggable-list-actions"}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={menuButtonClicked}
        sx={{
          color: 'black',
          backgroundColor: 'transparent',
          '& .MuiPaper-root': {
            left: "unset !important",
            right: "0",
            '& .MuiList-root': {
              padding: "0"
            }
          }
        }}
      >
        <MoreVertIcon/>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="action-menu"
        open={open}
        onClose={menuDropDownClosed}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiList-root': {
              padding: '0',
              '& .MuiMenuItem-root': {
                padding: '0'
              }
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem>
          <DeleteAction onDeleteItem={onDeleteItem} item={item}/>
        </MenuItem>
        {/*<MenuItem>*/}
        {/*  <DuplicateAction duplicateItem={duplicateItem} item={item}/>*/}
        {/*</MenuItem>*/}
      </Menu>
    </>
  )
}


export default ActionButton;
