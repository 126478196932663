import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {toast, ToastContainer} from "react-toastify";
import {
  Field
} from 'Components/Common/Forms';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import Button from '../../Components/Common/Button';
import Section from '../../Components/Common/Section';
import {signIn} from '../../utils/queries'
import {useHistory} from "react-router-dom";
import {removeLocalStorage, setLocalStorage} from "../../utils/globals";


function LoginForm() {
  const [formData, setFormData] = useState({})
  const {register, handleSubmit, setValue, setError, clearErrors, reset, formState: {errors}} = useForm();
  const navigate = useHistory();

  // Update all fields
  const handleFormChange = (e) => {
    // Update the state(merge current data to the ones found in the state)
    setFormData({
      ...formData, [e.target.id]: e.target.value.trim()
    })
    // Set the value
    setValue(e.target.id, e.target.value.trim())
  }

  // FORM
  const notifySuccess = () => toast.success("Logged in.");
  const notifyError = () => toast.error("Access Denied.")

  async function postForm(data) {
    // Unpack values to variable
    const {email, password} = data
    // Make the request and wait for the response
    const payload = {
      email: email,
      password: password
    }
    return signIn(payload)
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postForm);

  const onSubmit = async (data, e) => {
    await sendData(data).then((resp) => {
      reset()
      e.target.reset()
      setLocalStorage(resp)
      window.location.href = "/"
    }).catch((err) => {
      // We can not allow a partial login. If something failed then
      // remove any stored key and force the user to retry
      removeLocalStorage()
      notifyError()
      console.log(err)
    })
  }

  return (
    <div className="flex h-screen">
      <section className="w-1/2 h-full">
        <img src="building_blue.jpg" className="h-full object-cover"/>
      </section>
      <section className="w-1/2 p-4 h-full">
        <Section height={"flex h-full"}>
          <div className="flex flex-col justify-center">
            <img src="/tenzing-logo-color.png" className=""/>
            <p className="py-4 text-lightGray">Welcome back! Please login to your account.</p>
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              {/* NAME */}
              <Field>
                <label htmlFor="email" className="text-tenzingGray">Email:</label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className={!errors.email ? "input-text" : 'input-text-error'}
                  {...register("email", {required: true})}
                  value={formData?.email ?? ""}
                  onChange={handleFormChange}
                />
                <FieldInputError item={errors.email}></FieldInputError>
              </Field>
              {/* Password */}
              <Field>
                <label htmlFor="password" className="text-tenzingGray">Password:</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className={!errors.password ? "input-text" : 'input-text-error'}
                  {...register("password", {required: true})}
                  value={formData?.password ?? ""}
                  onChange={handleFormChange}
                />
                <FieldInputError item={errors.password}></FieldInputError>
              </Field>

              <Button type="submit"
                      buttonStyle="orange-solid-button">
                Login
              </Button>
            </form>
          </div>
        </Section>
      </section>
      <ToastContainer
        position="bottom-left"
        autoClose={false}
        closeOnClick
        rtl={false}
      />
    </div>
  );
}

export default LoginForm;

// flex flex-col justify-center self-center