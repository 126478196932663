import React, {useCallback, useRef} from 'react'
import {DataSet, Timeline} from "vis-timeline/standalone";
import {
  calculateItemClasses,
  groupLineTemplate,
  groupLineNameTemplate,
  getRandomInt,
  groupLineHeaders,
  isExtensionApproved,
  hasRisk,
  isLessOrEqualToEndsAt
} from "../Resources/index"
import {useHistory} from "react-router-dom";
import '../../../Components/Styles/Timeline.css'

const TimelineChart = ({
                         projectStart,
                         projectEnd,
                         activities,
                         permissions,
                         activityType,
                         isOverview,
                         activitiesPermissions,
                         setActivityToEdit
                       }) => {
  const randomFeed = 99999999
  const activityIdPostfix = '-activityidfortimeline'
  const navigate = useHistory()
  const groups = useRef(null);
  const items = useRef(null);
  const timeline = useRef(null)

  const options = {
    stack: false,
    // maxHeight: 640,
    showMinorLabels: true,
    groupHeightMode: 'fixed',
    horizontalScroll: true,
    verticalScroll: false,
    zoomKey: "ctrlKey",
    dataAttributes: 'all',
    start: new Date(projectStart),
    // min: new Date(projectStart),
    end: new Date(projectEnd),
    // max: new Date(projectEnd),
    orientation: {
      axis: "both",
      item: "top"
    },
  };

  // onSelect Callback
  const onSelect = (properties) => {
    // If the title exists then this is a risk and we can redirect to the id
    if (properties.event.target.parentNode?.dataset?.title != undefined
      && properties.event.target.parentNode?.dataset?.title != ''
      && !properties.event.target.parentNode?.dataset?.group.includes("all_group_")) {
      if (!properties.event.target.parentNode?.dataset?.id.includes(activityIdPostfix)) {
        navigate.push('/risks/' + properties.event.target.parentNode?.dataset?.id)
      } else {
        // Remove the postfix
        let complexId = properties.event.target.parentNode?.dataset?.id
        const taskId = parseInt(properties.event.target.parentNode?.dataset?.id.replace(activityIdPostfix, ''))
        // Render the form
        setActivityToEdit({taskId: taskId, activityType: activityType})
      }
    }
  }

  // Construct timeline dataset
  const createGroupsAndItems = (activities, groups, items) => {
    groups.current = new DataSet()
    items.current = new DataSet()
    // Add the activities
    activities.map((activity, index) => {
      groups.current.update({
        id: activity.id != undefined ? activityType + activity.id : activityType + "_group_" + index,
        content: isOverview ? groupLineNameTemplate(activity.attributes['name'])
          : groupLineTemplate(activity, setActivityToEdit, activityType, permissions)
      })

      // Main duration
      items.current.update({
        id: activity.id != undefined ? activity.id + activityIdPostfix : getRandomInt(randomFeed),
        group: activity.id != undefined ? activityType + activity.id : activityType + "_group_" + index,
        start: new Date(activity.attributes["starts_at"]),
        end: new Date(activity.attributes["ends_at"]),
        type: 'range',
        content: '',
        title: activity.id != undefined
          ? `<div>${activity.attributes['name']}</div><cite class="text-muted-tenz act-id-display">ID: ${activity.id}</cite>`
          : `<div>${activity.attributes['name']}</div>`,
        style: calculateItemClasses(activity)

      });

      // Extension
      if(activity.attributes.extended_until != undefined
         && !isLessOrEqualToEndsAt(activity)
         && hasRisk(activity)) {
        // Approved Extension
        if (isExtensionApproved(activity)) {
          items.current.update({
            id: parseInt(activity.attributes.risk?.id) || getRandomInt(randomFeed),
            group: activity.id != undefined ? activityType + activity.id : activityType + "_group_" + index,
            start: new Date(activity.attributes["ends_at"]),
            end: new Date(activity.attributes["extended_until"]),
            type: 'range',
            title: activity.attributes?.risk?.name ?? "",
            content: '',
            style: "background-color: rgb(12 34 108);" +
              "border-top-right-radius: 9999px;" +
              "border-bottom-right-radius: 9999px;"
          });
        } else {
          // Not approved
          items.current.update({
            id: parseInt(activity.attributes.risk?.id) || getRandomInt(randomFeed),
            group: activity.id != undefined ? activityType + activity.id : activityType + "_group_" + index,
            start: new Date(activity.attributes["ends_at"]),
            end: new Date(activity.attributes["extended_until"]),
            type: 'range',
            title: activity.attributes?.risk?.name ?? "",
            content: '',
            style: "background-color: transparent;" +
              "border-top-right-radius: 9999px;" +
              "border-bottom-right-radius: 9999px;" +
              "border-color: red;"
          });
        }
      } // Activity has an extension
    })
  }

  // Create and handle timeline
  const timelinechartRef = useCallback(node => {
    createGroupsAndItems(activities, groups, items)

    if (node !== null && timeline.current == undefined) {
      timeline.current = new Timeline(node, null, options)
      // create a Timeline
      timeline.current?.setGroups(groups.current);
      timeline.current?.setItems(items.current);
      timeline.current?.on("scroll", debounce(groupFocus, 200))
      timeline.current?.on("select", onSelect)


      if (activityType != 'all') {
        groupLineHeaders(`timelinechart-${activityType}`)
      }

    }
  }, [activities]);

  let groupFocus = (e) => {
    let vGroups = timeline.current?.getVisibleGroups()
    let vItems = vGroups.reduce((res, groupId) => {
      let group = timeline.current?.itemSet.groups[groupId]
      if (group.items) {
        res = res.concat(Object.keys(group.items))
      }
      return res
    }, [])
    timeline.current?.focus(vItems)
  }

  const debounce = (func, wait = 100) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  }


  return (
    <div
      ref={timelinechartRef}
      id={`timelinechart-${activityType}`}>
    </div>
  )
}

export default TimelineChart;
