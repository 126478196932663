import React, {useState} from 'react'

const Collapsible = ({open, children, title, subCategory=false, isProforma=false}) => {

  const [isOpen, setIsOpen] = useState(open)

  const handleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  const DownIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
           strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
      </svg>
    )
  }

  const UpIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
           strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7"/>
      </svg>
    )
  }
  return (
    <section className={`${isOpen && isProforma ? "" : "border-b border-outlineGray"}`}>
      <div className="p-3 flex flex-row space-x-3">
        <h6 className={`${subCategory ? "Section-h2" : "Section-h1"}`}>{title}</h6>
        <button type="button" className="font-bold text-lightGray" onClick={handleOpen}>
          {!isOpen ? (
            <DownIcon/>
          ) : (
            <UpIcon/>
          )}
        </button>
      </div>
      <div>
        {isOpen && <div className="border-y border-outlineGray">{children}</div>}
      </div>
    </section>
  )
}

export default Collapsible; 
