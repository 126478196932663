import React from 'react'

const Section = ({children, show=true, height}) => {
  return (
    <div className={`${height} ${show ? "p-4 mb-4 bg-white rounded-md shadow-sm w-full" : "hidden"}`}>
      {children}
    </div>
  )
}

export default Section
