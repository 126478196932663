import axios from "axios"

const client = axios.create({
  baseURL: "https://dev.stratos.picl.co.uk/api",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin" : "*",
    "Authorization": `Bearer ${window.localStorage.getItem("api_token") ? window.localStorage.getItem("api_token") : ""}`,
  },
  transformResponse: [ (data) => {
    const responseData = JSON.parse(data)
    return responseData.errors ? responseData.errors : responseData.data
  }],
})


export {
  client
}
