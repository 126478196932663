import React, {useRef} from 'react'
import BoxTree from './index'

const BoxTenzingNode = ({
                          children,
                          boxFileName,
                          boxFileId,
                          setTemplateFilesSelected,
                          templateFilesSelected,
                          treeProps
                        }) => {
  const boxName = useRef(boxFileName)
  const boxId = useRef(boxFileId)

  const handleClick = (visible) => {
    if (!visible) {
      setTemplateFilesSelected([...templateFilesSelected, boxId.current])
    } else {
      setTemplateFilesSelected(templateFilesSelected.filter(fileId => fileId !== boxId.current))
    }
  }

  return (
    <BoxTree {...treeProps} onClick={handleClick}>
      {children}
    </BoxTree>
  )
}

export default BoxTenzingNode;
