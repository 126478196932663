import React, {useState, useEffect} from 'react'
import {client as apiClient} from 'utils/api';
import {useParams} from "react-router-dom"
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import Button from 'Components/Common/Button';
import {parseISO} from 'date-fns';
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {
  Field
} from 'Components/Common/Forms';
import {contact as contactQuery} from "../../../utils/queryKeys";
import {fields} from '../Utils/index'

function UpdateOrDeleteContactForm({
                                     contact
                                   }) {
  const {contactId} = useParams()
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({})
  const {register, handleSubmit, setValue, setError, reset, formState: {errors}} = useForm();

  const notifySuccess = () => toast.success("Contact updated!");
  const notifyError = () => toast.error("Contact update failed.")

  useEffect(() => {
    if (contact.data?.data?.attributes?.id > 0) {
      setFormData(contact.data.data.attributes)
      // Update the form values
      fields.forEach((fd) => {
        if (fd === "due_date") {
          setValue(fd, parseISO(contact.data.data.attributes[fd]))
        } else if (["company"].includes(fd)) {
          setValue(fd + '_id', contact.data.data.attributes[fd]?.id)
        } else {
          setValue(fd, contact.data.data.attributes[fd])
        }
      })
    }
  }, [!contact.isLoading && contact.isSuccess])

  // Update all fields
  const handleFormChange = (e) => {
    setFormData({
      ...formData, [e.target.id]: e.target.value
    })
    // Set the value
    setValue(e.target.id, e.target.value)
  }

  async function patchForm(data) {
    // Unpack values to variable
    const {first_name, last_name, title, phone, address} = data
    // Make the request and wait for the response
    return await apiClient.patch("/contacts/" + contactId, {
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      title: title.trim(),
      phone: phone.trim(),
      address: address.trim()
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(patchForm);

  const onSubmit = async (data, e) => {
    try {
      await sendData(data)
      await queryClient.invalidateQueries([contactQuery, {id: contactId}])
      notifySuccess()
      reset()
      e.target.reset()
    } catch (err) {
      notifyError()
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  if (contact.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
      <form method="put" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

        <Field>
          <label htmlFor="first_name" className="text-tenzingGray">First Name</label>
          <input
            id="first_name"
            type="text"
            placeholder="First Name"
            {...register("first_name", {
              required: true,
              pattern: {value: /[A-Za-z]{2}/, message: "Required string with minimum two Characters"}
            })}
            value={formData.first_name ?? ""}
            onChange={handleFormChange}
            className={!errors.first_name ? "input-text" : 'input-text-error'}
          />
          <FieldInputError item={errors.first_name}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="last_name" className="text-tenzingGray">Last Name</label>
          <input
            id="last_name"
            type="text"
            placeholder="Last Name"
            {...register("last_name", {
              required: true,
              pattern: {value: /[A-Za-z]{2}/, message: "Required string with minimum two Characters"}
            })}
            value={formData.last_name ?? ""}
            onChange={handleFormChange}
            className={!errors.last_name ? "input-text" : 'input-text-error'}
          />
          <FieldInputError item={errors.last_name}></FieldInputError>
        </Field>


        <Field>
          <label htmlFor="title" className="text-tenzingGray">Title</label>
          <input
            id="title"
            type="text"
            placeholder="Title"
            {...register("title", {
              required: true,
              pattern: {value: /[A-Za-z]{2}/, message: "Required string with minimum two Characters"}
            })}
            value={formData.title ?? ""}
            onChange={handleFormChange}
            className={!errors.title ? "input-text" : 'input-text-error'}
          />
          <FieldInputError item={errors.title}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="phone" className="text-tenzingGray">Phone Number</label>
          <input
            id="phone"
            type="text"
            placeholder="Phone"
            {...register("phone", {
              required: true,
              pattern: {value: /^[(]\d{3}[)]\s\d{3}[-]\d{4}$/, message: "Valid phone format is \"(nnn) nnn-nnnn\""}
            })}
            value={formData.phone ?? ""}
            onChange={handleFormChange}
            className={!errors.phone ? "input-text" : 'input-text-error'}
          />
          <FieldInputError item={errors.phone}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="address" className="text-tenzingGray">Address</label>
          <input
            id="address"
            type="text"
            placeholder="Address"
            {...register("address", {
              required: true
            })}
            value={formData.address ?? ""}
            onChange={handleFormChange}
            className={!errors.address ? "input-text" : 'input-text-error'}
          />
          <FieldInputError item={errors.address}></FieldInputError>
        </Field>

        <section className="mt-4 flex justify-end">
          <Button buttonStyle="orange-solid-button" type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </Button>
        </section>
      </form>
  )
}

export default UpdateOrDeleteContactForm
