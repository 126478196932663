export const headCells = [
  {
    id: 'rankIcon',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Risk #',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Risk Name',
  },
  {
    id: 'assigned',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
  },
  {
    id: 'due_date',
    numeric: false,
    date: true,
    disablePadding: false,
    label: 'Due Date',
  },
  {
    id: 'impact',
    Boolean: true,
    disablePadding: false,
    label: 'Impact Type',
  },
  {
    id: 'ranking',
    numeric: false,
    disablePadding: false,
    label: 'Rank',
  },
  {
    id: 'status_risk',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'critical',
    numeric: false,
    disablePadding: false,
    label: 'Critical',
  },
  {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: '',
    width: '230px'
  }
];


export const rankColor = (rank) => {

  switch (rank) {
    case 'low':
      return 'bg-trueGreen'
    case 'medium':
      return 'bg-yellow-500'
    case 'high':
      return 'bg-tenzingRed'
  }
}

// Status options list
export const statusValues = [
  "Pending",
  "In process",
  "Completed"
]

export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "assigned") {
    if (b.attributes["user"].email < a.attributes["user"].email) {
      return -1;
    }
    if (b.attributes["user"].email > a.attributes["user"].email) {
      return 1;
    }
    return 0;
  } else {
    if (b.attributes[orderBy] < a.attributes[orderBy]) {
      return -1;
    }
    if (b.attributes[orderBy] > a.attributes[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
