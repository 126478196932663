export const headCells = [
  {
    id: 'categoryBreakdown',
    numeric: false,
    disablePadding: false,
    label: 'Category Breakdown',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'itemId',
    numeric: true,
    disablePadding: true,
    label: 'Item #',
  },
  {
    id: 'approvedBudget',
    numeric: true,
    disablePadding: false,
    label: 'Approved Budget',
  },
  {
    id: 'anticipatedCost',
    numeric: true,
    disablePadding: false,
    label: 'Anticipated Cost',
  },
  {
    id: 'varianceFromBudget',
    numeric: true,
    disablePadding: false,
    label: 'Variance From Budget',
  },
  {
    id: 'committedToDate',
    numeric: true,
    disablePadding: false,
    label: 'Committed To Date',
  },
  {
    id: 'expendituresToDate',
    numeric: true,
    disablePadding: false,
    label: 'Expenditures To Date',
  },
  {
    id: 'remainingBalance',
    numeric: true,
    disablePadding: false,
    label: 'Remaining Balance',
  },
];


export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export const formatAmount = (amount) => {
  return `$${parseFloat(amount || 0).toLocaleString('en-US')}`
}

export const sum = (rows, attribute) => {
  return rows.map((row) => {
    return row[attribute]
  }).reduce((result, value) => {
    return result + value
  }, 0)
}


export const filterRows = (rows, category, subCategory) => {
  // TODO: Select by category and sub-category ID instead of category and sub-category name
  if(!subCategory){
    return rows.filter((row) => category.attributes["name"] == row.category["name"])
  } else {
    return rows.filter((row) => category.attributes["name"] == row.category["name"] && subCategory.attributes["name"] == row.subCategory["name"])
  }
}

export const getRows = (lineItems) => {
  let items = []
  if (!lineItems.isLoading
    && lineItems?.data?.data != undefined
    && lineItems?.data?.permissions?.['index']) {
    items = lineItems.data.data.map((item) => {
      const attr = item.attributes
      return {
        id: attr.id,
        name: attr.name,
        category: attr.category,
        subCategory: attr.sub_category,
        categoryBreakdown: attr.category_breakdown,
        approvedBudget: attr.approved_budget,
        anticipatedCost: attr.anticipated_cost,
        varianceFromBudget: attr.variance_from_budget,
        committedToDate: attr.committed_to_date,
        expendituresToDate: attr.expenditures_to_date,
        remainingBalance: attr.remaining_balance,
        invoices: attr.invoices,
        lineItemObject: item
      };
    })
  }
  return items
}
