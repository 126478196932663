import React from 'react'
import Button from "../../Common/Button";
import {RefreshIcon} from "./Index"
import {gmailGetListKey} from "../../../utils/google/Queries";
import {useLocation} from "react-router-dom";
import {useQueryClient} from "react-query";

function RefreshButton({
                         gapi,
                         selectedLabels
                       }) {
  const currentURL = useLocation();
  const queryClient = useQueryClient();
  let location = currentURL.pathname.split("/").pop()

  const refreshList = (event) => {
    if (gapi?.auth != undefined
      && gapi?.auth?.getToken() != undefined) {
      // do a re-fetch here
      if(!['sent', 'read', 'unread'].includes(location)) {
        location = 'inbox'
      }
      queryClient.refetchQueries([
        gmailGetListKey,
        {
          userId: "me",
          access_token: gapi?.auth?.getToken()?.access_token,
          queue: `in:${location}`,
          labelIds: selectedLabels
        }
      ]);
    }
  }

  return (
    <>
      <Button
        onClick={() => refreshList()}
        type="button"
        buttonStyle={"mr-2 p-2 bg-white text-[#757575] text-sm shadow-sm shadow-[#757575] rounded-sm bg-[#FFFFFF]"}>
        <span className="mr-1"><RefreshIcon/></span>
        Refresh
      </Button>
    </>
  )
}

export default RefreshButton;
