import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Controller, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Button from 'Components/Common/Button';
import {Field} from "Components/Common/Forms";
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {gapi} from "gapi-script";
import moment from "moment";
import {useMutation, useQuery} from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import {googleCalendarClient} from '../../utils/google/Api';
import {getGoogleCalendarAccessToken} from "../../utils/queries";
import {googleCalendarToken} from "../../utils/queryKeys";
import {
  DiscoveryDocs,
  formatEvents,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "utils/google/Auth";

function CalendarForm({
                        calendarId,
                        setIsRefreshed,
                        isRefreshed,
                        showForm,
                        setShowForm
                      }) {
  const defaultFormValues = {
    start: "",
    end: "",
    summary: ""
  }
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newEvent, setNewEvent] = useState(defaultFormValues);
  const [isAuthorized, setIsAuthorized] = useState(false);


  const {reset, register, handleSubmit, setValue, control, formState: {errors}} = useForm();

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["due_date"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setNewEvent({
        ...newEvent, [e.target.id]: e.target.value
      })
      // Set the value
      setValue(e.target.id, e.target.value)
    }
  }

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleCalendarQuery = useQuery(
    [googleCalendarToken, {companyId: companyId}],
    getGoogleCalendarAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )


  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleCalendarQuery.isLoading && googleCalendarQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleCalendarQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleCalendarQuery?.data?.access_token})
            setIsAuthorized(true)
          }
        })
      }

      gapi?.load('client', gstart)

    }
  }, [!googleCalendarQuery.isLoading && googleCalendarQuery.isSuccess])

  const notifySuccess = () => toast.success("Event added successfully");
  const notifyError = () => toast.error("There was an error adding the event");

  async function postEvent(data) {
    const {summary, start, end, attendees} = data;

    const formattedStartDate = new Date(start)
    const formattedEndDate = new Date(end)
    const formattedAttendees = attendees ? attendees.split(',').map(email => ({email: email.trim()})) : null

    googleCalendarClient.defaults.headers['Authorization'] = `Bearer ${gapi.auth.getToken().access_token}`

    return await googleCalendarClient.post(calendarId + "/events", {
      end: {
        dateTime: formattedEndDate
      },
      start: {
        dateTime: formattedStartDate
      },
      summary: summary.trim(),
      attendees: formattedAttendees,
    }, { params: { sendUpdates: 'all' } })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postEvent);

  async function onSubmit(data, e) {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      notifySuccess()
      reset(defaultFormValues)
      setNewEvent(defaultFormValues)
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
      setIsRefreshed(!isRefreshed)
      setShowForm(false)
    } catch (err) {
      setLoading(false)
      setDoDisable(false)
      notifyError()
      if (err.response) {
        const error = err.response.data;
        console.log('err', error)
        // err.response.data.forEach((error) => {
        //   const param = error.source.pointer.replace('data/params/', '')
        //   setError(param, {type: error.status, message: error.detail})
        // })
      } else if (err.request) {
        console.log(err.request);
      } else {
        console.log('Error', err.message);
      }
      throw new Error(err)
    }
  }

  return (
    <div className="w-96">
      <h1 className="Section-h1 mb-2">Create Event</h1>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>

        {/* Summary */}
        <Field>
          <label htmlFor="summary" className="text-tenzingGray">Event Summary:</label>
          <input
            id="summary"
            name="summary"
            type="text"
            className={!errors.summary ? "input-text" : 'input-text-error'}
            {...register("summary", {required: true})}
            value={newEvent?.summary ?? ""}
            onChange={handleFormChange}
          />
          <FieldInputError item={errors.summary}></FieldInputError>
        </Field>

        {/* Start Date */}
        <Field>
          <label htmlFor="start" className="text-tenzingGray">Event Start Date:</label>
          <Controller
            name="start"
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <DatePicker
                id="start"
                className={!errors.start ? "input-text" : 'input-text-error'}
                showTimeSelect
                minDate={null}
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("start", date)
                  // Update the state
                  setNewEvent({
                    ...newEvent, ["start"]: date
                  })
                }}
                selected={newEvent?.start ? moment(newEvent.start).toDate() : ''}
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            )}
          />
          <FieldInputError item={errors.start}></FieldInputError>
        </Field>

        {/* End Date */}
        <Field>
          <label htmlFor="end" className="text-tenzingGray">Event End Date:</label>
          <Controller
            name="end"
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <DatePicker
                id="end"
                className={!errors.end ? "input-text" : 'input-text-error'}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={null}
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("end", date)
                  // Update the state
                  setNewEvent({
                    ...newEvent, ["end"]: date
                  })
                }}
                selected={newEvent?.end ? moment(newEvent.end).toDate() : ''}
              />
            )}
          />
          <FieldInputError item={errors.end}></FieldInputError>
        </Field>

        {/* Attendees */}
        <Field>
          <label htmlFor="attendees" className="text-tenzingGray">Event Attendees: (separate by comma)</label>
          <input
            id="attendees"
            name="attendees"
            type="text"
            placeholder="ex. example@stratos.com, example2@stratos-app.com"
            className={!errors.attendees ? "input-text" : 'input-text-error'}
            {...register("attendees", {required: false})}
            value={newEvent?.attendees ?? ""}
            onChange={handleFormChange}
          />
          <FieldInputError item={errors.attendees}></FieldInputError>
        </Field>

        {/* SUBMIT BUTTON */}
        <section className="mt-4 flex justify-end">
          <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={loading || doDisable}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add
          </Button>
          <button onClick={() => setShowForm(false)} className="gray-transparent-button">Cancel</button>
        </section>
      </form>
    </div>
  );
}

export default CalendarForm;
