export const SCOPES = [
  // Access to calendar
  "https://www.googleapis.com/auth/calendar",
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  // Create, read, update, and delete labels only
  "https://www.googleapis.com/auth/gmail.labels",
  // Send messages only. No read or modify privileges on mailbox
  "https://www.googleapis.com/auth/gmail.send",
  // Read all resources and their metadata—no write operations
  "https://www.googleapis.com/auth/gmail.readonly",
  // Create, read, update, and delete drafts. Send messages and drafts
  "https://www.googleapis.com/auth/gmail.compose",
  "https://www.googleapis.com/auth/gmail.modify"
  // "https://www.googleapis.com/auth/script.send_mail"
]

export const REACT_APP_CLIENT_ID = "297487949951-9k4o9tr99q92rfom08hno1gkh1rgevfk.apps.googleusercontent.com"
// export const API_KEY = "AIzaSyCuA8sDM7jk8nMXMRiksk7Ye29a3-GpUI4"

export const DiscoveryDocs = [
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
]


export const formatEvents = (list, color) => {
  return list.map((item) => ({
    googleId: item.id,
    title: item.summary,
    start: item.start.dateTime || item.start.date,
    end: item.end.dateTime || item.end.date,
    description: "",
    attendees: item.attendees,
    textColor: "black",
    backgroundColor: "#F4F4F4",
    borderColor: color
  }));
};
