import React, {useState} from 'react';
import {useMutation} from "react-query";
import {phaseToTaskPatch} from "../../../utils/queries";

const PhaseToTask = ({
                       phaseId,
                       phaseQuery,
                       onModulesConfigUpdate,
                       setCurrentTask,
                       setCurrentTaskId,
                       setTasksConfig,
                       setTaskCreated,
                       locked
                     }) => {
  const [hideToTask, setHideToTask] = useState(false)
  const handleClick = (event) => {
    // XXX Actually i do not need this. I just add it in order to make the request
    //     Then the backend will ignore it.
    const tdata = {
      order: phaseQuery.data?.data?.attributes?.order
    }

    patchPhase([phaseToTaskPatch, {phaseId: parseInt(phaseId), data: tdata}])
  }

  // Update Task
  const {isLoading: phaseToTaskLoading, mutateAsync: patchPhase} = useMutation(phaseToTaskPatch, {
    onSuccess: (data, variables, context) => {
      setCurrentTask(data?.data?.data?.attributes?.task)
      setCurrentTaskId(data?.data?.data?.attributes?.task?.id)
      setTasksConfig(true)
      setHideToTask(true)
      setTaskCreated(true)
    },
    onError: (error, variables, context) => {
      console.log('Failed to link Task to Phase.', error)
    }
  });

  if (phaseToTaskLoading) {
    onModulesConfigUpdate(false)
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <>
      {
        !hideToTask
        && !locked
        && phaseQuery.data?.permissions?.['update']
        && !phaseQuery.isLoading
        && phaseQuery.isSuccess
        && phaseQuery.data?.data?.attributes?.task == null
        && phaseQuery.data?.data?.attributes?.process_modules?.length === 0 ?
          <div className="bg-tenzingOrange
                          flex
                          text-white
                          cursor-pointer
                          text-center
                          justify-center
                          font-bold"
               data-phasename={phaseQuery.data?.data?.attributes?.phase_pool?.name}
               onClick={handleClick}>
            TO TASK
          </div>
          : null
      }
    </>
  )
}

export default PhaseToTask;
