import React from "react";
import InboxHtml from "./InboxHtml";
import {useQuery} from "react-query";
import {gmailGetList, gmailGetListKey} from "../../../utils/google/Queries";
import InboxLine from "./InboxLine";

function UnReadList({
                      isAuthorized,
                      gapi,
                      selectedLabels,
                      setShowForm,
                      setShowSendEmail,
                      setSelectedMessages,
                      selectedMessages,
                      setUpdateEmailList,
                      updateEmailList
                    }) {
  // Fetch all Inbox unread emails
  const mailUnReadList = useQuery(
    [gmailGetListKey, {
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      queue: 'is:unread',
      labelIds: ['INBOX', 'UNREAD'].concat(selectedLabels)
    }],
    gmailGetList,
    {
      enabled: (isAuthorized
        && gapi.auth != undefined
        && gapi.auth.getToken() != undefined)
    })

  const inboxUnReadList =
    mailUnReadList.isLoading ? <div className="lds-dual-ring"></div> :
      !!mailUnReadList.data
      && mailUnReadList.data.length > 0
      && mailUnReadList.data.map((message) => {
        return (
          <InboxLine
            key={message.id}
            mailListItem={message}
            gapi={gapi}
            tabType="unread"
            setShowForm={setShowForm}
            setShowSendEmail={setShowSendEmail}
            setSelectedMessages={setSelectedMessages}
            selectedMessages={selectedMessages}
            setUpdateEmailList={setUpdateEmailList}
            updateEmailList={updateEmailList}
          />
        )
      })

  return (
    <InboxHtml emailsList={inboxUnReadList}/>
  )
}

export default UnReadList;
