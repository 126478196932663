import React from 'react'
import { Link } from 'react-router-dom'

const BadgeCircle = ({item}) => {

let count = 0 
item.length > 0 ? count = item.length : count

const companyId = parseInt(window.localStorage.getItem("company_id"))

  return(
    <section className="grid justify-items-center text-lightGray border-r border-l border-lightGray"> 
        <h4 className="text-center">My Critical Tasks</h4>
        <Link 
          to={`companies/${companyId}/tasks`}
          className="bg-lightGray flex flex-col justify-center items-center rounded-full p-3 w-12 h-12 text-center">
            <h4 className="text-xl orange-semibold">{count}</h4>
        </Link>
    </section>
  )
}

export default BadgeCircle; 