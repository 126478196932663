import React, {useEffect, useState} from 'react';
import {client as apiClient} from '../../utils/api';
import pluralize from "pluralize";
import {IntlProvider} from 'react-intl';
import ContentExplorer from 'box-ui-elements/es/elements/content-explorer';
import {toast} from "react-toastify";
import {box_host_name_upload as uploadHostName} from '../../utils/globals'


function BoxFileExplorer({props}) {
  // fixme: Move boxAccess element in here
  // const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)
  const [requestOpenWith, setRequestOpenWith] = useState(false)
  const [boxFilePath, setBoxFilePath] = useState('')
  const [boxFileName, setBoxFileName] = useState('')

  const notifySuccess = () => toast.success("File uploaded successfully!");
  const notifyOpenWith = () => toast.success("Send request to open file");
  const notifyErrorOpenWith = () => toast.error("Request to open file failed");

  useEffect(() => {
    if (requestOpenWith) {
      let mydata = {
        path: boxFilePath + boxFileName,
        queue: window.localStorage.getItem("user_uid").toString()
      }
      apiClient.post(pluralize(props.boxable.data.type) + "/" + props.boxable.data.id + "/openwith", mydata)
        .then((response) => {
          notifyOpenWith()
          setRequestOpenWith(false)
          setBoxFileName('')
        })
        .catch((error) => {
          notifyErrorOpenWith()
          setRequestOpenWith(false)
          setBoxFileName('')
        });
    }
  }, [requestOpenWith])

  const getItemInfo = (response) => {
    // I will construct the file path
    if (response.data.path_collection != undefined) {
      let path = ''
      response.data.path_collection.entries.forEach((crumb, idx) => {
        if (idx != 0) {
          path = path + '/' + crumb.name
        }
      })
      // Now add the current directory
      path = path + '/' + response.data.name
      setBoxFilePath(path)
    } else {
      if (boxFilePath != ''
        && response.data?.type == 'file_version'
        && response.data?.name != undefined) {
        setBoxFileName("/" + response.data?.name)
        setRequestOpenWith(true)
      }
    }
    return response;
  }

  return (
    (props.boxable.isLoading ||
      props.access_token.isLoading) ?
      <div className="lds-dual-ring"></div>
      : props.boxable?.data?.attributes?.["box_folderid"] == undefined ?
        <div>Box Service is not linked</div>
        : <IntlProvider locale="en">
          <h3 className="Section-h1 mt-3">Files/Folders:</h3>
          <ContentExplorer
            language='en-US'
            token={props.access_token.data.attributes['access_token']}
            rootFolderId={props.boxable.data?.attributes?.[props.root_folder_column ?? "box_folderid"]}
            canCreateNewFolder={false}
            canDelete={false}
            canRename={false}
            canUpload={true}
            uploadHost={uploadHostName}
            onUpload={notifySuccess}
            responseInterceptor={getItemInfo}
            logoUrl="https://s3.amazonaws.com/s3.alfajango.com/1653421552670-stratos_light.png"
            contentPreviewProps={{
              contentOpenWithProps: {
                show: true
              },
              contentSidebarProps: {
                hasActivityFeed: true,
                hasSkills: true,
                hasMetadata: true,
                detailsSidebarProps: {
                  hasProperties: true,
                  hasNotices: true,
                  hasAccessStats: true,
                  hasVersions: true,
                },
              },
            }}
          />
        </IntlProvider>
  )
}

export default BoxFileExplorer
