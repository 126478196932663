import React from 'react'
import FolderIcon from '@mui/icons-material/Folder';
import {useQuery} from "react-query";
import {
  getListOfFolderItems,
  getListOfFolderItemsKey
} from "../../../utils/boxApi/Queries";
import BoxTenzingNode from "../../Common/BoxTree/BoxTenzingNode";
import NodeContent from "./NodeContent"

const FiFoNode = ({
                    boxAccess,
                    boxFolderId,
                    setTemplateFilesSelected,
                    templateFilesSelected
                  }) => {

  const templateFiles = useQuery(
    [getListOfFolderItemsKey,
      {
        folderId: boxFolderId,
        accessToken: boxAccess?.data?.attributes?.['access_token']
      }],
    getListOfFolderItems,
    {
      enabled: !!boxAccess?.data?.attributes?.['access_token']
        && !!boxFolderId
    })

  return (
    <>
      {
        !templateFiles.isLoading
        && templateFiles.isSuccess
        && templateFiles?.data?.data?.entries?.length > 0 ?
          <>
            {
              templateFiles.data.data.entries.map((item) => {
                if (item.type == 'folder') {
                  return (
                    <BoxTenzingNode boxFileId={item.id}
                                    boxFileName={item.name}
                                    treeProps={{
                                      content: item.name,
                                      type: <FolderIcon />
                                    }}>
                      <FiFoNode boxAccess={boxAccess}
                                boxFolderId={item.id}
                                setTemplateFilesSelected={setTemplateFilesSelected}
                                templateFilesSelected={templateFilesSelected}/>
                    </BoxTenzingNode>
                  )
                } else {
                  return (<BoxTenzingNode boxFileId={item.id}
                                          boxFileName={item.name}
                                          setTemplateFilesSelected={setTemplateFilesSelected}
                                          templateFilesSelected={templateFilesSelected}
                                          treeProps={{
                                            content: <NodeContent item={item}/>,
                                            canHide: true
                                          }}/>)
                }
              })
            }
          </> : null
      }
    </>
  );

}

export default FiFoNode;

