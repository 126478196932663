import React, {useEffect, useState} from 'react'
import Form from 'Components/Projects/Form'
import Section from 'Components/Common/Section'
import {Link, Route, Switch, useHistory, useLocation, useParams} from "react-router-dom";
import {
  createProject,
  assignManager,
  assignApprover,
  uploadImage
} from "../../utils/urls";
import {CreatedProjectContext} from "../../context/CreatedProjectContext";
import TeamForm from "../../Components/Projects/Team";
import ImageForm from "../../Components/Projects/Image";
import {gapi} from "gapi-script";
import {
  DiscoveryDocs,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "../../utils/google/Auth";
import {
  googleMailToken
} from "../../utils/queryKeys";
import {useQuery} from "react-query";
import {
  getGoogleMailAccessToken
} from "../../utils/queries";


function New() {
  const currentURL = useLocation()
  const navigate = useHistory();
  const {projectId} = useParams();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [createdProject, setCreatedProject] = useState({});

  // Redirect to the first tab if there is no id
  // fixme: This is not working if i redirect since i do not have the project id
  if(currentURL.pathname !== '/projects/new'
    && projectId === undefined) {
    navigate.push('/projects/new');
  }

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  )

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  const props = {
    projectId: projectId
  }

  if(googleMailQuery.isLoading
     || !isAuthorized) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <section className="flex flex-row">
      <CreatedProjectContext.Provider value={{createdProject, setCreatedProject}}>
        <Section>
          <div className="flex flex-row justify-between">
            <section className="flex flex-row space-x-4 mb-4">
              <Link to={createProject()}
                    className={`pb-2 Section-h1 ${currentURL.pathname === createProject() ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Create Project
              </Link>
              <Link to={projectId === undefined ? '#' : assignManager(projectId)}
                    className={`pb-2 Section-h1 ${currentURL.pathname === "/projects/"+projectId+"/manager/first" ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Assign Manager
              </Link>
              <Link to={projectId === undefined ? '#' : assignApprover(projectId)}
                    className={`pb-2 Section-h1 ${currentURL.pathname === "/projects/"+projectId+"/approver/first" ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Assign Approver
              </Link>
              <Link to={projectId === undefined ? '#' : uploadImage(projectId)}
                    className={`pb-2 Section-h1 ${currentURL.pathname === "/projects/"+projectId+"/image" ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                Project Image
              </Link>
            </section>
          </div>
          <Switch>
            <Route path="/projects/new"
                   component={() => (<ProjectCreate />)}/>
            <Route path={projectId === undefined ? "projects/new" : "/projects/"+projectId+"/manager/first"}
                   component={() => (<ProjectManager props={props}/>)}/>
            <Route path={projectId === undefined ? "projects/new" : "/projects/"+projectId+"/approver/first"}
                   component={() => (<ProjectApprover props={props}/>)}/>
            <Route path={projectId === undefined ? "projects/new" : "/projects/"+projectId+"/image"}
                   component={() => (<ProjectImage props={props}/>)}/>
          </Switch>
        </Section>
      </CreatedProjectContext.Provider>
    </section>
  )
}

export default New


function ProjectCreate() {
  return (
    <Section>
      <Form gapi={gapi}/>
    </Section>
  )
}

function ProjectManager(props) {
  return (
    <Section>
      <TeamForm props={props.props}/>
    </Section>
  )
}

function ProjectApprover(props) {
  return (
    <Section>
      <TeamForm props={props.props}/>
    </Section>
  )
}

function ProjectImage(props) {
  return (
    <Section>
      <ImageForm props={props.props}/>
    </Section>
  )
}

