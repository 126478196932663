import React, {useState, useEffect} from 'react'
import
{
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material/';
import {useQuery} from 'react-query';
import {projectInvoices} from 'utils/queryKeys'
import {getProjectInvoices} from 'utils/queries'
import {headCells} from '../Utils/Invoice'
import InvoiceRow from '../Components/Tables/invoiceRow'

function InvoicesTable({
                         projectId,
                         setInvoiceObject,
                         setShowForm,
                         setInvoicePermissions
                       }) {

  const invoices = useQuery(
    [projectInvoices, {projectId: projectId}],
    getProjectInvoices)

  useEffect(() => {
    if (invoices.data?.permissions != undefined
        && invoices.data?.permissions != null) {
      setInvoicePermissions(invoices.data?.permissions)
    }
  }, [!!invoices.data?.permissions])

  return (
    !invoices.isLoading
    && invoices.isSuccess
    && invoices.data?.data != undefined ?
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.id ? 'left' : 'center'}
                >
                  {headCell.label}
                </TableCell>
              ))}
              <TableCell
                key={"deleteInvoice"}
                align={"center"}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.data.data.map((invoice) => (
              <InvoiceRow
                invoice={invoice}
                projectId={projectId}
                setShowForm={setShowForm}
                setInvoiceObject={setInvoiceObject}
                permissions={invoices.data.permissions}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      : null
  )
}

export default InvoicesTable;
