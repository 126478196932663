import React, {useEffect, useState} from 'react'
import {useQuery} from "react-query";
import {Link, useLocation, Switch, Route} from "react-router-dom";
import Section from 'Components/Common/Section'
import TaskList from '../../Components/Tasks/List/List'
import {getMyTasks, getGoogleMailAccessToken} from "utils/queries";
import {myTasks, googleMailToken} from "utils/queryKeys";
import {gapi} from "gapi-script";
import SendEmail from "../../Components/Correspondence/Inbox/SendEmail";
import DropZone from "../../Components/BoxDropZone/DropZone";
import {CorrespondenceContext} from "../../context/CorrespondenceContext";
import {
  DiscoveryDocs,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "../../utils/google/Auth"

const MyCompanyTasks = () => {
  const [_, setShowForm] = useState(false)
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [objectLabelId, setObjectLabelId] = useState(null)
  const [objectLabelName, setOjbectLabelName] = useState("")
  const [dropZoneVisibility, setDropZoneVisibility] = useState(false)
  const [dropZoneTask, setDropZoneTask] = useState("")
  const [selectedLabels, setSelectedLabels] = useState([])
  const [isAuthorized, setIsAuthorized] = useState(false)

  const currentURL = useLocation()
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  const userTaskQuery = useQuery(
    [myTasks, {assigneeId: window.localStorage.getItem("assignee_id")}],
    getMyTasks)

  let overdueTasksQuery = !userTaskQuery.isLoading
    && !!userTaskQuery.data?.data
    && userTaskQuery.data?.data.filter((task) => {
      let today = new Date()
      let dueDate = new Date(task.attributes["due_date"])
      if (
        task.attributes["status"] !== "pending"
        && task.attributes["status"] !== "completed"
        && today.getTime() > dueDate.getTime()
      ) {
        return task
      }
    })


  let todayTasksQuery = !userTaskQuery.isLoading
    && !!userTaskQuery.data?.data
    && userTaskQuery.data?.data.filter((task) => {
      let today = new Date()
      let dueDate = new Date(task.attributes["due_date"])
      if (dueDate.getDate() == today.getDate()
          && dueDate.getMonth() == today.getMonth()
          && dueDate.getFullYear() == today.getFullYear()
      ) {
        return task
      }
    })

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if(googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])


  let pendingTasksQuery = !userTaskQuery.isLoading && userTaskQuery.data?.data.filter(task => task.attributes["status"] === "pending")

  return (
    <>
      {!overdueTasksQuery || !todayTasksQuery || !pendingTasksQuery ?
        <div className="lds-dual-ring"></div> :
        <div className='flex flex-col order-last xl:flex-row xl:order-first'>
          <div className='w-full'>
            <Section>
              <section className="flex flex-row space-x-8 mb-4">
                <Link to={`/companies/${companyId}/tasks/overdue`}
                      className={`Section-h1 ${currentURL.pathname === `/companies/${companyId}/tasks/overdue` ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                  Overdue
                </Link>
                <Link to={`/companies/${companyId}/tasks/today`}
                      className={`Section-h1 ${currentURL.pathname === `/companies/${companyId}/tasks/today` ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                  Due Today
                </Link>
                <Link to={`/companies/${companyId}/tasks/pending`}
                      className={`Section-h1 ${currentURL.pathname === `/companies/${companyId}/tasks/pending` ? "border-b-2 border-tenzingOrange" : "text-lightGray"}`}>
                  Pending
                </Link>
              </section>
              <Switch>
                <Route exact path={`/companies/${companyId}/tasks/overdue`}
                       component={() => (<TaskList items={overdueTasksQuery}
                                                   projectName={true}
                                                   gapi={gapi}
                                                   setShowSendEmail={setShowSendEmail}
                                                   setShowForm={setShowForm}
                                                   setObjectLabelId={setObjectLabelId}
                                                   setOjbectLabelName={setOjbectLabelName}
                                                   setDropZoneVisibility={setDropZoneVisibility}
                                                   setDropZoneTask={setDropZoneTask}
                                                   setSelectedLabels={setSelectedLabels}/>)}/>
                <Route exact path={`/companies/${companyId}/tasks/today`}
                       component={() => (<TaskList items={todayTasksQuery}
                                                   projectName={true}
                                                   gapi={gapi}
                                                   setShowSendEmail={setShowSendEmail}
                                                   setShowForm={setShowForm}
                                                   setObjectLabelId={setObjectLabelId}
                                                   setOjbectLabelName={setOjbectLabelName}
                                                   setDropZoneVisibility={setDropZoneVisibility}
                                                   setDropZoneTask={setDropZoneTask}
                                                   setSelectedLabels={setSelectedLabels}/>)}/>
                <Route exact path={`/companies/${companyId}/tasks/pending`}
                       component={() => (<TaskList items={pendingTasksQuery}
                                                   projectName={true}
                                                   gapi={gapi}
                                                   setShowSendEmail={setShowSendEmail}
                                                   setShowForm={setShowForm}
                                                   setObjectLabelId={setObjectLabelId}
                                                   setOjbectLabelName={setOjbectLabelName}
                                                   setDropZoneVisibility={setDropZoneVisibility}
                                                   setDropZoneTask={setDropZoneTask}
                                                   setSelectedLabels={setSelectedLabels}/>)}/>
              </Switch>
            </Section>
          </div>
          <div className='order-first xl:order-last xl:ml-2'>
            {showSendEmail
            && isAuthorized
            && objectLabelId ?
              <CorrespondenceContext.Provider value={[correspondenceContext, setCorrespondenceContext]}>
                <div className='xl:min-w-[32em]'>
                  <SendEmail gapi={gapi}
                             objectLabelId={objectLabelId}
                             setShowSendEmail={setShowSendEmail}
                             setShowForm={setShowForm}
                             objectLabelName={objectLabelName}
                             selectedLabels={selectedLabels}
                             labelListUpdated={false}/>
                </div>
              </CorrespondenceContext.Provider>
              : null}
            {
              dropZoneVisibility ?
                <div className='xl:min-w-[32em]'><DropZone dropZoneObject={dropZoneTask}
                                                           setDropZoneVisibility={setDropZoneVisibility}
                                                           setShowSendEmail={setShowSendEmail}
                                                           setShowForm={setShowForm}/></div> : null
            }
          </div>
        </div>
      }
    </>
  )
}

export default MyCompanyTasks;
