import React, {useEffect} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom';
import List from '../../Components/Tasks/List/List';
import {getProjectTasks} from "utils/queries";
import {projectTasksNotPendingIndex} from "utils/queryKeys";
import {gapi} from "gapi-script";
import {
  DiscoveryDocs,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "../../utils/google/Auth";


function TaskOverview({
                        setTasksPersmissions,
                        columnsToHide
                      }) {

  const params = useParams()

  const taskQuery = useQuery(
    [projectTasksNotPendingIndex, {
      projectId: params['projectId'],
      status: 'not_pending'
    }],
    getProjectTasks)

  useEffect(() => {
    if (!taskQuery.isLoading && taskQuery.isSuccess) {
      setTasksPersmissions(taskQuery.data?.permissions)
    }
  }, [!taskQuery.isLoading && taskQuery.isSuccess])

  useEffect(() => {
    function gstart() {
      gapi?.client?.init({
        client_id: REACT_APP_CLIENT_ID,
        discoveryDocs: DiscoveryDocs,
        scope: SCOPES.join(' ')
      })
    }

    gapi?.load('client:auth2', gstart)
  }, [])

  if (taskQuery.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }


  return (
    <section>
      {!taskQuery.isLoading
      && taskQuery.isSuccess
      && !!taskQuery.data?.data?.length ?
        <List items={taskQuery.data?.data}
              columnsToHide={columnsToHide}
              gapi={gapi}/>
        : <p>No Tasks</p>
      }
    </section>
  )
}

export default TaskOverview;
