import React from 'react';
import {Link, useParams} from "react-router-dom";
import {useQuery} from 'react-query';
import Section from 'Components/Common/Section';
import {projectProcessMapPhaseURL} from '../../utils/urls';
import {
  projectStats
} from '../../utils/queryKeys'
import {
  getProjectStats
} from '../../utils/queries'
import {
  getPhaseStatusColor,
  getPhaseNameStatusColor
} from './Utils/index'

function ProcessMapOverview() {
  const {projectId} = useParams()

  // Get the project stats
  const projectStatsQuery = useQuery(
    [projectStats, {projectId: projectId}],
    getProjectStats,
    {
      retry: false,
      retryOnMount: false,
      cacheTime: 0,
      staleTime: 0
    }
  )

  return (
    <Section>
      {/* Header */}
      <div className="flex justify-between">
        <h1 className="Section-h1">Process Map</h1>
        <section className="flex space-x-4">
          <div className="flex flex-row">
            <span className="bg-trueGreen w-8 h-3 m-1 text-white"></span>
            <p className="text-sm text-lightGray">Complete</p>
          </div>
          <div className="flex flex-row">
            <span className="bg-tenzingBlue w-8 h-3 m-1 text-white"></span>
            <p className="text-sm text-lightGray">In-Progress</p>
          </div>
          <div className="flex flex-row">
            <span className="bg-lightGray w-8 h-3 m-1 text-white"></span>
            <p className="text-sm text-lightGray">Not Started</p>
          </div>
        </section>
      </div>
      {/* Process Map overview Bar */}
      {projectStatsQuery.isLoading ? <div className="lds-dual-ring"></div> :
        <>
          <section className={`p-2 my-4 divide-x border border-outlineGray grid grid-cols-${projectStatsQuery?.data?.data?.length}`}>
            {!!projectStatsQuery.data?.data
              && projectStatsQuery.data.data.map(phase => {
              return <Link key={"project-process-overview-" + phase.id.toString() + "-" + projectId.toString()}
                           to={`${projectProcessMapPhaseURL(projectId, phase.id)}`}
                           className={`h-11 ${getPhaseStatusColor(phase.status)}`}></Link>
            })}
          </section>
          <section className={`p-2 grid grid-cols-${projectStatsQuery?.data?.data?.length}`}>
            {!!projectStatsQuery.data?.data
              && projectStatsQuery.data.data.map(phase => {
              return <p key={"label-project-process-overview-" + phase.id.toString() + "-" + projectId.toString()}
                        className={getPhaseNameStatusColor(phase.status)}>{phase.name}</p>
            })}
          </section>
        </>
      }
    </Section>
  )
}

export default ProcessMapOverview; 
