import React, {useEffect, useState} from 'react'
import {client as apiClient} from 'utils/api';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import Section from 'Components/Common/Section';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import {useQuery} from 'react-query';
import Button from 'Components/Common/Button';
import {
  projectRisks as projectRisks,
} from 'utils/queryKeys'
import {FieldInputError} from 'Components/Common/Forms/Errors';
import {Field} from 'Components/Common/Forms';
import {useParams, useHistory} from "react-router-dom";
import moment from 'moment';
import {projectUsers} from "utils/queryKeys";
import {getProjectUsers} from "utils/queries";
import CircularProgress from "@mui/material/CircularProgress";
import {
  disableCalculation,
  impactValues,
  statusValues
} from '../Utils/index'
import {
  getNumberStatus,
  formatDate,
  capitalize
} from "../../../utils/globals";


function Form({gapi, project}) {
  const [formData, setFormData] = useState({})
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const params = useParams()
  const navigate = useHistory();

  // Handle input changes
  const handleFormChange = (e) => {
    if (!["due_date", "critical"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value
      })
      // Set the value
      setValue(e.target.id, e.target.value)
    }
  }

  // FETCH USER LIST
  const users = useQuery([projectUsers, {projectId: parseInt(params['projectId'])}], getProjectUsers)

  const userOptions =
    users.isLoading ? <div className="lds-dual-ring"></div>
      : users.data.length && users.data.map((item) => {
      return (
        <option key={"riskindex" + item.attributes.email + item.id} value={item.id}>{item.attributes.email}</option>
      )
    }, this);

  const statusOptions = statusValues.map((item, index) => {
    return (
      <option key={"risk-add-" + item} value={index}>{capitalize(item)}</option>
    )
  }, this)

  // FORM
  const queryClient = useQueryClient();

  const {control, register, handleSubmit, reset, setError, setValue, clearErrors, formState: {errors}} = useForm();

  // Fetch the title from the Notes
  useEffect(() => {
    if (navigate.location.state?.from === "riskQuickNote") {
      setFormData({
        ...formData, ["name"]: JSON.parse(window.localStorage.getItem("quickNotes"))
      })
      // Set the value
      setValue("name", JSON.parse(window.localStorage.getItem("quickNotes")))
    }
  }, [navigate.location.state?.from])

  const notifySuccess = () => toast.success("New risk added successfully!");
  const notifyError = () => toast.error("New risk unsuccessful.")

  async function postForm(data) {
    const {name, user_id, due_date, impact, rank, critical, status} = data
    const formattedDueDate = moment(due_date).utc().format()


    return await apiClient.post("/risks", {
      name: name.trim(),
      project_id: parseInt(params['projectId']),
      user_id: parseInt(user_id),
      status: parseInt(status), // This is the default, status: in-process
      due_date: formattedDueDate,
      impact: impact,
      rank: rank,
      critical: critical
    })
  }

  const {isLoading, mutateAsync: sendData} = useMutation(postForm);


  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.invalidateQueries([projectRisks, {projectId: params['projectId']}])
      if (window.localStorage.getItem("quickNotes") != undefined) {
        window.localStorage.removeItem("quickNotes")
      }
      window.dispatchEvent(new Event('storage'))
      notifySuccess()
      reset()
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
    } catch (err) {
      setLoading(false)
      setDoDisable(false)
      notifyError()
      err.response.data.forEach((error) => {
        const param = error.source.pointer.replace('data/params/', '')
        setError(param, {type: error.status, message: error.detail})
      })
      throw new Error(err)
    }
  }

  return (
    <Section>
      <h2 className="Section-h1">Add New Risk</h2>
      <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

        <Field>
          <label htmlFor="name" className="text-tenzingGray">Risk Name:</label>
          <input
            id="name"
            name="name"
            type="text"
            className={!errors.name ? "input-text" : 'input-text-error'}
            {...register("name", {required: true})}
            value={formData?.name ?? ""}
            onChange={handleFormChange}
          />
          <FieldInputError item={errors.name}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="user_id" className="text-tenzingGray">User</label>
          <select
            id="user_id"
            {...register("user_id", {required: true})}
            className={!errors.user_id ? "input-text" : 'input-text-error'}
            value={formData?.user_id ?? formData?.user?.id ?? ""}
            onChange={handleFormChange}
          >User
            {userOptions}
          </select>
          <FieldInputError item={errors.user_id}></FieldInputError>
        </Field>

        <div className="flex justify-between items-baseline">
          <Field>
            <label htmlFor="due_date" className="text-tenzingGray">Due Date</label>
            <Controller
              defaultValue=""
              control={control}
              name='due_date'
              rules={{required: true}}
              render={({field}) => (
                <DatePicker
                  id='due_date'
                  minDate={null}
                  onChange={(date) => {
                    field.onChange(date)
                    // Set the value
                    setValue("due_date", date)
                    // Update the state
                    setFormData({
                      ...formData, ["due_date"]: date
                    })
                  }}
                  selected={formData?.due_date ? moment(formData.due_date).toDate() : ''}
                  className={!errors.due_date ? "input-text" : 'input-text-error'}
                />
              )}
            />
            <FieldInputError item={errors.due_date}></FieldInputError>
          </Field>
        </div>

        <Field>
          <label htmlFor="impact" className="text-tenzingGray">Select Impact</label>
          <select
            id="impact"
            name="impact"
            {...register("impact", {required: true})}
            className={!errors.impact ? "input-text" : 'input-text-error'}
            value={formData.impact ?? ""}
            onChange={handleFormChange}
          >Impact Type
            <option value="cost">Cost</option>
            <option value="time">Time</option>
            <option value="other">Other</option>
          </select>
          <FieldInputError item={errors.impact}></FieldInputError>
        </Field>

        <Field>
          <label htmlFor="rank" className="text-tenzingGray">Select Rank</label>
          <select
            id="rank"
            name="rank"
            {...register("rank", {required: true})}
            className={!errors.rank ? "input-text" : 'input-text-error'}
            value={formData.rank ?? ""}
            onChange={handleFormChange}
          >Risk Rank
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
          <FieldInputError item={errors.rank}></FieldInputError>
        </Field>

        <Field>
          <div className="flex items-end">
            <p className="text-lg text-tenzingGray">Critical:</p>
            <div>
              <label className="flex items-baseline" htmlFor="critical">
                <input
                  id="critical"
                  name="critical"
                  type="checkbox"
                  checked={Boolean(formData?.critical ?? false)}
                  onClick={(event) => {
                    // Set the value
                    setValue("critical", event.target.checked)
                    // Update the state
                    setFormData({
                      ...formData, ["critical"]: event.target.checked
                    })
                  }}
                  className="appearance-none invisible peer"
                  {...register("critical")}
                />
                <span className="w-14 h-8
                                  flex items-center
                                  flex-shrink-0
                                  mr-4 p-1
                                  bg-lightGray
                                  rounded-full
                                  duration-300 ease-in-out
                                  peer-checked:bg-tenzingBlue
                                  after:w-6
                                  after:h-6
                                  after:bg-white
                                  after:rounded-full
                                  after:shadow-md
                                  after:duration-300
                                  peer-checked:after:translate-x-6
                                  "></span>
              </label>
            </div>
          </div>
        </Field>

        {/* STATUS */}
        <Field>
          <label htmlFor="status" className="text-tenzingGray ">Risk Status:</label>
          <select
            id="status"
            name="status"
            className={!errors.status ? "input-text" : 'input-text-error'}
            {...register("status", {required: true})}
            value={formData.status ? getNumberStatus(formData.status) : ""}
            onChange={handleFormChange}
          >
            {statusOptions}
          </select>
          <FieldInputError item={errors.status}></FieldInputError>
        </Field>

        <section className="mt-4 flex justify-end">
          <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={loading || doDisable}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add New
          </Button>
        </section>
      </form>
    </Section>
  )
}

export default Form
