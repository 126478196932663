import React from "react";
import ProfileImageUpdate from './profileImageUpdate'
import ProfileImagePick from './profileImagePick'
import ProjectImagePick from './projectImagePick'


// pass the cloud name, upload_preset, base_url in the props
function CldUploadRestApi({
                            assignCloudinaryUrl,
                            config,
                            profile,
                            user
                          }) {
  const processFile = async (e) => {
    const file = e.target.files[0];
    // https://cloudinary.com/console/c-074e9953225bc994e8f6ed820b6db5/settings/upload
    // https://codesandbox.io/s/chunked-client-side-upload-rohxp?from-embed=&file=/src/CldCustUploadLgRestApi.js:1260-1287
    // Set your cloud name and unsigned upload preset here:
    const YOUR_CLOUD_NAME = config.cloud_name;
    const YOUR_UNSIGNED_UPLOAD_PRESET = config.unsigned_upload_preset;
    const POST_URL = config.base_url + "/" + YOUR_CLOUD_NAME + "/auto/upload";

    const XUniqueUploadId = +new Date();

    processF();

    function processF(e) {
      const size = file.size;
      const sliceSize = 20000000;
      let start = 0;

      setTimeout(loop, 3);

      function loop() {
        let end = start + sliceSize;

        if (end > size) {
          end = size;
        }
        const s = slice(file, start, end);
        send(s, start, end - 1, size);
        if (end < size) {
          start += sliceSize;
          setTimeout(loop, 3);
        }
      }
    }

    function send(piece, start, end, size) {
      const formdata = new FormData();

      formdata.append("file", piece);
      formdata.append("cloud_name", YOUR_CLOUD_NAME);
      formdata.append("upload_preset", YOUR_UNSIGNED_UPLOAD_PRESET);
      formdata.append("public_id", e.target.files[0].name);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", POST_URL, false);
      xhr.setRequestHeader("X-Unique-Upload-Id", XUniqueUploadId);
      xhr.setRequestHeader(
        "Content-Range",
        "bytes " + start + "-" + end + "/" + size
      );

      xhr.onload = function () {
        if (xhr.status != 200) { // analyze HTTP status of the response
          console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else { // show the result
          const response = JSON.parse(xhr.response)
          assignCloudinaryUrl(response.secure_url)
        }
      };

      xhr.onerror = function () { // only triggers if the request couldn't be made at all
        console.error('Cloudinary request failed')
      };
      xhr.send(formdata);
    }

    function slice(file, start, end) {
      const slice = file.mozSlice
        ? file.mozSlice
        : file.webkitSlice
          ? file.webkitSlice
          : file.slice
            ? file.slice
            : noop;

      return slice.bind(file)(start, end);
    }
  };

  return (
    <>
      {
        profile ?
          user?.contact != undefined ?
            <ProfileImageUpdate user={user}
                                processFile={processFile}/> : <ProfileImagePick processFile={processFile}/>
          : <ProjectImagePick processFile={processFile}/>
      }
    </>
  );
}

export default CldUploadRestApi;




