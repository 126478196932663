import React from "react";
import InboxHtml from "./InboxHtml";
import {useQuery} from "react-query";
import {gmailGetList, gmailGetListKey} from "../../../utils/google/Queries";
import InboxLine from "./InboxLine";

function ReadList({
                    isAuthorized,
                    gapi,
                    selectedLabels,
                    setShowForm,
                    setShowSendEmail,
                    setSelectedMessages,
                    selectedMessages,
                    setUpdateEmailList,
                    updateEmailList
                  }) {
  // Fetch all Inbox emails
  const mailReadList = useQuery(
    [gmailGetListKey, {
      userId: "me",
      access_token: gapi?.auth?.getToken()?.access_token,
      queue: 'is:read',
      labelIds: ['INBOX'].concat(selectedLabels)
    }],
    gmailGetList,
    {
      enabled: (isAuthorized
        && gapi.auth != undefined
        && gapi.auth.getToken() != undefined)
    })

  const inboxReadList =
    mailReadList.isLoading ? <div className="lds-dual-ring"></div> :
      !!mailReadList.data
      && mailReadList.data.length > 0
      && mailReadList.data.map((message) => {
        return (
          <InboxLine
            key={message.id}
            mailListItem={message}
            gapi={gapi}
            tabType="read"
            setShowForm={setShowForm}
            setShowSendEmail={setShowSendEmail}
            setSelectedMessages={setSelectedMessages}
            selectedMessages={selectedMessages}
            setUpdateEmailList={setUpdateEmailList}
            updateEmailList={updateEmailList}
          />
        )
      })

  return (
    <InboxHtml emailsList={inboxReadList}/>
  )
}

export default ReadList;
