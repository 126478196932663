import React, {useState, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form';
import Button from 'Components/Common/Button';
import Section from 'Components/Common/Section';
import CategorySelector from '../../Components/CategorySelector';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from 'react-toastify';
import {
  Field,
  FieldError,
  ErrorText
} from 'Components/Common/Forms';
import {client as apiClient} from 'utils/api';
import {useMutation, useQueryClient, useQuery} from 'react-query';
import {company as companyByIdQuery, projectContractBreakdowns, projectLineItems} from "../../../../utils/queryKeys";
import {getCompany, getProjectLineItems} from "../../../../utils/queries";
import moment from "moment";
import {
  defaultValues,
  formatDate
} from "../../Utils/Contracts";
import CircularProgress from "@mui/material/CircularProgress";
import MultiSelectBoxfiles from "../../../FileExplorer/MultiSelectBoxfiles";
import BoxFileExplorer from "../../../FileExplorer";
import {getListOfFolderItems, getListOfFolderItemsKey} from "../../../../utils/boxApi/Queries";
import FiFoWrapper from "../../../../Components/Common/BoxTree/FiFoWrapper"


function Form({
                setShowForm,
                categories,
                projectId,
                bexpConfig
              }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategoryBreakdown, notifyCategoryBreakdown] = useState();
  const [formData, setFormData] = useState(defaultValues)
  const [selectedLineItem, setSelectedLineItem] = useState();

  const notifySuccess = () => toast.success("New Contract Breakdown added successfully!");
  const notifyError = () => toast.error("New Contract Breakdown unsuccessful.")

  const lineItems = useQuery(
    [projectLineItems, {projectId: projectId, category_breakdown_id: selectedCategoryBreakdown?.id}],
    getProjectLineItems,
    {
      enabled: !!projectId
        && !!selectedCategoryBreakdown
        && Object.keys(selectedCategoryBreakdown).length !== 0
    }
  )

  useEffect(() => {
    if (selectedCategoryBreakdown?.id != undefined
      && Object.keys(selectedCategoryBreakdown).length !== 0
      && selectedCategoryBreakdown.id > 0) {
      queryClient.refetchQueries([
        projectLineItems,
        {projectId: projectId, category_breakdown_id: selectedCategoryBreakdown?.id}
      ])
    }
    setFormData({
      ...formData, ['line_item_id']: ''
    })
    setValue('line_item_id', '')
  }, [selectedCategoryBreakdown])

  // Update all non-Date fields
  const handleFormChange = (e) => {
    if (!["commitment_date"].includes(e.target.id)) {
      // Update the state(merge current data to the ones found in the state)
      setFormData({
        ...formData, [e.target.id]: e.target.value
      })
      // Set the value
      setValue(e.target.id, e.target.value)
    }
  }

  const queryClient = useQueryClient();
  const {mutateAsync: sendData} = useMutation(postForm);
  const {setValue, control, register, handleSubmit, reset, formState: {errors}} = useForm(defaultValues);

  async function postForm(data) {
    const {
      line_item_id,
      firm_name,
      commitment_date,
      commitment_value,
      coi_expiration
    } = data

    return await apiClient.post("/contract_breakdowns", {
      line_item_id: parseInt(line_item_id),
      firm_name: firm_name.trim(),
      commitment_date: moment(commitment_date).utc().format(),
      commitment_value_cents: parseInt(commitment_value) * 100, // Manually transform to cents
      project_id: parseInt(projectId),
      coi_expiration: moment(coi_expiration).utc().format()
    })
  }

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      await queryClient.refetchQueries([projectContractBreakdowns, {projectId: projectId}])
      notifySuccess()
      reset(defaultValues)
      e.target.reset()
      setLoading(false)
      setDoDisable(false)
    } catch (error) {
      setLoading(false)
      setDoDisable(false)
      notifyError();
      throw new Error(error)
    }
  }

  // Template Files
  // FETCH Company data in order to extract the Box Folder Ids we need
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const company = useQuery(
    [companyByIdQuery, {companyId: companyId}],
    getCompany)

  // const templateFolderItems = useQuery(
  //   [getListOfFolderItemsKey,
  //     {
  //       folderId: company?.data?.attributes?.["templates_box_folderid"],
  //       accessToken: bexpConfig?.access_token?.data?.attributes['access_token']
  //     }],
  //   getListOfFolderItems,
  //   {
  //     enabled: !company.isLoading
  //       && company.isSuccess
  //       && !!company?.data?.attributes?.["templates_box_folderid"]
  //   })

  if (bexpConfig.access_token.isLoading
    || company.isLoading
    || lineItems.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  if (projectId == undefined) {
    return (<p>Select the project first </p>)
  }


  return (
    <Section>
      <h2 className="Section-h1 mb-4">Add New Contract</h2>
      <CategorySelector categories={categories} notifyCategoryBreakdown={notifyCategoryBreakdown}/>
      <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        {/* LINE ITEM */}
        <Field>
          <label htmlFor="line_item_id" className="text-tenzingGray">Line Item:</label>
          <select id="line_item_id"
                  className="input-select"
                  value={selectedLineItem}
                  onChange={handleFormChange}>
            <option defaultValue="Line Item">Line Item</option>
            {lineItems
              && !lineItems.isLoading
              && lineItems?.data?.data
              && lineItems?.data?.permissions?.['index']
              && lineItems.data.data.map((lineItem) => {
                return (
                  <option key={'contract-breakdown-form-line-item-id-' + lineItem.id.toString()}
                          value={lineItem.id}>
                    {lineItem.attributes["name"]}</option>
                )
              })}
          </select>
          <FieldError>
            {errors.line_item_id &&
              <ErrorText>{errors.line_item_id.type === "required" ? "Line item is required" : errors.line_item_id.message}</ErrorText>
            }
          </FieldError>
        </Field>

        {/* FIRM NAME */}
        <Field>
          <label htmlFor="firm_name" className="text-tenzingGray">Firm Name:</label>
          <input
            id="firm_name"
            name="firm_name"
            type="text"
            {...register("firm_name", {required: true})}
            className={!errors.firm_name ? "input-text" : 'input-text-error'}
            onChange={handleFormChange}
          />
          <FieldError>
            {errors.firm_name &&
              <ErrorText>{errors.firm_name.type === "required" ? "Firm name is required" : errors.firm_name.message}</ErrorText>
            }
          </FieldError>
        </Field>

        {/* COMMITMENT DATE */}
        <Field>
          <label htmlFor="commitment_date" className="text-tenzingGray">Commitment Date:</label>
          <Controller
            control={control}
            name='commitment_date'
            rules={{required: true}}
            render={({field}) => (
              <DatePicker
                id='commitment_date'
                minDate={null}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("commitment_date", date)
                  // Update the state
                  setFormData({
                    ...formData, ["commitment_date"]: date
                  })
                }}
                selected={formatDate(formData.commitment_date)}
                className={!errors.commitment_date ? "input-text" : 'input-text-error'}
              />
            )}
          />
          <FieldError>
            {errors.commitment_date &&
              <ErrorText>{errors.commitment_date.type === "required" ? "Commitment date is required" : errors.commitment_date.message}</ErrorText>
            }
          </FieldError>
        </Field>

        {/* COMMITMENT VALUE */}
        <Field>
          <label htmlFor="commitment_value" className="text-tenzingGray">Commitment Value:</label>
          <div className='currency-wrap'>
            <input
              id="commitment_value"
              name="commitment_value"
              type="text"
              {...register("commitment_value", {required: true})}
              className={`pl-4 ${!errors.commitment_value ? "input-text" : "input-text-error"}`}
              onChange={handleFormChange}
            />
          </div>
          <FieldError>
            {errors.commitment_value &&
              <ErrorText>{errors.commitment_value.type === "required" ? "Commitment value is required" : errors.commitment_value.message}</ErrorText>
            }
          </FieldError>
        </Field>

        {/* CONTRACT EXPIRATION DATE */}
        <Field>
          <label htmlFor="coi_expiration" className="text-tenzingGray">COI Expiration Date:</label>
          <Controller
            control={control}
            name='coi_expiration'
            rules={{required: false}}
            render={({field}) => (
              <DatePicker
                id='coi_expiration'
                placeholderText='COI Expiration Date'
                minDate={null}
                onChange={(date) => {
                  field.onChange(date)
                  // Set the value
                  setValue("coi_expiration", date)
                  // Update the state
                  setFormData({
                    ...formData, ["coi_expiration"]: date
                  })
                }}
                selected={formatDate(formData.coi_expiration)}
                className={!errors.coi_expiration ? "input-text" : 'input-text-error'}
              />
            )}
          />
          <FieldError>
            {errors.coi_expiration &&
              <ErrorText>{errors.coi_expiration.type === "required" ? "COI Expiration date is required" : errors.coi_expiration.message}</ErrorText>
            }
          </FieldError>
        </Field>

        <section className="mt-4 mb-4">
          <Button buttonStyle="gray-transparent-button" type="button"
                  onClick={() => setShowForm(false)}>Cancel</Button>
          <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={loading || doDisable}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Add New
          </Button>
          <Button variant="text"
                  buttonStyle="text-gray-600 float-right"
                  type="button"
                  onClick={() => {
                    reset(defaultValues)
                  }}
          >Clear</Button>
        </section>
      </form>

      {/* Template files add */}
      {/*<h3 className="Section-h1">Select Templates:</h3>*/}
      {/*{*/}
      {/*  bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != null*/}
      {/*  && !templateFolderItems.isLoading*/}
      {/*  && templateFolderItems.isSuccess*/}
      {/*  && templateFolderItems.data?.data?.entries?.length > 0 ?*/}
      {/*    templateFolderItems.data?.data?.entries.map(dir => {*/}
      {/*      if (dir.type === 'folder') {*/}
      {/*        return (*/}
      {/*          <>*/}
      {/*            <MultiSelectBoxfiles key={dir.id}*/}
      {/*                                 boxAccess={bexpConfig.access_token}*/}
      {/*                                 boxFolderId={dir.id}*/}
      {/*                                 boxFolderName={dir.name}*/}
      {/*                                 parentFolderId={bexpConfig.boxable?.data?.attributes["cost_box_folderid"]}/>*/}
      {/*          </>*/}
      {/*        )*/}
      {/*      }*/}
      {/*    }) : null*/}
      {/*}*/}

      {/* Template files add */}
      {
        !bexpConfig.access_token.isLoading
        && bexpConfig.access_token.isSuccess
        && bexpConfig.boxable?.data?.attributes != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != ''
        && company?.data?.attributes?.["templates_box_folderid"] != null ?
          <FiFoWrapper boxAccess={bexpConfig.access_token}
                       boxFolderId={company?.data?.attributes?.["templates_box_folderid"]}
                       parentFolderId={bexpConfig.boxable?.data?.attributes["cost_box_folderid"]}/>
          : null
      }

      {/* Import a Box Form */}
      {
        bexpConfig.access_token.isLoading
        || bexpConfig.boxable.isLoading
        || bexpConfig.boxable?.data?.attributes == undefined ? "" :
          (bexpConfig.boxable?.data?.attributes.hasOwnProperty("cost_box_folderid")
            && (bexpConfig.boxable?.data?.attributes["cost_box_folderid"] == undefined
              || bexpConfig.boxable?.data?.attributes["cost_box_folderid"] == "")) ?
            <div>Not linked to Box</div> :
            <BoxFileExplorer props={bexpConfig}/>
      }

    </Section>
  );
}

export default Form
