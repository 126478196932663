import React, {useState} from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  getListOfFolderItems,
  getListOfFolderItemsKey
} from "../../utils/boxApi/Queries";
import {useQuery} from "react-query";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CopyTemplateBoxFiles from "../../Components/Common/BoxTree/CopyTemplateBoxFiles";
import FolderOffIcon from '@mui/icons-material/FolderOff';


const MultiSelectBoxfiles = ({
                               boxAccess,
                               boxFolderId,
                               boxFolderName,
                               parentFolderId
                             }) => {
  const [templateFilesSelected, setTemplateFilesSelected] = useState([]);
  let templateCache = {}

  const templateFiles = useQuery(
    [getListOfFolderItemsKey,
      {
        folderId: boxFolderId,
        accessToken: boxAccess?.data?.attributes?.['access_token']
      }],
    getListOfFolderItems,
    {
      enabled: !!boxAccess?.data?.attributes?.['access_token']
        && !!boxFolderId
    })


  if (templateFiles.isLoading) {
    return (
      <div className="lds-dual-ring"></div>
    )
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: 0
      }
    },
  };

  return (
    <>
      {
        !templateFiles.isLoading
        && templateFiles.isSuccess
        && templateFiles?.data?.data?.entries?.length > 0 ?
          <div className="mt-3 flex justify-between items-center flex-wrap">
            <FormControl sx={{
              minWidth:
                templateFilesSelected.length > 0 ? '20em' : '100%'
            }}>
              <InputLabel id={`template-multiple-checkbox-${boxFolderName}`}>{boxFolderName}</InputLabel>
              <Select
                labelId={"multiple-checkbox-label" + boxFolderId}
                id={"multiple-checkbox-" + boxFolderId}
                IconComponent={KeyboardArrowDownIcon}
                multiple
                sx={{
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 0,
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: "inherit",
                      "&:hover": {
                        borderColor: "inherit",
                      },
                      "&:focus": {
                        borderColor: "#E66B19"
                      }
                    },
                  }
                }}
                value={templateFilesSelected}
                onChange={(event) => setTemplateFilesSelected(event.target.value)}
                input={<OutlinedInput label={boxFolderName}/>}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {templateFiles.data.data.entries.map((item) => {
                  if (item.type === 'file') {
                    templateCache[item.name] = item.id
                    return (
                      <MenuItem key={item.id}
                                value={item.name}>
                        <Checkbox checked={templateFilesSelected.indexOf(item.name) > -1}/>
                        <ListItemText primary={item.name}/>
                      </MenuItem>
                    )
                  }
                })}
              </Select>
            </FormControl>
            {
              templateFilesSelected.length > 0
              && parentFolderId != null ?
                <CopyTemplateBoxFiles boxAccess={boxAccess}
                                      parentFolderId={parentFolderId}
                                      templateFilesSelected={templateFilesSelected}
                                      templateCache={templateCache}
                /> : null
            }

          </div>
          :
          <>
            <div className="flex justify-between items-center h-14 mt-3 text-tenzingRed w-full input-text">
              <span>{boxFolderName}</span>
              <FolderOffIcon/>
            </div>
          </>
      }
    </>
  )
}

export default MultiSelectBoxfiles;
