import React, {useContext, useState, useEffect} from 'react'
import Button from "@mui/material/Button";
import {
  calcIcon,
  customDropdownStyles,
  calcProjectName,
  customDropdownStylesActive
} from "./Resources";
import Menu from "@mui/material/Menu";
import NestedMenuItems from "./NestedMenuItems";
import {useQuery, useQueryClient} from "react-query";
import {companyProjects} from "../../utils/queryKeys";
import {getCompanyProjects} from "../../utils/queries";
import {ProjectContext} from "../../context/ProjectContext";
import {useHistory, useLocation} from "react-router-dom";

function NavbarLink({menuItem, idx}) {
  const navigate = useHistory();
  const currentURL = useLocation();
  const queryClient = useQueryClient();

  const {selectedProject, setSelectedProject} = useContext(ProjectContext);
  let preroute = '';
  if (selectedProject.value !== undefined) {
    preroute = '/projects/' + selectedProject.value;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const projects = useQuery(
    [companyProjects, {companyId: companyId, filter: 'self'}],
    getCompanyProjects,
    {
      cacheTime: 0,
      staleTime: 0,
    })

  useEffect(() => {
    if (selectedProject?.resetQuery) {
      queryClient.refetchQueries([companyProjects, {companyId: companyId, filter: 'self'}]);
      delete selectedProject.resetQuery
      setSelectedProject(selectedProject)
    }
  }, [selectedProject])


  const menuButtonClicked = (event) => {
    if (event.currentTarget.id === 'User Homebasic-menu-button0') {
      setSelectedProject({});
      window.localStorage.removeItem("projectValue");
      navigate.push(menuItem.route)
    } else if (event.currentTarget.id === 'All My Tasksbasic-menu-button2') {
      navigate.push(`/companies/${companyId}${menuItem.route}`)
    } else if (menuItem.type === 'nested') {
      setAnchorEl(event.currentTarget)
    } else {
      // Note. Eventually all navbar item will be company wide. When this finally happens remove the condition and
      //       keep the navigate to menuItem.route
      if (["Correspondence", "Documents", "Forms & Templates"].includes(menuItem.name)) {
        navigate.push(menuItem.route);
      } else {
        const route = (selectedProject.value == undefined || selectedProject.value == '') ? "#" : preroute + menuItem.route
        navigate.push(route);
      }
    }
  }

  const menuDropDownClosed = () => {
    setAnchorEl(null)
  }

  if (projects.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <>
      <Button
        id={menuItem.name + "basic-menu-button" + idx.toString()}
        aria-controls={open ? menuItem.name + "basic-menu-button" + idx.toString() : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={menuButtonClicked}
        sx={(currentURL.pathname === preroute + menuItem.route) ? customDropdownStylesActive : customDropdownStyles}
      >
        {calcProjectName(menuItem)}{calcIcon(menuItem?.icon, open)}
      </Button>
      <Menu
        id={menuItem.name + "menu-item" + idx.toString()}
        anchorEl={anchorEl}
        open={open}
        onClose={menuDropDownClosed}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          projects?.data?.data?.length > 0 ?
            <NestedMenuItems projects={projects}
                             setAnchorEl={setAnchorEl}
                             menuItem={menuItem}
            /> : null
        }
      </Menu>
    </>
  )
}

export default NavbarLink;
