export const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'User #',
    default: true
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    default: true
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    default: true
  },
  {
    id: 'user_role',
    numeric: true,
    disablePadding: false,
    label: 'User Role',
    default: true
  },
  {
    id: 'contact_name',
    numeric: false,
    disablePadding: false,
    label: 'Contact',
    default: false
  },
  {
    id: 'project_team_membership',
    numeric: false,
    disablePadding: false,
    label: 'Project Team Member',
    default: false
  },
];

export const fields = ["email", "status"];

export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "email") {
    if (b["email"] < a["email"]) {
      return -1;
    }
    if (b["email"] > a["email"]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export const availableRoles = [
  'Owner(Client)',
  'Project Director',
  'Consultant',
  'Architect/Engineer',
  'Contractor',
  'Project Manager'
]
