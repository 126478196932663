import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Section from 'Components/Common/Section'
import
{
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
} from '@mui/material/';
import EditTaskForm from 'Components/Tasks/Form/edittaskform'
import
  ContentTwoColumn, {WideColumn, NarrowColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import {
  boxAccessToken, project as projectKey,
  task as taskQueryKey,
  googleMailToken
} from "../../utils/queryKeys";
import {
  getBoxAccessToken,
  getTask,
  getGoogleMailAccessToken
} from "../../utils/queries";
import {
  statusColor
} from "../../utils/globals"
import {
  headCells
} from './Resources/index'
import {formatDate} from 'utils/globals';
import {gapi} from "gapi-script";
import {
  DiscoveryDocs,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "../../utils/google/Auth"
import {useLocation} from "react-router-dom";
import Inbox from "../../Components/Correspondence/Inbox/Inbox";
import {CorrespondenceContext} from "../../context/CorrespondenceContext";
import {emailContext} from "../../Components/Correspondence/Utils/Index";
import SendEmail from "../../Components/Correspondence/Inbox/SendEmail";

function Show(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)

  const [showForm, setShowForm] = useState(true)
  const [showSendEmail, setShowSendEmail] = useState(false)
  const [objectLabelId, setObjectLabelId] = useState(null)
  const [objectLabelName, setObjectLabelName] = useState("")
  const [currentAuthorizedEmailAccount, setCurrentAuthorizedEmailAccount] = useState(null)
  const [taskUpdated, setTaskUpdated] = useState(false)
  const [selectedLabels, setSelectedLabels] = useState([])
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [correspondenceContext, setCorrespondenceContext] = useState(emailContext);

  const taskId = props.match.params.taskId
  const task = useQuery([taskQueryKey, {taskId: taskId}], getTask)
  const boxAccess = useQuery([boxAccessToken], getBoxAccessToken)

  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const googleMailQuery = useQuery(
    [googleMailToken, {companyId: companyId}],
    getGoogleMailAccessToken,
    {
      cacheTime: 10000,
      staleTime: 10000
    }
  )

  useEffect(() => {
    if (!task.isLoading && task.isSuccess) {
      if (task.data?.data?.attributes?.["gmail_labelid"]
        && task.data?.data?.attributes?.['project']?.["gmail_labelid"]) {
        setSelectedLabels([
          task.data?.data?.attributes["gmail_labelid"],
          task.data?.data?.attributes['project']["gmail_labelid"]
        ])
        setObjectLabelId(task.data?.data?.attributes["gmail_labelid"])
        setObjectLabelName(task.data?.data?.attributes["name"])
      }
    }
  }, [!task.isLoading && task.isSuccess])

  // Load the google client so that i can use it to authenticate
  useEffect(() => {
    if (!googleMailQuery.isLoading && googleMailQuery.isSuccess) {
      function gstart() {
        gapi?.client?.init({
          'clientId': REACT_APP_CLIENT_ID,
          'discoveryDocs': DiscoveryDocs,
          'scope': SCOPES.join(' ')
        }).then(() => {
          if (googleMailQuery?.data?.access_token != undefined) {
            gapi?.client?.setToken({access_token: googleMailQuery?.data?.access_token})
            setIsAuthorized(true)
            window.localStorage.setItem("email_account", gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail());
            setCurrentAuthorizedEmailAccount(window.gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile()?.getEmail())
          }
        })
      }

      gapi?.load('client', gstart)
    }
  }, [!googleMailQuery.isLoading && googleMailQuery.isSuccess])

  const bexpConfig = {
    boxable: task.data,
    access_token: boxAccess,
    taskId: taskId,
    processMap: false
  }

  if (task.isLoading || boxAccess.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  if (task?.data?.data?.attributes == undefined) {
    return null
  }

  return (
    <ContentTwoColumn>
      <CorrespondenceContext.Provider value={[correspondenceContext, setCorrespondenceContext]}>
        <WideColumn>
          <Section>
            <h1 className="Section-h1">{task?.data?.data?.attributes?.["name"]}</h1>
            <React.Fragment>
              <Box sx={{width: '100%', borderColor: 'grey'}}>
                <Paper sx={{width: '100%', mb: 2}}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.id ? 'left' : 'center'}
                            >
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={task?.data?.data?.id}>
                          <TableCell>
                      <span
                        className={`inline-block align-middle w-5 h-5 rounded-full mr-2 ${statusColor(task?.data?.data?.attributes?.["status"])}`}></span>
                          </TableCell>
                          <TableCell>{task?.data?.data?.id}</TableCell>
                          <TableCell>{task?.data?.data?.attributes?.project?.["name"]}</TableCell>
                          <TableCell>{task?.data?.data?.attributes?.["name"]}</TableCell>
                          <TableCell>{task?.data?.data?.attributes?.["assignee"]?.["email"]}</TableCell>
                          <TableCell>{formatDate(task?.data?.data?.attributes?.["due_date"])}</TableCell>
                          <TableCell>{task?.data?.data?.attributes?.["critical"] ? 'Yes' : 'No'}</TableCell>
                          <TableCell>{task?.data?.data?.attributes?.["buttons"]}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </React.Fragment>
          </Section>
          <Section>
            <div className='flex flex-col justify-between'>
              <h2 className="Section-h1 mb-2">Correspondence</h2>
            </div>
            {
              selectedLabels.length > 0 ?
                <Inbox isAuthorized={isAuthorized}
                       gapi={gapi}
                       selectedLabels={selectedLabels}
                       url={props.match.url}
                       queryParams={queryParams}
                       setShowForm={setShowForm}
                       setShowSendEmail={setShowSendEmail}/>
                : "Email Label Information is missing."
            }
          </Section>
        </WideColumn>
        <NarrowColumn>
          {
            showForm ?
              <EditTaskForm props={bexpConfig}
                            setTaskUpdated={setTaskUpdated}
                            gapi={gapi}/> : null
          }
          {
            showSendEmail
            && isAuthorized
            && objectLabelId ?
              <SendEmail gapi={gapi}
                         objectLabelId={objectLabelId}
                         setShowSendEmail={setShowSendEmail}
                         setShowForm={setShowForm}
                         objectLabelName={objectLabelName}
                         selectedLabels={selectedLabels}
                         labelListUpdated={false}/>
              : null}
        </NarrowColumn>
      </CorrespondenceContext.Provider>
    </ContentTwoColumn>
  )
}

export default Show;
