import React from 'react'

export const displayStatusBadge = (status) => {
  switch (status) {
    case "approved":
      return <span
        className="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded capitalize">{status}</span>
    case "pending":
      return <span
        className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded capitalize">{status}</span>
    case "rejected":
      return <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded capitalize">{status}</span>
    case "paid":
      // For this one we have an explicit request to render the phrase "Payment sent
      return <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded capitalize">payment sent</span>
  }
}

export const getRemainingStatuses = (status) => {
  const statusList = [
    "approved", "pending", "rejected", "paid"
  ]

  // todo: Clarify the status hops
  // pending to approved or rejected
  // approved to rejected

  return statusList.filter(status_item => status_item != status)
}

export const displayStatusButton = (status) => {
  switch (status) {
    case "approved":
      return {label: "Approve", icon: "CheckCircle"}
    case "pending":
      return {label: "Pending", icon: "PauseCircle"}
    case "rejected":
      return {label: "Reject", icon: "Unpublished"}
    case "paid":
      return {label: "Paid", icon: "Paid"}
  }
}

export const statusToNum = (status) => {
  switch (status) {
    case "approved":
      return 1
    case "pending":
      return 0
    case "rejected":
      return 2
    case "paid": 
      return 3
  }
}
