import moment from 'moment'

// From the modules source code:
// export const DEFAULT_HOSTNAME_UPLOAD = 'https://upload.box.com';
// export const DEFAULT_HOSTNAME_UPLOAD_APP = 'https://upload.app.box.com';
//
//     async isReachable(uploadHost: string) {
//         // The default upload host should always reachable
//         if (uploadHost === `${DEFAULT_HOSTNAME_UPLOAD}/` || uploadHost === `${DEFAULT_HOSTNAME_UPLOAD_APP}/`) {
//             return true;
//         } ..
//
// The preflight returns true if the uploadHost matches the default value configured in the module
export const box_host_name_upload = 'https://upload.box.com';
export const box_api_version_2 = '/api/2.0/';
export const box_host_name_api = 'https://api.box.com/2.0/';

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

export const getTaskColor = (status) => {
  let numberStatus = getNumberStatus(status)

  switch (numberStatus) {
    case 0: // 'not-started'
      return 'bg-outlineGray'
    case 1: // 'in-progress'
      return 'bg-lightBlue text-white text-base'
    case 2: // 'completed'
      return 'bg-trueGreen'
    case 3: // 'Note on Process Map'
      return ''
    case 4: // 'Question on Process Map'
      return ''
  }
}

export const statusColor = (status) => {
  let numberStatus = getNumberStatus(status)

  switch (numberStatus) {
    case 0:
      return 'bg-outlineGray'
    case 1:
      return 'bg-tenzingBlue'
    case 2:
      return 'bg-trueGreen'
  }
}

export const getTaskShape = (shape) => {
  switch (shape) {
    case 1: // 'Normal Task shape'
      return 'p-1 m-4 mb-10 rounded-none w-3/4 break-normal '
    case 2: // 'Note on Process Map'
      return 'bg-tenzingOrange text-white text-white text-base ring ring-inset ring-color-bg-lightGray p-1 m-4 mb-7 italic rounded-r-lg w-3/4 shadow-inner break-normal '
  }
}

// Handle both Task and Activity status values
export const getNumberStatus = (status) => {
  switch (status) {
    case "pending":
    case "not_started":
      return 0
    case "in_progress":
    case "in_process":
      return 1
    case "completed":
      return 2
  }
}

export const rankColor = (rank) => {
  switch (rank) {
    case 'low':
      return 'bg-trueGreen'
    case 'medium':
      return 'bg-yellow-500'
    case 'high':
      return 'bg-tenzingRed'
  }
}

export const setLocalStorage = (resp) => {
  window.localStorage.setItem("api_token", resp.token.toString());
  window.localStorage.setItem("company_id", resp.company_id.toString());
  window.localStorage.setItem("assignee_id", resp.assignee_id.toString());
  window.localStorage.setItem("user_uid", resp.uid.toString());
  window.localStorage.setItem("sn", resp.sn.toString());
}

export const removeLocalStorage = () => {
  // Remove all the stored items
  window.localStorage.removeItem("api_token");
  window.localStorage.removeItem("company_id");
  window.localStorage.removeItem("assignee_id");
  window.localStorage.removeItem("projectValue");
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("user_uid");
  window.localStorage.removeItem("quickNotes");
  window.localStorage.removeItem("email_account")
  window.localStorage.removeItem("sn")
}

export const isLocalStorageComplete = (setForceLogout) => {
  if (!window.localStorage.getItem("api_token")) {
    setForceLogout(true)
  } else if (!window.localStorage.getItem("company_id")) {
    setForceLogout(true)
  } else if (!window.localStorage.getItem("sn")) {
    setForceLogout(true)
  } else if (!window.localStorage.getItem("assignee_id")) {
    setForceLogout(true)
  } else if (!window.localStorage.getItem("user_uid")) {
    setForceLogout(true)
  }
}

export const createEmailLabel = (name) => {
  if (!window.localStorage.getItem("projectValue")) {
    return name
  } else {
    const project_state = JSON.parse(window.localStorage.getItem("projectValue"));
    return project_state?.project?.attributes?.identifier + "-" + name
  }
}

//format date to MM/DD/YYYY
export const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY')
}
export const formatDateWithTime = (date) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export const removeClassByPrefix = (node, prefix) => {
  var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
  node.className = node.className.replace(regx, '');
  return node;
}

export const removeClassByPostfix = (node, postfix) => {
  var regx = new RegExp('\\b[^ ]*[ ]?' + postfix + '\\b', 'g');
  node.className = node.className.replace(regx, '');
  return node;
}
