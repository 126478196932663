import React, {useState, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form';
import Button from 'Components/Common/Button';
import Section from 'Components/Common/Section'
import CategorySelector from '../../Components/CategorySelector';
import {toast} from 'react-toastify';
import {
  Field,
  FieldError,
  ErrorText
} from 'Components/Common/Forms';
import {client as apiClient} from 'utils/api';
import {useQuery, useMutation} from 'react-query';
import {
  projectLineItems,
  company as companyByIdQuery,
} from '../../../../utils/queryKeys';
import {
  getCompany
} from '../../../../utils/queries';
import CircularProgress from "@mui/material/CircularProgress";
import BoxFileExplorer from "../../../FileExplorer";
import {formFields} from "../../Utils/LineItem"
import FiFoWrapper from "../../../../Components/Common/BoxTree/FiFoWrapper"
import currency from 'currency.js';


function EditLineItem({
                        setShowForm,
                        categories,
                        projectId,
                        bexpConfig,
                        lineItemObject,
                        setRefetchData,
                        refetchData,
                      }) {
  const [doDisable, setDoDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategoryBreakdown, notifyCategoryBreakdown] = useState({});
  const [formData, setFormData] = useState({})

  // Template Files
  // FETCH Company data in order to extract the Box Folder Ids we need
  const companyId = parseInt(window.localStorage.getItem("company_id"))
  const company = useQuery(
    [companyByIdQuery, {companyId: companyId}],
    getCompany)


  useEffect(() => {
    // Update the form values
    if(lineItemObject?.attributes != undefined) {
      setFormData(lineItemObject?.attributes)
      formFields.forEach((fd) => {
        if (["project", "category_breakdown"].includes(fd)) {
          setValue(fd + '_id', lineItemObject.attributes[fd]?.id)
        } else if (["approved_budget_cents", "anticipated_cost_cents"].includes(fd)) {
          const formatted_value = parseInt(currency(lineItemObject.attributes[fd], {fromCents: true, precision: 2}))
          setFormData({
            ...formData, [fd]: formatted_value
          })
          setValue(fd, formatted_value)
        } else {
          setValue(fd, lineItemObject.attributes[fd])
        }
      })
    }
  }, [lineItemObject])


  const notifySuccess = () => toast.success("Budget Line Item updated successfully!");
  const notifyError = () => toast.error("Budget Line Item update unsuccessful.")

  // Update all non-Date fields
  const handleFormChange = (e) => {
    setFormData({
      ...formData, [e.target.id]: e.target.value
    })
    // Set the value
    setValue(e.target.id, e.target.value)
  }

  const {mutateAsync: sendData} = useMutation(putForm);

  async function putForm(data) {
    const {
      name,
      approved_budget_cents,
      anticipated_cost_cents
    } = data

    return await apiClient.put("/line_items/" + lineItemObject.id, {
      "line_item": {
        name: name,
        approved_budget_cents: parseInt(approved_budget_cents) * 100,
        anticipated_cost_cents: parseInt(anticipated_cost_cents) * 100,
        category_breakdown_id: parseInt(lineItemObject?.attributes?.category_breakdown?.id),
        project_id: parseInt(lineItemObject?.attributes?.project?.id)
      }
    })
  }

  const {control, register, handleSubmit, setValue, reset, formState: {errors}} = useForm();
  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      setDoDisable(true)
      await sendData(data)
      notifySuccess()
      setLoading(false)
      setDoDisable(false)
      setRefetchData(!refetchData)
    } catch (error) {
      setLoading(false)
      setDoDisable(false)
      notifyError();
      throw new Error(error)
    }
  }


  if (lineItemObject.isLoading
    || bexpConfig.access_token.isLoading
    || company.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  return (
    <Section>
      <h2 className="Section-h1 mb-4">Edit Line Item</h2>
      {/* Select the Category */}
      <CategorySelector
        categories={categories}
        notifyCategoryBreakdown={notifyCategoryBreakdown}
        derivedObject={lineItemObject}
      />

      {/* Create my Form */}
      <form method="post" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>

        {/* NAME */}
        <Field>
          <label htmlFor="name" className="text-tenzingGray">Name:</label>
          <input
            id="name"
            name="name"
            type="text"
            {...register("name", {required: true})}
            value={formData.name}
            onChange={handleFormChange}
            className={!errors.name ? "input-text" : 'input-text-error'}
          />
          <FieldError>
            {errors.name?.type === 'required' &&
              <ErrorText>Name is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* APPROVED BUDGET */}
        <Field>
          <label htmlFor="approved_budget_cents" className="text-tenzingGray">Approved Budget:</label>
          {/* <div className="currency-wrap"> */}
            <input
              id="approved_budget_cents"
              name="approved_budget_cents"
              type="number"
              {...register("approved_budget_cents", {required: true})}
              value={formData.approved_budget_cents}
              onChange={handleFormChange}
              className={`pl-4 currency-wrap ${!errors.approved_budget_cents ? "input-text" : "input-text-error"}`}
            />
          {/* </div> */}
          <FieldError>
            {errors.approved_budget_cents?.type === 'required' &&
              <ErrorText>Approved Line Item is required</ErrorText>
            }
          </FieldError>
        </Field>

        {/* ANTICIPATED COST */}
        <Field>
          <label htmlFor="anticipated_cost_cents" className="text-tenzingGray">Anticipated Cost:</label>
          <div className="currency-wrap">
            <input
              id="anticipated_cost_cents"
              name="anticipated_cost_cents"
              type="number"
              {...register("anticipated_cost_cents", {required: true})}
              value={formData.anticipated_cost_cents}
              onChange={handleFormChange}
              className={`pl-4 ${!errors.anticipated_cost_cents ? "input-text" : "input-text-error"}`}
            />
          </div>
          <FieldError>
            {errors.anticipated_cost_cents?.type === 'required' &&
              <ErrorText>Anticipated cost is required</ErrorText>
            }
          </FieldError>
        </Field>

        <section className="mt-4 mb-4">
          <Button buttonStyle="gray-transparent-button" type="button"
                  onClick={() => setShowForm(false)}>Cancel</Button>
          <Button buttonStyle={(loading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
                  disabled={loading || doDisable}>
            {
              doDisable ?
                <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
                : <></>
            }
            Save
          </Button>
          <Button variant="text"
                  buttonStyle="text-gray-600 float-right"
                  type="button"
                  onClick={() => {
                    reset(defaultValues)
                  }}
          >Clear</Button>
        </section>
      </form>

      {/* Template files add */}
      {
        !bexpConfig.access_token.isLoading
        && bexpConfig.access_token.isSuccess
        && bexpConfig.boxable?.data?.attributes != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != ''
        && company?.data?.attributes?.["templates_box_folderid"] != null ?
          <FiFoWrapper boxAccess={bexpConfig.access_token}
                       boxFolderId={company?.data?.attributes?.["templates_box_folderid"]}
                       parentFolderId={bexpConfig.boxable?.data?.attributes["cost_box_folderid"]}/>
          : null
      }

      {/* Import a Box Form */}
      {
        !bexpConfig.access_token.isLoading
        && bexpConfig.access_token.isSuccess
        && bexpConfig.boxable?.data?.attributes != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != undefined
        && bexpConfig.boxable?.data?.attributes["cost_box_folderid"] != '' ?
          <BoxFileExplorer props={bexpConfig}/> : <div>Not linked to Box</div>
      }
    </Section>
  );
}

export default EditLineItem
