import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import Button from 'Components/Common/Button';
import {useQuery} from 'react-query'
import Section from 'Components/Common/Section'
import
{
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
} from '@mui/material/';
import UpdateOrDeleteContactForm from 'Components/Contacts/Form/editContactForm'
import
  ContentTwoColumn, {WideColumn, NarrowColumn}
  from 'Components/Common/Layout/ContentTwoColumn'
import {allUsers, contact as contactQuery} from "../../utils/queryKeys";
import {getContact, getUsers} from "../../utils/queries";
import UserList from '../../Components/Users/List'
import EditUserForm from '../../Components/Users/Forms/editUserForm'
import {
  headCells
} from "./Resources/index"
import CreateUserForm from "../../Components/Users/Forms/createUserForm";

function Show() {
  const [showEditContact, setShowEditContact] = useState(true)
  const [showEditUser, setShowEditUser] = useState(false)
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [userId, setUserID] = useState(null)

  // Get Contact
  const {contactId} = useParams()
  // Use the contact ID with the contactQuery in order to properly mark the query as unique
  const contact = useQuery(
    [contactQuery, {id: contactId}],
    getContact
  )

  const users = useQuery(
    [allUsers, {contactId: contactId}],
    getUsers
  )

  if (contact.isLoading || users.isLoading) {
    return (<div className="lds-dual-ring"></div>)
  }

  const handleCreateUser = (event) => {
    setShowEditContact(false)
    setShowEditUser(false)
    setShowCreateUser(true)
  }


  return (
    <>
      {contact.isSuccess
      && users.isSuccess
      && users.data != undefined
      && contact.data?.data != undefined ?
        <ContentTwoColumn>
          <WideColumn>
            {/* Contact */}
            <Section>
              <h1 className="Section-h1">{
                contact.data.data.attributes['first_name'] + ' ' + contact.data.data.attributes['last_name']
              }</h1>
              <React.Fragment>
                <Box sx={{width: '100%', marginTop: '10px'}}>
                  <Paper sx={{width: '100%', mb: 2}}>
                    <TableContainer>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {headCells.map((headCell) => (
                              <TableCell
                                key={headCell.id.toString()}
                                align={headCell.id ? 'left' : 'center'}
                              >
                                {headCell.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={contact.data.data.id.toString()}>
                            <TableCell align="left">{contact.data.data.id}</TableCell>
                            <TableCell>
                              <div>{contact.data.data.attributes["first_name"] + ' ' + contact.data.data.attributes["last_name"]}</div>
                            </TableCell>
                            <TableCell>{contact.data.data.attributes['title']}</TableCell>
                            <TableCell>{contact.data.data.attributes["phone"]}</TableCell>
                            <TableCell>{contact.data.data.attributes["address"]}</TableCell>
                            <TableCell>{contact.data.data.attributes["company"]?.name}</TableCell>
                            {/*<TableCell>*/}
                            {/*  {*/}
                            {/*    <ul>*/}
                            {/*      {contact.data.data.attributes['users']?.map((usr) => {*/}
                            {/*        return (*/}
                            {/*          <li key={usr.id.toString()}>{usr.email}</li>*/}
                            {/*        )*/}
                            {/*      })}*/}
                            {/*    </ul>*/}
                            {/*  }*/}
                            {/*</TableCell>*/}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </React.Fragment>
            </Section>

            {/*  Users*/}

            {contact.data.permissions["update"]
            && users.data != undefined ?
              <Section>
                <div className="flex justify-between mb-2">
                  <h1 className="Section-h1">Linked Users</h1>
                  <Button buttonStyle="orange-transparent-button"
                          onClick={handleCreateUser}>
                    Create
                  </Button>
                </div>
                <UserList users={users.data}
                          setShowEditContact={setShowEditContact}
                          setShowEditUser={setShowEditUser}
                          setShowCreateUser={setShowCreateUser}
                          setUserID={setUserID}/>
              </Section>
              : null
            }
          </WideColumn>
          {
            showEditContact
            && contact.data.permissions["update"] ?
              <NarrowColumn>
                <Section>
                  <h2 className="Section-h1">Edit Contact</h2>
                  <UpdateOrDeleteContactForm contact={contact}/>
                </Section>
              </NarrowColumn>
              : null
          }
          {
            showEditUser
            && contact.data.permissions["update"] ?
              <NarrowColumn>
                <Section>
                  <h2 className="Section-h1">Edit User</h2>
                  <EditUserForm userId={userId}
                                contactId={contactId}
                                setShowEditContact={setShowEditContact}
                                setShowEditUser={setShowEditUser}/>
                </Section>
              </NarrowColumn>
              : null
          }
          {
            showCreateUser
            && contact.data.permissions["update"] ?
              <NarrowColumn>
                <Section>
                  <h2 className="Section-h1">Add User</h2>
                  <CreateUserForm contactId={contactId}
                                  setShowEditContact={setShowEditContact}
                                  setShowCreateUser={setShowCreateUser}
                                  setShowEditUser={setShowEditUser}/>
                </Section>
              </NarrowColumn>
              : null
          }
        </ContentTwoColumn> : null
      }
    </>
  )
}

export default Show
