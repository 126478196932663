import React from 'react'

function DocumentTitle({object}) {
  return (
    object.isLoading ? null :
      <h1 className="Section-h1">
        {object.data.attributes['name']} Documents
      </h1>
  )
}

export default DocumentTitle
