import React, {useState} from 'react'
import {useMutation} from "react-query";
import {updateProject} from "../../../utils/queries";
import {invertProjectStatus, mapStatusToActions} from "../Utils";
import CircularProgress from "@mui/material/CircularProgress";
import StratosButton from "../../Common/Button";
import {toast} from "react-toastify";

function ActionColumn({
                        project,
                        setResetQuery
                      }) {
  const [doDisable, setDoDisable] = useState(false);


  const notifySuccess = (msg) => {
    toast.success(msg);
  }
  const notifyError = (msg) => {
    toast.error(msg)
  }

  // Update Project
  const {isLoading: updateProjectLoading, mutateAsync: patchProject} = useMutation(updateProject, {
    onSuccess: (data, variables, context) => {
      notifySuccess("Project status updated.")
      //  I am not sure if i need to refetch something on success here
      setDoDisable(false)
      setResetQuery(true)
    },
    onError: (error, variables, context) => {
      notifyError("Project status update failed.")
      setDoDisable(false)
      console.log("project update failed", error)
    }
  });

  const handleProjectUpdateClick = (projectId, status) => {
    // Update the project
    const tdata = {
      status: status
    }

    setDoDisable(true)
    patchProject([updateProject, {projectId: parseInt(projectId), data: tdata}])
  }

  return (
    <StratosButton
      buttonStyle={(updateProjectLoading || doDisable) ? "gray-solid-button" : "orange-solid-button"}
      onClick={
        () => handleProjectUpdateClick(project.id, invertProjectStatus[project.attributes["status"]])
      }
      disabled={updateProjectLoading || doDisable}>
      {
        (updateProjectLoading || doDisable) ?
          <CircularProgress size="1rem" color="inherit" style={{marginRight: "0.5em"}}/>
          : <></>
      }
      {mapStatusToActions[project.attributes["status"]]}
    </StratosButton>
  )
}

export default ActionColumn
