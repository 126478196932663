import React, {useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import {ProjectContext} from "../../context/ProjectContext";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {Tooltip} from '@material-ui/core';
import {sidebarConfig, calcIcon} from '../../Components/NavBar/SidebarResources';
import {sidebarNoProjectConfig} from "../../Components/NavBar/SidebarNoProjectResources";


function ProjectSidebar({}) {
  const {selectedProject, setSelectedProject} = useContext(ProjectContext);
  const [isOpen, setOpen] = useState(false)

  const SideBarLinks = () => {
    const preroute = (selectedProject.value !== undefined)
      ? '/projects/' + selectedProject.value
      : '';

    return (
      <ul className="text-sm text-white space-y-4">
        {
          sidebarConfig.map(item => {
            // const route = item.route.includes("contacts")
            //   ? `/${item.route}`
            //   : `${preroute}/${item.route}`
            const route = `${preroute}/${item.route}`

            return (
              <li
                key={item.route}
                className={(selectedProject.value == undefined || selectedProject.value == '') ? 'hidden' : 'list-item'}>
                <Link to={route}>
                <span className="hidden lg:inline xl:hidden">
                  <Tooltip arrow placement={"right"} title={item.name}>
                    {calcIcon(item.icon)}
                  </Tooltip>
                </span>
                  <span className="lg:hidden xl:inline">{calcIcon(item.icon)}<span
                    className="sidebar-link-decoration">{item.name}</span></span>
                </Link>
              </li>
            )
          })
        }
      </ul>
    )
  }


  const NoProjectLinks = () => {
    return (
      <ul className="text-sm text-white space-y-4">
        {
          sidebarNoProjectConfig.map(item => {
            const route = item.route

            return (
              <li
                key={item.route}
                className={(selectedProject.value == undefined || selectedProject.value == '') ? 'list-item' : 'hidden'}>
                <Link to={route}>
                <span className="hidden lg:inline xl:hidden">
                  <Tooltip arrow placement={"right"} title={item.name}>
                    {calcIcon(item.icon)}
                  </Tooltip>
                </span>
                  <span className="lg:hidden xl:inline">{calcIcon(item.icon)}<span
                    className="sidebar-link-decoration">{item.name}</span></span>
                </Link>
              </li>
            )
          })
        }
      </ul>
    )
  }

  return (
    <nav className="p-4 bg-tenzingBrown h-full">
      <div className="flex lg:hidden">
        {!isOpen ? <MenuIcon sx={{color: "white"}} onClick={() => setOpen(true)}/> :
          <>
            <ul className={`${isOpen ? "" : "space-x-10 ml-3 pt-5 z-20"} `}>
              <SideBarLinks/>
            </ul>
            <CloseIcon sx={{color: "white"}} onClick={() => setOpen(false)}/>
          </>
        }
      </div>
      <div className="hidden lg:flex">
        <SideBarLinks/>
      </div>
      <div className="hidden lg:flex">
        <NoProjectLinks/>
      </div>
    </nav>
  )
}

export default ProjectSidebar

