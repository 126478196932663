import React, {useState, useContext} from 'react'
import {Link, useHistory} from 'react-router-dom'
import
{
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import EnhancedTableHead from '../Utils/EnhancedTableHead';
import {
  getComparator,
  stableSort
} from '../Utils/index'
import Button from "@mui/material/Button";
import {ProjectContext} from "../../../context/ProjectContext";
import {toast} from "react-toastify";
import ActionColumn from "./ActionColumn";

function List({
                items,
                permissions,
                setResetQuery
              }) {
  const navigate = useHistory();

  const {selectedProject, setSelectedProject} = useContext(ProjectContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');

  console.log('items', items)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (project, event) => {
    setSelectedProject(project);
    window.localStorage.removeItem("projectValue");
    // Add the entire project object in the localstorage
    project['project'] = JSON.parse(event.target.dataset.projectdata)
    window.localStorage.setItem("projectValue", JSON.stringify(project));
    navigate.push('/projects/' + project.value + '/home');
  }


  return (
    <React.Fragment>
      <Box sx={{width: '100%', borderColor: 'grey'}}>
        <Paper sx={{width: '100%', mb: 2}}>
          <TableContainer>
            <Table stickyHeader>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(items, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((project) => (
                    <TableRow key={project.id.toString()}>
                      <TableCell align="left">{project.id}</TableCell>
                      <TableCell>
                        <Link to="#"
                              data-projectdata={JSON.stringify(project)}
                              onClick={(e) => handleClick({
                                value: project.id,
                                label: project.attributes.name
                              }, e)}
                              className="underline">
                          {project.attributes["name"]}
                        </Link>
                      </TableCell>
                      <TableCell>{project.attributes['description']}</TableCell>
                      <TableCell>{project.attributes["manager"]?.email}</TableCell>
                      <TableCell>{project.attributes["approver"]?.email}</TableCell>
                      <TableCell>
                        {
                          (permissions?.['update']) ?
                            <ActionColumn project={project}
                                          setResetQuery={setResetQuery}/> : null

                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default List
