import React from 'react'
import {formatDate, formatDateWithTime} from 'utils/globals'

function EmailHeaderBox({
                          from,
                          to,
                          cc,
                          subject,
                          when
                        }) {
  return (
    <div className="border-gray-100 mx-0 bg-gray-100 p-2.5">
      <div className="flex space-x-1">
        <span className="w-20 text-right font-bold mr-2">From:</span>
        <h3>{from}</h3>
      </div>
      <div className="flex space-x-1">
        <span className="w-20 text-right font-bold mr-2">To:</span>
        <h3>{to}</h3>
      </div>
      <div className="flex space-x-1">
        <span className="w-20 text-right font-bold mr-2">Cc:</span>
        <h3>{cc}</h3>
      </div>
      <div className="flex space-x-1">
        <span className="w-20 text-right font-bold mr-2">Subject:</span>
        <h3>{subject}</h3>
      </div>
      <div className="flex space-x-1">
        <span className="w-20 text-right font-bold mr-2">Date:</span>
        <h3>{formatDateWithTime(when)}</h3>
      </div>
    </div>
  )
}

export default EmailHeaderBox;
