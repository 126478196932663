// Home
export const home = "/home"
// Projects
export const allProjects = "api/projects"
export const project = "api/projects/:id"
// Box
export const boxAccessToken = "box_authorizes/token"
// Risks
export const allRisks = "api/risks"
export const risk = "api/risks/:id"
export const projectRisks = "api/projects/:id/risks"
// Tasks
export const allTasks = "api/tasks"
export const task = "api/tasks/:id"
export const companyTasks = "api/company/:id/tasks"
export const criticalTasks = "api/tasks/critical"
export const myTasks = "api/tasks/mine"
export const projectTasks = "api/projects/:id/tasks"
export const projectMyTasks = "api/projects/:id/tasks?assignee_id=:id"
export const projectTasksNotPending = "api/projects/:id/tasks?status=not_pending"
export const projectTasksNotPendingIndex = "api/projects/:id/tasks?status=not_pending&view=index"
export const projectCriticalTasks = "api/projects/:id/tasks/critical"
export const projectPendingTasks = "api/projects/:id/tasks/pending"
export const projectCriticalTasksIndex = "api/projects/:id/tasks/critical?status=not_pending&view=index"
export const startedTasks = "api/tasks/status/2"
// Schedule
export const projectSchedule = "api/schedule/all"
export const ownersSchedule = "api/schedule/owners"
export const designSchedule = "api/schedule/design"
export const contractorSchedule = "api/schedule/contractor"
// Cost
export const allCategories = "api/categories"
export const allSubCategories = "api/sub_categories"
export const allCategoryBreakdowns = "api/category_breakdowns"
export const allContracts = "api/contracts"
export const projectLineItems = "api/projects/:id/line_items"
export const projectBudgetItems = "api/projects/:id/budget_items"
export const projectInvoices = "api/projects/:id/invoices"
export const invoice = "api/invoices/:id"
export const contracts = "api/contracts"
export const projectContractBreakdowns = "api/projects/:id/contract_breakdowns"
export const contractBreakdown = "/contract_breakdowns/:id"
// Activities/Schedule
export const allActivities = "api/activities"
export const projectActivities = "api/projects/:id/activities"
export const activity = "api/activities/:id"
// Contacts
export const allContacts = "api/contacts"
export const contact = "api/contacts/:id"
export const companyContacts = "api/companies/:id/contacts"
export const projectContacts = "api/projects/:id/contacts"
// Companies
export const allCompanies = "api/companies"
export const company = "api/companies/:id"
export const overdueCompanyTasks = "api/companies/:id/tasks/overdue"
export const companyProjects = "companies/:id/projects"
// Users
export const allUsers = "api/users"
export const user = "api/users/:id"
export const companyUsers = "api/companies/:id/users"
export const projectUsers = "api/projects/:id/users"
export const companyLoggedInUser = "api/companies/:id/users/loggedin"
export const usersSignOut = "users/sign_out"
// Project Teams
export const allProjectTeams = "api/project_teams"
export const assignProjectTeams = "api/companies/:id/project_teams/assign"
// Roles
export const getRoleByName = "user_roles?name="
export const companyUserRoles = "companies/:id/user_roles"
// AWS Clients
export const companyAWS = "/api/companies/:id/aws_clients"
// Stats
export const companyStats = "api/companies/:id/stats"
export const projectStats = "api/projects/:id/stats"
// Cloudinary Clients
export const companyCloudinary = "/api/companies/:id/cloudinary_clients"
// Phases
export const companyPhasePool = "api/companies/:id/phase_pools"
export const projectPhases = "api/projects/:id/phases"
export const projectPhase = "api/phases/:id"
export const phaseSetup = "phases/setup"
// Modules
export const phasePoolModulePool = "api/phase_pools/:id/module_pools"
export const phaseModules = "api/phases/:id/process_modules"
export const processModule = "api/process_modules/:id"
export const projectPhaseModules = "api/projects/:id/phases/:id/process_modules"
export const phaseModulesSetup = "process_modules/setup"

// Steps
export const processStepsSetup = "process_steps/setup"
export const modulePoolStepPool = "api/module_pools/:id/step_pools"
export const moduleSteps = "api/process_modules/:id/process_steps"
// Email Labels
export const emailLabels = "api/email_labels"
export const companyEmailLabels = "api/companies/:id/email_labels"
export const emailLabel = "api/email_labels/:id"
export const emailLabelAdd = "api/email_labels"
// Google
export const googleMailToken = "api/companies/:id/gmtoken"
export const googleCalendarToken = "api/companies/:id/gctoken"



