import React, {useState} from 'react'
import {Switch, Route} from 'react-router-dom'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from 'Pages/Navigation/Navbar'
import ProjectSidebar from 'Pages/Navigation/ProjectSidebar'
import HomeIndex from 'Pages/Home/Index'
import ProjectsShow from 'Pages/Projects/Show'
import ProjectsNew from 'Pages/Projects/New'
import ProjectsIndex from 'Pages/Projects/Index'
import TasksIndex from 'Pages/Tasks/Index'
import TasksShow from 'Pages/Tasks/Show'
import RisksIndex from 'Pages/Risks/Index'
import RisksShow from 'Pages/Risks/Show'
import CostsIndex from 'Pages/Costs/Index'
import CorrespondenceIndex from 'Pages/Correspondence/Index'
import DocumentsIndex from 'Pages/Documents/Index'
import ProcessIndex from 'Pages/Process/Index'
import ScheduleIndex from 'Pages/Schedule/Index'
import ContactsIndex from 'Pages/Contacts/Index'
import UsersIndex from 'Pages/Users/Index'
import ContactsShow from 'Pages/Contacts/Show'
import GoogleCalendar from 'Pages/Calendar/GoogleCalendar'
import FilesIndex from 'Pages/Files/Index'
import TemplatesIndex from 'Pages/Templates/Index'
import LateRisks from 'Pages/Projects/LateRisks/index.js'
import MyCompanyTasks from 'Pages/Tasks/Mine.js';
import {ProjectContext} from "./app/context/ProjectContext";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {
  DiscoveryDocs,
  SCOPES,
  REACT_APP_CLIENT_ID
} from "utils/google/Auth"

function AuthenticatedApp() {

  const [selectedProject, setSelectedProject] = useState({value: '', name: 'Loading...'});

  // https://www.dolthub.com/blog/2022-05-04-google-signin-migration/
  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId={REACT_APP_CLIENT_ID}>
        <ProjectContext.Provider value={{selectedProject, setSelectedProject}}>
          <Navbar/>
          <div className="flex-col lg:flex lg:flex-row">
            <div className="">
              <ProjectSidebar/>
              {/* ENV: {process.env.NODE_ENV} */}
              <ToastContainer
                position="bottom-left"
                autoClose={3000}
                closeOnClick
                rtl={false}
                pauseOnHover
              />
            </div>
            <div className="m-5 w-full">
              <AppRoutes/>
            </div>
          </div>
        </ProjectContext.Provider>
      </GoogleOAuthProvider>
    </React.Fragment>
  )
}

function AppRoutes() {
  return (
    <Switch>
      <Route path="/projects/new" component={ProjectsNew}/>
      <Route path="/projects/:projectId/tasks/mine" component={TasksIndex}/>
      <Route path="/projects/:projectId/tasks" component={TasksIndex}/>
      <Route path="/projects/:projectId/tasks/critical" component={TasksIndex}/>
      <Route path="/projects/:projectId/tasks/pending" component={TasksIndex}/>
      <Route path="/projects/:projectId/risks" component={RisksIndex}/>
      <Route path="/projects/:projectId/costs" component={CostsIndex}/>
      <Route path="/projects/:projectId/correspondence" component={CorrespondenceIndex}/>
      <Route path="/projects/:projectId/contacts" component={ContactsIndex}/>
      <Route path="/projects/:projectId/calendar" component={GoogleCalendar}/>
      <Route path="/projects/:projectId/schedule" component={ScheduleIndex}/>
      <Route path="/projects/:projectId/documents" component={DocumentsIndex}/>
      <Route path="/projects/:projectId/files" component={FilesIndex}/>
      <Route path="/projects/:projectId/templates" component={TemplatesIndex}/>
      <Route path="/projects/:projectId/manager/first" component={ProjectsNew}/>
      <Route path="/projects/:projectId/approver/first" component={ProjectsNew}/>
      <Route path="/projects/:projectId/image" component={ProjectsNew}/>
      <Route path="/projects/:projectId/process" component={ProcessIndex}/>
      <Route path="/projects/:projectId/phases/:id" component={ProcessIndex}/>
      <Route path="/projects/:projectId/home" component={ProjectsShow}/>
      <Route path="/projects/archived" component={() => <ProjectsIndex status={"archived"}/>}/>
      <Route path="/projects" component={ProjectsIndex}/>
      <Route path="/tasks/:taskId" component={TasksShow}/>
      <Route path="/risks/:riskId" component={RisksShow}/>
      <Route path="/correspondence" component={CorrespondenceIndex}/>
      <Route path="/companies/:id/tasks/overdue" component={MyCompanyTasks}/>
      <Route path="/companies/:id/tasks/today" component={MyCompanyTasks}/>
      <Route path="/companies/:id/tasks/pending" component={MyCompanyTasks}/>
      <Route path="/companies/:id/risks" component={LateRisks}/>
      <Route path="/companies/:id/tasks" component={LateRisks}/>
      <Route path="/companies/:id/users" component={UsersIndex}/>
      <Route path="/companies/:id/tasks/mine_all" component={LateRisks}/>
      <Route path="/contacts/:contactId" component={ContactsShow}/>
      <Route path="/contacts" component={ContactsIndex}/>
      <Route path="/correspondence" component={CorrespondenceIndex}/>
      <Route path="/documents" component={DocumentsIndex}/>
      <Route path="/templates" component={TemplatesIndex}/>
      <Route path="/" component={HomeIndex}/>
      <Route path="*">
        <p>404 Not Found</p>
      </Route>
    </Switch>
  )
}


export default AuthenticatedApp
