import React, {useRef} from 'react'
import {Link} from 'react-router-dom'
import
{
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Box,
  Paper,
  TablePagination,
} from '@mui/material/';
import LabelIcon from '@mui/icons-material/Label';
import {useMutation, useQueryClient} from "react-query";
import {
  gmailCreateLabel,
  gmailLabelList,
  gmailLabelKey,
} from "../../../utils/google/Queries";
import {
  updateTask,
  updateRisk
} from "../../../utils/queries";
import {
  projectTasksNotPendingIndex,
  projectRisks,
} from "../../../utils/queryKeys";
import ReportIcon from "@mui/icons-material/Report";
import Tooltip from "@mui/material/Tooltip";
import {toast} from "react-toastify";

function CreateGmailLabel({
                            object,
                            objectType,
                            gapi,
                            iconBadge = false,
                            setIsLabelCreated
                          }) {
  const queryClient = useQueryClient();
  const existingTaskId = useRef(null)

  const notifySuccess = (msg) => {
    toast.success(msg);
  }
  const notifyError = (msg) => {
    toast.error(msg)
  }

  // Google Create Gmail Label
  const {mutateAsync: postGmailLabel} = useMutation(gmailCreateLabel, {
    onSuccess: (data, variables, context) => {
      // Update the task
      const tdata = {
        gmail_labelid: data.data.id
      }

      notifySuccess('Email Tag created')
      if(objectType === 'task') {
        patchTask([updateTask, {taskId: parseInt(object.id), data: tdata}])
      } else if(objectType === 'risk') {
        patchRisk([updateRisk, {riskId: parseInt(object.id), data: tdata}])
      }
    },
    onError: (error, variables, context) => {
      console.error('Gmail Label creation failed:', error.message)

      if (error.response.status === 409) {
        console.log('Gmail Label already exists:', error.response.data.message)
        notifySuccess('Email Tag created')
        // The label already exists. I will fetch id of the label.
        // Create the label
        getGmailLabelList([gmailLabelKey, {
          access_token: gapi?.auth?.getToken()?.access_token
        }])
      } else {
        notifyError('Email Tag creation failed')
      }
    }
  });

  // Get the list of Gmail Labels. Iterate over the list and pick the one with the same name
  const {mutateAsync: getGmailLabelList} = useMutation(gmailLabelList, {
    onSuccess: (data, variables, context) => {
      const lblName = object?.project?.identifier + "/" + object.name;
      data.data.labels.forEach(label => {
        if (label.name === lblName) {
          existingTaskId.current = label.id
        }
      })

      // Update the task
      const tdata = {
        gmail_labelid: existingTaskId.current
      }

      if(objectType === 'task') {
        patchTask([updateTask, {taskId: parseInt(object.id), data: tdata}])
      } else if(objectType === 'risk') {
        patchRisk([updateRisk, {riskId: parseInt(object.id), data: tdata}])
      }
    },
    onError: (error, variables, context) => {
      console.error('Gmail label list fetch failed.', error.message)
    }
  });

  // Update Task
  const {mutateAsync: patchTask} = useMutation(updateTask, {
    onSuccess: (data, variables, context) => {
      notifySuccess("Email tag saved.")
      if (iconBadge) {
        setIsLabelCreated(true)
      } else {
        queryClient.invalidateQueries([projectTasksNotPendingIndex, {
          projectId: object?.project?.id?.toString(),
          status: 'not_pending',
          view: 'index'
        }]);
      }
    },
    onError: (error, variables, context) => {
      console.error(`Failed to update ${objectType} with label id.`, error.message)
      notifyError('Email Tag save failed')
      if (error?.response?.data?.message != undefined
        && error?.response?.data?.message != '') {
        console.error(error.response.data.message)
      }
    }
  });

  // Update Risk
  const {mutateAsync: patchRisk} = useMutation(updateRisk, {
    onSuccess: (data, variables, context) => {
      notifySuccess("Email tag saved.")
      if (iconBadge) {
        setIsLabelCreated(true)
      } else {
        queryClient.invalidateQueries(
          [projectRisks, {projectId: object?.project?.id?.toString()}]
        )
      }
    },
    onError: (error, variables, context) => {
      console.error(`Failed to update ${objectType} with label id.`, error.message)
      notifyError('Email Tag save failed')
      if (error?.response?.data?.message != undefined
        && error?.response?.data?.message != '') {
        console.error(error.response.data.message)
      }
    }
  });

  const handleClick = () => {
    const gdata = {
      "labelListVisibility": "labelShow",
      "messageListVisibility": "show",
      "name": object?.project?.identifier + "/" + object.name
    }

    // Create the label
    postGmailLabel([gmailLabelKey, {
      data: gdata,
      access_token: gapi?.auth?.getToken()?.access_token,
    }])
  }

  return (
    !iconBadge ?
      <Link
        component="button"
        variant="body2"
        onClick={handleClick}
      >
        <div className="flex flex-col items-center mr-4">
          <LabelIcon fontSize="small"/>
          <u>Create Mail Label</u>
        </div>
      </Link> :
      <Tooltip component={'span'} title="Create Email Label">
        <IconButton type="button"
                    sx={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                      backgroundColor: "#E00000",
                      marginRight: "0.5em",
                      "&:hover": {
                        color: "#E00000",
                        backgroundColor: "#fff",
                      },
                    }}
                    onClick={handleClick}
                    aria-label="label">
          <ReportIcon/>
        </IconButton>
      </Tooltip>
  )
}

export default CreateGmailLabel;
