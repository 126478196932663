export const durationInDays = (start, end) => {
  let startDay = moment(new Date(start));
  let endDay = moment(new Date(end));

  return Math.abs(endDay.diff(startDay, 'days'));
}

export const mapStatusToActions = {
  active: "Archive",
  archived: "Activate"
}

export const invertProjectStatus = {
  active: "archived",
  archived: "active"
}

export const phaseOrder = () => {
  return [
    'Pre-Design',
    'Design Team Selection',
    'Design Activities',
    'Non-Design Activities',
    'Contractor Selection',
    'Construction Phase'
  ]
}

export const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Project #',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Project Name',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'manager',
    numeric: false,
    disablePadding: false,
    label: 'Manager Email',
  },
  {
    id: 'approver',
    numeric: false,
    disablePadding: false,
    label: 'Approver Email',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "name") {
    if (b.attributes["name"] < a.attributes["name"]) {
      return -1;
    }
    if (b.attributes["name"] > a.attributes["name"]) {
      return 1;
    }
    return 0;
  } else {
    if (b.attributes[orderBy] < a.attributes[orderBy]) {
      return -1;
    }
    if (b.attributes[orderBy] > a.attributes[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
